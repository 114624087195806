import { useCallback } from 'react'
import { useHistory } from 'react-router'

import { useTranslation } from 'react-i18next'

export const usePdfFileActions = () => {
  const { push } = useHistory()
  const { t } = useTranslation()

  const clearDocumentLS = useCallback(() => {
    localStorage.removeItem('initialDocValue')
    localStorage.removeItem('currentDocValue')
  }, [])

  const onOpen = (documentId: string) => {
    clearDocumentLS()
    push(`/${t('pdf')}/${documentId}`)
  }

  return { onOpen }
}
