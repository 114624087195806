import FileComponent from './FileComponent'
import SkeletonFileComponent from './SkeletonFileComponent'
import ListFile from './ListFile'

import './style.scss'

const classes = {
  listView: {
    wrapper: 'Document-ListView-wrapper',
    preview: 'Document-ListView-preview',
    selectButton: 'Document-ListView-selectButton',
    base: {
      wrapper: 'Document-ListView-base-wrapper',
      title: 'Document-ListView-base-title-wrapper',
      relativeTime: 'Document-ListView-base-relativeTime',
      signbar: { wrapper: 'Document-ListView-base-signbar-wrapper', progress: 'Document-ListView-base-signbar-progress' },
    },
  },
  thumbnail: {
    wrapper: 'Document-Thumbnail-wrapper',
    signaturesTag: {
      wrapper: 'Document-Thumbnail-signaturesTag-wrapper',
      icon: 'Document-Thumbnail-signaturesTag-icon',
    },
    selectButton: 'Document-Thumbnail-selectButton',
    sideActions: {
      wrapper: 'Document-Thumbnail-sideActions-wrapper',
      button: 'Document-Thumbnail-sideActions-button',
      more: {
        wrapper: 'Document-Thumbnail-sideActions-dropdown-wrapper',
        button: 'Document-Thumbnail-sideActions-dropdown-button',
        dropdownList: 'Document-Thumbnail-sideActions-dropdown-dropdownList',
        dropdownListItem: 'Document-Thumbnail-sideActions-dropdown-dropdownListItem',
        dropdownListItemIcon: 'Document-Thumbnail-sideActions-dropdown-dropdownListItem-icon',
        dropdownListItemDivider: 'Document-Thumbnail-sideActions-dropdown-dropdownListItem-divider',
      },
    },
    preview: 'Document-Thumbnail-preview',
    base: {
      wrapper: 'Document-Thumbnail-base-wrapper',
      title: 'Document-Thumbnail-base-title',
      relativeTime: 'Document-Thumbnail-base-relativeTime',
      premiumIcon: 'Document-Thumbnail-base-premiumIcon',
      signbar: { wrapper: 'Document-Thumbnail-base-signbar-wrapper', progress: 'Document-Thumbnail-base-signbar-progress' },
    },
  },
}

export { FileComponent, SkeletonFileComponent, ListFile, classes }

