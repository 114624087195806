import { Icon } from 'components/common'
import { useDocumentFileActions } from 'hooks/useDocumentFileActions/useDocumentFileActions'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './style.scss'

const classes = {
  container: 'actions-container',
  row: 'actions-row',
  primary: 'primary-button',
  secondary: 'secondary-button',
}


export const SignatureActions = ({ data, type }) => {
  const { onOpenSigned, onDownloadSigned } = useDocumentFileActions()

  const { t } = useTranslation()

  const structure = [
    [
      {
        type: 'secondary',
        iconName: 'preview',
        label: t('preview'),
        action: () => onOpenSigned(data.id),
        disabled: false,
      },
      {
        type: 'secondary',
        iconName: 'download',
        label: t('download'),
        action: () => onDownloadSigned(data.id),
        disabled: false,
      },
    ],
  ]

  const renderButton = (key, type, iconName, label, action, disabled) => {
    return (
      !disabled && (
        <button title={label} key={key} onClick={() => action()} type="button" className={classes[type]}>
          <Icon iconName={iconName} fill="#9ca4b5" />
        </button>
      )
    )
  }

  return (
    <div className={classes.container}>
      {structure.map((row, rowIndex) => (
        <div key={rowIndex} className={classes.row}>
          {row.map((item, index) => renderButton(index, item.type, item.iconName, item.label, item.action, item.disabled))}
        </div>
      ))}
    </div>
  )
}

