import { Icon, MenuPopover } from 'components/common'
import { usePdfFileActions } from 'hooks/usePdfFileActions'
import { useDocumentFileActions } from 'hooks/useDocumentFileActions/useDocumentFileActions'
import React, {useCallback, useState} from 'react'
import { useTranslation } from 'react-i18next'
import './style.scss'
import { useDocumentsDuplicate } from 'queries/documents/useDocumentDuplicate'
import { useHistory } from 'react-router-dom'
import { useDetails } from 'components/DetailsSidebar/provider/DetailsProvider'
import { useConfirmPopup } from 'providers/ConfirmPopupProvider'
import { POPUP_CONFIRM_TRASH_FILE } from 'constants/confirmation-popups'
import { useDocumentsUpdate } from 'queries/documents'

const classes = {
  container: 'actions-container',
  row: 'actions-row',
  primary: 'primary-button',
  secondary: 'secondary-button',
}


export const PdfActions = ({ data }) => {
  const { onOpen } = usePdfFileActions()
  const { onOpenSigned, onDownloadSigned } = useDocumentFileActions()
  const duplicate = useDocumentsDuplicate()
  const { push, location } = useHistory()
  const { clear } = useDetails()
  const { t } = useTranslation()
  const { popup, close, setIsLoading } = useConfirmPopup()
  const update = useDocumentsUpdate()
  const [anchorEl, setAnchorEl] = useState(null)

  const deleteDocument = useCallback(
      id => {
        const status = 'trashed'
        const POPUP = POPUP_CONFIRM_TRASH_FILE

        popup({
          ...POPUP,
          onConfirm: () => {
            setIsLoading(true)
            update.mutate(
                { id: id, data: { status } },
                {
                  onSuccess: () => {
                    close()
                    clear()
                  },
                  onSettled: () => setIsLoading(false),
                }
            )
          },
          onCancel: close,
        })
      },
      [close, clear, popup, setIsLoading, update]
  )

  const structure = [
    [
      {
        type: 'primary',
        iconName: 'preview',
        label: t('preview'),
        action: () => onOpen(data.id),
        disabled: data.signatureRequest,
      },
      {
        type: 'primary',
        iconName: 'preview',
        label: t('preview'),
        action: () => onOpenSigned(data.id),
        disabled: !data.signatureRequest,
      },
    ],
  ]

  const menuItems = [
    {
      type: 'secondary',
      iconName: 'download',
      label: t('download_signed'),
      handleClick: () => onDownloadSigned(data.id),
      disabled: !data.signatureRequest,
    },
    {
      type: 'secondary',
      icon: 'move',
      label: t('move_document'),
      handleClick: () => push(`${location.pathname}?file=${data.id}&action=move`),
      disabled: true,
    },
    {
      type: 'secondary',
      icon: 'duplicate',
      label: t('duplicate'),
      handleClick: () => {
        duplicate.mutate(data)
        clear()
      },
      disabled: data.signatureRequest,
    },
    {
      type: 'secondary',
      icon: 'trash',
      label: t('delete'),
      action: () => deleteDocument(data.id),
      disabled: true,
    },
  ].filter(item => !item.disabled)

  const renderButton = (key, type, iconName, label, action, disabled) => {
    return (
        !disabled && (
            <button title={label} key={key} onClick={() => action()} type="button" className={classes[type]}>
              <Icon iconName={iconName} fill="#9ca4b5" /> {label}
            </button>
        )
    )
  }

  return (
      <div className={classes.container}>
        {structure.map((row, rowIndex) => (
            <div key={rowIndex} className={classes.row}>
              {row.map((item, index) => renderButton(index, item.type, item.iconName, item.label, item.action, item.disabled))}
              <button title={'more'} key={'more'} onClick={e => setAnchorEl(e.currentTarget)} type="button" className={classes.more}>
                <Icon iconName="more" fill="#9ca4b5" />
              </button>
              <MenuPopover
                  anchorEl={anchorEl}
                  handleClose={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  menuItems={menuItems}
              />
            </div>
        ))}
      </div>
  )
}

