import React, { FunctionComponent } from 'react'
import { useHistory } from 'react-router'
import { DateTime } from 'luxon'
import useStore, { SelectedTemplateIdSelector, InitializeWizardAction } from '___store'

import { DOCUMENT_GENERATION_MODE, DateTimeC } from '___types'
import { useTemplate } from '___hooks'
import { Clock, Person, Spinner, VerticalEllipsis } from 'assets/svgIconComponents'
import { Button, Dropdown } from 'components/CasusComponents'
import {
  MainLayoutRightPaneTemplateInfoSectionProps,
  MainLayoutRightPaneTemplateActionsSectionProps,
  mainLayoutRightPaneTemplateClasses as classes,
} from '../..'

const TemplateInfoSection: FunctionComponent<MainLayoutRightPaneTemplateInfoSectionProps> = React.memo(({ owner, created }) => {
  const { hour = 0, minute = 0, second = 0 } = created?.c || {}
  const m = minute + second / 60
  const h = hour + m / 60
  const sectionClass = `${classes.section} ${classes.info.wrapper}`
  return (
    <div className={sectionClass}>
      <div className={classes.info.snippet}>
        <Person />
        <span>{owner}</span>
      </div>
      <div className={classes.info.snippet}>
        <Clock h={h} m={m} />
        <span>{created?.toLocaleString(DateTime.DATETIME_SHORT)}</span>
      </div>
    </div>
  )
})

TemplateInfoSection.displayName = 'RightPane-Template-InfoSection'

type TemplateActionsSectionUseStoreHookResultType = { initializeWizard: InitializeWizardAction }

const TemplateActionsSection: FunctionComponent<MainLayoutRightPaneTemplateActionsSectionProps> = React.memo(({ templateId }) => {
  const history = useHistory()
  const { initializeWizard } = useStore('initializeWizard') as TemplateActionsSectionUseStoreHookResultType
  const sectionClass = `${classes.section} ${classes.actions.wrapper}`
  return (
    <div className={sectionClass}>
      <Button
        className={classes.actions.button}
        primary
        onClick={() => {
          initializeWizard({ mode: DOCUMENT_GENERATION_MODE, templateId })
          history.push(`/dev/document/${templateId}/new`)
        }}
      >
        {/* <Preview /> */}
        Use
      </Button>
      <Button className={classes.actions.button} onClick={() => console.log('EDIT!')}>
        Edit
      </Button>
      <Dropdown className={classes.actions.dropdown} replaceCaret={<VerticalEllipsis />}>
        pera
        <div />
        {/* <DropdownOption id="pera" label="mika" />
        <DropdownOption id="zika" label="franjo" />
        <DropdownOption id="omg" label="wtf" /> */}
      </Dropdown>
    </div>
  )
})

TemplateActionsSection.displayName = 'RightPane-Template-ActionsSection'

type UseStoreHookResultType = { selectedTemplateId: SelectedTemplateIdSelector }

const Template: FunctionComponent = React.memo(() => {
  const { selectedTemplateId } = useStore('selectSelectedTemplateId') as UseStoreHookResultType
  const { isLoading, data: template } = useTemplate(selectedTemplateId)

  const { name: title, author: { firstName = '', lastName = '' } = {}, created } = template || {}
  const owner = [firstName, lastName].join(' ')
  const createdDateTime = DateTime.fromMillis(isLoading ? 0 : created!?._seconds * 1000) as DateTimeC

  console.log(isLoading, template)

  return (
    <div className={classes.wrapper} data-loading={isLoading || undefined}>
      {isLoading ? (
        <Spinner fill="rgba(30, 144, 255, 0.15)" strokeWidth="1" strokeFill={0.25} />
      ) : (
        <>
          <span className={classes.title}>{title}</span>
          <TemplateInfoSection owner={owner} created={createdDateTime} />
          <TemplateActionsSection templateId={template!.id} />
          <div className={classes.section}>SHARE</div>
        </>
      )}
    </div>
  )
})

Template.displayName = 'RightPane-Template'

export default Template
