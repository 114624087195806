import React, { FunctionComponent, useState, useMemo, useEffect } from 'react'
import useStore, { WizardAnsweringSelector, WizardQuestionsSelector } from '___store'

import { Button } from 'components/CasusComponents'
import {
  WizardLayoutRightPaneEditorPreviewFocusModeToggleProps,
  wizardLayoutRightPaneEditorPreviewFocusModeToggleClasses as classes,
  wizardLayoutRightPaneEditorPreviewClasses,
  wizardLayoutRightPaneEditorMarkerSegmentsClasses,
  wizardLayoutRightPaneEditorMarkerTextClasses,
} from '../../../..'

type UseStoreHookResultType = {
  wizardQuestions: WizardQuestionsSelector
  wizardAnswering: WizardAnsweringSelector
}

export const FocusModeToggle: FunctionComponent<WizardLayoutRightPaneEditorPreviewFocusModeToggleProps> = React.memo(() => {
  const { wizardQuestions, wizardAnswering } = useStore('selectWizardQuestions', 'selectWizardAnswering') as UseStoreHookResultType

  const [toggle, setToggle] = useState(false)

  const focusedMarkersIds = useMemo(() => {
    if (!toggle) return ''
    const answeringQuestionId = wizardAnswering?.split(':')[1]
    if (!answeringQuestionId) return ''
    const relevantQuestion = wizardQuestions?.find(question => question.id === answeringQuestionId)
    if (!relevantQuestion) return ''
    const relevantOptionMarkers = relevantQuestion.optionGroups.reduce(
      (resultingMarkerIds, optionGroup) =>
        optionGroup.options.reduce((accumulated, option) => accumulated.concat(option.markers), resultingMarkerIds),
      [] as string[]
    )
    return Array.from(new Set(relevantOptionMarkers.concat(relevantQuestion.markers))).join('; ')
  }, [toggle, wizardQuestions, wizardAnswering])

  useEffect(() => {
    const editorPreviewElement = document.querySelector(`.${wizardLayoutRightPaneEditorPreviewClasses.wrapper}`) as HTMLDivElement
    if (!editorPreviewElement) return () => {}
    if (toggle) editorPreviewElement.dataset.focusMode = ''
    if (!toggle) delete editorPreviewElement.dataset.focusMode
    return () => delete editorPreviewElement.dataset.focusMode
  }, [toggle])

  useEffect(() => {
    let timeout = undefined as unknown as NodeJS.Timeout
    let relevantMarkerElements = [] as HTMLElement[] // For some reason HTMLMarkElement does not exist :/
    const markerIdArray = focusedMarkersIds.split('; ')
    if (markerIdArray.length)
      timeout = setTimeout(() => {
        const segmentsMarkers = Array.from(
          document.querySelectorAll(`.${wizardLayoutRightPaneEditorMarkerSegmentsClasses.wrapper}`)
        ) as HTMLDivElement[]
        const textMarkers = Array.from(document.querySelectorAll(`.${wizardLayoutRightPaneEditorMarkerTextClasses.wrapper}`)) as HTMLElement[] // For some reason HTMLMarkElement does not exist :/
        const filteredSegmentsMarkers = segmentsMarkers.filter(marker => markerIdArray.includes(marker.id.replace(';', '')))
        const filteredTextMarkers = textMarkers.filter(marker => markerIdArray.includes(marker.id))
        const allFocusedMarkers = (filteredSegmentsMarkers as HTMLElement[]).concat(filteredTextMarkers)
        relevantMarkerElements = allFocusedMarkers
        relevantMarkerElements.forEach(marker => (marker.dataset.focused = ''))
        if (relevantMarkerElements.length) relevantMarkerElements[0].scrollIntoView({ behavior: 'smooth', block: 'center' })
      }, 0)
    return () => {
      if (timeout) clearTimeout(timeout)
      relevantMarkerElements.forEach(marker => delete marker.dataset.focused)
    }
  }, [focusedMarkersIds])

  return (
    <div className={classes.wrapper}>
      <div>
        <Button className={classes.button} tertiary onClick={() => setToggle(prev => !prev)}>
          Focus Mode: {toggle ? 'ON' : 'OFF'}
        </Button>
      </div>
    </div>
  )
})

FocusModeToggle.displayName = 'WizardLayout-RightPane-Editor-Preview-FocusModeToggle'

export default FocusModeToggle
