import { useMutation, useQueryClient } from 'react-query'

import { DocumentsFilter, IDocument, MAPPED_DOCUMENTS_FILTERS } from '___types'
import { documentsAPI } from '___api'
import { QUERY_KEYS } from '___queries'
import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { queryKeys } from 'queries'

export type CreateVariables = { document: IDocument; category: DocumentsFilter }
const createDocumentMutationFunction = (variables: CreateVariables) => documentsAPI.createDocument(variables.document, variables.category)
const updateDocumentMutationFunction = (document: IDocument) => documentsAPI.updateDocument(document)

export const useMutateDocument = (id?: string | null) => {
  const { currentFolder } = useCurrentFolder('docs')
  const { type, id: folderId } = currentFolder
  console.log(type, folderId)
  const queryClient = useQueryClient()
  const documentCreateMutation = useMutation([QUERY_KEYS.DOCUMENT, id], createDocumentMutationFunction, {
    // onMutate: () => console.log('MUTATE!'),
    onSuccess: () => {
      Object.values(MAPPED_DOCUMENTS_FILTERS).forEach(filter => {
        queryClient.refetchQueries({ queryKey: [QUERY_KEYS.DOCUMENTS, filter] })
      })
      queryClient.refetchQueries([queryKeys.DOCUMENTS, { type, folderId }])
    },
  })
  const documentUpdateMutation = useMutation([QUERY_KEYS.DOCUMENT, id], updateDocumentMutationFunction, {
    // onSuccess: () => console.log('SUCCESS!'),
  })
  return { create: documentCreateMutation.mutate, update: documentUpdateMutation.mutate }
}

export default useMutateDocument
