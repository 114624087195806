import { useMemo } from 'react'
import useStore, { WizardLocationByTypeAndIdSelector, NavigateQuestionnaireToAction } from '___store'

import {
  CUSTOM_TEXT_SPLIT,
  LOCATION_TYPES,
  OptionValueTypeUnionType,
  SEGMENT_TYPES,
  TextChunks,
  TextLocation,
  extractPropertiesFromCustomText,
} from '___types'
import { useEditorPreviewContext } from '..'
import { IteratedChunks, getSpanStyles, parseChunkContent } from '../helpers'
import { getFormattedAnswerValue } from '___store/storeSegments/wizard/typified/helpers'

type UseStoreHookResultType = { wizardLocationByTypeAndId: WizardLocationByTypeAndIdSelector; navigateQuestionnaireTo: NavigateQuestionnaireToAction }
type UseTextMarkerResultType = { content: IteratedChunks; keep: boolean; markerNavigate: (() => void) | null }

export const useTextMarker = (id: string, range: [number, number], chunks?: TextChunks): UseTextMarkerResultType => {
  const editorContext = useEditorPreviewContext()
  const contextChildMarkers = editorContext.locations?.text[id]

  const offsetMarkers = useMemo(() => {
    if (!contextChildMarkers?.length) return []
    return contextChildMarkers.map(marker => ({ type: marker.type, id: marker.id, range: marker.range.map(r => r - range[0]) } as TextLocation))
  }, [contextChildMarkers, range])

  const { wizardLocationByTypeAndId, navigateQuestionnaireTo } = useStore(
    `selectWizardLocationByTypeAndId[${LOCATION_TYPES.TEXT},${id}]`,
    'navigateQuestionnaireTo'
  ) as UseStoreHookResultType
  const { questionId, keep, replace } = wizardLocationByTypeAndId || ({} as WizardLocationByTypeAndIdSelector)!

  const content = useMemo(() => {
    if (replace) {
      const replaceText = replace
        .split(CUSTOM_TEXT_SPLIT)
        .map(replaceString => {
          const properties = extractPropertiesFromCustomText(replaceString, 'markerReplace')
          const replacementText = properties.type
            ? getFormattedAnswerValue(properties.type as OptionValueTypeUnionType, properties.value)
            : replaceString
          return replacementText ?? null
        })
        .join('; ')
      return [{ type: SEGMENT_TYPES.TEXT_CHUNK, text: replaceText, styles: chunks ? getSpanStyles(chunks) : [] }] as IteratedChunks
    }
    return chunks?.length ? parseChunkContent(offsetMarkers, chunks) : []
  }, [replace, offsetMarkers, chunks])
  const markerNavigate = useMemo(() => {
    if (!questionId) return null
    if (editorContext) return () => navigateQuestionnaireTo(`:${questionId}:`)
    return null // Implement template-automation mode question navigation
  }, [questionId, editorContext, navigateQuestionnaireTo])
  const useTextMarkerReturn = { content, keep, markerNavigate }

  return useTextMarkerReturn
}

export default useTextMarker
