import { useQuery } from 'react-query'
import { queryKeys } from 'queries/queryKeys'
import ExternalApi from 'api/external.api'

export const useFetchExternalServiceEntries = selectedServices => {
  const query = useQuery([queryKeys.EXTERNAL_SERVICE_ENTRY, selectedServices], () => ExternalApi.getExternalApiEntries(selectedServices), {
    // enabled: false,
    refetchOnWindowFocus: false,
  })
  return query
}
