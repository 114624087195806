import { CASUS_INPUT_TYPES } from 'components/CasusComponents'
import { ReverseMap } from '.'
import { OPTION_VALUE_TYPES } from './types.template'

// =============================================================================== //
// ============================== CONSTANTS & TYPES ============================== //
// =============================================================================== //
export const TEMPLATE_AUTOMATION_MODE = 'template-automation' as const
export const DOCUMENT_GENERATION_MODE = 'document-generation' as const
export const TEMPLATES_DIRECTORY = 'templates' as const
export const DOCUMENTS_DIRECTORY = 'documents' as const
export const WIZARD_MODE_DIRECTORY_MAP = {
  [TEMPLATE_AUTOMATION_MODE]: TEMPLATES_DIRECTORY,
  [DOCUMENT_GENERATION_MODE]: DOCUMENTS_DIRECTORY,
} as const
export type WizardMode = keyof typeof WIZARD_MODE_DIRECTORY_MAP
export const DIRECTORY_WIZARD_MODE_MAP = Object.entries(WIZARD_MODE_DIRECTORY_MAP).reduce(
  (map, [mode, directory]) => Object.assign(map, { [directory]: mode as WizardMode }),
  {}
) as ReverseMap<typeof WIZARD_MODE_DIRECTORY_MAP>
export type Directory = keyof typeof DIRECTORY_WIZARD_MODE_MAP
export const INPUT_TYPE_MAP = {
  [OPTION_VALUE_TYPES.STRING]: CASUS_INPUT_TYPES.MULTILINE,
  [OPTION_VALUE_TYPES.NUMBER]: CASUS_INPUT_TYPES.NUMBER,
  [OPTION_VALUE_TYPES.DATE]: CASUS_INPUT_TYPES.DATE_TIME,
  [OPTION_VALUE_TYPES.DATE_TIME]: CASUS_INPUT_TYPES.DATE_TIME,
  [OPTION_VALUE_TYPES.CURRENCY]: CASUS_INPUT_TYPES.CURRENCY,
} as const
// =============================================================================== //

// =============================================================================== //
// ============================= REGULAR EXPRESSIONS ============================= //
// =============================================================================== //
export const SUB_QUESTION_TO_MATCH = new RegExp('(?<questionId>^.[^:]+):(?<optionGroupId>.[^:]+):(?<optionId>.[^:]+$)', 's')
// export const ANSWER_VALUE_MATCH = new RegExp(`^(?<input>${CASUS_KEYSTRINGS.INPUT}:)?(?<id>(_external_[^:]+:[^:]+)|([^:]*)){1}(:(?<value>.*))?$`, 's')
export const ANSWER_VALUE_MATCH = new RegExp(`^(?<id>(_external_[^:]+:[^:]+)|([^:]*)){1}(:(?<value>.*))?$`, 's')

export const ANSWERING_IDS_MATCH = new RegExp('(?<questionLayoutGroupId>^[^:]*):(?<questionId>[^:]+):(?<orderString>[^:]*$)', 's')
// =============================================================================== //

// =============================================================================== //
// ====================== HELPER FUNCTION CONSTANTS & TYPES ====================== //
// =============================================================================== //
export const FIND_WITH_INDEX_NOT_FOUND = [undefined, -1] as const
export const COMPLEX_GETTER_NOT_FOUND = [undefined, undefined, undefined, -1] as const
// =============================================================================== //
