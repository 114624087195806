import { UseMutateFunction, UseQueryResult } from 'react-query'

import { IDocument } from '___types'
import { useFetchDocument, useMutateDocument } from '___queries'
import { CreateVariables } from '___queries/documents/useMutateDocument'

// ======================= USE DOCUMENT OVERLOAD ======================= //
function useDocument(id?: null): { create: UseMutateFunction<IDocument, unknown, CreateVariables, void> }
function useDocument(id: string): UseQueryResult<IDocument> & { update: UseMutateFunction<IDocument, unknown, IDocument, void> }
// ===================================================================== //
function useDocument(id?: string | null) {
  const document = useFetchDocument(id)
  const { create, update } = useMutateDocument(id)
  if (!id) return { create }
  return Object.assign({}, document, { update })
}

export default useDocument
