import React from 'react'
import { classes } from '.'
import { useTranslation } from 'react-i18next'

const EmptyTemplates: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className={classes.empty.wrapper}>
      <p className={classes.empty.text}>{t('no_templates_available')}</p>
    </div>
  )
}

export default EmptyTemplates

