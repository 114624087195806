import i18next, { i18n as i18nInstance } from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'

const createI18n = (): i18nInstance => {
  const i18n = i18next.createInstance().use(initReactI18next)

  i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .init({
      fallbackLng: 'de_CH',
      supportedLngs: ['de_CH', 'en_GB', 'fr_FR'],
      backend: {
        loadPath: `${process.env.REACT_APP_BASE_API_URL_VERSIONLESS}/v1/locale/{{lng}}/{{ns}}`,
        request: function (options, url, payload, callback) {
          fetch(url)
            .then((res) => (res.ok ? res.json() : Promise.reject('err')))
            .then((result) =>
              callback(null, { status: 200, data: result.data })
            )
            .catch((err) => callback(err, { status: 500, data: '' }))
        },
      },
      debug: false,
      react: { useSuspense: true },
      // have a common namespace used around the full app
      defaultNS: 'translation',
      keySeparator: false, // we use content as keys
      interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ',',
      },
    })

  return i18n
}

export const i18n = createI18n()
