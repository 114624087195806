import React, { FunctionComponent } from 'react'
import { Redirect } from 'react-router'
import { useTranslation } from 'react-i18next'

import { WizardLayoutLeftPaneQuestionnairePostGenerationProps, wizardLayoutLeftPaneQuestionnairePostGenerationClasses as classes } from '../../..'

const QuestionnairePostGeneration: FunctionComponent<WizardLayoutLeftPaneQuestionnairePostGenerationProps> = React.memo(
  ({ isLoading, isFetching }) => {
    const { t } = useTranslation()
    return (
      <div className={classes.wrapper}>
        Post Generation
        <Redirect to={`/${t('documents')}`} />
      </div>
    )
  }
)

QuestionnairePostGeneration.displayName = 'WizardLayout-Questionnaire-PostGeneration'

export default QuestionnairePostGeneration
