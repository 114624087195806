import { PropsWithChildren } from 'react'

import Grid from './Grid'

import './style.scss'

const classes = {
  wrapper: 'Casus-Components-Grid-wrapper',
  error: 'Casus-Components-Grid-error',
} as const

export type GridProps = PropsWithChildren<{ className?: string; isLoading?: boolean; isFetching?: boolean; error?: string; title?: string }>

export { Grid, classes }
export default Grid
