import React from 'react'
import { useTranslation } from 'react-i18next'
import { Switch, Route } from 'react-router-dom'
import SignatureListPage from './view/SignatureList/SignatureListPage'
import { SignaturePreviewPage } from './view/SignaturePreview/SignaturePreviewPage'

const SignaturePage: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Switch>
      <Route exact path={`/${t('signatures')}`} component={SignatureListPage} />
      <Route exact path={`/${t('signatures')}/:id`} component={SignaturePreviewPage} />
    </Switch>
  )
}

export default SignaturePage