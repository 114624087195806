import React, { FunctionComponent } from 'react'
import useStore, { WizardTitleSelector } from '___store'

import { CasusLogo } from 'assets/svgIconComponents'
import { BackButton } from '.'
import { WizardLayoutHeaderProps, wizardLayoutHeaderClasses as classes } from '../..'

type UseStoreHookResultType = { wizardTitle: WizardTitleSelector }

export const Header: FunctionComponent<WizardLayoutHeaderProps> = React.memo(({ isLoading, isFetching }) => {
  const { wizardTitle } = useStore('selectWizardTitle') as UseStoreHookResultType
  return (
    <div className={classes.wrapper}>
      <CasusLogo className={classes.casusLogo} />
      <span className={classes.documentTitle}>{wizardTitle}</span>
      <BackButton />
    </div>
  )
})
Header.displayName = 'WizardLayout-Header'

export default Header
