import { useQuery, QueryFunctionContext, useQueryClient, InfiniteData } from 'react-query'

import { IDocument, ITemplate } from '___types'
import { documentsAPI, templatesAPI } from '___api'
import { QUERY_KEYS } from '___queries'
// import { extractDataStructureCustomStyles } from '___utilities/dataStructure'
// import { evaluateAllMarkers } from '___store/storeSegments/wizard/helpers/index'
// import { applyAnswerValuesToDataStructure } from 'Wizard/helpers'
import { MAPPED_DOCUMENTS_FILTERS } from '___types/types.document'

const getDocumentQueryFunction = ({ queryKey }: QueryFunctionContext) => documentsAPI.getDocument(queryKey[1] as string)
const getTemplateQueryFunction = ({ queryKey }: QueryFunctionContext) => templatesAPI.getTemplate(queryKey[1] as string)

// const generatePartialPreviewInfo = (document?: IDocument, template?: ITemplate) => {
// if (!(document && template)) return null
// console.log(document)
// const { answers } = document
// const { dataStructure, locations, questions, cssData } = template
// const pseudoState = { mode: 'document-generation', locations, questions, answers }
// console.log(pseudoState.answers)
// const evaluatedMarkers = evaluateAllMarkers(pseudoState).locations
// const previewDataStructure = applyAnswerValuesToDataStructure(dataStructure, evaluatedMarkers, questions, answers)
// const partialPreview = previewDataStructure.segments.slice(0, 20)
// const customStyles = extractDataStructureCustomStyles({ segments: partialPreview })
// console.log(partialPreview)
// console.log(cssData)
// console.log(customStyles)
// return null
// }

export const useFetchDocument = (id?: string | null) => {
  const queryClient = useQueryClient()
  const documentQuery = useQuery({
    queryKey: [QUERY_KEYS.DOCUMENT, id],
    queryFn: getDocumentQueryFunction,
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    placeholderData: () => {
      const allDocuments = Object.values(MAPPED_DOCUMENTS_FILTERS).reduce((allDocuments: IDocument[], filter) => {
        const documentsQuery = queryClient.getQueryData([QUERY_KEYS.DOCUMENTS, filter]) as InfiniteData<IDocument[]>
        const documents = (documentsQuery?.pages || []).reduce((filterDocuments, page) => filterDocuments.concat(page), [])
        return allDocuments.concat(documents) as IDocument[]
      }, [])
      return allDocuments.find(document => document.id === id)
    },
    onSuccess: (document?: IDocument) => {
      const { templateId } = document || {}
      const template = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, templateId]) as ITemplate
      if (template) {
        // const partialPreview = generatePartialPreviewInfo(document, template)
        // console.log(partialPreview)
      }
    },
  })
  const { data: document } = documentQuery
  const templateId = document?.templateId
  const templateQuery = useQuery({
    queryKey: [QUERY_KEYS.TEMPLATE, templateId],
    queryFn: getTemplateQueryFunction,
    enabled: Boolean(templateId),
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    // onSuccess: template => {
    //   // const partialPreview = generatePartialPreviewInfo(document!, template)
    // },
  })
  const { data: template } = templateQuery
  if (false) console.log(template)
  return documentQuery
}

export default useFetchDocument
