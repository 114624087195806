import React from 'react'
// import { useUser } from 'queries/user/useUser'
import SettingsSidebar from 'components/Sidebar/SettingsSidebar'
import { PageAssetsContainer } from '../../Authenticated.page.styles'
import { useTranslation } from 'react-i18next'
// import { USER_TYPE_CLIENT_ADMIN, USER_TYPE_LAWYER_ADMIN } from 'constants/user-types'

const AccountSettingsSidebar: React.FC = () => {
  // const user = useUser()
  const {t} = useTranslation()
  
  const urlLinks = [
    { urlName: t('profile'), url: '' },
    { urlName: 'E-Mail', url: '/email' },
    { urlName: t('u_password'), url: `/${t('password')}` },
    { urlName: t('language'), url: '/lang' },
    { urlName: t('security'), url: '/2fa' },
  ]
  // if (user?.type === USER_TYPE_CLIENT_ADMIN || user?.type === USER_TYPE_LAWYER_ADMIN) {
  //   urlLinks.push({ urlName: 'Abo', url: '/abo' })
  // }
  return (
    <SettingsSidebar baseUrl={`/${t('settings')}`} urlLinks={urlLinks}>
      <PageAssetsContainer> </PageAssetsContainer>
    </SettingsSidebar>
  )
}

export default AccountSettingsSidebar
