import React, { useMemo } from 'react'
import useStore from '___store'

// import { VerticalEllipsis, Warning } from 'assets/svgIconComponents'
// import { Input, Select } from 'components'
import { CASUS_CLASSES } from 'Wizard/constants'
import {
  createTextContent,
  // extractStylesFromChunks,
  parseTextChunks,
} from 'Wizard/helpers'
import { TextMarker } from '../Markers'
// import { ActionButton } from '.'
import { classes } from '../..'

// const actionOptions = {
//   removeSegment: null,
//   editText: null,
//   insertAbove: null,
//   insertBelow: null,
//   emphasis: null,
//   alignment: null,
//   customStyle: null,
// }

const mapChunkToSpan = (chunk, id, i = 0) => {
  const { type, id: markerId, text, classes, textChunks } = chunk
  if (type === 'marker') return <TextMarker key={markerId} id={markerId} textChunks={textChunks} />
  if (classes?.length < 2) return text
  return (
    <span key={`paragraph-${id}-chunk-${i}`} className={classes.join(' ')}>
      {text}
    </span>
  )
}

const Paragraph = React.memo(({ id, styles, chunks }) => {
  const { textMarkerLocationsByParentId: markers = [], numberingById: numbering } = useStore(
    `selectTextMarkerLocationsByParentId[${id}]`,
    `selectNumberingById[${id}]`
  )

  // paragraphInfo: { styles = '', textChunks = [] } = {},
  // segmentById: { customStyle = '', styles = [], textChunks = [] } = {},
  // beingConfigured, // possible optimization??
  // configStack,
  // replaceParagraphContent,
  // setConfiguring,
  // removeConfiguring,
  // `selectParagraphInfo[${id}]`,
  // `selectSegmentById[${id}]`,
  // 'selectConfigStack',
  // `selectBeingConfigured[${id}]`, // possible optimization??
  // 'replaceParagraphContent',
  // 'setConfiguring',
  // 'removeConfiguring'

  // const beingConfigured = useMemo(() => configStack.find(config => config.id === id), [configStack, id])

  const [numberingSystem, numberingValue] = useMemo(() => (numbering || '').split(':'), [numbering])

  const content = useMemo(
    () => createTextContent(parseTextChunks(markers, chunks)).map((chunk, i) => mapChunkToSpan(chunk, id, i)),
    [markers, chunks, id]
  )

  // const textContent = useMemo(() => textChunks.reduce((acc, { text }) => acc.concat(text), ''), [textChunks])

  // const paragraphMode = mode === 'template-creation' ? 'edit' : 'preview'
  // const paragraphMode = 'preview'

  // const segmentClass = [CASUS_CLASSES.segmentDiv, classes.segment.wrapper, customStyle, ...styles].filter(s => s).join(' ')
  // const textareaClass = [CASUS_CLASSES.textarea, classes.segment.paragraph.textarea].filter(s => s).join(' ')
  // const inputClass = [customStyle, ...styles].filter(s => s).join(' ')
  // const paragraphClass = [CASUS_CLASSES.paragraphSegment, classes.segment.paragraph.wrapper, customStyle, ...styles].filter(s => s).join(' ')
  // const controlsClass = [CASUS_CLASSES.paragraphControls, classes.segment.paragraph.controlsWrapper].filter(s => s).join(' ')
  // const overlayClass = [CASUS_CLASSES.textareaOverlay, classes.segment.paragraph.textareaOverlay, customStyle, ...styles].filter(s => s).join(' ')
  // const styled = extractStylesFromChunks(textChunks)
  // const marked = markers?.length

  // const warnings = ['Confirming changes will remove any styles and markers from this paragraph! Discard changes to keep existing styles and markers.']
  // if (marked) warnings.unshift('Markers detected!')
  // if (styled.length) warnings.unshift(`Styles detected! [${styled.join(', ')}]`)

  const paragraphClass = [
    CASUS_CLASSES.segmentDiv,
    CASUS_CLASSES.paragraphSegment,
    classes.segment.wrapper,
    classes.segment.paragraph.wrapper,
    styles,
  ]
    .filter(s => s)
    .join(' ')

  return (
    // <div className={segmentClass} data-configuring={beingConfigured} data-mode={paragraphMode}>
    // {beingConfigured ? (
    //   <>
    //     <Input
    //       id={id}
    //       className={textareaClass}
    //       inputClass={inputClass}
    //       defaultValue={textContent}
    //       onChange={val => {
    //         replaceParagraphContent({ id, text: val })
    //         removeConfiguring({ id })
    //       }}
    //       onBlur={(event, modified) => (!modified ? removeConfiguring({ id }) : event.target?.focus())}
    //       onDiscard={() => removeConfiguring({ id })}
    //       needsConfirmation
    //       placeholder="Paragraph text content goes here..."
    //       multiline
    //       autofocus
    //     />
    //     {warnings.length > 1 ? (
    //       <div className={classes.segment.warning}>
    //         <Warning />
    //         <pre>{warnings.join('\n')}</pre>
    //       </div>
    //     ) : null}
    //   </>
    // ) : (
    <pre id={id} className={paragraphClass} tabIndex={-1} style={{ counterSet: numberingSystem && `${numberingSystem} ${numberingValue}` }}>
      {content}
    </pre>
    // <pre id={id} className={paragraphClass} tabIndex={-1}>
    //   {paragraphMode === 'edit' ? (
    //     <div className={controlsClass}>
    //       <Select
    //         className={classes.segment.paragraph.actionSelect}
    //         options={actionOptions}
    //         render={key => <ActionButton key={`Paragraph-${id}-ActionSelect-${key}`} button={key} id={id} />}
    //         chooseMode
    //         chooseRender={<VerticalEllipsis />}
    //         noCaret
    //         noFocus
    //         directionPriority="horizontal"
    //         horizontalDirection="left"
    //         cornerAnchor={false}
    //       />
    //     </div>
    //   ) : null}
    //   {content}
    // </pre>
    // )}
    // {/* </div> */}
  )
})

Paragraph.displayName = 'Wizard-Editor-Segment-Paragraph'

export default Paragraph
