import ACTION_TYPES from '../../actionTypes'

const actionCreators = {
  // resetWizard: () => ({ type: ACTION_TYPES.WIZARD.WIZARD_RESET }),
  // initializeWizard: payload => ({ type: ACTION_TYPES.WIZARD.WIZARD_INITIALIZED, payload }),
  setDirty: () => ({ type: ACTION_TYPES.WIZARD.STATE_POLLUTED }),

  // ///////////////////////////////////////////////////////////////////////////////// //
  // //////////////////////////////////// GENERAL //////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////// //
  updateTitle: payload => ({ type: ACTION_TYPES.WIZARD.TITLE_UPDATED, payload }),
  // ///////////////////////////////////////////////////////////////////////////////// //
  // ////////////////////////////////// CONFIGURING ////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////// //
  removeConfiguring: payload => ({ type: ACTION_TYPES.WIZARD.CONFIGURING_REMOVED, payload }),
  // ///////////////////////////////////////////////////////////////////////////////// //
  // //////////////////////////////// QUESTION LAYOUT //////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////// //
  addNewSeparator: () => ({ type: ACTION_TYPES.WIZARD.QUESTION_LAYOUT_SEPARATOR_ADDED }),
  addNewQuestionLayoutGroup: () => ({ type: ACTION_TYPES.WIZARD.QUESTION_LAYOUT_GROUP_ADDED }),
  updateQuestionLayoutGroup: payload => ({ type: ACTION_TYPES.WIZARD.QUESTION_LAYOUT_GROUP_UPDATED, payload }),
  unpackQuestionLayoutGroup: payload => ({ type: ACTION_TYPES.WIZARD.QUESTION_LAYOUT_GROUP_UNPACKED, payload }),
  removeQuestionLayoutGroup: payload => ({ type: ACTION_TYPES.WIZARD.QUESTION_LAYOUT_GROUP_REMOVED, payload }),
  assignQuestion: payload => ({ type: ACTION_TYPES.WIZARD.QUESTION_ASSIGNED, payload }),
  unassignQuestion: payload => ({ type: ACTION_TYPES.WIZARD.QUESTION_UNASSIGNED, payload }),
  // ///////////////////////////////////////////////////////////////////////////////// //
  // /////////////////////////////////// QUESTIONS /////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////// //
  addNewQuestion: payload => ({ type: ACTION_TYPES.WIZARD.NEW_QUESTION_ADDED, payload }),
  updateQuestion: payload => ({ type: ACTION_TYPES.WIZARD.QUESTION_UPDATED, payload }),
  removeQuestion: payload => ({ type: ACTION_TYPES.WIZARD.QUESTION_REMOVED, payload }),
  // updateQuestionComplex: payload => ({ type: ACTION_TYPES.WIZARD.QUESTION_COMPLEX_UPDATED, payload }),
  // assignMarkersAutomatically: payload => ({ type: ACTION_TYPES.WIZARD.QUESTION_MARKERS_ASSIGNED_AUTOMATICALLY, payload }),
  // ///////////////////////////////////////////////////////////////////////////////// //
  // ///////////////////////////////// OPTION GROUPS ///////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////// //
  addNewOptionGroup: payload => ({ type: ACTION_TYPES.WIZARD.NEW_OPTION_GROUP_ADDED, payload }),
  updateOptionGroup: payload => ({ type: ACTION_TYPES.WIZARD.OPTION_GROUP_UPDATED, payload }),
  removeOptionGroup: payload => ({ type: ACTION_TYPES.WIZARD.OPTION_GROUP_REMOVED, payload }),
  // ///////////////////////////////////////////////////////////////////////////////// //
  // //////////////////////////////////// OPTIONS //////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////// //
  addNewOption: payload => ({ type: ACTION_TYPES.WIZARD.NEW_OPTION_ADDED, payload }),
  updateOption: payload => ({ type: ACTION_TYPES.WIZARD.OPTION_UPDATED, payload }),
  removeOption: payload => ({ type: ACTION_TYPES.WIZARD.OPTION_REMOVED, payload }),
  addNewSubQuestion: payload => ({ type: ACTION_TYPES.WIZARD.NEW_SUB_QUESTION_ADDED, payload }),
  // ///////////////////////////////////////////////////////////////////////////////// //
  // /////////////////////////////// ADVANCED QUESTION /////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////// //
  updateAdvancedQuestionConfiguration: payload => ({ type: ACTION_TYPES.WIZARD.ADVANCED_QUESTION_CONFIGURATION_UPDATED, payload }),
  updateQuestionVisibility: payload => ({ type: ACTION_TYPES.WIZARD.QUESTION_VISIBILITY_UPDATED, payload }),
  updateQuestionRuleLogic: payload => ({ type: ACTION_TYPES.WIZARD.QUESTION_RULE_LOGIC_UPDATED, payload }),
  addNewConditionalRule: payload => ({ type: ACTION_TYPES.WIZARD.QUESTION_CONDITIONAL_RULE_ADDED, payload }),
  updateConditionalRule: payload => ({ type: ACTION_TYPES.WIZARD.QUESTION_CONDITIONAL_RULE_UPDATED, payload }),
  removeConditionalRule: payload => ({ type: ACTION_TYPES.WIZARD.QUESTION_CONDITIONAL_RULE_REMOVED, payload }),
  makeIntoSubQuestion: payload => ({ type: ACTION_TYPES.WIZARD.QUESTION_MADE_INTO_SUB_QUESTION, payload }),
  removeSubQuestionStatus: payload => ({ type: ACTION_TYPES.WIZARD.SUB_QUESTION_STATUS_REMOVED, payload }),
  // ///////////////////////////////////////////////////////////////////////////////// //
  // //////////////////////////////////// ANSWERS //////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////// //
  goToNextQuestion: () => ({ type: ACTION_TYPES.WIZARD.NEXT_QUESTION_SET }),
  goToPreviousQuestion: () => ({ type: ACTION_TYPES.WIZARD.PREVIOUS_QUESTION_SET }),
  // answerWithOption: payload => ({ type: ACTION_TYPES.WIZARD.QUESTION_ANSWERED_WITH_OPTION, payload }),
  unanswerQuestion: payload => ({ type: ACTION_TYPES.WIZARD.QUESTION_UNANSWERED, payload }),
  // unanswerOption: payload => ({ type: ACTION_TYPES.WIZARD.QUESTION_OPTION_UNANSWERED, payload }),
  // ///////////////////////////////////////////////////////////////////////////////// //
  // //////////////////////////////////// CONTENT //////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////// //
  replaceParagraphContent: payload => ({ type: ACTION_TYPES.WIZARD.PARAGRAPH_CONTENT_REPLACED, payload }),
  addNewParagraphStyles: payload => ({ type: ACTION_TYPES.WIZARD.NEW_PARAGRAPH_STYLES_ADDED, payload }),
  removeParagraphStyles: payload => ({ type: ACTION_TYPES.WIZARD.PARAGRAPH_STYLES_REMOVED, payload }),
  toggleParagraphStyle: payload => ({ type: ACTION_TYPES.WIZARD.PARAGRAPH_STYLE_TOGGLED, payload }),
  applyParagraphCustomStyle: payload => ({ type: ACTION_TYPES.WIZARD.PARAGRAPH_CUSTOM_STYLE_APPLIED, payload }),
  alignParagraphContent: payload => ({ type: ACTION_TYPES.WIZARD.PARAGRAPH_CONTENT_ALIGNED, payload }),
  insertParagraphAbove: payload => ({ type: ACTION_TYPES.WIZARD.PARAGRAPH_INSERTED_ABOVE, payload }),
  insertParagraphBelow: payload => ({ type: ACTION_TYPES.WIZARD.PARAGRAPH_INSERTED_BELOW, payload }),
  removeParagraph: payload => ({ type: ACTION_TYPES.WIZARD.PARAGRAPH_REMOVED, payload }),
  // ///////////////////////////////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////// //

  markLocation: payload => ({ type: ACTION_TYPES.WIZARD.LOCATION_MARKED, payload }),
  unwrapMarker: payload => ({ type: ACTION_TYPES.WIZARD.LOCATION_UNMARKED, payload }),
  assignMarker: payload => ({ type: ACTION_TYPES.WIZARD.MARKER_ASSIGNED, payload }),
  unassignMarker: payload => ({ type: ACTION_TYPES.WIZARD.MARKER_UNASSIGNED, payload }),
  answerQuestion: payload => ({ type: ACTION_TYPES.WIZARD.QUESTION_ANSWERED, payload }),
  updateSegmentsMarker: payload => ({ type: ACTION_TYPES.WIZARD.SEGMENTS_MARKER_UPDATED, payload }),
  updateReplacementMarker: payload => ({ type: ACTION_TYPES.WIZARD.REPLACEMENT_MARKER_UPDATED, payload }),
  setConfiguring: payload => ({ type: ACTION_TYPES.WIZARD.CONFIGURING_SET, payload }),
  setAnswering: payload => ({ type: ACTION_TYPES.WIZARD.ANSWERING_SET, payload }),
  concludeConfiguring: () => ({ type: ACTION_TYPES.WIZARD.CONFIGURING_DONE }),
  // addQuestionOption: payload => ({ type: ACTION_TYPES.WIZARD.QUESTION_OPTION_ADDED, payload }),
  removeQuestionOption: payload => ({ type: ACTION_TYPES.WIZARD.QUESTION_OPTION_REMOVED, payload }),
  reorderQuestionOption: payload => ({ type: ACTION_TYPES.WIZARD.QUESTION_OPTION_REORDERED, payload }),
  // insertSegmentAtIndex: payload => ({ type: ACTION_TYPES.WIZARD.SEGMENT_INSERTED_AT_INDEX, payload }),
  // removeSegmentAtIndex: payload => ({ type: ACTION_TYPES.WIZARD.SEGMENT_REMOVED_AT_INDEX, payload }),
  // insertSegmentAbove: payload => ({ type: ACTION_TYPES.WIZARD.SEGMENT_INSERTED_ABOVE, payload }),
  // applyCustomStyle: payload => ({ type: ACTION_TYPES.WIZARD.CUSTOM_STYLE_APPLIED, payload }),
  // removeSegment: payload => ({ type: ACTION_TYPES.WIZARD.SEGMENT_REMOVED, payload }),
  // insertPageSegment: payload => ({ type: ACTION_TYPES.WIZARD.SEGMENT_INSERTED_INTO_PAGE, payload }),
  // updateSegmentLabel: payload => ({ type: ACTION_TYPES.WIZARD.SEGMENT_LABEL_UPDATED, payload }),
}

export { actionCreators }
export default actionCreators
