import React, { forwardRef, Dispatch, SetStateAction, RefCallback, useState, useCallback, useEffect, useImperativeHandle } from 'react'
import { DateTime as LuxonDateTime } from 'luxon'

import { InputDateTimeProps, classes } from '.'

export const DateTimeInput = React.memo(
  forwardRef<HTMLPreElement, InputDateTimeProps>(({ defaultValue, inputTime, autoselect, onInput, onEnter, onEscape }, ref) => {
    const [input, setInput]: [HTMLPreElement | undefined, Dispatch<SetStateAction<HTMLPreElement | undefined>>] = useState()
    const inputRef: RefCallback<HTMLPreElement | undefined> = useCallback(node => node && setInput(node), [])

    const value = defaultValue ? LuxonDateTime.fromISO(defaultValue).toFormat(`yyyy-MM-dd${inputTime ? "'T'HH:mm" : ''}`) : undefined

    const keyDownHandler = useCallback(
      event => {
        if (event.key === 'Enter' && typeof onEnter === 'function' && !event.shiftKey) {
          event.preventDefault()
          event.stopPropagation()
          onEnter(event)
        }
        if (event.key === 'Escape' && typeof onEscape === 'function') {
          event.preventDefault()
          event.stopPropagation()
          onEscape(event)
        }
      },
      [onEnter, onEscape]
    )

    useEffect(() => {
      if (input && autoselect) input.focus()
    }, [input, autoselect])

    useImperativeHandle(ref, () => input!)

    return (
      <input
        ref={inputRef}
        className={classes.input}
        type={inputTime ? 'datetime-local' : 'date'}
        defaultValue={value}
        onInput={onInput}
        onKeyDown={keyDownHandler}
        onBlur={() => window.getSelection()?.removeAllRanges()}
      ></input>
    )
  })
)

export default DateTimeInput
