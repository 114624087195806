import React, { FunctionComponent, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
// import useStore from '___store'

// import { DOCUMENT_GENERATION_MODE } from '___types'
import { CasusLogo, Plus, Template } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import { HeaderProps } from '.'
import Navigation from './Header.Navigation'
import { headerClasses as classes } from '../../'

//
import * as T from 'constants/user-types'
import { useTranslation } from 'react-i18next'
import { useUser } from 'queries/user/useUser'
import { useMasterData } from 'queries/app/useMasterdata'
import Profile from './Header.Profile'

const useData = () => {
  const masterdata = useMasterData()
  const user = useUser()

  const teamClientAllowed = Boolean(masterdata?.client.team_members_enabled) && user?.type === T.USER_TYPE_CLIENT_ADMIN
  const teamLawyerAllowed = Boolean(masterdata?.lawyer.team_members_enabled) && user?.type === T.USER_TYPE_LAWYER_ADMIN

  return {
    teamPageDisabled: !Boolean(teamClientAllowed || teamLawyerAllowed),
    templatePageDisabled: Boolean(user?.type === 'USER_TYPE_CLIENT_EMPLOYEE'),
  }
}

export const Header: FunctionComponent<HeaderProps> = React.memo(() => {
  const { teamPageDisabled, templatePageDisabled } = useData()

  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const pathname = useMemo(() => {
    const path = location.pathname.split('/')[1]
    return path.charAt(0).toUpperCase() + path.slice(1)
  }, [location])
  // const { initializeWizard } = useStore('initializeWizard')

  return (
    <div className={classes.wrapper}>
      <Navigation teamDisabled={teamPageDisabled} templateDisabled={templatePageDisabled} />
      <div onClick={() => history.push("/")} className={classes.logo}>
        <CasusLogo />
      </div>
      &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
      {pathname === '' ? 'Dashboard' : `${pathname}`}
      <Button
        className={classes.addDocumentButton}
        emphasized
        onClick={() => {
          // initializeWizard({ mode: DOCUMENT_GENERATION_MODE })
          history.push(`/${t('document')}/${t('new')}`)
        }}
      >
        <Plus />
      </Button>
      {!templatePageDisabled && (
        <Button className={classes.documentsButton} secondary onClick={() => history.push(t('/template/new'))}>
          <Template />
        </Button>
      )}
      {/* <Dropdown className={classes.signaturesDropdown} secondary replaceCaret={<Signature />}></Dropdown> */}
      {/* <Dropdown className={classes.signaturesDropdown} secondary replaceCaret={<Signature />}>
        pera
        <div />
        <DropdownOption id="pera" label="mika" />
        <DropdownOption id="zika" label="franjo" />
        <DropdownOption id="omg" label="wtf" />
      </Dropdown> */}
      <Profile />
    </div>
  )
})

Header.displayName = 'Header'

export default Header
