import { Answers, CASUS_IDS, PartialRecord } from '___types'
import initialWizardState, { WizardState, fullAssign, initializeSections, updateQuestionOrder, applyParagraphNumbering, evaluateMarkers } from '.'
import { FOUND_SEGMENT_REFERENCES } from './editor-content'

const resetWizard = (): WizardState => {
  Object.keys(FOUND_SEGMENT_REFERENCES).forEach(id => delete FOUND_SEGMENT_REFERENCES[id])
  return Object.assign({}, initialWizardState)
}

export type InitializeWizardPayload = PartialRecord<keyof WizardState, unknown>
const initializeWizard = (payload: UpdateWizardStatePayload = {}): WizardState =>
  updateWizardState(Object.assign({}, resetWizard()), Object.assign({}, { initialized: true }, payload))

export type UpdateWizardStatePayload = PartialRecord<keyof WizardState, unknown>
const UPDATED_WIZARD_CONDITIONAL_METHODS = {
  applyDataStructureId: {
    conditional: (_: WizardState, payload: UpdateWizardStatePayload = {}): boolean => Object.keys(payload).includes('dataStructure'),
    method: (state: WizardState): WizardState =>
      fullAssign({}, state, { dataStructure: Object.assign({}, state.dataStructure, { id: CASUS_IDS.DATASTRUCTURE_ID }) }) as WizardState,
  },
  initializeSections: {
    conditional: (_: WizardState, payload: UpdateWizardStatePayload = {}): boolean => Object.keys(payload).includes('dataStructure'),
    method: (state: WizardState): WizardState => initializeSections(state),
  },
  applyParagraphNumbering: {
    conditional: (_: WizardState, payload: UpdateWizardStatePayload = {}): boolean => Object.keys(payload).includes('dataStructure'),
    method: (state: WizardState): WizardState => applyParagraphNumbering(state),
  },
  evaluateMarkers: {
    conditional: (_: WizardState, payload: UpdateWizardStatePayload = {}, previousState: WizardState): boolean =>
      (Object.keys(payload).includes('answers') && (Boolean((payload.answers as Answers).length) || Boolean(previousState.answers?.length))) ||
      Object.keys(payload).includes('locations'),
    method: (state: WizardState): WizardState => applyParagraphNumbering(evaluateMarkers(state, false)),
  },
  updateQuestionOrder: {
    conditional: (currentState: WizardState, payload: UpdateWizardStatePayload = {}, previousState: WizardState): boolean =>
      Object.keys(payload).some(key => ['questions', 'questionLayout'].includes(key)),
    method: (state: WizardState): WizardState => updateQuestionOrder(state),
  },
} as const

const updateWizardState = (state: WizardState, payload: UpdateWizardStatePayload): WizardState =>
  fullAssign(
    {},
    state,
    Object.values(UPDATED_WIZARD_CONDITIONAL_METHODS).reduce(
      (currentState, { conditional, method }) => (conditional(currentState, payload, state) ? method(currentState) : currentState),
      Object.assign({}, state, payload) as WizardState
    )
  ) as WizardState

export type SetWizardTemplateIdPayload = string
const setWizardTemplateId = (state: WizardState, payload: SetWizardTemplateIdPayload): WizardState => {
  if (state.templateId === payload || !(typeof payload === 'string' || payload === null)) return state
  return updateWizardState(state, { templateId: payload })
}
const resetWizardTemplateId = (state: WizardState): WizardState => (state.templateId ? initializeWizard({ mode: state.mode }) : state)
export type UpdateWizardTitlePayload = string
const updateWizardTitle = (state: WizardState, payload: UpdateWizardTitlePayload): WizardState => updateWizardState(state, { title: payload })

export { initializeWizard, resetWizard, updateWizardState, setWizardTemplateId, resetWizardTemplateId, updateWizardTitle }
