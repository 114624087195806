import Api from './api'

export const externalApiTitles = { 'bamboo-hr': 'BambooHR', of: 'OnlyFans' }

class ExternalApi extends Api {
  constructor() {
    super('')
  }

  getExternalApiFields = async () => {
    try {
      const res = await this.api().get(`/users/external/services/fields`)
      const fields = res?.data?.data?.services.map(({ service: { type, name }, list }) => ({
        id: type,
        title: name || externalApiTitles[name],
        fields: list,
      }))
      return Object.assign({}, res, { data: fields })
    } catch (err) {
      throw err
    }
  }

  getExternalApiIdedObjects = async service => {
    try {
      const res = await this.api().post(`/users/external/services/employees`, { serviceType: service })
      return Object.assign({}, res, { data: res?.data?.data?.employees })
    } catch (err) {
      throw err
    }
  }

  getExternalApiEntries = async selectedServices => {
    return Object.entries(selectedServices).reduce(async (acc, [service, { id, fields }]) => {
      try {
        const res = await this.api().post(`/users/external/services/employees/${id}`, { serviceType: service, fields })
        return Object.assign(acc, { [service]: res?.data?.data })
      } catch (err) {
        throw err
      }
    }, {})
  }
}

const externalApi = new ExternalApi()

export { externalApi }
export default externalApi
