import React from 'react'

import { className } from './index'

const CheckBox = React.memo(({ active = false }) => (
  <svg
    className={className}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="none"
  >
    <rect
      x="15"
      y="15"
      width="70"
      height="70"
      stroke="currentColor"
      strokeWidth="10"
      strokeLinecap="round"
      fill="none"
      rx="10"
    />
    <path
      d="M 30 47 L 45 62 L 70 37"
      stroke={active ? 'currentColor' : 'none'}
      strokeWidth="10"
      strokeLinecap="round"
      fill="none"
    />
  </svg>
))

export { CheckBox }
export default CheckBox
