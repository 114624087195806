import React from 'react'
import { useTranslation } from 'react-i18next'
import { Switch, Route } from 'react-router-dom'

import TemplateCreate from './views/TemplateCreate/TemplateCreate.page'
import TemplateEdit from './views/TemplateEdit/TemplateEdit.page'

const TemplatePage: React.FC = () => {
  const {t} = useTranslation()
  return (
    <Switch>
      <Route exact path={`/${t('template')}/${t('new')}`} component={TemplateCreate} />
      <Route path={`/${t('template')}/:id/${t('edit')}`} component={TemplateEdit} />
    </Switch>
  )
}

export default TemplatePage
