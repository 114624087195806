import { ValuesOf, TupleToUnion, PartialRecord, ReadonlyRecord, RecursiveRecord } from '.'
import { PaperName, PageLayout, Time, Author } from './types.general'
import { WizardEditorStylesType } from './types.editor'

// ===========================================================================================  //
// ======================================== CONSTANTS ========================================  //
// ===========================================================================================  //
export const NESTED_STRUCTURE_KEYS = ['segments', 'textChunks', 'header', 'body', 'footer', 'cells', 'content'] as const
type NestedStructureKeyArray = typeof NESTED_STRUCTURE_KEYS
export type NestedStructureKeys = TupleToUnion<NestedStructureKeyArray>

export const TEMPLATES_FILTERS = {
  MINE: 'mine',
  COMPANY: 'company',
  SHARED: 'shared',
  PUBLIC: 'public',
  TRASHED: 'trashed',
} as const
type TemplatesFilters = typeof TEMPLATES_FILTERS
type TemplatesFiltersKeys = keyof TemplatesFilters
export type TemplatesFilter = TemplatesFilters[TemplatesFiltersKeys]

export const TEMPLATE_FILTER_MAP = {
  [TEMPLATES_FILTERS.MINE]: 'my',
  [TEMPLATES_FILTERS.TRASHED]: 'bin',
} as const
type TemplatesFilterMap = typeof TEMPLATE_FILTER_MAP
export type TemplatesFilterMapKeys = keyof TemplatesFilterMap
type TemplatesFilterMapValues = TemplatesFilterMap[TemplatesFilterMapKeys]
export type MappedTemplatesFilter = TemplatesFilterMapValues | Exclude<TemplatesFilter, TemplatesFilterMapKeys>

export const mapTemplatesFilter = (templatesFilter: TemplatesFilter): MappedTemplatesFilter =>
  TEMPLATE_FILTER_MAP[templatesFilter as TemplatesFilterMapKeys] || templatesFilter
export const MAPPED_TEMPLATES_FILTERS = (Object.entries(TEMPLATES_FILTERS) as [TemplatesFiltersKeys, TemplatesFilter][]).reduce(
  (acc, [key, value]) => Object.assign(acc, { [key]: mapTemplatesFilter(value) }),
  {}
) as ReadonlyRecord<TemplatesFiltersKeys, MappedTemplatesFilter>
// ===========================================================================================  //

// ===========================================================================================  //
// ======================================== QUESTIONS ========================================  //
// ===========================================================================================  //
type QuestionAdvancedRule = {} // DEFINE WHEN IMPLEMENTING RULES
type QuestionAdvancedRules = QuestionAdvancedRule[]
type QuestionAdvancedConfiguration = { visibility: 'show' | 'hide'; logic: 'and' | 'or'; subQuestionTo: string; rules: QuestionAdvancedRules }
type QuestionDetails = { description: string; example: string; hint: string }
export type OptionLimits = { min: number | string; max: number | string; step: number | string }
export const OPTION_VALUE_TYPES = { STRING: 'string', NUMBER: 'number', DATE: 'date', DATE_TIME: 'date-time', CURRENCY: 'currency' } as const
type OptionValueTypesType = typeof OPTION_VALUE_TYPES
export type OptionValueTypeUnionType = OptionValueTypesType[keyof OptionValueTypesType]
interface IOptionBase {
  id: string
  value: string
  label: string
  valueType: OptionValueTypeUnionType
  markers: string[]
  placeholder?: string
  limits?: OptionLimits
}
export const OPTION_TYPES = { STATIC: 'static', DYNAMIC: 'dynamic' } as const
type OptionTypesType = typeof OPTION_TYPES
export type OptionTypesUnionType = OptionTypesType[keyof OptionTypesType]
export type StaticOption = IOptionBase & { type: OptionTypesType['STATIC']; text: string }
export type DynamicOption = IOptionBase & { type: OptionTypesType['DYNAMIC'] }
export type Option = StaticOption | DynamicOption
export type Options = Option[]
export const OPTION_GROUP_SELECT = { SINGLE: 'single', MULTI: 'multi' } as const
type OptionGroupSelectType = typeof OPTION_GROUP_SELECT
export type OptionGroupSelectUnionType = OptionGroupSelectType[keyof OptionGroupSelectType]
type OptionGroupSelect = { select: OptionGroupSelectUnionType; minimum: number; maximum: number; enforceLimit: boolean }
export type OptionGroup = { id: string; label: string; options: Options; select: OptionGroupSelect }
export type OptionGroups = OptionGroup[]
export type Question = {
  id: string
  text: string
  markers: string[]
  optionGroups: OptionGroups
  questionDetails: QuestionDetails
  advanced: QuestionAdvancedConfiguration
}
export type Questions = Question[]
// ===========================================================================================  //

// ===========================================================================================  //
// ===================================== QUESTION LAYOUT =====================================  //
// ===========================================================================================  //
interface IQuestionLayoutGroupBase {
  id: string
  label: string
  questions: string[] // Change to questionIds when appropriate --- will break backwards compatibility and previously created templates will not work!
}
type QuestionLayoutSubQuestionGroup = IQuestionLayoutGroupBase & { type: 'sub-questions' }
type QuestionLayoutRegularGroup = IQuestionLayoutGroupBase & { type: 'group' }
type QuestionLayoutLooseGroup = IQuestionLayoutGroupBase & { type: 'loose' }
export type QuestionLayoutGroup = QuestionLayoutLooseGroup | QuestionLayoutRegularGroup | QuestionLayoutSubQuestionGroup
export type QuestionLayout = QuestionLayoutGroup[]
// ===========================================================================================  //

// ===========================================================================================  //
// ======================================== LOCATIONS ========================================  //
// ===========================================================================================  //
export const LOCATION_TYPES = { SEGMENTS: 'segments', TEXT: 'text' } as const
export type LocationType = ValuesOf<typeof LOCATION_TYPES>
interface ILocationBase {
  id: string
  type: LocationType
  range: [number, number]
  label: string
  questionId: string
  optionIds: string[]
  defaultKeep: boolean
  keep: boolean
  replace: string
}
interface ISegmentsLocationSpecificProperties {
  type: typeof LOCATION_TYPES.SEGMENTS
  contentCustomStyle: string
  contentStyles: string[]
}
interface ITextLocationSpecificProperties {
  type: typeof LOCATION_TYPES.TEXT
  contentText: string
}
type LocationTypeSpecificProperties = ISegmentsLocationSpecificProperties | ITextLocationSpecificProperties
type Location<T extends LocationTypeSpecificProperties> = ILocationBase & { [P in keyof T]: T[P] }
export type SegmentsLocation = Location<ISegmentsLocationSpecificProperties>
export type SegmentsLocations = { [key: string]: SegmentsLocation[] }
export type TextLocation = Location<ITextLocationSpecificProperties>
export type TextLocations = { [key: string]: TextLocation[] }
export type Locations = { segments: SegmentsLocations; text: TextLocations }
// ===========================================================================================  //

// ===========================================================================================  //
// =========================================== CSS ===========================================  //
// ===========================================================================================  //
export type CSSData = RecursiveRecord<string, string>
// ===========================================================================================  //

// ===========================================================================================  //
// ======================================== NUMBERING ========================================  //
// ===========================================================================================  //
type NumberingSystemLevel = { styleName: string; prefix: string; suffix: string; combined: boolean; combineString: string; type: string }
export type Numbering = NumberingSystemLevel[]
type Numberings = { [key: string]: Numbering }
export type NumberingSystem = { [key: string]: Numbering }
// ===========================================================================================  //

// ==========================================================================================  //
// ===================================== DATA STRUCTURE =====================================  //
// ==========================================================================================  //
type Header = { id: string; segments: Segments }
type Footer = { id: string; segments: Segments }
type SectionLayout = { paperName: PaperName; margins: { top: number; left: number; bottom: number; right: number } }
export type PageBreak = { type: 'page' }
export type SectionBreak = { type: 'section'; id: string; layout: SectionLayout }
type Break = PageBreak | SectionBreak
export const SEGMENT_TYPES = {
  CONTAINER: 'container',
  MARKER: 'mark',
  PARAGRAPH: 'paragraph',
  TEXT_CHUNK: 'chunk',
  TABLE: 'table',
  TABLE_HEADER: 'header',
  TABLE_BODY: 'body',
  TABLE_FOOTER: 'footer',
  TABLE_ROW: 'row',
  TABLE_CELL: 'cell',
  IMAGE: 'image',
} as const
type SegmentType = ValuesOf<typeof SEGMENT_TYPES>
export const TABLE_SECTION_TYPES = [SEGMENT_TYPES.TABLE_HEADER, SEGMENT_TYPES.TABLE_BODY, SEGMENT_TYPES.TABLE_FOOTER] as const
export type TableSectionType = TupleToUnion<typeof TABLE_SECTION_TYPES>
export type TableSectionsType = PartialRecord<TableSectionType, TableRows>
interface IDataStructureObjectBase {
  customStyle: string
  styles: string[]
  type: SegmentType
}
interface ITextChunkProperties {
  text: string
  type: typeof SEGMENT_TYPES.TEXT_CHUNK
}
interface IParagraphProperties {
  id: string
  textChunks: TextChunks
  type: typeof SEGMENT_TYPES.PARAGRAPH
  break: Break
}
interface ITableCellProperties {
  id: string
  content: Segments
  type: typeof SEGMENT_TYPES.TABLE_CELL
}
interface ITableRowProperties {
  id: string
  cells: TableCells
  type: typeof SEGMENT_TYPES.TABLE_ROW
}
interface ITableProperties {
  id: string
  header: TableRows
  body: TableRows
  footer: TableRows
  type: typeof SEGMENT_TYPES.TABLE
  break: Break
}

type DataStructureObjectProperties = IParagraphProperties | ITextChunkProperties | ITableProperties | ITableRowProperties | ITableCellProperties
type DataStructureObject<T extends DataStructureObjectProperties> = IDataStructureObjectBase & { [P in keyof T]: T[P] }

export type TextChunkObject = DataStructureObject<ITextChunkProperties>
export type TextChunks = TextChunkObject[]
export type ParagraphObject = DataStructureObject<IParagraphProperties>
export type TableCellObject = DataStructureObject<ITableCellProperties>
export type TableCells = TableCellObject[]
type TableRowObject = DataStructureObject<ITableRowProperties>
export type TableRows = TableRowObject[]
export type TableObject = DataStructureObject<ITableProperties>
export type Segment = ParagraphObject | TableObject
export type Segments = Segment[]

export type WizardStateDataStructureNumbering = { [key: string]: { system: string; value: string } }
export type WizardStateDataStructureSection = { id: string; title: string; layout: PageLayout; pages: [number, number][] }
export type WizardStateDataStructureSections = WizardStateDataStructureSection[]
export type DataStructure = {
  id: '_casus_data_structure'
  storageId: string
  segments: Segments
  headers: Header
  footers: Footer
  numberingSystem: NumberingSystem
  numberings: Numberings

  // ============== GENERATED ============== //
  sections: WizardStateDataStructureSections
  numbering: WizardStateDataStructureNumbering
}
// ==========================================================================================  //

export interface ITemplate {
  id: string
  status: string
  name: string
  contentVersionId: string

  dataStructure: DataStructure
  cssData: CSSData
  styles: WizardEditorStylesType
  locations: Locations
  questionLayout: QuestionLayout
  questions: Questions
  author: Author
  created: Time
  edited: Time
}
