import { FormEventHandler, MouseEventHandler, KeyboardEventHandler } from 'react'

import Input from './Input'

import './style.scss'

const classes = {
  wrapper: 'Casus-Components-Input-wrapper',
  pre: 'Casus-Components-Input-contentEditablePre',
  input: 'Casus-Components-Input-input',
  actionButtons: {
    wrapper: 'Casus-Components-Input-actionButtons-wrapper',
    confirmButton: 'Casus-Components-Input-actionButtons-confirmButton',
    discardButton: 'Casus-Components-Input-actionButtons-discardButton',
  },
} as const

export const CASUS_INPUT_TYPES = { TEXT: 'text', MULTILINE: 'multiline', NUMBER: 'number', DATE_TIME: 'date-time', CURRENCY: 'currency' } as const
type CasusInputTypesType = typeof CASUS_INPUT_TYPES
export type CasusInputType = CasusInputTypesType[keyof CasusInputTypesType]

type InputOptions = {
  inputTime: boolean
  min: number | string
  max: number | string
  step: number | string
}

export type InputProps = {
  className?: string
  emphasized?: boolean
  primary?: boolean
  secondary?: boolean
  tertiary?: boolean
  dataSet?: Record<keyof any, unknown>
  type: CasusInputType
  defaultValue?: string | number
  placeholder?: string
  options?: InputOptions
  debouncedInput?: number
  autoselect?: boolean
  showActionButtons?: boolean
  onInput?: FormEventHandler<HTMLPreElement>
  onConfirm?: MouseEventHandler<HTMLButtonElement>
  onDiscard?: MouseEventHandler<HTMLButtonElement>
}
export type InputTextProps = {
  onInput: FormEventHandler<HTMLInputElement>
}
export type InputMultilineProps = {
  defaultValue?: string
  placeholder?: string
  autoselect?: boolean
  onInput: FormEventHandler<HTMLPreElement>
  onEnter: KeyboardEventHandler<HTMLPreElement>
  onEscape: KeyboardEventHandler<HTMLPreElement>
}
export type InputNumberProps = {
  defaultValue?: number
  min?: number | string
  max?: number | string
  step?: number | string
  autoselect?: boolean
  onInput: FormEventHandler<HTMLPreElement>
  onEnter: KeyboardEventHandler<HTMLPreElement>
  onEscape: KeyboardEventHandler<HTMLPreElement>
}
export type InputDateTimeProps = {
  defaultValue?: string
  inputTime?: boolean
  autoselect?: boolean
  onInput: FormEventHandler<HTMLInputElement>
  onEnter: KeyboardEventHandler<HTMLInputElement>
  onEscape: KeyboardEventHandler<HTMLInputElement>
}
export type InputCurrencyProps = {
  onInput: FormEventHandler<HTMLInputElement>
}

export { Input, classes }
export default Input
