// import { v4 as uuid } from 'uuid'
import { SEGMENT_TYPES, SEGMENT_TAGS, CASUS_CLASSES, IGNORE_NODE_CLASSES } from 'Wizard/constants'
import { classes } from '.'

// import { classes } from './components/Page'

// const ignoreClasses = [classes.page, classes.content]

// const findNodeWithContentDeep = (node = {}, offset = 0, direction = 'start') => {
//   debugger
//   //   console.log('DEEP NODE: ', node)
//   const isTextNode = node.nodeName === '#text'
//   const length = isTextNode ? node.length : node.childNodes.length
//   const isOnEdge = offset === 0 + Number(direction === 'start') * length - Number(direction === 'end')
//   //    - Number(direction === 'end' && !isTextNode)
//   if (length && !isOnEdge) {
//     if (isTextNode) return [node, offset + Number(direction === 'end')]
//     else {
//       const childNodes = Array.from(node.childNodes || [])
//       const firstNodeToCheck = childNodes[offset]
//       if (firstNodeToCheck) {
//         const isChildTextNode = firstNodeToCheck.nodeName === '#text'
//         const innerNodeLength = isChildTextNode
//           ? firstNodeToCheck.length
//           : Array.from(firstNodeToCheck.childNodes || []).length
//         if (innerNodeLength) {
//           const offsetWithin = Number(direction === 'end') * (innerNodeLength - Number(!isChildTextNode))
//           const res = findNodeWithContentDeep(firstNodeToCheck, offsetWithin, direction)
//           return res
//         }
//       }
//       const nextOffset = offset + (-1) ** Number(direction === 'end')
//       if (nextOffset >= 0) return findNodeWithContentDeep(node, nextOffset, direction)
//     }
//   }
//   return null
// }

// const findNodeWithContentShallow = (node = {}, offset = 0, direction = 'start') => {
//   debugger
//   //   console.log('SHALLOW NODE: ', node)
//   const res = findNodeWithContentDeep(node, offset - Number(direction === 'end'), direction)
//   if (res) return res
//   let nodeToCheck = node
//   let parentNode = null
//   let offsetWithinParent = null
//   let isOnEdge = true
//   do {
//     const tempNode = nodeToCheck
//     parentNode = tempNode.parentNode
//     const siblings = Array.from(parentNode.childNodes)
//     offsetWithinParent = siblings.findIndex(n => n === tempNode) + Number(direction === 'start')
//     const length = siblings.length
//     isOnEdge = offsetWithinParent === Number(direction === 'start') * length
//     nodeToCheck = parentNode
//   } while (isOnEdge && parentNode.id !== CASUS_CLASSES.section)
//   if (isOnEdge) return null
//   return findNodeWithContentShallow(nodeToCheck, offsetWithinParent, direction)
// }

// ================================================================================== //
// ================================================================================== //
// ================================================================================== //
// ================================================================================== //
// ================================================================================== //

// const FALSE_CHOICE_PARENT_TYPES = [
//   SEGMENT_TYPES.table,
//   SEGMENT_TYPES.tableHeader,
//   SEGMENT_TYPES.tableBody,
//   SEGMENT_TYPES.tableFooter,
//   SEGMENT_TYPES.tableRow,
// ]

// const validateForMarking = (start, end) => {
//   if (!(start && end)) return undefined
//   const { offset: startOffset, edgeSegments: startEdgeSegments, parentSegments: startParentSegments } = start
//   const { offset: endOffset, edgeSegments: endEdgeSegments, parentSegments: endParentSegments } = end

//   const choice = {}
//   const replacement = {}

//   while (true) {
//     const startNode = startParentSegments.pop() || startEdgeSegments.pop()
//     const endNode = endParentSegments.pop() || endEdgeSegments.pop()
//     if (startNode?.id !== endNode?.id) break
//     const clearedParentArrays = !(startParentSegments.length || endParentSegments.length)
//     const clearedEdgeArrays = !(startEdgeSegments.length || endEdgeSegments.length)
//     if (!clearedParentArrays) continue
//     if (!choice.parent && startEdgeSegments.length && endEdgeSegments.length && !FALSE_CHOICE_PARENT_TYPES.includes(startNode.type)) {
//       choice.parent = startNode
//       // =========================== MAYBE ALSO NEEDED FOR REPLACEMENT, CHECK AND FIX =========================== //
//       const filteredStartEdgeSegments = startEdgeSegments.filter(({ type }) => type !== SEGMENT_TYPES.container)
//       const filteredEndEdgeSegments = endEdgeSegments.filter(({ type }) => type !== SEGMENT_TYPES.container)
//       choice.range = [filteredStartEdgeSegments.pop().id, filteredEndEdgeSegments.pop().id]
//       // ======================================================================================================== //
//     }
//     if (!clearedEdgeArrays) continue
//     if (startNode.type !== SEGMENT_TYPES.paragraph) continue
//     replacement.parent = startNode
//     replacement.range = [startOffset, endOffset]
//     break
//   }

//   return { choice, replacement }
// }

// const getChildNodes = node =>
//   Array.from(node?.childNodes || []).filter(n => !Array.from(n.classList || []).some(cn => IGNORE_NODE_CLASSES.includes(cn)))

// const getNodeLength = node => (node?.nodeName === '#text' ? node.length : getChildNodes(node).length)

// const ponder = (given, expected) => Number(given === expected)

// const isOnEdgeWithinParent = (node, directionString, ponderString) => {
//   const offset = getChildNodes(node?.parentNode).indexOf(node) + ponder(directionString, ponderString)
//   const onEdge = offset === 0 + ponder(directionString, ponderString) * getNodeLength(node?.parentNode)
//   return [onEdge, offset]
// }

// const tableMap = {
//   [CASUS_CLASSES.tableHeader]: SEGMENT_TYPES.tableHeader,
//   [CASUS_CLASSES.tableBody]: SEGMENT_TYPES.tableBody,
//   [CASUS_CLASSES.tableHeader]: SEGMENT_TYPES.tableFooter,
// }

// const getUpstreamSegmentIdsArray = (node, directionString, check = false, propagatedArray = [[], []]) => {
//   if (!(node?.parentNode && node.parentNode.nodeName !== '#document')) return propagatedArray
//   if (Array.from(node.classList || []).some(cn => STOP_DEPTH_NODE_CLASSES.includes(cn))) return propagatedArray
//   const parent = node.parentNode
//   const match = parent.id.match(/((^[^-]+)-)?(.+$)/) || []
//   const [parentType] = Object.entries(SEGMENT_TAGS).find(tag => tag[1] === parent.localName) || []
//   const isParentMarker = parentType === SEGMENT_TYPES.container && Array.from(parent.classList).includes(CASUS_CLASSES.segmentsMarkerDiv)
//   const markerRange = (match[2] || '0/0/0').split('/').map(s => Number(s))
//   const isOnMarkerEdge = isParentMarker && markerRange[ponder(directionString, 'end')] === markerRange[2] * ponder(directionString, 'end')
//   const onEdge = isOnEdgeWithinParent(node, directionString, 'end')[0] && (!isParentMarker || isOnMarkerEdge)
//   const outer = check || !onEdge
//   if (Array.from(parent.classList).some(c => SKIP_DEPTH_NODE_CLASSES.includes(c)))
//     return getUpstreamSegmentIdsArray(parent, directionString, outer, propagatedArray)
//   const parentId = match[2] === 'section' ? 'root' : match[3] || ''
//   const relevantArray = propagatedArray[Number(outer)]
//   if (parentId) relevantArray.push({ type: parentType, id: parentId })
//   else Object.entries(tableMap).some(([cn, type]) => Array.from(parent.classList).includes(cn) && relevantArray.push({ type }))
//   return getUpstreamSegmentIdsArray(parent, directionString, outer, propagatedArray)
// }

// const getTextOffsetWithinParent = (parent, node, accumulatedOffset = 0) => {
//   const findChildOffset = p =>
//     getChildNodes(p).find(c => c === node || (c.nodeName === '#text' ? (accumulatedOffset += c.length) * 0 : findChildOffset(c)))
//   return findChildOffset(parent) && accumulatedOffset
// }

// const getTextNodeParent = node => {
//   if (!node?.parentNode || node.parentNode.nodeName === '#document') return undefined
//   return Array.from(node.classList || []).includes(CASUS_CLASSES.paragraphSegment) ? node : getTextNodeParent(node.parentNode)
// }

// const getUpstreamNode = (node, directionString) => {
//   if (!node?.parentNode) return [undefined, 0]
//   const [onEdge, offset] = isOnEdgeWithinParent(node, directionString, 'start')
//   return onEdge ? getUpstreamNode(node.parentNode, directionString) : [node.parentNode, offset]
// }

// const CONTENT_CLASSES = [
//   CASUS_CLASSES.tableSegment,
//   CASUS_CLASSES.tableHeader,
//   CASUS_CLASSES.tableBody,
//   CASUS_CLASSES.tableFooter,
//   CASUS_CLASSES.tableRow,
//   CASUS_CLASSES.tableCell,
// ]

// const getDownstreamNode = (node, offset, directionString, hasUpstreamContent) => {
//   const isContentNode = Array.from(node?.classList || []).some(cn => CONTENT_CLASSES.includes(cn)) || (node.nodeName === '#text' && node.length)
//   if (!node?.childNodes?.length) return [node, hasUpstreamContent || isContentNode]
//   const nextNode = getChildNodes(node)[Math.max(offset - ponder(directionString, 'end'), 0)]
//   return getDownstreamNode(nextNode, directionString === 'start' ? 0 : getNodeLength(nextNode), directionString, hasUpstreamContent || isContentNode)
// }

// const nodeMarch = (node, directionString) => {
//   const [upstreamNode, offset] = getUpstreamNode(node, directionString)
//   const [downstreamNode, hasContent] = getDownstreamNode(upstreamNode, offset, directionString)
//   return hasContent ? downstreamNode : nodeMarch(downstreamNode, directionString)
// }

// const isOffsetOnEdge = (node, offset, directionString) => {
//   const length = getNodeLength(node)
//   return [offset === 0 || offset === length, !length && offset === 0 + ponder(directionString, 'start') * length ? 'upstream' : 'downstream']
// }

// const getRelevantNode = (node, offset, directionString) => {
//   const [onEdge, edgeFlow] = isOffsetOnEdge(node, offset, directionString)
//   const flow = onEdge ? edgeFlow : 'downstream'
//   if (node.nodeName === '#text' && flow === 'downstream') return node
//   return flow === 'downstream' ? getDownstreamNode(node, offset, directionString)[0] : nodeMarch(node, directionString)
// }

// const validateRange = (range, marchedRange = document.createRange()) =>
//   range
//     ? [
//         validateForMarking(
//           ...Object.values(
//             ['start', 'end'].reduce((result, directionString) => {
//               const { [`${directionString}Container`]: node, [`${directionString}Offset`]: offset } = range
//               const relevantNode = getRelevantNode(node, offset, directionString)
//               if (!relevantNode) return result
//               const relevantNodeOffset = ponder(directionString, 'end') * getNodeLength(relevantNode)
//               const nodeOffset = relevantNode === node ? offset : relevantNodeOffset
//               marchedRange[`set${directionString.charAt(0).toUpperCase()}${directionString.slice(1)}`](relevantNode, nodeOffset)
//               const textNodeOffsetWithinIdedParent = getTextOffsetWithinParent(getTextNodeParent(relevantNode), relevantNode, nodeOffset)
//               const relevantOffset = relevantNode.nodeName === '#text' ? textNodeOffsetWithinIdedParent : nodeOffset
//               const onEdge = isOffsetOnEdge(relevantNode, nodeOffset, directionString)[0]
//               const [edgeSegments, parentSegments] = getUpstreamSegmentIdsArray(relevantNode, directionString, !onEdge)
//               if (relevantNode.id)
//                 edgeSegments.unshift({ type: Object.entries(SEGMENT_TAGS).find(tag => tag[1] === relevantNode.localName)[0], id: relevantNode.id })
//               return Object.assign(result, { [directionString]: { offset: relevantOffset, edgeSegments, parentSegments } })
//             }, {})
//           )
//         ),
//         marchedRange,
//       ]
//     : [undefined, undefined]

// const mergeRange = selection => {
//   if (!selection?.rangeCount) return undefined
//   const mergedRange = document.createRange()
//   const [{ startContainer, startOffset }, { endContainer, endOffset }] = [selection.getRangeAt(0), selection.getRangeAt(selection.rangeCount - 1)]
//   return mergedRange.setStart(startContainer, startOffset) || mergedRange.setEnd(endContainer, endOffset) || mergedRange
// }

// export const parseSelection = (selection, mergedRange = undefined) =>
//   selection?.isCollapsed ? [undefined, undefined] : (mergedRange = mergeRange(selection)) && validateRange(mergedRange)

// export const generateLocationObjects = ({ choice: c, replacement: r } = {}) => {
//   const choice = c?.parent ? { type: 'choice', id: c.parent.id, parentType: c.parent.type, range: c.range, locationId: uuid() } : null
//   const replacement = r?.parent ? { type: 'replacement', id: r.parent.id, parentType: r.parent.type, range: r.range, locationId: uuid() } : null
//   return Object.assign({}, { choice }, { replacement })
// }

// const getRangeBoundaries = range => {
//   const startPoint = range?.cloneRange()
//   const endPoint = range?.cloneRange()
//   return startPoint?.collapse(true) || endPoint?.collapse(false) || [startPoint.getBoundingClientRect(), endPoint.getBoundingClientRect()]
// }

// export const getSelectionRangeRect = (range, editor) => {
//   if (!editor) return {}
//   const [{ top, left }, { bottom, right }] = getRangeBoundaries(range)
//   const editorRect = editor.getBoundingClientRect() || {}
//   return { top: top - (editorRect.top - editor.scrollTop), left: Math.min(left, right) - editorRect.left, width: right - left, height: bottom - top }
// }

export const getAbsolutePositions = (start, end) => {
  const [{ top, left: sLeft, right: sRight } = {}, { bottom, left: eLeft, right: eRight } = {}] = [
    start?.getBoundingClientRect(),
    end?.getBoundingClientRect(),
  ]
  return { top, bottom, left: Math.min(sLeft, eLeft), right: Math.max(sRight, eRight) }
}

export const calculateBoundaries = range => {
  const editor = document.getElementsByClassName(classes.wrapper)[0]
  if (!editor) return null
  const { top, bottom, left, right } = getAbsolutePositions(...range)
  const rect = editor.getBoundingClientRect()
  if (!(top && bottom && left && right)) return null
  return {
    top: Math.min(top, bottom) - (rect.top - editor.scrollTop),
    left: Math.min(left, right) - rect.left,
    width: Math.abs(right - left),
    height: Math.abs(bottom - top),
  }
}

export const generateTextLocationObject = text => ({ type: 'text', parentId: text.parent.id, range: text.range })

export const generateFixedString = (editorHeight, scrollTop, top, ponder) =>
  (editorHeight + scrollTop < top + ponder && 'bottom') || (top < scrollTop && 'top') || null

const idMatchRegex = /(^[^/:]+)(\/)?([^/:]+)?((:)(s?e?))?$/
// const idMatchRegex = /((^[^-]+)-)?(.+$)/

const parseId = idString => {
  const idMatch = idString?.match(idMatchRegex) || []
  return idMatch[3] || idMatch[1]
}

export const generateSegmentsLocationObject = segments => ({
  type: 'segments',
  parentId: segments.parent.id,
  range: segments.range?.map(({ id }) => id),
})

const getTextOffsets = (parent, startNode, endNode, startOffset, endOffset, startDone = false, endDone = false) =>
  Array.from(parent.childNodes).reduce(
    (acc, cur) => {
      if ((startDone && endDone) || Array.from(cur.classList || []).some(c => IGNORE_NODE_CLASSES.includes(c))) return acc
      if (cur.nodeName !== '#text') return (acc = getTextOffsets(cur, startNode, endNode, ...acc.slice()))
      acc[2] = acc[2] || startDone || !(cur !== startNode && (acc[0] += cur.length))
      acc[3] = acc[3] || endDone || !(cur !== endNode && (acc[1] += cur.length))
      return acc
    },
    [startOffset, endOffset, startDone, endDone]
  )

const stopNodeNames = {
  segments: [],
  // text: [SEGMENT_TAGS[SEGMENT_TYPES.mark]].map(s => s.toUpperCase()),
  text: [SEGMENT_TAGS[SEGMENT_TYPES.container]],
}
const rangeNodeNames = {
  segments: [
    SEGMENT_TAGS[SEGMENT_TYPES.paragraph],
    SEGMENT_TAGS[SEGMENT_TYPES.table],
    SEGMENT_TAGS[SEGMENT_TYPES.tableRow],
    SEGMENT_TAGS[SEGMENT_TYPES.container],
  ].map(s => s.toUpperCase()),
  text: ['#text'],
}
const parentNodeNames = {
  segments: [
    SEGMENT_TAGS[SEGMENT_TYPES.container],
    SEGMENT_TAGS[SEGMENT_TYPES.table],
    // SEGMENT_TAGS[SEGMENT_TYPES.tableBody],
    // SEGMENT_TAGS[SEGMENT_TYPES.tableFooter],
    SEGMENT_TAGS[SEGMENT_TYPES.tableData],
  ].map(s => s.toUpperCase()),
  text: [SEGMENT_TAGS[SEGMENT_TYPES.paragraph], SEGMENT_TAGS[SEGMENT_TYPES.mark]].map(s => s.toUpperCase()),
}

const breakTest = nodeNameArray => node => {
  let id
  try {
    id = node.id
  } finally {
    return Boolean(id) && nodeNameArray.includes(node.nodeName)
  }
}

const findUpstreamNodeByTest = (node, test, stopTest, parentNode) => {
  try {
    parentNode = node.parentNode
  } finally {
    // console.groupCollapsed(`${node.nodeName}: ${node.id}`)
    // console.log('NODE: ', node)
    // console.log('STOP TEST: ', stopTest(node))
    // console.log('TEST: ', test(node))
    // console.log('PARENT NODE: ', parentNode)
    // console.groupEnd()
    return node && !stopTest(node) && ((test(node) && node) || (parentNode && findUpstreamNodeByTest(parentNode, test, stopTest)))
  }
}

const matchIds = (...ids) => parseId(ids[0]) === parseId(ids[1])

const stopTest = parentId => (node, id) => {
  try {
    id = node.id
  } finally {
    return matchIds(id, parentId)
  }
}

const getUpmostSegment = (node, parent, id, parentNode, parentId) => {
  // console.groupCollapsed(`${node.nodeName}: ${node.id}`)
  // console.log('NODE: ', node)
  try {
    id = node.id
    parentNode = node.parentNode
    parentId = parent.id
  } finally {
    // console.log('PARENT NODE: ', parentNode)
    // console.log('PARENT: ', parent)
    const upstreamNode = findUpstreamNodeByTest(parentNode, breakTest(rangeNodeNames.segments), stopTest(parentId))
    // console.log('UPSTREAM NODE: ', upstreamNode)
    // console.groupEnd()
    return upstreamNode ? getUpmostSegment(upstreamNode, parent) : node
  }
}

const getAllValidSegmentsParents = (node, accumulated = [], parentNode) => {
  try {
    parentNode = node.parentNode
  } finally {
    return (
      (breakTest(parentNodeNames.segments)(node) && !accumulated.push(node)) ||
      (parentNode ? getAllValidSegmentsParents(parentNode, accumulated) : accumulated)
    )
  }
}

const findSegmentsParent = (start, end) => {
  const parents = [getAllValidSegmentsParents(start), getAllValidSegmentsParents(end)].filter(a => a.length).sort((a, b) => b.length - a.length)
  return (
    parents.length === 2 &&
    parents[0].unshift(parents[0][0]) &&
    parents[0].reverse() &&
    parents[0][parents[0].findIndex(({ id }) => !matchIds(id, parents[1].pop()?.id)) - 1]
  )
}

const getValidParagraphParent = node =>
  findUpstreamNodeByTest(node, breakTest(SEGMENT_TAGS[SEGMENT_TYPES.paragraph].toUpperCase()), breakTest(stopNodeNames.text))

const getValidTextParent = node => findUpstreamNodeByTest(node, breakTest(parentNodeNames.text), breakTest(stopNodeNames.text))

const findTextParent = (start, end) => {
  const startParent = getValidTextParent(start)
  const endParent = getValidTextParent(end)
  if (startParent !== endParent) return
  const parent = startParent.nodeName === SEGMENT_TAGS[SEGMENT_TYPES.paragraph].toUpperCase() ? startParent : getValidParagraphParent(startParent)
  return parent
}

const markerStartEndSuffixMatchRegex = /^[^:]+((:(s?e?)?)?$)/
const markerNotStarting = (id, starting = '') =>
  (starting = ((id.match(markerStartEndSuffixMatchRegex)?.slice(-1) || [])[0] || '').slice(0, 1)) && starting !== 's'
const markerNotEnding = (id, ending = '') =>
  (ending = ((id.match(markerStartEndSuffixMatchRegex)?.slice(-1) || [])[0] || '').slice(-1)) && ending !== 'e'

const getValidMarkers = (idMatchArray = []) =>
  Array.from(document.getElementsByClassName(CASUS_CLASSES.segmentsMarkerDiv)).reduce((acc, cur) => {
    const markerId = parseId(cur.id)
    return (
      (!idMatchArray.includes(markerId) || acc.push({ node: cur, id: markerId, start: !markerNotStarting(cur.id), end: !markerNotEnding(cur.id) })) &&
      acc
    )
  }, [])

const expandMarkerSelection = (start, end) => {
  if (!(breakTest(rangeNodeNames.segments)(start) && breakTest(rangeNodeNames.segments)(end))) return []
  // const startMatch = start.id.match(idMatchRegex)
  // const endMatch = end.id.match(idMatchRegex)
  const startId = parseId(start.id)
  const endId = parseId(end.id)
  const [shouldExpandStart, shouldExpandEnd] = [markerNotStarting(start.id), markerNotEnding(end.id)]
  // const shouldExpandStart = startMatch[2] && startMatch[2].split('/')[0] !== '0'
  // const endSplit = endMatch[2]?.split('/') || []
  // const shouldExpandEnd = endSplit[1] !== endSplit[2]
  if (!(shouldExpandStart || shouldExpandEnd)) return [start, end]
  const validMarkers = getValidMarkers([startId, endId])
  return [
    (shouldExpandStart && validMarkers.find(({ id, start }) => start && id === startId)?.node) || start,
    (shouldExpandEnd && validMarkers.find(({ id, end }) => end && id === endId)?.node) || end,
  ]
}

export const locationHandler = (segmentsCallback, textCallback, noRange) => {
  const selection = document.getSelection()
  if (selection.isCollapsed) return segmentsCallback(noRange) || textCallback(noRange)
  const { startContainer, startOffset } = selection.getRangeAt(0)
  const { endContainer, endOffset } = selection.getRangeAt(selection.rangeCount - 1)

  // console.groupCollapsed('SEGMENTS PARENT')
  const segmentsParent = findSegmentsParent(startContainer, endContainer)
  // console.groupEnd()
  // console.groupCollapsed('TEXT PARENT')
  const textParent = findTextParent(startContainer, endContainer)
  // console.groupEnd()

  // console.log('SEGMENTS PARENT: ', segmentsParent)
  // console.log('TEXT PARENT: ', textParent)

  let resultingSegments = noRange
  let resultingText = noRange
  if (segmentsParent) {
    // console.groupCollapsed('SEGMENTS START')
    // const s = getUpmostSegment(startContainer, segmentsParent)
    // console.groupEnd()
    // console.groupCollapsed('SEGMENTS END')
    // const e = getUpmostSegment(endContainer, segmentsParent)
    // console.groupEnd()
    const range = expandMarkerSelection(getUpmostSegment(startContainer, segmentsParent), getUpmostSegment(endContainer, segmentsParent))
    if (range[0] && range[1]) {
      const isSameAs = prev => prev.parent === segmentsParent && prev.range[0] === range[0] && prev.range[1] === range[1]
      resultingSegments = prev => (isSameAs(prev) ? prev : { parent: segmentsParent, range })
    }
  }
  if (textParent) {
    if (rangeNodeNames.text.includes(startContainer.nodeName) && rangeNodeNames.text.includes(endContainer.nodeName)) {
      const [start, end] = getTextOffsets(textParent, startContainer, endContainer, startOffset, endOffset)
      const isSameAs = prev => prev.parent === textParent && prev.range[0] === start && prev.range[1] === end
      resultingText = prev => (isSameAs(prev) ? prev : { parent: textParent, range: [start, end] })
    }
  }
  return segmentsCallback(resultingSegments) || textCallback(resultingText)
}
