import { State } from 'types'
import React from 'react'
import { useSelector } from 'react-redux'
import MessagePopup from 'components/common/MessagePopup/MessagePopup'
import { MainContainer, Container, OverflowWrapper } from './MainLayout.styles'
import { Header } from 'Layouts/components'

const MainLayout: React.FC = ({ children }) => {
  const message = useSelector((state: State) => state.message)

  return (
    <MainContainer>
      <Header/>
      <OverflowWrapper>
        <Container>
          {children}
          <MessagePopup message={message} />
        </Container>
      </OverflowWrapper>
    </MainContainer>
  )
}

export default MainLayout
