import React, { FunctionComponent, Dispatch, SetStateAction, RefCallback, useState, useCallback, useEffect } from 'react'
import useStore, { NavigateQuestionnaireForwardAction, NavigateQuestionnaireBackwardAction } from '___store'

import { Caret } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import { WizardLayoutLeftPaneQuestionnaireConfirmationProps, wizardLayoutLeftPaneQuestionnaireConfirmationClasses as classes } from '../../..'

type UseStoreHookResultType = {
  navigateQuestionnaireForward: NavigateQuestionnaireForwardAction
  navigateQuestionnaireBackward: NavigateQuestionnaireBackwardAction
}

const QuestionnaireConfirmation: FunctionComponent<WizardLayoutLeftPaneQuestionnaireConfirmationProps> = React.memo(({ isLoading, isFetching }) => {
  const { navigateQuestionnaireForward, navigateQuestionnaireBackward } = useStore(
    'navigateQuestionnaireForward',
    'navigateQuestionnaireBackward'
  ) as UseStoreHookResultType
  const [button, setInput]: [HTMLButtonElement | undefined, Dispatch<SetStateAction<HTMLButtonElement | undefined>>] = useState()

  const buttonRef: RefCallback<HTMLButtonElement | undefined> = useCallback(node => node && setInput(node), [])

  useEffect(() => {
    if (button) button.focus()
  }, [button])

  return (
    <div className={classes.wrapper} data-loading={isLoading || isFetching || undefined}>
      <span className={classes.texts.primary}>Almost done. Are you happy with your input?</span>
      <span className={classes.texts.secondary}>
        Pro tip: Review all markers & click on them to make some final tweaks. If everything looks tidy, hit Confirm.
      </span>
      <div className={classes.actions.wrapper}>
        <Button onClick={() => navigateQuestionnaireBackward()}>
          <Caret />
          Go Back
        </Button>
        <Button ref={buttonRef} tertiary onClick={() => navigateQuestionnaireForward()}>
          Confirm
          <Caret />
        </Button>
      </div>
    </div>
  )
})

QuestionnaireConfirmation.displayName = 'WizardLayout-Questionnaire-Confirmation'

export default QuestionnaireConfirmation
