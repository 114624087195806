import React, { FunctionComponent, useMemo } from 'react'

import Cell from './Table.Cell'
import { TableContextProvider, useTableContext } from '..'
import {
  WizardLayoutRightPaneEditorSegmentTableProps,
  WizardLayoutRightPaneEditorSegmentTableHeaderProps,
  WizardLayoutRightPaneEditorSegmentTableBodyProps,
  WizardLayoutRightPaneEditorSegmentTableFooterProps,
  WizardLayoutRightPaneEditorSegmentTableRowProps,
  wizardLayoutRightPaneEditorSegmentTableClasses as classes,
} from '../../../..'

const Row: FunctionComponent<WizardLayoutRightPaneEditorSegmentTableRowProps> = React.memo(({ cells }) => {
  const render = useMemo(() => cells.map(({ id }) => <Cell key={id} id={id} />), [cells])
  return <tr>{render}</tr>
})

const Header: FunctionComponent<WizardLayoutRightPaneEditorSegmentTableHeaderProps> = React.memo(({ rows, index }) => {
  const { id } = useTableContext()
  const render = useMemo(() => rows.map(({ cells }, i) => <Row key={`Table-${id}-Header-${index}-Row-${i}`} cells={cells} />), [rows, id, index])
  return <thead>{render}</thead>
})

const Body: FunctionComponent<WizardLayoutRightPaneEditorSegmentTableBodyProps> = React.memo(({ rows, index }) => {
  const { id } = useTableContext()
  const render = useMemo(() => rows.map(({ cells }, i) => <Row key={`Table-${id}-Body-${index}-Row-${i}`} cells={cells} />), [rows, id, index])
  return <tbody>{render}</tbody>
})

const Footer: FunctionComponent<WizardLayoutRightPaneEditorSegmentTableFooterProps> = React.memo(({ rows, index }) => {
  const { id } = useTableContext()
  const render = useMemo(() => rows.map(({ cells }, i) => <Row key={`Table-${id}-Footer-${index}-Row-${i}`} cells={cells} />), [rows, id, index])
  return <tfoot>{render}</tfoot>
})

export const Table: FunctionComponent<WizardLayoutRightPaneEditorSegmentTableProps> = React.memo(({ id, customStyle, styles, tableSections }) => {
  const render = useMemo(() => {
    if (!(tableSections && Object.keys(tableSections).length)) return null
    return Object.entries(tableSections)
      .map(([section, rows], i) => {
        if (section === 'header')
          return <Header key={`Table-${id}-${section.charAt(0).toUpperCase()}${section.slice(1)}-${i}`} rows={rows!} index={i} />
        if (section === 'body') return <Body key={`Table-${id}-${section.charAt(0).toUpperCase()}${section.slice(1)}-${i}`} rows={rows!} index={i} />
        if (section === 'footer')
          return <Footer key={`Table-${id}-${section.charAt(0).toUpperCase()}${section.slice(1)}-${i}`} rows={rows!} index={i} />
        return null
      })
      .filter(tableSection => tableSection)
  }, [tableSections, id])
  const tableClasses = [classes.wrapper, customStyle, ...styles].filter(cn => cn)
  return (
    <table id={id} className={tableClasses.join(' ')}>
      <TableContextProvider id={id}>{render}</TableContextProvider>
    </table>
  )
})

Table.displayName = 'WizardLayout-RightPane-Editor-Segment-Table'

export default Table
