import React from 'react'
import { Link, Switch, Route, useHistory } from 'react-router-dom'

import { Signature } from 'assets/svgIconComponents'
import { classes } from 'Layouts'
import Documents from './LeftPane.Documents'
import Templates from './LeftPane.Templates'
import { Button } from 'components/CasusComponents'

export const LeftPane = React.memo(() => {
  const history = useHistory()
  const {
    components: {
      leftPane: {
        wrapper: wrapperClass,
        section: { wrapper: sectionClass, navButton: buttonClass },
      },
    },
  } = classes
  return (
    <div className={wrapperClass}>
      <Switch>
        <Route path="/dev/documents">
          <Documents />
        </Route>
        <Route path="/dev/templates">
          <Templates />
        </Route>
      </Switch>
      <div className={sectionClass}>
        <Link className={buttonClass} to="/dev/signatures">
          <Signature />
          Signatures
        </Link>
        <Link className={buttonClass} to="/dev/reminders">
          Reminders
        </Link>
      </div>
      <Button onClick={() => history.push('/documents')}>DOCUMENTS</Button>
      <Button onClick={() => history.push('/templates')}>TEMPLATES</Button>
    </div>
  )
})

LeftPane.displayName = 'LeftPane'

export default LeftPane
