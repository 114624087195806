import useStore, { WizardTableCellContentByIdSelector, WizardState, getMappedSegmentIds, getSegmentById } from '___store'

import { useEditorPreviewContext } from '..'

type UseStoreHookResultType = { wizardTableCellContentById?: WizardTableCellContentByIdSelector }
type UseTextMarkerResultType = { contentIds: string[]; styles: string[] }

export const useTableCell = (id: string): UseTextMarkerResultType => {
  const editorContext = useEditorPreviewContext()

  const staticPseudoState = Object.assign({}, editorContext) as WizardState

  const contextCell = getSegmentById(staticPseudoState, id)[0]
  // ========================================================================= //
  // IMPLEMENT DYNAMIC STYLE FETCHING FROM THE STORE FOR TEMPLATE AUTOMATION MODE
  // const { wizardCellStylesById } = useStore(!contextCell ? `selectWizardCellStylesById[${id}]` : undefined)
  // ========================================================================= //

  const styles =
    contextCell?.styles.concat(contextCell.customStyle) ||
    // || wizardCellStylesById.split(' ')
    []

  const mappedSegmentIds = getMappedSegmentIds(staticPseudoState, id)
  const topLayerContentIds = mappedSegmentIds.map(id => id.split(';').pop())
  const contextTableCellContentIds = `${Array.from(new Set(topLayerContentIds)).join(';')}`

  const { wizardTableCellContentById = '' } = useStore(
    !contextTableCellContentIds ? `selectWizardTableCellContentById[${id}]` : undefined
  ) as UseStoreHookResultType

  const resultingTopLayerContentIds = contextTableCellContentIds || wizardTableCellContentById
  const useTableCellReturn = { contentIds: resultingTopLayerContentIds.split(';'), styles }

  return useTableCellReturn
}

export default useTableCell
