import React, { FunctionComponent, useMemo, useEffect } from 'react'
import useStore, { WizardEditorStylesSelector, WizardDataStructureSelector, WizardSectionsSelector } from '___store'

import { generateNumberingSystemStyleSheet, generateSectionPageLayoutStyleSheet, parseStyleObject } from './helpers'
import { Preview } from './RightPane.Editor.Components'
import { WizardLayoutRightPaneEditorProps, wizardLayoutRightPaneEditorPageClasses as pageClasses } from '../..'

type UseStoreHookResultType = {
  wizardEditorStyles: WizardEditorStylesSelector
  wizardDataStructure: WizardDataStructureSelector
  wizardSections: WizardSectionsSelector
}

export const Editor: FunctionComponent<WizardLayoutRightPaneEditorProps> = React.memo(({ isLoading, isFetching, mode }) => {
  const {
    wizardEditorStyles: styles,
    wizardDataStructure,
    wizardSections,
  } = useStore('selectWizardEditorStyles', 'selectWizardDataStructure', 'selectWizardSections') as UseStoreHookResultType

  const sectionLayouts = useMemo(() => {
    if (!wizardSections?.length) return ''
    const styleSheets = wizardSections.map(({ layout }) => generateSectionPageLayoutStyleSheet(layout))
    return styleSheets.join('/n')
  }, [wizardSections])

  const numberingStyles = useMemo(() => {
    const numberingEntries = Object.entries(Object.assign({}, wizardDataStructure?.numberingSystem, wizardDataStructure?.numberings))
    if (!numberingEntries.length) return {}
    return numberingEntries.reduce(
      (result, [systemKey, levels]) => Object.assign(result, { [systemKey]: generateNumberingSystemStyleSheet(systemKey, levels) }),
      {} as Record<string, string>
    )
  }, [wizardDataStructure])

  const editorStyles = useMemo(() => {
    if (!styles) return
    const standardStyles = parseStyleObject(styles.standardStyles, `.${pageClasses.wrapper} .`).join('\n')
    const customStyles = parseStyleObject(styles.customStyles, '.').join('\n')
    const customProperties = parseStyleObject(styles.customProperties).join('\n')
    return { standardStyles, customStyles, customProperties }
  }, [styles])

  useEffect(() => {
    const styleElements = Object.entries(Object.assign({}, { sectionLayouts }, numberingStyles, editorStyles))
      .map(([key, entry]) => {
        if (!entry) return null
        const styleElement = document.createElement('style')
        styleElement.dataset.sheet = key
        styleElement.setAttribute('type', 'text/css')
        styleElement.innerHTML = entry
        return styleElement
      })
      .filter(element => element)
    styleElements.forEach(element => document.head.appendChild(element!))
    return () => {
      styleElements.forEach(element => document.head.removeChild(element!))
    }
  }, [sectionLayouts, numberingStyles, editorStyles])

  const render = useMemo(() => {
    if (mode === 'preview') return <Preview />
  }, [mode])
  return <>{render}</>
})

Editor.displayName = 'WizardLayout-RightPane-Editor'

export default Editor
