import React, { useEffect, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import useStore from '___store'

import { mapDocumentsFilter } from '___types'
import { QUERY_KEYS } from '___queries'
import { useDocumentList } from '___hooks'
import { Button } from 'components/CasusComponents'
import { DocumentThumbnail } from 'components/document'
import { classes } from 'Layouts'

const LoadMoreButton = React.memo(({ onClick, label, disabled, loading }) => {
  return (
    <Button onClick={onClick} className={classes.components.content.documents.loadMoreButton} disabled={disabled} loading={loading} showSpinner>
      {label}
    </Button>
  )
})

export const Documents = React.memo(() => {
  const { documentsFilter, deselectDocuments } = useStore('selectDocumentsFilter', 'deselectDocuments')
  const { data: { pages = [] } = {}, status, hasNextPage, isFetchingNextPage, fetchNextPage } = useDocumentList(documentsFilter)

  const documents = useMemo(() => {
    const documentEntries = pages?.reduce((acc, page) => acc.concat(page), [])
    return documentEntries.map(({ loading, id, name: title, edited, extension, signees, signed }, i) => {
      const info = { title, edited, extension, signees, signed }
      return <DocumentThumbnail key={`Content-Documents-DocumentThumbnail-${id}`} loading={loading} id={id} info={info} />
    })
  }, [pages])

  const buttonLabel = useMemo(() => {
    if (status === 'error') return 'Error loading documents'
    if (status === 'loading') return 'Loading...'
    if (isFetchingNextPage) return 'Loading more...'
    return 'Load more'
  }, [status, isFetchingNextPage])

  useEffect(() => {
    const deselectDocumentsOnEscKey = event => event.key === 'Escape' && deselectDocuments()
    document.addEventListener('keydown', deselectDocumentsOnEscKey)
    return () => {
      document.removeEventListener('keydown', deselectDocumentsOnEscKey)
      return deselectDocuments()
    }
  }, [deselectDocuments])

  const sectionClass = `${classes.components.content.section.wrapper} ${classes.components.content.documents.wrapper}`

  const queryClient = useQueryClient()

  return (
    <div className={sectionClass}>
      {documents}
      {hasNextPage ? (
        <LoadMoreButton
          onClick={() => fetchNextPage()}
          label={buttonLabel}
          disabled={!hasNextPage || isFetchingNextPage}
          loading={status === 'loading' || isFetchingNextPage}
        />
      ) : null}
      <Button
        className={classes.components.content.documents.loadMoreButton}
        tertiary
        onClick={() => {
          console.log('RESETTING: ', documentsFilter)
          queryClient.setQueryData([QUERY_KEYS.DOCUMENTS, mapDocumentsFilter(documentsFilter)], () => {
            return { pages: [], pageParams: [] }
          })
          queryClient.removeQueries([QUERY_KEYS.DOCUMENTS, mapDocumentsFilter(documentsFilter)])
        }}
      >
        RESET TEST
      </Button>
    </div>
  )
})

Documents.displayName = 'Content-Documents'

export default Documents
