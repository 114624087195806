import React, { useMemo } from 'react'
import useStore from '___store'

import { SEGMENT_TAGS, SEGMENT_TYPES } from 'Wizard/constants'
import { Paragraph, Table } from './index'

const sectionTypes = [SEGMENT_TYPES.tableHeader, SEGMENT_TYPES.tableBody, SEGMENT_TYPES.tableFooter]

const Segment = React.memo(({ id }) => {
  const { segmentInfo = {} } = useStore(`selectSegmentInfo[${id}]`)
  const { tag = SEGMENT_TAGS[SEGMENT_TYPES.paragraph], customStyle = '', styles = [], textChunks = [], /* header, body, footer */ } = segmentInfo

  const tableSections = useMemo(
    () => sectionTypes.reduce((acc, cur) => (segmentInfo[cur]?.length ? Object.assign(acc, { [cur]: segmentInfo[cur] }) : acc), {}),
    [segmentInfo]
  )
  if (tag === SEGMENT_TAGS[SEGMENT_TYPES.paragraph]) return <Paragraph id={id} styles={`${customStyle} ${styles.join(' ')}`} chunks={textChunks} />
  if (tag === SEGMENT_TAGS[SEGMENT_TYPES.table]) return <Table id={id} styles={`${customStyle} ${styles.join(' ')}`} tableSections={tableSections} />
  // const { segmentTag: tag } = useStore(`selectSegmentTag[${id}]`)

  // console.log(`SEGMENT %c${id}`, 'color: lightgreen;', 'RENDER!')

  // const render = useMemo(() => {
  //   if (!tag) return null
  //   switch (tag) {
  //     case SEGMENT_TAGS[SEGMENT_TYPES.paragraph]:
  //       return <Paragraph id={id} />
  //     case SEGMENT_TAGS[SEGMENT_TYPES.table]:
  //       return <Table id={id} />
  //     default:
  //       return null
  //   }
  // }, [id, tag])

  // return render
  return null
})

Segment.displayName = 'Wizard-Editor-Segment'

export default Segment
