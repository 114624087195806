import React, { FunctionComponent } from 'react'
import { DateTime } from 'luxon'
import useStore, { SelectedDocumentIdSelector } from '___store'

import { DateTimeC } from '___types'
import { useDocument } from '___hooks'
import { Clock, Person, Preview, Spinner, Template, VerticalEllipsis } from 'assets/svgIconComponents'
import {
  Button,
  Dropdown,
  // DropdownOption
} from 'components/CasusComponents'
import { MainLayoutRightPaneDocumentInfoSectionProps, mainLayoutRightPaneDocumentClasses as classes } from '../..'

const DocumentInfoSection: FunctionComponent<MainLayoutRightPaneDocumentInfoSectionProps> = React.memo(({ template, owner, created }) => {
  const { hour = 0, minute = 0, second = 0 } = created?.c || {}
  const m = minute + second / 60
  const h = hour + m / 60
  const sectionClass = `${classes.section} ${classes.info.wrapper}`
  return (
    <div className={sectionClass}>
      <div className={classes.info.snippet}>
        <Template />
        <span>{template}</span>
      </div>
      <div className={classes.info.snippet}>
        <Person />
        <span>{owner}</span>
      </div>
      <div className={classes.info.snippet}>
        <Clock h={h} m={m} />
        <span>{created?.toLocaleString(DateTime.DATETIME_SHORT)}</span>
      </div>
    </div>
  )
})

DocumentInfoSection.displayName = 'RightPane-Document-InfoSection'

const DocumentActionsSection: FunctionComponent = React.memo(() => {
  const sectionClass = `${classes.section} ${classes.actions.wrapper}`
  return (
    <div className={sectionClass}>
      <Button className={classes.actions.button} primary onClick={() => console.log('PREVIEW!')}>
        <Preview />
        Preview
      </Button>
      <Button className={classes.actions.button} onClick={() => console.log('EDIT!')}>
        Edit
      </Button>
      <Dropdown className={classes.actions.dropdown} replaceCaret={<VerticalEllipsis />}>
        pera
        <div />
        {/* <DropdownOption id="pera" label="mika" />
        <DropdownOption id="zika" label="franjo" />
        <DropdownOption id="omg" label="wtf" /> */}
      </Dropdown>
    </div>
  )
})

DocumentActionsSection.displayName = 'RightPane-Document-ActionsSection'

type DocumentUseStoreHookResultType = { selectedDocumentId: SelectedDocumentIdSelector }

const Document: FunctionComponent = React.memo(() => {
  const { selectedDocumentId } = useStore('selectSelectedDocumentId') as DocumentUseStoreHookResultType
  const { isLoading, data: document } = useDocument(selectedDocumentId!)

  const { name: title, templateName: template, author: { firstName = '', lastName = '' } = {}, created } = document || {}
  const owner = [firstName, lastName].join(' ')
  const createdDateTime = DateTime.fromMillis(isLoading ? 0 : created!?._seconds * 1000) as DateTimeC

  return (
    <div className={classes.wrapper} data-loading={isLoading || undefined}>
      {isLoading ? (
        <Spinner fill="rgba(30, 144, 255, 0.15)" strokeWidth="1" strokeFill={0.25} />
      ) : (
        <>
          <span className={classes.title}>{title}</span>
          <DocumentInfoSection template={template} owner={owner} created={createdDateTime} />
          <DocumentActionsSection />
          <div className={classes.section}>ESIG</div>
          <div className={classes.section}>SHARE</div>
        </>
      )}
    </div>
  )
})

Document.displayName = 'RightPane-Document'

export default Document
