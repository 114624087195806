import React, { useEffect } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'
import * as Sentry from '@sentry/react'

import { useAuthenticateUser } from 'hooks/useAuthenticateUser'
import { useLoadMasterdata } from 'queries/app/useLoadMasterdata'
import { useAuthenticateCalendly } from 'queries/auth/useAuthenticateCalendly'
import { useBrowserVersionCheck } from 'hooks/useBrowserVersionCheck'
import { useMaintenanceCheck } from 'hooks/useMaintenanceCheck'

import { Redirects } from 'components/helpers/redirects/Redirects'
import AuthenticatedRouter from './pages/Authenticated/Authenticated.page'
import ErrorPage from './pages/Public/ErrorPage/ErrorPage'
import ErrorPageForFallback from './pages/Public/ErrorPage/ErrorPage'
import NewVersion from './pages/Public/NewVersion/NewVersion'
import IntercomWrapper from './components/helpers/intercom/Intercom'
import { MaintenancePage } from 'pages/Public/Maintenance/Maintenance.page'
import QuestionnairePublic from 'pages/Public/QuestionnairePublic/QuestionnairePublic.page'
import AuthenticationPage from 'pages/Auth/AuthenticationPage'
import MobileInfoScreen from 'components/MobileInfoScreen/MobileInfoScreen'
import { Loader } from 'components/common'
import { isDev } from 'config'

import './style.scss'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'utils/other/i18n/i18n.constants'
import StepZero from 'pages/Public/AssociationsFlow/StepZero/StepZero'
import FinalStep from 'pages/Public/AssociationsFlow/FinalStep'

const App: React.FC = () => {
  const { pathname } = useLocation()
  const { data: masterdata } = useLoadMasterdata()
  const authIsLoading = useAuthenticateUser()
  const calendlyAuth = useAuthenticateCalendly()
  useBrowserVersionCheck()
  const isMaintenance = useMaintenanceCheck()
  const { i18n } = useTranslation('translation')

  useEffect(() => {
    ReactGA.set({
      page: pathname,
    })
    ReactGA.pageview(pathname)
  }, [pathname])

  if (isMaintenance) return <MaintenancePage />

  if (!masterdata || authIsLoading || calendlyAuth.isLoading || !i18n.hasLoadedNamespace(namespaces.translation)) return <Loader />
  // console.log(i18n.getResourceBundle('de_CH', "translation"))

  return (
    <Sentry.ErrorBoundary fallback={ErrorPageForFallback}>
      {!isDev && <IntercomWrapper />}
        <Switch>
          <Route path="/redirect" component={Redirects} />
          <Route exact path="/public/:templateId" component={StepZero} />
          <Route path="/public/questionnaire/:templateId" component={QuestionnairePublic} />
          <Route path="/public/download/:documentId" component={FinalStep} />
          <Route path="/auth" component={AuthenticationPage} />
          <Route exact path="/error" component={ErrorPage} />
          <Route exact path="/newversion" component={NewVersion} />
          <Route path="/" component={AuthenticatedRouter} />
        </Switch>
        <MobileInfoScreen />
    </Sentry.ErrorBoundary>
  )
}

export default Sentry.withProfiler(App)
