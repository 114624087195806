import React from 'react'
import { classes } from '.'
// import { useTranslation } from 'react-i18next'
import FeaturesImage from 'assets/features.png'
import { Button } from 'components/CasusComponents'

const SubscriptionAdPanel: React.FC = () => {
  // const { t } = useTranslation()

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>Subscribe now to unlock all features</div>
      <div className={classes.image}>
        <img src={FeaturesImage} alt="Features" />
      </div>
      <div className={classes.button.wrapper}>
        <Button secondary className={classes.button.item} onClick={() => window.open('https://casus.com/pricing', '_blank')}>
          Subscribe now
        </Button>
      </div>
    </div>
  )
}

export default SubscriptionAdPanel
