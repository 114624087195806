import React from 'react'

import { className } from './index'

const Person = React.memo(props => {
  const { strokeWidth = 8, fill = 'none' } = props

  const path = 'M 20 90 C 10 90 20 60 50 60 C 80 60 90 90 80 90 Z'
  return (
    <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
      <path d={path} stroke="currentColor" strokeWidth={`${strokeWidth}`} strokeLinejoin="round" fill={`${fill}`} />
      <ellipse cx="50" cy="35" rx="12" ry="15" stroke="currentColor" strokeWidth={`${strokeWidth}`} fill={`${fill}`} />
    </svg>
  )
})

export { Person }
export default Person
