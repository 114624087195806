import React, { FunctionComponent } from 'react'
import useStore, { SelectedDocumentIdSelector, SelectedTemplateIdSelector } from '___store'

import { MainLayoutProps, classes } from '.'
import { RightPane } from './components'
import { Header, LeftPane, Content, Footer } from '../components'

type UseStoreHookResultType = { selectedDocumentId: SelectedDocumentIdSelector; selectedTemplateId: SelectedTemplateIdSelector }

export const MainLayout: FunctionComponent<MainLayoutProps> = React.memo(() => {
  const { selectedDocumentId, selectedTemplateId } = useStore('selectSelectedDocumentId', 'selectSelectedTemplateId') as UseStoreHookResultType

  const showRightPane = selectedDocumentId || selectedTemplateId || undefined

  return (
    <div className={classes.wrapper} data-show-header data-show-left-pane data-show-right-pane={showRightPane}>
      <Header />
      <LeftPane />
      <Content />
      <RightPane />
      <Footer />
    </div>
  )
})

MainLayout.displayName = 'MainLayout'

export default MainLayout
