import React, { useEffect } from 'react'
import { Heading } from '../../common-styles'
import { Form } from '../LoginForm.styles'
import { CustomButton } from 'components/common'
import { useTranslation } from 'react-i18next'
import { firebase } from 'config/firebase'
import AuthApi from 'api/auth.api'

interface Props {
  isLoading: boolean
  handleSubmit: React.FormEventHandler
}

const LoginForm: React.FC<Props> = ({ isLoading, handleSubmit }) => {
  const { t } = useTranslation()

  const checkLogin = () => {
    firebase
      .auth()
      .getRedirectResult()
      .then(async result => {
        if (result.user) {
          console.info('SUCCESS', result)

          await AuthApi.authenticate(result.user)
        } else {
          // console.warn("NO RESULT", result)
        }
        // User is signed in.

        // Provider data available in result.additionalUserInfo.profile,
        // or from the user's ID token obtained from result.user.getIdToken()
        // as an object in the firebase.sign_in_attributes custom claim.
      })
      .catch(error => {
        // Handle error.
        console.error('ERRR', error)
      })
  }

  useEffect(checkLogin)

  const handleLogin = () => {
    if (!process.env.REACT_APP_SAML_VISECA_ID) throw new Error('REACT_APP_SAML_VISECA_ID not set')
    const provider = new firebase.auth.SAMLAuthProvider(process.env.REACT_APP_SAML_VISECA_ID)
    firebase.auth().signInWithRedirect(provider)
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Heading variant="h3">Login ({process.env.REACT_APP_NODE_ENV})</Heading>
      <CustomButton isLoading={isLoading} btntype="secondary" size="large" width="100%" disabled={isLoading} onClick={handleLogin}>
        {!isLoading ? 'Viseca Sign In' : t('one_moment_please')}
      </CustomButton>
    </Form>
  )
}

export default LoginForm
