import React, { FunctionComponent } from 'react'
// import { v4 as uuid } from 'uuid'

import {
  ClockProps,
  // VIEWPORT_SIZE,
  className,
} from './index'

const Clock: FunctionComponent<ClockProps> = React.memo(props => {
  const { strokeWidth = 10, h = 4, m = 0 } = props

  const minuteAngle = (m / 60) * 2 * Math.PI
  const hourAngle = (h / 12) * 2 * Math.PI
  const minuteHandLength = 25
  const hourHandLength = 20

  // const maskId = useMemo(uuid, [])

  return (
    <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
      <defs>
        {/* <mask id={maskId} x={`-${strokeWidth}`} y={`-${strokeWidth}`} width={`${VIEWPORT_SIZE}`} height={`${VIEWPORT_SIZE}`}>
          <rect x="0" y="0" width={`${VIEWPORT_SIZE}`} height={`${VIEWPORT_SIZE}`} stroke="none" fill="white" />
          <circle cx="50" cy="50" r={`${strokeWidth / 2}`} stroke="black" strokeWidth={`${strokeWidth / 2}`} fill="black" />
        </mask> */}
      </defs>
      <path
        d={`M ${50 + Math.sin(hourAngle) * hourHandLength} ${50 - Math.cos(hourAngle) * hourHandLength} L 50 50 L ${
          50 + Math.sin(minuteAngle) * minuteHandLength
        } ${50 - Math.cos(minuteAngle) * minuteHandLength}`}
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        // mask={`url(#${maskId})`}
      />
      {/* <circle cx="50" cy="50" r={`${strokeWidth / 2}`} stroke="none" fill="currentColor" /> */}
      <circle cx="50" cy="50" r="40" stroke="currentColor" strokeWidth={strokeWidth} fill="none" />
    </svg>
  )
})

export { Clock }
export default Clock
