import { Icon, MenuPopover } from 'components/common'
import { useDocumentFileActions } from 'hooks/useDocumentFileActions/useDocumentFileActions'
import { useDocumentsUpdate } from 'queries/documents'
import { useDocumentsDuplicate } from 'queries/documents/useDocumentDuplicate'
import React, { useCallback, useState } from 'react'
import './style.scss'
import { POPUP_CONFIRM_DELETE_FILE, POPUP_CONFIRM_TRASH_FILE } from 'constants/confirmation-popups'
import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { useConfirmPopup } from 'providers/ConfirmPopupProvider'
import { useHistory } from 'react-router-dom'
import { useDetails } from 'components/DetailsSidebar/provider/DetailsProvider'
import { useTranslation } from 'react-i18next'

const classes = {
  container: 'actions-container',
  row: 'actions-row',
  primary: 'primary-button',
  secondary: 'secondary-button',
  more: 'more-button',
}

export const DocumentActions = ({ data, type }) => {
  const update = useDocumentsUpdate()
  const duplicate = useDocumentsDuplicate()
  const { push, location } = useHistory()
  const { clear } = useDetails()
  const { popup, close, setIsLoading } = useConfirmPopup()

  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState(null)

  const { currentFolder } = useCurrentFolder(type === 'template' ? 'templates' : type === 'document' ? 'docs' : null)
  const { type: folderType } = currentFolder

  // const superadmins = useMasterData()?.superadmins ?? []
  const { onEdit, onOpen, onDownload } = useDocumentFileActions()

  const deleteDocument = useCallback(
    id => {
      const status = folderType === 'trashed' ? 'deleted' : 'trashed'
      const POPUP = folderType === 'trashed' ? POPUP_CONFIRM_DELETE_FILE : POPUP_CONFIRM_TRASH_FILE

      popup({
        ...POPUP,
        onConfirm: () => {
          setIsLoading(true)
          update.mutate(
            { id: id, data: { status } },
            {
              onSuccess: () => {
                close()
                clear()
              },
              onSettled: () => setIsLoading(false),
            }
          )
        },
        onCancel: close,
      })
    },
    [close, clear, popup, setIsLoading, folderType, update]
  )

  const structure = [
    [
      {
        type: 'primary',
        iconName: 'preview',
        label: t('preview'),
        action: () => onOpen(data.id),
        disabled: false,
      },
      {
        type: 'secondary',
        iconName: 'edit',
        label: t('edit'),
        action: () => onEdit(data),
        disabled: type === 'signature',
      },
    ],
  ]

  const menuItems = [
    {
      type: 'secondary',
      icon: 'docx',
      label: `${t('download')} DOCX`,
      handleClick: () => onDownload(data.id, 'docx'),
      disabled: false,
    },
    {
      type: 'secondary',
      icon: 'pdf',
      label: `${t('download')} PDF`,
      handleClick: () => onDownload(data.id, 'pdf'),
      disabled: false,
    },
    {
      type: 'secondary',
      icon: 'move',
      label: t('move_document'),
      handleClick: () => push(`${location.pathname}?file=${data.id}&action=move`),
      disabled: false,
    },
    {
      type: 'secondary',
      icon: 'duplicate',
      label: t('duplicate'),
      handleClick: () => {
        duplicate.mutate(data)
        clear()
      },
      disabled: false,
    },
    {
      type: 'secondary',
      icon: 'trash',
      label: t('delete'),
      action: () => deleteDocument(data.id),
      disabled: false,
    },
  ].filter(item => !item.disabled)

  const renderButton = (key, type, iconName, label, action, disabled) => {
    return (
      !disabled && (
        <button title={label} key={key} onClick={() => action()} type="button" className={classes[type]}>
          <Icon iconName={iconName} fill="#9ca4b5" /> {label}
        </button>
      )
    )
  }

  return (
    <div className={classes.container}>
      {structure.map((row, rowIndex) => (
        <div key={rowIndex} className={classes.row}>
          {row.map((item, index) => renderButton(index, item.type, item.iconName, item.label, item.action, item.disabled))}
          <button title={'more'} key={'more'} onClick={e => setAnchorEl(e.currentTarget)} type="button" className={classes.more}>
            <Icon iconName="more" fill="#9ca4b5" />
          </button>
          <MenuPopover
            anchorEl={anchorEl}
            handleClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            menuItems={menuItems}
          />
        </div>
      ))}
    </div>
  )
}

