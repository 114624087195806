import { QueryFunctionContext, useInfiniteQuery } from 'react-query'

import { MAPPED_DOCUMENTS_FILTERS, MappedDocumentsFilter } from '___types'
import { documentsAPI, documentListPerPage } from '___api'
import { QUERY_KEYS } from '___queries'

const getDocumentListQueryFunction = ({ queryKey, pageParam }: QueryFunctionContext) =>
  documentsAPI.getDocumentList(queryKey[1] as MappedDocumentsFilter, pageParam as string | null)

export const useFetchDocumentList = (filter?: MappedDocumentsFilter | null) => {
  const documentListQuery = useInfiniteQuery({
    queryKey: [QUERY_KEYS.DOCUMENTS, filter],
    queryFn: getDocumentListQueryFunction,
    getNextPageParam: (lastPage = []) => {
      if (lastPage.length < documentListPerPage) return
      const lastDocument = lastPage[lastPage.length - 1]
      return lastDocument?.id
    },
    staleTime: filter === MAPPED_DOCUMENTS_FILTERS.MINE ? Infinity : 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  })
  return documentListQuery
}

export default useFetchDocumentList
