import { IAnswer } from 'types'
import React, { useState, useEffect } from 'react'
import Validator from 'validator'
import {
  ScreenContainer,
  InputGroupContainer,
  Container,
  ButtonsContainer,
} from '../../../QuestionnaireScreen/QuestionnaireScreen.styles'
import { CustomButton, ButtonSquare, Icon } from '../../../../common'
import QuestionDescriptionDrawer from '../../../QuestionnaireScreen/QuestionDescriptionDrawer/QuestionDescriptionDrawer'
import QuestionComponent from '../../../QuestionnaireScreen/Question/Question'
import TableOfContent from '../../../QuestionnaireScreen/TableOfContent/TableOfContent'
import { secondary } from 'config/themes/colors'
import { questionToAnswerType, questionToAnswerState } from './services'
import { deepExtractSubquestionsFromOption } from 'utils'
import { QUESTION_TYPE_NUMBER } from 'constants/question-types'
import { QuestionFlattedMap } from './services/createQuestionMap'
import { initQuestionsToAnswers } from '../../services/initQuestionsToAnswers'
import { useTranslation } from 'react-i18next'

export interface IsOnEdgeQuestion {
  goBack?: boolean
  moveOn?: boolean
}

interface Props {
  editQuestionId?: string | null
  questionsMap: QuestionFlattedMap
  saving: boolean
  isCasus: boolean
  values: { [key: string]: IAnswer }
  finish: boolean
  updateAnswers: (qid: string, value: IAnswer, props: IsOnEdgeQuestion) => void
}

const QuestionaireScreenDocGen: React.FC<Props> = ({
  editQuestionId,
  questionsMap,
  values,
  saving,
  isCasus,
  finish,
  updateAnswers,
}) => {
  const firstQuestion = questionsMap.order[0]

  const [theOrderOfQuestions, setTheOrderOfQuestions] = useState(questionsMap.order)

  const [showInfo, setShowInfo] = useState(true)
  const [showTableOfContent, setShowTableOfContent] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [error, setError] = useState<string | boolean>(false)
  const { t } = useTranslation()

  const initQuestion =
    questionsMap.questions[editQuestionId ? editQuestionId : firstQuestion]

  const [answer, setAnswer] = useState<IAnswer>(
    editQuestionId && !values[editQuestionId]
      ? initQuestionsToAnswers([questionsMap.questions[editQuestionId]])[0]
      : {
          ...values[editQuestionId ? editQuestionId : firstQuestion],
          questionId: initQuestion.id,
          type: questionToAnswerType(initQuestion.type),
        }
  )

  const [index, setIndex] = useState(
    theOrderOfQuestions.findIndex(id =>
      !editQuestionId ? id === firstQuestion : id === editQuestionId
    )
  )

  const [currentQuestion, setCurrentQuestion] = useState(initQuestion)

  const { description, answerExample } = currentQuestion

  useEffect(() => {
    updateAnswers(currentQuestion.id, answer, { moveOn: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (finish) {
      updateAnswers(currentQuestion.id, answer, { moveOn: true })
      setDisabled(true)
    }
  }, [finish, updateAnswers, answer, currentQuestion.id])

  const handleQuestionChange = (value: string | string[]): void => {
    const isValid = isNumberAnswerValid(value)
    if (!isValid) return
    setAnswer({
      questionId: currentQuestion.id,
      type: questionToAnswerType(currentQuestion.type),
      value,
    })
  }

  const isNumberRangeValid = () => {
    setError(false)
    if (currentQuestion.type !== QUESTION_TYPE_NUMBER) return true
    const isValid = isNumberAnswerValid(answer.value)
    if (!isValid) return false
    const rangeFrom = currentQuestion.rangeFrom
    const rangeTo = currentQuestion.rangeTo
    const theValue = answer.value.toString().replace('-', '')

    if (rangeFrom !== 0 || rangeTo !== 0) {
      if (Number(theValue) > Number(rangeTo) || Number(theValue) < Number(rangeFrom)) {
        setError(`Bitte geben Sie eine Zahl zwischen ${rangeFrom} und ${rangeTo} ein.`)
        return false
      }
    }
    return true
  }

  const checkNum = (val: string) => {
    let isErr = false
    for (const char of val) {
      if (['.', '-', ','].includes(char) || Validator.isNumeric(char)) continue
      isErr = true
    }

    const last = val[val.length - 1]
    const secondLast = val[val.length - 2]
    const first = val[0]

    if (val.includes('-') && (last !== '-' || (last === '-' && secondLast === '-')))
      isErr = true
    if (['.', '-', ','].includes(first)) isErr = true

    setError(isErr)

    return isErr ? false : true
  }

  const isNumberAnswerValid = (value: string | string[]) => {
    if (!value) {
      setError(false)
      return true
    }
    setError(false)
    if (currentQuestion.type !== QUESTION_TYPE_NUMBER) return true
    if (typeof value !== 'string') return setAnswer(prev => ({ ...prev, value: '' }))
    return checkNum(value)
  }

  const updateQuestionOrder = (): string[] => {
    if (!currentQuestion.options) {
      return theOrderOfQuestions
    }

    let newOrderOfQuestions = [...theOrderOfQuestions]
    const indexOfCurrent = theOrderOfQuestions.findIndex(
      qid => qid === currentQuestion.id
    )

    if (indexOfCurrent === -1) {
      alert('Something went very wrong')
      return theOrderOfQuestions
    }

    let totalSubqCount = 0
    currentQuestion.options.forEach((opt, i) => {
      if (answer?.value?.includes(opt.id)) {
        // inject subquestions from selected options
        const indexToInsert = indexOfCurrent + 1 + totalSubqCount
        newOrderOfQuestions.splice(indexToInsert, 0, ...opt.subquestions.map(s => s.id))
        totalSubqCount += opt.subquestions.length
      } else {
        // remove subquestions from not selected options
        const subquestions = deepExtractSubquestionsFromOption(opt)
        newOrderOfQuestions = newOrderOfQuestions.filter(
          qid => !subquestions.some(q => qid === q.id)
        )
      }
    })

    const newOrderUnique = [...new Set(newOrderOfQuestions)]
    setTheOrderOfQuestions(newOrderUnique)
    return newOrderUnique
  }

  const handleNavigateQuestion = (nextIndex: number): void => {
    const order = updateQuestionOrder()
    const qid = order[nextIndex]
    const theQuestion = questionsMap.questions[qid]
    const theAnswer = values[qid] ? values[qid] : questionToAnswerState(theQuestion)
    setCurrentQuestion(theQuestion)
    setAnswer({
      ...theAnswer,
      type: questionToAnswerType(theQuestion.type),
      questionId: theQuestion.id,
    })
    setIndex(nextIndex)
  }

  const onNext = (): void => {
    const isValid = isNumberRangeValid()
    if (!isValid) return
    const order = updateQuestionOrder()
    const nextQ = questionsMap.questions[order[index + 1]]

    let moveOn = index + 1 === order.length || !nextQ

    // Check if editing question has related subquestions
    // PROBLEM ??
    if (editQuestionId && !moveOn) {
      moveOn = nextQ?.id !== editQuestionId && !nextQ?.parentId
      if (nextQ?.parentId) moveOn = !nextQ.parentId.includes(editQuestionId)
    }

    setDisabled(moveOn)
    updateAnswers(currentQuestion.id, answer, { moveOn })

    if (index < order.length - 1) {
      handleNavigateQuestion(index + 1)
    }
  }

  const onBack = (): void => {
    let goBack = index === 0
    let moveOn = false
    if (editQuestionId && currentQuestion.id === editQuestionId) {
      moveOn = true
      goBack = false
    }
    updateAnswers(currentQuestion.id, answer, { goBack, moveOn })
    if (index > 0) handleNavigateQuestion(index - 1)
  }

  const handleEditQuestion = (qid: string): void => {
    const editIndex = theOrderOfQuestions.findIndex(q => q === qid)
    if (editIndex > -1) {
      setIndex(editIndex)
      const theQ = questionsMap.questions[qid]
      setCurrentQuestion(questionsMap.questions[qid])
      setAnswer(
        values[qid]
          ? { ...values[qid], type: questionToAnswerType(theQ.type), questionId: theQ.id }
          : initQuestionsToAnswers([questionsMap.questions[qid]])[0]
      )
      setShowTableOfContent(false)
    }
  }

  const handleOpenTOC = (): void => {
    setShowTableOfContent(true)
    updateAnswers(currentQuestion.id, answer, {})
  }

  const handleCloseTOC = (): void => {
    setShowTableOfContent(false)
    updateAnswers(currentQuestion.id, answer, {})
  }

  const backDisabled = index === 0 && !editQuestionId
  return (
    <ScreenContainer>
      <TableOfContent
        currentQuestionId={currentQuestion.id}
        questionsMap={questionsMap}
        showTableOfContent={showTableOfContent!}
        values={values}
        isCasus={isCasus}
        handleOpen={handleOpenTOC}
        handleClose={handleCloseTOC}
        handleEditQuestion={handleEditQuestion}
      />
      <Container>
        {!showTableOfContent && (
          <InputGroupContainer data-intercom-target='question-box-intercom'>
            <QuestionComponent
              key={currentQuestion.id}
              question={currentQuestion}
              disabled={disabled}
              questionIndex={index}
              value={answer}
              error={error}
              handleChange={handleQuestionChange}
            />
            <ButtonsContainer>
              <ButtonSquare
                btntype='secondary'
                size={48}
                style={{ marginRight: 16 }}
                disabled={saving || disabled || backDisabled}
                onClick={onBack}
              >
                <Icon iconName='arrow-left' fill={secondary.main} />
              </ButtonSquare>
              <CustomButton
                onClick={onNext}
                btntype='primary'
                size='large'
                width={162}
                disabled={saving || disabled}
                isLoading={saving}
              >
                {t('next')}
                {!saving && !disabled && (
                  <Icon
                    iconName='arrow-right'
                    fill='#fff'
                    style={{ marginLeft: 'auto' }}
                  />
                )}
              </CustomButton>
            </ButtonsContainer>
          </InputGroupContainer>
        )}
      </Container>
      {showInfo && (
        <QuestionDescriptionDrawer
          questionType={currentQuestion.type}
          answerExample={answerExample}
          description={description}
        />
      )}
      <ButtonSquare
        onClick={() => setShowInfo(prev => !prev)}
        size={24}
        btntype='secondary-static'
        style={{ position: 'absolute', top: 8, right: 8 }}
      >
        <Icon iconName={showInfo ? 'arrow-right' : 'arrow-left'} fill={secondary.main} />
      </ButtonSquare>
    </ScreenContainer>
  )
}

export default QuestionaireScreenDocGen
