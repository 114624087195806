import Header from './Header'

import './style.scss'

export type HeaderProps = {}
export type HeaderNavigationProps = {
    templateDisabled?: boolean
    teamDisabled?: boolean
}
export type HeaderProfileProps = {}

export { Header }
export default Header
