import React, { FunctionComponent, RefCallback, Dispatch, SetStateAction, useState, useMemo, useCallback, useEffect } from 'react'
import useStore, {
  WizardQuestionOrderSelector,
  WizardAnswersSelector,
  NavigateQuestionnaireForwardAction,
  NavigateQuestionnaireToAction,
} from '___store'
import { getUnansweredQuestionInfo } from '___store/storeSegments/wizard/typified/helpers'

import { Caret } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import {
  WizardLayoutLeftPaneQuestionnaireSkippedQuestionReviewProps,
  wizardLayoutLeftPaneQuestionnaireSkippedQuestionReviewClasses as classes,
} from '../../..'

type UseStoreHookResultType = {
  wizardQuestionOrder: WizardQuestionOrderSelector
  wizardAnswers: WizardAnswersSelector
  navigateQuestionnaireForward: NavigateQuestionnaireForwardAction
  navigateQuestionnaireTo: NavigateQuestionnaireToAction
}

const QuestionnaireSkippedQuestionReview: FunctionComponent<WizardLayoutLeftPaneQuestionnaireSkippedQuestionReviewProps> = React.memo(
  ({ isLoading, isFetching }) => {
    const { wizardQuestionOrder, wizardAnswers, navigateQuestionnaireForward, navigateQuestionnaireTo } = useStore(
      'selectWizardQuestionOrder',
      'selectWizardAnswers',
      'navigateQuestionnaireForward',
      'navigateQuestionnaireTo'
    ) as UseStoreHookResultType
    const [button, setInput]: [HTMLButtonElement | undefined, Dispatch<SetStateAction<HTMLButtonElement | undefined>>] = useState()

    const [firstUnansweredQuestionOrderId, unansweredQuestionCount] = useMemo(
      () => getUnansweredQuestionInfo(wizardAnswers || [], wizardQuestionOrder || []),
      [wizardAnswers, wizardQuestionOrder]
    )

    const buttonRef: RefCallback<HTMLButtonElement | undefined> = useCallback(node => node && setInput(node), [])

    useEffect(() => {
      if (button) button.focus()
    }, [button])

    return (
      <div className={classes.wrapper} data-loading={isLoading || isFetching || undefined}>
        <span className={classes.texts.primary}>
          You haven't answered {unansweredQuestionCount} question{unansweredQuestionCount > 1 ? 's' : ''} that{' '}
          {unansweredQuestionCount > 1 ? 'are' : 'is'} required to complete this document.
        </span>
        <span className={classes.texts.secondary}>
          You can answer {unansweredQuestionCount > 1 ? 'them' : 'it'} now or return once you have the required information to complete the document.
          We'll save the document in drafts and safely store your other answers.
        </span>
        <div className={classes.actions.wrapper}>
          {firstUnansweredQuestionOrderId ? (
            <Button ref={buttonRef} tertiary onClick={() => navigateQuestionnaireTo(firstUnansweredQuestionOrderId)}>
              <Caret />
              Review Questions
            </Button>
          ) : null}
          <Button onClick={() => navigateQuestionnaireForward()}>
            Continue
            <Caret />
          </Button>
        </div>
      </div>
    )
  }
)

QuestionnaireSkippedQuestionReview.displayName = 'WizardLayout-Questionnaire-SkippedQuestionReview'

export default QuestionnaireSkippedQuestionReview
