import { FolderItem } from 'types'
import React from 'react'
import FolderStructure from 'components/Sidebar/FolderStructure/FolderStructure'
import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { useRootFolder } from 'hooks/useRootFolder'
import { useHistory } from 'react-router'
import { useUser } from 'queries/user/useUser'
import { FolderStructureAdapter } from 'components/Sidebar/FolderStructure/services/FolderStructureAdapter'
import { documentsRoutes } from 'constants/routes'
import { useTranslation } from 'react-i18next'

const SidebarFolderTree: React.FC = () => {
  const { currentFolder, setCurrentFolder } = useCurrentFolder('docs')
  const { rootFolder } = useRootFolder('docs')
  const { location } = useHistory()
  const user = useUser()
  const { t } = useTranslation()

  const documentCreationActive = location.pathname.startsWith('/dokument')

  const handleFolderClick = (folder: FolderItem) => {
    if (currentFolder.id !== folder.id) setCurrentFolder(folder)
  }

  return (
    <FolderStructure
      rootFolder={FolderStructureAdapter.adaptRootChildrenToUserSubscriptionType(
        rootFolder,
        user?.subscription.type!,
        'docs'
      )}
      currentFolder={currentFolder}
      handleFolderClick={handleFolderClick}
      type={documentCreationActive ? 'dark' : 'white'}
      withReminders
      baseUrl={t(documentsRoutes.main)}
    />
  )
}

export default SidebarFolderTree
