import React, { useMemo } from 'react'
import useStore from '___store'

import { ButtonGroup, Button, Input } from 'components'

import Logo from 'assets/logoDark.svg'

const classes = {
  wrapper: 'Wizard-Header-wrapper',
  header: 'Wizard-Header-header',
  logo: 'Wizard-Header-logo',
  title: 'Wizard-Header-title',
  editButton: 'Wizard-Header-editButton',
  buttonGroup: 'Wizard-Header-buttonGroup',
  button: 'Wizard-Header-button',
  info: 'Wizard-Header-info',
}

export const Header = React.memo(props => {
  const {
    // configuringStack = [], // for header breadcrumbs
    dirty,
    title,
    dataStructure,
    locations,
    questions,
    questionLayout,
    answers,
    mode,
    updateTitle,
  } = useStore(
    // 'selectConfiguringStack',
    'selectDirty',
    'selectTitle',
    'selectDataStructure',
    'selectLocations',
    'selectQuestions',
    'selectQuestionLayout',
    'selectAnswers',
    'selectMode',
    'updateTitle'
  )
  const { onCloseHandler, onSaveHandler } = props

  const input = useMemo(
    () =>
      typeof title === 'string' ? (
        <Input
          className={classes.title}
          placeholder="Title goes here..."
          defaultValue={title}
          needsConfirmation={true}
          onChange={value => updateTitle(value)}
          blurShowUnmodified={true}
        />
      ) : null,
    [title, updateTitle]
  )
  return (
    <section className={classes.wrapper}>
      <div className={classes.header}>
        <img src={Logo} alt="logo" className={classes.logo} />
        {input}
        <ButtonGroup className={classes.buttonGroup}>
          <Button className={classes.button} onClick={onCloseHandler}>
            {dirty ? 'Discard Changes' : 'Close'}
          </Button>
          {typeof onSaveHandler === 'function' ? (
            <Button
              className={classes.button}
              onClick={() => onSaveHandler({ title, dataStructure, locations, questions, questionLayout, answers })}
              colorScheme="primary"
            >
              {mode === 'template-creation' ? 'Save Template' : 'Save Draft'}
            </Button>
          ) : null}
        </ButtonGroup>
      </div>
      <div className={classes.info}>{null}</div>
    </section>
  )
})

Header.displayName = 'Wizard-Header'

export default Header
