import { v4 as uuid } from 'uuid'
import { useMutation } from 'react-query'
import TemplatesApi from 'api/templates.api'
import { useToast } from 'hooks/useToast'
import useStore from '___store'

export const useAIQuestion = (id, contentText) => {
  const { concludeConfiguring, addNewQuestion, updateQuestion, assignMarker } = useStore(
    'concludeConfiguring',
    'addNewQuestion',
    'updateQuestion',
    'assignMarker'
  )
  const toast = useToast()
  const questionId = uuid()

  const mutation = useMutation(['AI-question-mutation', contentText], payload => TemplatesApi.generateAIQuestion(payload), {
    onMutate: () => {
      concludeConfiguring()
      addNewQuestion({ id: questionId })
      concludeConfiguring()
    },
    onSuccess: ({ question, description, type, example }) => {
      updateQuestion({
        id: questionId,
        description: description,
        hint: '',
        example: '',
        text: question,
        valueType: type,
        markers: [],
        advanced: { logic: 'or', rules: [], subQuestionTo: '', visibility: 'show' },
        optionGroups: [
          {
            id: uuid(),
            label: '',
            enforceLimit: true,
            minimum: 0,
            maximum: 1,
            select: 'single',
            valueType: type,
            options: [{ id: uuid(), markers: [], placeholder: example || 'User input...', text: '', type: 'static', value: '', valueType: type }],
          },
        ],
      })
      assignMarker({ markerId: id, questionId })
    },
    onError: () => {
      toast('error', 'default')
    },
  })

  return contentText ? mutation : null
}

export default useAIQuestion
