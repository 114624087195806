import React, { useMemo } from 'react'
import useStore from '___store'

import { Button, ButtonGroup } from 'components'
import { classes } from '../..'

export const AssignToCurrentQuestionButton = React.memo(({ markerId }) => {
  const {
    locationById: { questionId: assignedQuestionId } = {},
    currentConfiguringQuestion: { id: currentQuestionId } = {},
    questions = [],
    unassignMarker,
    assignMarker,
  } = useStore(`selectLocationById[${markerId}]`, 'selectCurrentConfiguringQuestion', 'selectQuestions', 'unassignMarker', 'assignMarker')
  const assignedToCurrentQuestion = useMemo(() => currentQuestionId === assignedQuestionId, [currentQuestionId, assignedQuestionId])
  const currentQuestionText = useMemo(
    () => (currentQuestionId && questions.find(({ id }) => id === currentQuestionId)?.text) || '',
    [questions, currentQuestionId]
  )
  if (!currentQuestionId) return null
  return (
    <ButtonGroup className={classes.configEntry.markerConfiguration.fragment.currentQuestionButtonGroup}>
      <Button
        className={classes.configEntry.markerConfiguration.fragment.assignToCurrentQuestionButton}
        onClick={() =>
          assignedToCurrentQuestion
            ? unassignMarker({ markerId, questionId: assignedQuestionId })
            : assignMarker({ markerId, questionId: currentQuestionId })
        }
        colorScheme="primary"
      >
        {assignedToCurrentQuestion ? 'Unassign from Current Question' : 'Assign to Current Question'}
        <span>{`(${currentQuestionText})`}</span>
      </Button>
    </ButtonGroup>
  )
})

export default AssignToCurrentQuestionButton
