import React from 'react'
import SigningDocumentModal from './components/SigningDocumentModal'
import ViewSignatureRequestModal from './components/ViewSignatureRequestModal'

const SignatureModal = ({ signDocumentId, type, anchorEl, close, isView, signatureRequest }) => {
  if (isView) {
    return <ViewSignatureRequestModal signDocumentId={signDocumentId} anchorEl={anchorEl} handleClose={close} signatureRequest={signatureRequest} />
  } else {
    return <SigningDocumentModal signDocumentId={signDocumentId} type={type} anchorEl={anchorEl} close={close} />
  }
}

export default SignatureModal

