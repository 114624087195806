import React, { FunctionComponent, useMemo } from 'react'

import { ANSWERING_IDS_MATCH } from '___types'
import { Question } from '.'
import { WizardLayoutLeftPaneQuestionnaireQuestionGroupProps, wizardLayoutLeftPaneQuestionnaireQuestionGroupClasses as classes } from '../../..'

const QuestionnaireQuestionGroup: FunctionComponent<WizardLayoutLeftPaneQuestionnaireQuestionGroupProps> = React.memo(
  ({ id, isLoading, isFetching }) => {
    const {
      // questionLayoutGroupId,
      questionId,
      orderString,
    } = useMemo(() => id?.match(ANSWERING_IDS_MATCH)?.groups || {}, [id])
    const question = useMemo(
      () => <Question key={questionId} id={questionId} isLoading={isLoading} isFetching={isFetching} orderString={orderString} />,
      [questionId, isLoading, isFetching, orderString]
    )
    return <div className={classes.wrapper}>{question}</div>
  }
)

QuestionnaireQuestionGroup.displayName = 'WizardLayout-Questionnaire-QuestionGroup'

export default QuestionnaireQuestionGroup
