import ACTION_TYPES, { Action } from '___store/actionTypes'
import { getLogArray } from '___store/helpers'

export type InterceptorCheckFunction = (store: any, payload: any) => boolean
export type InterceptorMethod = (store: any, next: any, action: Action) => void

const interceptors = {
  [ACTION_TYPES.BATCH_ACTIONS]: {
    check: (() => true) as InterceptorCheckFunction,
    method: ((store, _, action) => {
      const { type, actionArray } = action as Action & { actionArray: Action[] }
      console.groupCollapsed(...getLogArray('intercept', type))
      actionArray.forEach(({ type, payload }) => {
        console.log(`${type}: `, payload)
      })
      console.groupEnd()
      actionArray.forEach(action => store.dispatch(action))
    }) as InterceptorMethod,
  },
}

export { interceptors }
export default interceptors
