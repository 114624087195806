import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { CasusLogo } from 'assets/svgIconComponents'
import { contentClasses as classes } from 'Layouts'
import Documents from './Content.Documents'
import Templates from './Content.Templates'

export const Content = React.memo(() => {
  return (
    <div className={classes.wrapper}>
      <CasusLogo className={classes.backgroundLogo} />
      <Switch>
        <Route path="/dev/documents">
          <Documents />
        </Route>
        <Route path="/dev/templates">
          <Templates />
        </Route>
      </Switch>
      <div className={classes.section}></div>
    </div>
  )
})

Content.displayName = 'Content'

export default Content
