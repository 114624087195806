import React, { FunctionComponent, RefCallback, Dispatch, SetStateAction, useState, useCallback, useEffect } from 'react'
import useStore, { NavigateQuestionnaireForwardAction } from '___store'

import { Caret } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import { WizardLayoutLeftPaneQuestionnairePreAnsweringProps, wizardLayoutLeftPaneQuestionnairePreAnsweringClasses as classes } from '../../..'

type UseStoreHookResultType = {
  navigateQuestionnaireForward: NavigateQuestionnaireForwardAction
}

const QuestionnairePreAnswering: FunctionComponent<WizardLayoutLeftPaneQuestionnairePreAnsweringProps> = React.memo(({ isLoading, isFetching }) => {
  const { navigateQuestionnaireForward } = useStore('navigateQuestionnaireForward') as UseStoreHookResultType
  const [button, setInput]: [HTMLButtonElement | undefined, Dispatch<SetStateAction<HTMLButtonElement | undefined>>] = useState()

  const buttonRef: RefCallback<HTMLButtonElement | undefined> = useCallback(node => node && setInput(node), [])

  useEffect(() => {
    if (button) button.focus()
  }, [button])

  return (
    <div className={classes.wrapper} data-loading={isLoading || isFetching || undefined}>
      <div className={classes.actions.wrapper}>
        <Button ref={buttonRef} tertiary onClick={() => navigateQuestionnaireForward()}>
          START HERE
          <Caret />
        </Button>
      </div>
    </div>
  )
})

QuestionnairePreAnswering.displayName = 'WizardLayout-Questionnaire-PreAnswering'

export default QuestionnairePreAnswering
