import React, { useMemo } from 'react'
import useStore from '___store'

import { DOCUMENTS_FILTERS } from '___types'
import { Company, Person, Team, Trash } from 'assets/svgIconComponents'
import { classes } from 'Layouts'

const Documents = React.memo(() => {
  const { documentsFilter, setDocumentsFilter } = useStore('selectDocumentsFilter', 'setDocumentsFilter')

  const {
    components: {
      leftPane: {
        section: { wrapper: sectionClass, navButton: buttonClass },
      },
    },
  } = classes

  const appStateButtons = useMemo(() => {
    const buttons = [
      { filter: DOCUMENTS_FILTERS.MINE, label: 'My Documents', Icon: Person },
      { filter: DOCUMENTS_FILTERS.SHARED, label: 'Shared Documents', Icon: Team },
      { filter: DOCUMENTS_FILTERS.COMPANY, label: 'Company Documents', Icon: Company },
      { filter: DOCUMENTS_FILTERS.TRASHED, label: 'Bin', Icon: Trash },
    ]
    return buttons.map(({ filter, label, Icon }) => (
      <button
        key={`LeftPane-Documents-button-${filter}`}
        className={buttonClass}
        data-active={filter === documentsFilter}
        type="button"
        onClick={() => setDocumentsFilter(filter)}
      >
        <Icon key={`LeftPane-Documents-button-icon-${filter}`} />
        {label}
      </button>
    ))
  }, [buttonClass, documentsFilter, setDocumentsFilter])

  return <div className={sectionClass}>{appStateButtons}</div>
})

Documents.displayName = 'LeftPane-Documents'

export default Documents
