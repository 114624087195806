export const queryKeys = {
  USER: 'QUERY_USER',
  REGISTER: 'QUERY_REGISTER',
  MASTER_DATA: 'QUERY_MASTER_DATA',
  TEMPLATE: 'QUERY_TEMPLATE_OLD',
  TEMPLATES: 'QUERY_TEMPLATES',
  TEMPLATE_FOLDER: 'QUERY_TEMPLATE_FOLDER',
  TEMPLATE_FOLDERS: 'QUERY_TEMPLATE_FOLDERS',
  DOCUMENTS: 'QUERY_DOCUMENTS_OLD',
  DOCUMENT: 'QUERY_DOCUMENT',
  DOCUMENT_FOLDER: 'QUERY_DOCUMENT_FOLDER',
  DOCUMENT_FOLDERS: 'QUERY_DOCUMENT_FOLDERS',
  DOCUMENT_REMINDERS: 'DOCUMENT_REMINDERS',
  DOCUMENT_REMINDER: 'DOCUMENT_REMINDER',
  SIGNATURE_PDF: 'SIGNATURE_PDF',
  TEAM_MEMBERS: 'QUERY_TEAM_MEMBERS',
  LEGAL_ADVICE_EVENTS: 'QUERY_LEGAL_ADVICE_EVENTS',
  EXTERNAL_SERVICE_FIELDS: 'QUERY_EXTERNAL_SERVICE_FIELDS',
  EXTERNAL_SERVICE_IDED_OBJECTS: 'QUERY_EXTERNAL_SERVICE_IDED_OBJECTS',
  EXTERNAL_SERVICE_ENTRY: 'QUERY_EXTERNAL_SERVICE_ENTRY',
  SUBSCRIPTIONS: 'QUERY_SUBSCRIPTIONS',
  SUBSCRIPTION: 'QUERY_SUBSCRIPTION',
}
