import React from 'react'

import { VIEWPORT_SIZE, className } from './index'

const Document = React.memo(props => {
  const { strokeWidth = 8, fill = 'none', borderRadius = 10 } = props
  const paddingHorizontal = 18
  const paddingVertical = 4
  return (
    <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
      <path
        d={`M ${paddingHorizontal + borderRadius} ${paddingVertical} H ${VIEWPORT_SIZE / 2} L ${VIEWPORT_SIZE - paddingHorizontal} ${
          VIEWPORT_SIZE / 2 - paddingHorizontal + paddingVertical
        } V ${VIEWPORT_SIZE - paddingVertical - borderRadius} q 0 ${borderRadius} ${-borderRadius} ${borderRadius} H ${
          paddingHorizontal + borderRadius
        } q ${-borderRadius} 0 ${-borderRadius} ${-borderRadius} V ${
          paddingVertical + borderRadius
        } q 0 ${-borderRadius} ${borderRadius} ${-borderRadius} Z`}
        stroke="currentColor"
        strokeWidth={`${strokeWidth}`}
        strokeLinejoin="round"
        fill={`${fill}`}
      />
      <path
        d={`M ${VIEWPORT_SIZE / 2} ${paddingVertical} L ${VIEWPORT_SIZE - paddingHorizontal} ${
          VIEWPORT_SIZE / 2 - paddingHorizontal + paddingVertical
        } H ${VIEWPORT_SIZE / 2} Z`}
        stroke="currentColor"
        strokeWidth={`${(strokeWidth * 2) / 3}`}
        strokeLinejoin="round"
        fill={`${fill}`}
      />
    </svg>
  )
})

export { Document }
export default Document
