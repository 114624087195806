import React, { useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { VIEWPORT_SIZE, className } from './index'

const HorizontalLines = React.memo(props => {
  const { count = 3, linecap = 'round', strokeWidth = 10 } = props
  const iconId = useMemo(uuid, [])
  const spacing = VIEWPORT_SIZE / count
  const lines = new Array(count)
    .fill(null)
    .map((_, i) => (
      <line
        key={`HorizontalLines-SVG-Icon-${iconId}-${i}`}
        x1={`${spacing / 2}`}
        y1={`${spacing * (i + 1 / 2)}`}
        x2={`${VIEWPORT_SIZE - spacing / 2}`}
        y2={`${spacing * (i + 1 / 2)}`}
        stroke="currentColor"
        strokeWidth={`${strokeWidth}`}
        strokeLinecap={`${linecap}`}
      />
    ))

  return (
    <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
      {lines}
    </svg>
  )
})

export { HorizontalLines }
export default HorizontalLines
