import React from 'react'
import { className } from './index'

const Download = React.memo(() => {
  return (
    <>
      <svg className={className} width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.0469 16.2969C13.0469 16.7734 12.7031 17.1328 12.2266 17.1328H1.78906C1.30469 17.1328 0.953125 16.7734 0.953125 16.2969C0.953125 15.8125 1.30469 15.4531 1.78906 15.4531H6.92969C6.71875 15.4375 6.52344 15.3359 6.36719 15.1797L1.21875 10.0234C1.03906 9.83594 0.953125 9.63281 0.953125 9.40625C0.953125 8.92969 1.30469 8.57812 1.77344 8.57812C2.00781 8.57812 2.22656 8.67188 2.375 8.82812L4.14844 10.5781L6.21094 12.875L6.14844 11.1953V1.48438C6.14844 0.96875 6.5 0.617188 7 0.617188C7.50781 0.617188 7.85938 0.96875 7.85938 1.48438V11.1953L7.78906 12.8828L9.85156 10.5781L11.625 8.82812C11.7812 8.67188 11.9922 8.57812 12.2344 8.57812C12.7031 8.57812 13.0469 8.92969 13.0469 9.40625C13.0469 9.63281 12.9609 9.83594 12.7812 10.0234L7.64062 15.1797C7.48438 15.3359 7.28125 15.4375 7.07812 15.4531H12.2266C12.7031 15.4531 13.0469 15.8125 13.0469 16.2969Z"
          fill="currentColor"
        />
      </svg>
    </>
  )
})

export { Download }
export default Download

