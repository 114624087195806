import Api from './api'


interface ISubscription {
  id: string,
  name: string,
  interval: string,
  price: number,
  currency: string,
  description: string
}

class Subscriptions extends Api {
  constructor() {
    super('', true)
  }

  all = async (): Promise<ISubscription[]> => {
    const res = await this.api().get(`/v2/subs/subscriptions/available`)
    return res.data.data.subscriptions
  }

  getOne = async (priceId: string, templateId?: string): Promise<any> => {
    const res = await this.api().post(`/v2/subs/subscriptions`, {
      priceId,
      //TODO: get base part of URL dynamically
      successUrl: `https://sbv.${process.env.REACT_APP_DOMAIN_V2}/?template=${templateId}&premium=true&paid=true`,
      cancelUrl: `https://sbv.${process.env.REACT_APP_DOMAIN_V2}/`,
      metadata: {},
    })
    return res.data.data
  }
}

const subscriptionsApi = new Subscriptions()

export default subscriptionsApi

