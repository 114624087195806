import axios, { AxiosRequestConfig, AxiosError } from 'axios'

function errorHandler(error: AxiosError) {
  if (error.response) {
    console.groupCollapsed('%cRESPONSE ERROR', 'color: red')
    console.log(error.response.status)
    console.log(error.response.headers)
    console.log(error.response.data)
    console.groupEnd()
  } else if (error.request) {
    console.groupCollapsed('%cREQUEST ERROR', 'color: red')
    console.log(error.request)
    console.groupEnd()
  } else {
    console.groupCollapsed('%cERROR: ', 'color: red', error.message)
    console.log(error)
    console.groupEnd()
  }
  console.log('%cERROR CONFIG: ', 'color: red', error.config)
  throw error
}

abstract class API {
  protected BASE_URL: string
  protected ENDPOINT: string

  constructor(endpoint: string) {
    this.BASE_URL = `${process.env.REACT_APP_BASE_API_URL_VERSIONLESS}`
    this.ENDPOINT = endpoint
  }

  protected api(options?: AxiosRequestConfig, versionOverride?: string) {
    const baseURL = `${this.BASE_URL}/${versionOverride || 'v2'}/${this.ENDPOINT}`
    const instance = axios.create(Object.assign({ baseURL }, options))
    instance.interceptors.response.use(response => response, errorHandler)
    instance.interceptors.request.use(async request => request)
    const token = localStorage.casusToken
    if (token) instance.defaults.headers.common['Authorization'] = `Bearer ${token}`
    return instance
  }
  protected post(url: string, data?: object, config?: AxiosRequestConfig, options?: AxiosRequestConfig, versionOverride?: string) {
    return this.api(options, versionOverride).post(url, data, config)
  }
  protected patch(url: string, data?: object, config?: AxiosRequestConfig, options?: AxiosRequestConfig, versionOverride?: string) {
    return this.api(options, versionOverride).patch(url, data, config)
  }
  protected get(url: string, config?: AxiosRequestConfig, options?: AxiosRequestConfig, versionOverride?: string) {
    return this.api(options, versionOverride).get(url, config)
  }
}

export default API
