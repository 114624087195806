import React, { FunctionComponent, useMemo } from 'react'
import useStore, { WizardModeSelector, WizardTemplateIdSelector } from '___store'

import { DOCUMENT_GENERATION_MODE } from '___types'
import Questionnaire from './LeftPane.Questionnaire'
import TemplateSelection from './LeftPane.TemplateSelection'
import { WizardLayoutLeftPaneProps, wizardLayoutLeftPaneClasses as classes } from '../..'

type UseStoreHookResultType = { wizardMode: WizardModeSelector; wizardTemplateId: WizardTemplateIdSelector }

export const LeftPane: FunctionComponent<WizardLayoutLeftPaneProps> = React.memo(({ isLoading, isFetching }) => {
  const { wizardMode: mode, wizardTemplateId: templateId } = useStore('selectWizardMode', 'selectWizardTemplateId') as UseStoreHookResultType

  const step = useMemo(() => {
    if (mode === DOCUMENT_GENERATION_MODE) {
      if (!templateId) return <TemplateSelection />
      return <Questionnaire isLoading={isLoading} isFetching={isFetching} />
    }
  }, [mode, templateId, isLoading, isFetching])

  return <div className={classes.wrapper}>{step}</div>
})

LeftPane.displayName = 'WizardLayout-LeftPane'

export default LeftPane
