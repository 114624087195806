import { useCallback, useState } from 'react'
import { useQueryClient } from 'react-query'

import { useToast } from 'hooks/useToast'

import AuthApi from 'api/auth.api'
import { firebase } from "../../../config/firebase";

const ERR_MSG =
    'Email oder Passwort ist nicht korrekt. Überprüfen Sie die eingegebenen Daten und versuchen Sie es erneut.'

let resolver: any = null

export const useLogin = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [isPromptSMSVerify, setIsPromptSMSVerify] = useState(false)
    const [smsCode, setSmsCode] = useState('')
    const [phoneVerificationId, setPhoneVerificationId] = useState('')
    const toast = useToast()
    const queryClient = useQueryClient()
    // let recaptchaVerifier: any = null

    const login = useCallback(
        async (email: string, password: string) => {
            setIsLoading(true)
            setSmsCode('')
            const backupLoginRedirect = localStorage.getItem('loginRedirect')
            localStorage.clear()
            if (backupLoginRedirect) {
                localStorage.setItem('loginRedirect', backupLoginRedirect)
            }
            queryClient.invalidateQueries()
            try {
                await AuthApi.loginUser(email, password)
            } catch (err) {
                // @ts-ignore
                if (['auth/user-not-found', 'auth/wrong-password'].includes(err?.code)) {
                    toast('error', ERR_MSG)
                    // @ts-ignore
                } else if (err?.code === 'auth/too-many-requests') {
                    toast('error', 'Issue with Google AUTH servers, try later')
                    // @ts-ignore
                } else if (err?.code === 'auth/multi-factor-auth-required') {
                    // multi-factor flow
                    // @ts-ignore
                    resolver = err.resolver;
                    // second factor is only one: 'phone'
                    if (resolver.hints[0].factorId ===
                        firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID) {

                        const recaptchaVerifier: any = new firebase.auth.RecaptchaVerifier('sign-in-button', {
                            'size': 'invisible',
                            'callback': function (response: any) {
                            }
                        })

                        const phoneInfoOptions = {
                            multiFactorHint: resolver.hints[0],
                            session: resolver.session
                        }
                        const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
                        // Send SMS verification code
                        // @ts-ignore
                        await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
                            .then(function (verificationId: any) {

                                setPhoneVerificationId(verificationId)

                                // Ask user for the SMS verification code.
                                // show SMS verification modal
                                setIsPromptSMSVerify(true)
                                return

                            })
                    } else {
                        // Unsupported second factor.
                        toast('error', 'Unsupported second factor')
                    }
                }
            }
        },
        [toast, queryClient]
    )


    const handleVerifySMSLogin = () => {
        const cred = firebase.auth.PhoneAuthProvider.credential(
            phoneVerificationId, smsCode)
        const multiFactorAssertion =
            firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
        // Complete sign-in.
        resolver.resolveSignIn(multiFactorAssertion)
            .then(() => {
                toast('success', 'Successfully confirmed')
            })
            .catch((e: any) => {
                toast('error', e.message)
                setIsLoading(false)
                // window.location.reload(false)
            })
            .finally(() => {
                setIsPromptSMSVerify(false)
                return
            })
    }

    return { login, isLoading, isPromptSMSVerify, setIsPromptSMSVerify, smsCode, setSmsCode, handleVerifySMSLogin }
}
