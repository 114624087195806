import React, { FunctionComponent } from 'react'
import { Switch, Route } from 'react-router-dom'
import useStore, { SelectedDocumentIdSelector, SelectedTemplateIdSelector } from '___store'

import Document from './RightPane.Document'
import Template from './RightPane.Template'
import { mainLayoutRightPaneClasses as classes } from '../..'

type UseStoreHookResultType = { selectedDocumentId: SelectedDocumentIdSelector; selectedTemplateId: SelectedTemplateIdSelector }

export const RightPane: FunctionComponent = React.memo(() => {
  const { selectedDocumentId, selectedTemplateId } = useStore('selectSelectedDocumentId', 'selectSelectedTemplateId') as UseStoreHookResultType
  return (
    <div className={classes.wrapper}>
      <Switch>
        <Route path="/dev/documents">{selectedDocumentId ? <Document /> : null}</Route>
        <Route path="/dev/templates">{selectedTemplateId ? <Template /> : null}</Route>
      </Switch>
    </div>
  )
})

RightPane.displayName = 'RightPane'

export default RightPane
