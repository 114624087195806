import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'

import { useToast } from 'hooks/useToast'

import { queryKeys } from 'queries/queryKeys'
import TemplatesApi from 'api/templates.api'
import { ITemplate } from 'types'
import { trackIntercomEvent } from 'components/helpers/intercom/services/trackIntercomEvent'
import { useUser } from 'queries/user/useUser'
import { useTranslation } from 'react-i18next'
import { parseBEData } from 'utilities'

interface Data {
  pageParams: [string | undefined] | never[]
  pages: ITemplate[][]
}

export const useCreateTemplate = () => {
  const { push } = useHistory()
  const queryClient = useQueryClient()
  const toast = useToast()
  const user = useUser()
  const docXMicro = !!user?.beta?.newDocxMicroservice
  const { t } = useTranslation()
  const mutation = useMutation(
    queryKeys.TEMPLATE,
    (payload: any) => TemplatesApi.create({ ...payload, new: docXMicro }),
    {
      onSuccess: temp => {
        let template = { ...temp }

        let beData = [] as any
        if (docXMicro) beData = parseBEData(temp?.dataStructure, temp?.cssData)
        const [frontEndParsedStructure, frontEndEnrichedCSS] = beData
        template = {
          ...temp,
        }
        if (frontEndParsedStructure) template.dataStructure = frontEndParsedStructure
        // if (frontEndGeneratedHTML) template.htmlData = frontEndGeneratedHTML
        if (frontEndEnrichedCSS) template.cssData = frontEndEnrichedCSS
        queryClient.setQueryData([queryKeys.TEMPLATE, template.id], template)
        push(`/${t('template')}/${template.id}/${t('edit')}`)
        queryClient.setQueryData(queryKeys.TEMPLATE, null)
        trackIntercomEvent('template-created', { id: template.id })

        queryClient.setQueryData<Data>([queryKeys.TEMPLATES, { type: 'my', folderId: 'my' }], data => {
          const pages = data?.pages ?? []
          const pageOne = pages[0] ?? []
          pageOne.unshift(template)
          pages[0] = pageOne
          return data ? { ...data, pages } : { pageParams: [], pages: [] }
        })
      },
      onError: () => {
        toast('error', 'default')
      },
    }
  )
  return mutation
}
