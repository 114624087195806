import React, { useState } from 'react'
import { Icon } from 'components/common'
import { useSignDocument, useSignPdf } from 'queries/documents/useSignDocument'
import './style.scss'
import theme from 'config/themes/theme'
import { useTranslation } from 'react-i18next'
import Validator from 'validator'
import { MESSAGE_INVALID_EMAIL } from 'constants/messages'
import { useToast } from 'hooks/useToast'
import ModalPopover from 'components/common/ModalPopover/ModalPopover'
import SecurityPicker from './components/SecurityPicker'
import Form from './components/Form'
import Footer from './components/Footer'
import { classes } from '.'
import { useDetails } from 'components/DetailsSidebar/provider/DetailsProvider'

const ErrorOccurred = React.memo(({ close, text }) => {
  return (
    <div>
      <div className={classes.empty.container.wrapper}>
        <Icon iconName="error" fill={theme.colorz.brand} className={classes.normal.icon.wrapper} />
        <span className={classes.normal.span.wrapper}>{text}</span>
      </div>
    </div>
  )
})

const startingUser = {
  email: '',
  firstName: '',
  lastName: '',
}

export const SigningDocumentModal = React.memo(({ signDocumentId, type, anchorEl, close }) => {
  const { reload } = useDetails()
  const [panelStep, setPanelStep] = useState(0)
  const [tmpUser, setTmpUser] = useState(startingUser)

  const [message, setMessage] = useState('')

  const [signatureLevel, setSignatureLevel] = useState('SES')
  const [countryLaw, setCountryLaw] = useState('ZERTES')

  const [signingWith, setSigningWith] = useState([])
  const signDocument = useSignDocument(close, reload)
  const signPdf = useSignPdf(close, reload)
  const { t } = useTranslation()
  const toast = useToast()

  // Breaking point template vs document
  const addSigner = user => {
    if (!signDocumentId || !document) return
    setSigningWith(arr => [...arr, user])
  }

  const isEnabled = () => {
    return signingWith.length > 0
  }

  const validateEmail = email => {
    const isEmail = Validator.isEmail(email)
    if (!isEmail) toast('error', MESSAGE_INVALID_EMAIL)
    return isEmail
  }

  const onSign = async () => {
    if (type === 'pdf') {
      signPdf.mutate({ documentId: signDocumentId, signatureEntities: signingWith, message, quality: signatureLevel, legislation: countryLaw })
    } else {
      signDocument
        .mutate({
          documentId: signDocumentId,
          signatureEntities: signingWith,
          message,
          quality: signatureLevel,
          legislation: countryLaw,
        })
    }
  }

  const handleButtonClick = e => {
    const isValid = validateEmail(tmpUser.email)
    if (!isValid) return
    const sanitizedEmail = Validator.normalizeEmail(tmpUser.email)
    if (signingWith.find(p => p.email === sanitizedEmail)) return
    addSigner(tmpUser)
    // setEmail('')
    // setFirstName('')
    // setLastName('')
    setTmpUser(startingUser)
  }

  const handleNextStep = () => {
    setPanelStep(panelStep => panelStep + 1)
  }

  const handleSignatureLevelChange = event => {
    if (event.target.value !== 'QES') setCountryLaw('ZERTES')
    setSignatureLevel(event.target.value)
  }

  const handleCountryLawChange = event => {
    setCountryLaw(event.target.value)
  }

  const onUserDelete = user => {
    setSigningWith(signingWith.filter(u => u.email !== user.email))
  }

  const onEditUser = user => {
    setSigningWith(signingWith.filter(u => u.email !== user.email))
    setTmpUser(user)
    // setEmail(email)
  }

  const closeAndReset = () => {
    close()
    setPanelStep(0)
    setSigningWith([])
    setTmpUser(startingUser)
  }

  const handlePreviousStep = () => {
    setPanelStep(panelStep => panelStep - 1)
  }

  let content = null

  switch (panelStep) {
    case 0:
      content = (
        <SecurityPicker
          handleSignatureLevelChange={handleSignatureLevelChange}
          handleCountryLawChange={handleCountryLawChange}
          countryLaw={countryLaw}
          signatureLevel={signatureLevel}
        />
      )
      break
    case 1:
      content = (
        <Form
          user={tmpUser}
          handleChange={e => {
            const { value, name } = e.target
            setTmpUser(prev => {
              return { ...prev, [name]: value }
            })
          }}
          handleMessageChange={e => {
            const { value } = e.target
            setMessage(value)
          }}
          addSigner={handleButtonClick}
          signingWith={signingWith}
          handleEditUser={onEditUser}
          handleDeleteUser={onUserDelete}
          message={message}
          handleDelete={onUserDelete}
          handleEdit={onEditUser}
        />
      )
      break
    default:
      content = <ErrorOccurred close={closeAndReset} text={t('err_msg_default')} />
  }

  return (
    <ModalPopover isOpen={!!anchorEl} close={closeAndReset} anchorEl={anchorEl} anchorOrigin={{ vertical: 'left', horizontal: 'top' }}>
      <div className={classes.container}>
        {content}
        <Footer
          handleNextStep={handleNextStep}
          handleFinishClick={onSign}
          handleClose={handlePreviousStep}
          enablePrimaryButton={isEnabled()}
          enableNextStepButton={true}
          isEnabled={isEnabled()}
          panelStep={panelStep}
          isLoading={signDocument.isLoading || signPdf.isLoading}
        />
      </div>
    </ModalPopover>
  )
})

