import {
  EditorMode,
  LocationType,
  Option,
  OptionGroup,
  OptionGroupSelectUnionType,
  StaticOption,
  TableCells,
  TableRows,
  TableSectionsType,
  TextChunks,
} from '___types'

import { WizardLayout } from './WizardLayout'
import { Header, LeftPane, RightPane, Footer } from './components'

import './style.scss'

export type WizardLayoutProps = {}

// ========================================================================================================== //
// ================================================= HEADER ================================================= //
// ========================================================================================================== //
export type WizardLayoutHeaderProps = { isLoading: boolean; isFetching: boolean }

// ========================================================================================================= //
// =============================================== LEFT PANE =============================================== //
// ========================================================================================================= //
export type WizardLayoutLeftPaneProps = { isLoading: boolean; isFetching: boolean }
export type WizardLayoutLeftPaneTemplateSelectionProps = {}
export type WizardLayoutLeftPaneQuestionnaireProps = { isLoading: boolean; isFetching: boolean }
export type WizardLayoutLeftPaneQuestionnairePreAnsweringProps = { isLoading: boolean; isFetching: boolean }
export type WizardLayoutLeftPaneQuestionnaireQuestionGroupProps = { isLoading: boolean; isFetching: boolean; id: string | null }
export type WizardLayoutLeftPaneQuestionnaireQuestionProps = { isLoading: boolean; isFetching: boolean; id?: string; orderString?: string }
export type WizardLayoutLeftPaneQuestionnaireQuestionDetailsProps = { text: string; details: { description: string; hint: string } }
export type WizardLayoutLeftPaneQuestionnaireOptionGroupProps = {
  optionGroup: OptionGroup
  singleGroup: boolean
  indexOffset: number
  firstGroup: boolean
}
export type WizardLayoutLeftPaneQuestionnaireOptionProps = {
  option: Option
  index: number
  singleOption: boolean
  select: OptionGroupSelectUnionType
  firstOption: boolean
}
export type WizardLayoutLeftPaneQuestionnairePredefinedStaticOptionProps = {
  option: StaticOption
  label: string
  select: OptionGroupSelectUnionType
  singleOption: boolean
  firstOption: boolean
}
export type WizardLayoutLeftPaneQuestionnaireUserInputStaticOptionProps = {
  option: StaticOption
  label: string
  select: OptionGroupSelectUnionType
  singleOption: boolean
  firstOption: boolean
}
export type WizardLayoutLeftPaneQuestionnaireSkippedQuestionReviewProps = { isLoading: boolean; isFetching: boolean }
export type WizardLayoutLeftPaneQuestionnaireConfirmationProps = { isLoading: boolean; isFetching: boolean }
export type WizardLayoutLeftPaneQuestionnaireDocumentRenameProps = { isLoading: boolean; isFetching: boolean }
export type WizardLayoutLeftPaneQuestionnairePostGenerationProps = { isLoading: boolean; isFetching: boolean }

// ========================================================================================================== //
// =============================================== RIGHT PANE =============================================== //
// ========================================================================================================== //
export type WizardLayoutRightPaneProps = { isLoading: boolean; isFetching: boolean }
export type WizardLayoutRightPaneEditorProps = { isLoading: boolean; isFetching: boolean; mode: EditorMode }
export type WizardLayoutRightPaneEditorPreviewProps = {}
export type WizardLayoutRightPaneEditorPreviewFocusModeToggleProps = {}
export type WizardLayoutRightPaneEditorSectionProps = { index: number }
export type WizardLayoutRightPaneEditorPageProps = { index: number; range: [number, number] }
export type WizardLayoutRightPaneEditorSegmentProps = { id: string }
export type WizardLayoutRightPaneEditorSegmentParagraphProps = { id: string; customStyle: string; styles: string[]; textChunks?: TextChunks }
export type WizardLayoutRightPaneEditorSegmentTableProps = { id: string; customStyle: string; styles: string[]; tableSections?: TableSectionsType }
export type WizardLayoutRightPaneEditorSegmentTableHeaderProps = { rows: TableRows; index: number }
export type WizardLayoutRightPaneEditorSegmentTableBodyProps = { rows: TableRows; index: number }
export type WizardLayoutRightPaneEditorSegmentTableFooterProps = { rows: TableRows; index: number }
export type WizardLayoutRightPaneEditorSegmentTableRowProps = { cells: TableCells }
export type WizardLayoutRightPaneEditorSegmentTableCellProps = { id: string }
export type WizardLayoutRightPaneEditorSegmentImageProps = {}
export type WizardLayoutRightPaneEditorMarkerProps = { id: string; type: LocationType; parent?: string; textChunks?: TextChunks }
export type WizardLayoutRightPaneEditorMarkerSegmentsProps = { id: string; parent: string }
export type WizardLayoutRightPaneEditorMarkerSegmentsParagraphProps = { id: string; customStyle: string; styles: string[]; text: string }
export type WizardLayoutRightPaneEditorMarkerTextProps = { id: string; range: [number, number]; textChunks: TextChunks }

// ========================================================================================================== //
// ================================================= FOOTER ================================================= //
// ========================================================================================================== //
export type WizardLayoutFooterProps = { isLoading: boolean; isFetching: boolean }

// /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ================================================= CLASSES ================================================= //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
export const wizardLayoutFooterClasses = { wrapper: 'WizardLayout-Footer-wrapper' } as const
export const wizardLayoutRightPaneEditorPreviewFocusModeToggleClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-Preview-FocusModeToggle-wrapper',
  button: 'WizardLayout-RightPane-Editor-Preview-FocusModeToggle-button',
}
export const wizardLayoutRightPaneEditorMarkerTextClasses = { wrapper: 'WizardLayout-RightPane-Editor-Marker-Text-wrapper' } as const
export const wizardLayoutRightPaneEditorMarkerSegmentsParagraphClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-Marker-Segments-Paragraph-wrapper',
} as const
export const wizardLayoutRightPaneEditorMarkerSegmentsClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-Marker-Segments-wrapper',
  paragraph: wizardLayoutRightPaneEditorMarkerSegmentsParagraphClasses,
} as const
export const wizardLayoutRightPaneEditorMarkerClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-Marker-wrapper',
  segments: wizardLayoutRightPaneEditorMarkerSegmentsClasses,
  text: wizardLayoutRightPaneEditorMarkerTextClasses,
} as const
export const wizardLayoutRightPaneEditorSegmentImageClasses = { wrapper: 'WizardLayout-RightPane-Editor-Segment-Image-wrapper' } as const
export const wizardLayoutRightPaneEditorSegmentTableCellClasses = { wrapper: 'WizardLayout-RightPane-Editor-Segment-Table-Cell-wrapper' } as const
export const wizardLayoutRightPaneEditorSegmentTableRowClasses = { wrapper: 'WizardLayout-RightPane-Editor-Segment-Table-Row-wrapper' } as const
export const wizardLayoutRightPaneEditorSegmentTableSectionFooterClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-Segment-Table-TableSection-Footer-wrapper',
} as const
export const wizardLayoutRightPaneEditorSegmentTableSectionBodyClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-Segment-Table-TableSection-Body-wrapper',
} as const
export const wizardLayoutRightPaneEditorSegmentTableSectionHeaderClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-Segment-Table-TableSection-Header-wrapper',
} as const
export const wizardLayoutRightPaneEditorSegmentTableSectionClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-Segment-Table-wrapper',
  header: wizardLayoutRightPaneEditorSegmentTableSectionHeaderClasses,
  body: wizardLayoutRightPaneEditorSegmentTableSectionBodyClasses,
  footer: wizardLayoutRightPaneEditorSegmentTableSectionFooterClasses,
  row: wizardLayoutRightPaneEditorSegmentTableRowClasses,
  cell: wizardLayoutRightPaneEditorSegmentTableCellClasses,
} as const
export const wizardLayoutRightPaneEditorSegmentTableClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-Segment-Table-wrapper',
  tableSection: wizardLayoutRightPaneEditorSegmentTableSectionClasses,
} as const
export const wizardLayoutRightPaneEditorSegmentParagraphClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-Segment-Paragraph-wrapper',
  chunk: 'WizardLayout-RightPane-Editor-Segment-Paragraph-chunk',
} as const
export const wizardLayoutRightPaneEditorSegmentClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-Segment-wrapper',
  paragraph: wizardLayoutRightPaneEditorSegmentParagraphClasses,
  table: wizardLayoutRightPaneEditorSegmentTableClasses,
  image: wizardLayoutRightPaneEditorSegmentImageClasses,
} as const
export const wizardLayoutRightPaneEditorPageClasses = { wrapper: 'WizardLayout-RightPane-Editor-Page-wrapper' } as const
export const wizardLayoutRightPaneEditorSectionClasses = { wrapper: 'WizardLayout-RightPane-Editor-Section-wrapper' } as const
export const wizardLayoutRightPaneEditorPreviewClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-Preview-wrapper',
  content: 'WizardLayout-RightPane-Editor-Preview-content',
  section: wizardLayoutRightPaneEditorSectionClasses,
  page: wizardLayoutRightPaneEditorPageClasses,
  segment: wizardLayoutRightPaneEditorSegmentClasses,
  components: { focusModeToggle: wizardLayoutRightPaneEditorPreviewFocusModeToggleClasses },
} as const
export const wizardLayoutRightPaneEditorClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-wrapper',
  preview: wizardLayoutRightPaneEditorPreviewClasses,
} as const
export const wizardLayoutRightPaneClasses = { wrapper: 'WizardLayout-RightPane-wrapper', editor: wizardLayoutRightPaneEditorClasses } as const
export const wizardLayoutLeftPaneQuestionnairePostGenerationClasses = {
  wrapper: 'WizardLayout-LeftPane-Questionnaire-PostGeneration-wrapper',
} as const
export const wizardLayoutLeftPaneQuestionnaireDocumentRenameClasses = {
  wrapper: 'WizardLayout-LeftPane-Questionnaire-DocumentRename-wrapper',
  input: 'WizardLayout-LeftPane-Questionnaire-DocumentRename-input',
  texts: { primary: 'WizardLayout-LeftPane-Questionnaire-DocumentRename-texts-primary' },
  actions: { wrapper: 'WizardLayout-LeftPane-Questionnaire-DocumentRename-actions-wrapper' },
} as const
export const wizardLayoutLeftPaneQuestionnaireConfirmationClasses = {
  wrapper: 'WizardLayout-LeftPane-Questionnaire-Confirmation-wrapper',
  texts: {
    primary: 'WizardLayout-LeftPane-Questionnaire-Confirmation-texts-primary',
    secondary: 'WizardLayout-LeftPane-Questionnaire-Confirmation-texts-secondary',
  },
  actions: { wrapper: 'WizardLayout-LeftPane-Questionnaire-Confirmation-actions-wrapper' },
} as const
export const wizardLayoutLeftPaneQuestionnaireSkippedQuestionReviewClasses = {
  wrapper: 'WizardLayout-LeftPane-Questionnaire-SkippedQuestionReview-wrapper',
  texts: {
    primary: 'WizardLayout-LeftPane-Questionnaire-SkippedQuestionReview-texts-primary',
    secondary: 'WizardLayout-LeftPane-Questionnaire-SkippedQuestionReview-texts-secondary',
  },
  actions: { wrapper: 'WizardLayout-LeftPane-Questionnaire-SkippedQuestionReview-actions-wrapper' },
} as const
export const wizardLayoutLeftPaneQuestionnaireOptionClasses = {
  wrapper: 'WizardLayout-LeftPane-Questionnaire-Option-wrapper',
  optionLabel: 'WizardLayout-LeftPane-Questionnaire-Option-optionLabel',
} as const
export const wizardLayoutLeftPaneQuestionnaireOptionGroupClasses = {
  wrapper: 'WizardLayout-LeftPane-Questionnaire-OptionGroup-wrapper',
} as const
export const wizardLayoutLeftPaneQuestionnaireQuestionClasses = {
  wrapper: 'WizardLayout-LeftPane-Questionnaire-Question-wrapper',
  orderNumber: 'WizardLayout-LeftPane-Questionnaire-Question-orderNumber',
  details: {
    wrapper: 'WizardLayout-LeftPane-Questionnaire-Question-details-wrapper',
    text: 'WizardLayout-LeftPane-Questionnaire-Question-details-text',
    description: 'WizardLayout-LeftPane-Questionnaire-Question-details-description',
    hint: 'WizardLayout-LeftPane-Questionnaire-Question-details-hint',
  },
  optionGroups: { wrapper: 'WizardLayout-LeftPane-Questionnaire-Question-optionGroups-wrapper' },
  actions: { wrapper: 'WizardLayout-LeftPane-Questionnaire-Question-actions-wrapper' },
} as const
export const wizardLayoutLeftPaneQuestionnaireQuestionGroupClasses = { wrapper: 'WizardLayout-LeftPane-Questionnaire-QuestionGroup-wrapper' } as const
export const wizardLayoutLeftPaneQuestionnairePreAnsweringClasses = {
  wrapper: 'WizardLayout-LeftPane-Questionnaire-PreAnswering-wrapper',
  actions: { wrapper: 'WizardLayout-LeftPane-Questionnaire-PreAnswering-actions-wrapper' },
} as const
export const wizardLayoutLeftPaneQuestionnaireClasses = {
  wrapper: 'WizardLayout-LeftPane-Questionnaire-wrapper',
  preStep: wizardLayoutLeftPaneQuestionnairePreAnsweringClasses,
  questionGroup: wizardLayoutLeftPaneQuestionnaireQuestionGroupClasses,
  question: wizardLayoutLeftPaneQuestionnaireQuestionClasses,
  optionGroup: wizardLayoutLeftPaneQuestionnaireOptionGroupClasses,
  option: wizardLayoutLeftPaneQuestionnaireOptionClasses,
  skippedQuestionReview: wizardLayoutLeftPaneQuestionnaireSkippedQuestionReviewClasses,
  confirmation: wizardLayoutLeftPaneQuestionnaireConfirmationClasses,
  documentRename: wizardLayoutLeftPaneQuestionnaireDocumentRenameClasses,
  postGeneration: wizardLayoutLeftPaneQuestionnairePostGenerationClasses,
} as const
export const wizardLayoutLeftPaneTemplateSelectionClasses = { wrapper: 'WizardLayout-LeftPane-TemplateSelection-wrapper' } as const
export const wizardLayoutLeftPaneClasses = {
  wrapper: 'WizardLayout-LeftPane-wrapper',
  templateSelection: wizardLayoutLeftPaneTemplateSelectionClasses,
  questionnaire: wizardLayoutLeftPaneQuestionnaireClasses,
} as const
export const wizardLayoutHeaderClasses = {
  wrapper: 'WizardLayout-Header-wrapper',
  casusLogo: 'WizardLayout-Header-casusLogo',
  documentTitle: 'WizardLayout-Header-documentTitle',
  backButton: 'WizardLayout-Header-backButton',
} as const
export const wizardLayoutClasses = {
  wrapper: 'WizardLayout-wrapper',
  header: wizardLayoutHeaderClasses,
  left: wizardLayoutLeftPaneClasses,
  right: wizardLayoutRightPaneClasses,
  footer: wizardLayoutFooterClasses,
} as const

export { WizardLayout, Header, LeftPane, RightPane, Footer, wizardLayoutClasses as classes }
export default WizardLayout
