import React from 'react'
import { Link } from 'react-router-dom'
import { Container, InnerContainer, Icon, Text } from './RemindersItem.styles'
import { documentsRoutes } from 'constants/routes'
import icon from './icon.png'
import { useTranslation } from 'react-i18next'

interface Props {
  active: boolean
}

const RemindersNavItem: React.FC<Props> = ({ active }) => {
  const {t} = useTranslation()
  const url = t(documentsRoutes.reminders)
  return (
    <Container>
      <Link to={url}>
        <InnerContainer>
          <Icon src={icon} />
          <Text active={active ? 1 : 0}>{t("reminders")}</Text>
        </InnerContainer>
      </Link>
    </Container>
  )
}

export default RemindersNavItem
