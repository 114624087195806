import React, { useMemo } from 'react'
import useStore from '___store'

import { classes } from '../..'
import { Select } from 'components'
import AssignToCurrentQuestionButton from './AssignToCurrentQuestionButton'
import AssignToOptionButton from './AssignToOptionButton'

const MarkerAssign = React.memo(
  ({
    markerId,
    assignedQuestionId,
    currentQuestionId,
    // assignedOptionIds
  }) => {
    const {
      // externals = [],
      questions = [],
      // questionById: { text = '' } = {},
      // questionOptionGroupIds = '',
      questionOptionIds = '',
      assignMarker,
    } = useStore(
      // 'selectExternals',
      'selectQuestions',
      // `selectQuestionById[${currentQuestionId}]`,
      // `selectQuestionOptionGroupIds[${currentQuestionId}]`,
      `selectQuestionOptionIds[${currentQuestionId}]`,
      'assignMarker'
    )
    const questionAssignOptions = useMemo(() => questions.reduce((result, { id, text }) => Object.assign(result, { [id]: text }), {}), [questions])
    // const optionGroupIds = useMemo(() => questionOptionGroupIds.split('; ').filter(o => o), [questionOptionGroupIds])
    const optionIds = useMemo(() => questionOptionIds.split('; ').filter(o => o), [questionOptionIds])
    // const externalIds = useMemo(() => externals.map(({ id }) => id), [externals])
    // const externalOptions = useMemo(() => externalIds.reduce((acc, cur) => Object.assign(acc, { [cur]: 'external' }), {}), [externalIds])
    // const allAssignedIds = useMemo(() => assignedOptionIds.concat(assignedQuestionId), [assignedOptionIds, assignedQuestionId])

    // const allOptions = useMemo(() => optionIds.concat(currentQuestionId).concat(externalIds), [optionIds, currentQuestionId, externalIds])
    // const availableOptions = useMemo(() => allOptions.filter(id => id && !allAssignedIds.includes(id)), [allOptions, allAssignedIds])
    // const selectOptions = useMemo(
    //   () =>
    //     optionGroupIds.reduce(
    //       (acc, cur) => Object.assign(acc, { [cur]: 'optionGroup' }),
    //       Object.assign(
    //         {},
    //         externalOptions,
    //         currentQuestionId && assignedQuestionId !== currentQuestionId ? { [currentQuestionId]: text || 'Current question' } : {}
    //       )
    //     ),
    //   [optionGroupIds, externalOptions, assignedQuestionId, currentQuestionId, text]
    // )

    const assignToQuestionSelect = useMemo(
      () => (
        <div className={classes.configEntry.markerConfiguration.fragment.wrapper}>
          <span className={classes.configEntry.markerConfiguration.fragment.title}>Assign to Question</span>
          <Select
            className={classes.configEntry.markerConfiguration.fragment.questionAssignSelect}
            value={assignedQuestionId}
            options={questionAssignOptions}
            onChange={questionId => assignMarker({ markerId, questionId })}
            directionPriority="vertical"
            horizontalDirection="right"
          ></Select>
        </div>
      ),
      [assignedQuestionId, questionAssignOptions, assignMarker, markerId]
    )

    const questionOptionAssignButtons = useMemo(
      () =>
        optionIds?.length
          ? optionIds.map(optionId => (
              <AssignToOptionButton key={`markerAssign-${markerId}-questionOption-${optionId}`} markerId={markerId} optionId={optionId} />
            ))
          : null,
      [optionIds, markerId]
    )

    return (
      <>
        {assignToQuestionSelect}
        <div className={classes.configEntry.markerConfiguration.fragment.wrapper}>
          <span className={classes.configEntry.markerConfiguration.fragment.title}>Current Configuring Question</span>
          <span className={classes.configEntry.markerConfiguration.fragment.text}>
            Assign this marker to the current configuring question, or its child options. If the marker is already assigned to the current configuring
            question, you can unassign this marker from it.
          </span>
          <AssignToCurrentQuestionButton markerId={markerId} />
          {questionOptionAssignButtons}
        </div>
      </>
    )
  }
)

MarkerAssign.displayName = 'Wizard-Editor-Marker-MarkerAssign'

export default MarkerAssign
