import React, { useEffect } from 'react'
import { Heading } from '../common-styles'
import { Form } from './LoginForm.styles'
import { TextInputGroup, CustomButton, TextWithUrl } from 'components/common'
import { ILoginFormState } from './LoginForm.container'
import { useTranslation } from 'react-i18next'
import { firebase } from 'config/firebase'
import AuthApi from 'api/auth.api'

interface Props {
  isLoading: boolean
  values: ILoginFormState
  allowClientRegister: boolean
  handleChange: React.ChangeEventHandler
  handleSubmit: React.FormEventHandler
}

const LoginForm: React.FC<Props> = ({ isLoading, allowClientRegister, values, handleChange, handleSubmit }) => {
  const { t } = useTranslation()

  const checkLogin = () => {
    firebase
      .auth()
      .getRedirectResult()
      .then(async result => {
        if (result.user) {
          // const usr = await result.user.getIdTokenResult(true)
          console.info('SUCCESS', result)

          await AuthApi.authenticate(result.user)
        } else {
          // console.warn("NO RESULT", result)
        }
        // User is signed in.

        // Provider data available in result.additionalUserInfo.profile,
        // or from the user's ID token obtained from result.user.getIdToken()
        // as an object in the firebase.sign_in_attributes custom claim.
      })
      .catch(error => {
        // Handle error.
        console.error('ERRR', error)
      })
  }

  useEffect(checkLogin)

  return (
    <Form onSubmit={handleSubmit}>
      <Heading variant="h3">Login ({process.env.REACT_APP_NODE_ENV})</Heading>
        <div>
          <TextInputGroup
            type="email"
            label={t('your_email_address')}
            name="email"
            value={values.email}
            placeholder="z.B. marina@meyer.ch"
            onChange={handleChange}
            required
          />

          <TextInputGroup
            type="password"
            label={t('u_password')}
            name="password"
            value={values.password}
            onChange={handleChange}
            labelUrl={{ text: t('forgot_password'), url: '/auth/passwort-wiederherstellen' }}
            required
          />

          <CustomButton type="submit" isLoading={isLoading} btntype="primary" size="large" width="100%" disabled={isLoading}>
            {!isLoading ? 'Login' : t('one_moment_please')}
          </CustomButton>
        </div>
      {allowClientRegister && (
        <TextWithUrl
          text={t('dont_have_account_yet')}
          urlText={'Book a Call'}
          handleUrlClick={() => (window.location.href = 'https://calendly.com/team-casus/call-mit-casus')}
        />
      )}
    </Form>
  )
}

export default LoginForm
