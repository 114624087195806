import { DocumentsFilter, TemplatesFilter } from '___types'

import { SetDocumentsFilterPayload, SetSelectedDocumentIdPayload } from './documents'
import { SetTemplatesFilterPayload, SetSelectedTemplateIdPayload } from './templates'

import { setDocumentsFilter, setSelectedDocumentId, deselectAllDocuments } from './documents'
import { setTemplatesFilter, setSelectedTemplateId, deselectAllTemplates } from './templates'

export const initialAppState = {
  documents: { filter: 'mine' as DocumentsFilter, selected: null as string | null },
  templates: { filter: 'mine' as TemplatesFilter, selected: null as string | null },
}
export type AppState = typeof initialAppState
export type { SetDocumentsFilterPayload, SetSelectedDocumentIdPayload }
export type { SetTemplatesFilterPayload, SetSelectedTemplateIdPayload }

export { setDocumentsFilter, setSelectedDocumentId, deselectAllDocuments }
export { setTemplatesFilter, setSelectedTemplateId, deselectAllTemplates }

export default initialAppState
