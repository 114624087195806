import ACTION_TYPES from '../../../actionTypes'
import {
  InitializeWizardPayload,
  UpdateWizardStatePayload,
  SetWizardTemplateIdPayload,
  UpdateWizardTitlePayload,
  NavigateQuestionnaireToPayload,
  AnswerWithOptionPayload,
  UnanswerOptionPayload,
} from './helpers'

export type ResetWizardAction = () => void
export type InitializeWizardAction = (payload: InitializeWizardPayload) => void
export type UpdateWizardStateAction = (payload: UpdateWizardStatePayload) => void
export type SetWizardTemplateIdAction = (payload: SetWizardTemplateIdPayload) => void
export type ResetWizardTemplateIdAction = () => void
export type UpdateWizardTitleAction = (payload: UpdateWizardTitlePayload) => void

// ================================================================= //
// ====================== DOCUMENT GENERATION ====================== //
// ================================================================= //

// ====================== QUESTION NAVIGATION ====================== //
export type NavigateQuestionnaireForwardAction = () => void
export type NavigateQuestionnaireBackwardAction = () => void
export type NavigateQuestionnaireToAction = (payload: NavigateQuestionnaireToPayload) => void
// ====================== ANSWERING QUESTIONS ====================== //
export type AnswerWithOptionAction = (payload: AnswerWithOptionPayload) => void
export type UnanswerOptionAction = (payload: UnanswerOptionPayload) => void
// ================================================================= //

const actionCreators = {
  resetWizard: () => ({ type: ACTION_TYPES.WIZARD.WIZARD_RESET }),
  initializeWizard: (payload: InitializeWizardPayload) => ({ type: ACTION_TYPES.WIZARD.WIZARD_INITIALIZED, payload }),
  updateWizardState: (payload: UpdateWizardStatePayload) => ({ type: ACTION_TYPES.WIZARD.STATE_UPDATED, payload }),
  setWizardTemplateId: (payload: SetWizardTemplateIdPayload) => ({ type: ACTION_TYPES.WIZARD.TEMPLATE_ID_SET, payload }),
  resetWizardTemplateId: () => ({ type: ACTION_TYPES.WIZARD.TEMPLATE_ID_RESET }),
  updateWizardTitle: (payload: SetWizardTemplateIdPayload) => ({ type: ACTION_TYPES.WIZARD.TITLE_UPDATED, payload }),

  // ================================================================= //
  // ====================== DOCUMENT GENERATION ====================== //
  // ================================================================= //

  // ====================== QUESTION NAVIGATION ====================== //
  navigateQuestionnaireForward: () => ({ type: ACTION_TYPES.WIZARD.QUESTIONNAIRE.NAVIGATED_FORWARD }),
  navigateQuestionnaireBackward: () => ({ type: ACTION_TYPES.WIZARD.QUESTIONNAIRE.NAVIGATED_BACKWARD }),
  navigateQuestionnaireTo: (payload: NavigateQuestionnaireToPayload) => ({ type: ACTION_TYPES.WIZARD.QUESTIONNAIRE.NAVIGATED_TO, payload }),
  // ====================== ANSWERING QUESTIONS ====================== //
  answerWithOption: (payload: AnswerWithOptionPayload) => ({ type: ACTION_TYPES.WIZARD.QUESTION_ANSWERED_WITH_OPTION, payload }),
  unanswerOption: (payload: UnanswerOptionPayload) => ({ type: ACTION_TYPES.WIZARD.QUESTION_OPTION_UNANSWERED, payload }),
  // ================================================================= //
}

export { actionCreators }
export default actionCreators
