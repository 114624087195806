import React, { useEffect } from 'react'

import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { useLoadTemplates } from 'queries/templates'
import { useFetchFolders } from 'queries/folders'
import { useRootCategoryFolder } from 'hooks/useRootCategoryFolder'
import { useUser } from 'queries/user/useUser'

import TemplatesList from './components/TemplatesList/TemplatesList'
import FolderList from '../../../components/files/Folder/FolderList.container'
import { TemplatesLayout } from './components/Layout/Layout'
import FileAndFolderActions from 'components/files/FileAndFolderActions/FileAndFolderActions'
import TemplatePreviewModal from './components/TemplatePreviewModal/TemplatePreviewModal.container'
import { useTranslation } from 'react-i18next'

const TemplatesListPage: React.FC = () => {
  const { currentFolder, setCurrentFolder } = useCurrentFolder('templates')
  useLoadTemplates(currentFolder)
  useFetchFolders('templates', currentFolder)
  const casusFolder = useRootCategoryFolder('templates', 'casus')
  const myFolder = useRootCategoryFolder('templates', 'my')
  const user = useUser()
  const {t} = useTranslation()

  useEffect(() => {
    document.title = `${t('u_templates')} | CASUS`
  }, [t])

  useEffect(() => {
    if (
      user?.subscription.type === 'free' &&
      user?.casusTemplatesEnabled &&
      currentFolder.type !== 'casus'
    )
      setCurrentFolder(casusFolder)
    else if (!user?.casusTemplatesEnabled && currentFolder.type === 'casus')
      setCurrentFolder(myFolder)
    // if (!currentFolder.type) setCurrentFolder(myFolder);
    // if (!user?.casusTemplatesEnabled && currentFolder.type === "casus")
    //   setCurrentFolder(myFolder);
  }, [user, casusFolder, myFolder, currentFolder, setCurrentFolder])

  return (
    <TemplatesLayout>
      <FolderList where="templates" />
      <TemplatesList />
      <FileAndFolderActions where="templates" />
      <TemplatePreviewModal />
    </TemplatesLayout>
  )
}

export default TemplatesListPage
