import React, { FunctionComponent } from 'react'

import { WizardLayoutFooterProps, wizardLayoutFooterClasses as classes } from '../..'

export const Footer: FunctionComponent<WizardLayoutFooterProps> = React.memo(() => {
  return <div className={classes.wrapper}></div>
})
Footer.displayName = 'WizardLayout-Footer'

export default Footer
