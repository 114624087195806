import { useQuery, QueryFunctionContext, useQueryClient, InfiniteData } from 'react-query'

import { ITemplate } from '___types'
import { templatesAPI } from '___api'
import { QUERY_KEYS } from '___queries'
import { MAPPED_TEMPLATES_FILTERS } from '___types/types.template'

const getTemplateQueryFunction = ({ queryKey }: QueryFunctionContext) => templatesAPI.getTemplate(queryKey[1] as string)

export const useFetchTemplate = (id?: string | null) => {
  const queryClient = useQueryClient()
  const templateQuery = useQuery({
    queryKey: [QUERY_KEYS.TEMPLATE, id],
    queryFn: getTemplateQueryFunction,
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    placeholderData: () => {
      const allTemplates = Object.values(MAPPED_TEMPLATES_FILTERS).reduce((allTemplates: ITemplate[], filter) => {
        const templatesQuery = queryClient.getQueryData([QUERY_KEYS.TEMPLATES, filter]) as InfiniteData<ITemplate[]>
        const templates = (templatesQuery?.pages || []).reduce((filterTemplates, page) => filterTemplates.concat(page), [])
        return allTemplates.concat(templates) as ITemplate[]
      }, [])
      return allTemplates.find(template => template.id === id)
    },
  })
  return templateQuery
}

export default useFetchTemplate
