import React, { useMemo } from 'react'
import useStore from '___store'

import { TEMPLATES_FILTERS } from '___types'
import { Company, Person, Team, Trash } from 'assets/svgIconComponents'
import { classes } from 'Layouts'

const Templates = React.memo(() => {
  const { templatesFilter, setTemplatesFilter } = useStore('selectTemplatesFilter', 'setTemplatesFilter')

  const {
    components: {
      leftPane: {
        section: { wrapper: sectionClass, navButton: buttonClass },
      },
    },
  } = classes

  const appStateButtons = useMemo(() => {
    const buttons = [
      { filter: TEMPLATES_FILTERS.MINE, label: 'My Templates', Icon: Person },
      { filter: TEMPLATES_FILTERS.SHARED, label: 'Shared Templates', Icon: Team },
      { filter: TEMPLATES_FILTERS.COMPANY, label: 'Company Templates', Icon: Company },
      { filter: TEMPLATES_FILTERS.TRASHED, label: 'Bin', Icon: Trash },
    ]
    return buttons.map(({ filter, label, Icon }) => (
      <button
        key={`LeftPane-Templates-button-${filter}`}
        className={buttonClass}
        data-active={filter === templatesFilter}
        type="button"
        onClick={() => setTemplatesFilter(filter)}
      >
        <Icon key={`LeftPane-Templates-button-icon-${filter}`} />
        {label}
      </button>
    ))
  }, [buttonClass, templatesFilter, setTemplatesFilter])

  return <div className={sectionClass}>{appStateButtons}</div>
})

Templates.displayName = 'LeftPane-Templates'

export default Templates
