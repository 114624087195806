import React, { FunctionComponent, Dispatch, SetStateAction, RefCallback, useState, useMemo, useCallback, useEffect } from 'react'
import useStore, {
  WizardAnswerByIdSelector,
  NavigateQuestionnaireForwardAction,
  NavigateQuestionnaireBackwardAction,
  AnswerWithOptionAction,
  UnanswerOptionAction,
} from '___store'
import { getFormattedAnswerValue, getOptionPropertiesFromAnswerById } from '___store/storeSegments/wizard/typified/helpers'

import {
  CASUS_KEYSTRINGS,
  ANSWER_VALUE_MATCH,
  OPTION_GROUP_SELECT,
  OPTION_TYPES,
  Question,
  StaticOption,
  INPUT_TYPE_MAP,
  OPTION_VALUE_TYPES,
  OptionLimits,
  OptionValueTypeUnionType,
} from '___types'
import { Caret, Check } from 'assets/svgIconComponents'
import { Button, Input } from 'components/CasusComponents'
import { applyLimitsToValue, extractValueFromInputEvent, getCurrentInputValue, useQuestionContext } from '.'
import {
  WizardLayoutLeftPaneQuestionnaireOptionProps,
  WizardLayoutLeftPaneQuestionnairePredefinedStaticOptionProps,
  WizardLayoutLeftPaneQuestionnaireUserInputStaticOptionProps,
  wizardLayoutLeftPaneQuestionnaireOptionClasses as classes,
} from '../../..'

type UseStoreHookResultType = {
  wizardAnswerById: WizardAnswerByIdSelector
  navigateQuestionnaireForward: NavigateQuestionnaireForwardAction
  navigateQuestionnaireBackward: NavigateQuestionnaireBackwardAction
  answerWithOption: AnswerWithOptionAction
  unanswerOption: UnanswerOptionAction
}

const UserInputStaticOption: FunctionComponent<WizardLayoutLeftPaneQuestionnaireUserInputStaticOptionProps> = React.memo(props => {
  const { id: questionId } = useQuestionContext() as Question
  const { wizardAnswerById, navigateQuestionnaireForward, navigateQuestionnaireBackward, answerWithOption, unanswerOption } = useStore(
    `selectWizardAnswerById[${questionId}]`,
    'navigateQuestionnaireForward',
    'navigateQuestionnaireBackward',
    'answerWithOption',
    'unanswerOption'
  ) as UseStoreHookResultType
  const { option, label, select, singleOption, firstOption } = props
  const { id, valueType, limits, placeholder } = option || {}
  const [input, setInput]: [HTMLPreElement | HTMLInputElement | undefined, Dispatch<SetStateAction<HTMLPreElement | HTMLInputElement | undefined>>] =
    useState()
  const inputRef: RefCallback<HTMLPreElement | HTMLInputElement | undefined> = useCallback(node => node && setInput(node), [])

  const [foundValue, selected] = useMemo(() => {
    const optionProperties = wizardAnswerById && getOptionPropertiesFromAnswerById(wizardAnswerById, id)
    return optionProperties?.type !== undefined
      ? [getFormattedAnswerValue(optionProperties.type as OptionValueTypeUnionType, optionProperties.value, { dateFormat: 'yyyy-MM-dd' }), true]
      : [undefined, false]
  }, [wizardAnswerById, id])

  const inputOptions = useMemo(
    () => Object.assign({}, valueType === OPTION_VALUE_TYPES.DATE_TIME ? { inputTime: true } : undefined, limits),
    [valueType, limits]
  )

  const inputHandler = useCallback(
    event => {
      if (!questionId) return
      const value = applyLimitsToValue(valueType, extractValueFromInputEvent(valueType, event), (limits || {}) as OptionLimits)
      if (value !== undefined && !Number.isNaN(Number(value)))
        answerWithOption({
          questionId,
          value: `${id}:{{${CASUS_KEYSTRINGS.INPUT} type="${valueType}" value="${value}"}}`,
        })
      if (selected && (value === undefined || Number.isNaN(Number(value)))) unanswerOption({ id, questionId })
    },
    [questionId, valueType, limits, answerWithOption, id, selected, unanswerOption]
  )
  const confirmHandler = useCallback(() => {
    if (!(id && questionId && input)) return
    const currentInputValue = applyLimitsToValue(valueType, getCurrentInputValue(valueType, input), (limits || {}) as OptionLimits)
    answerWithOption({ questionId, value: `${id}:{{${CASUS_KEYSTRINGS.INPUT} type="${valueType}" value="${currentInputValue}"}}` })
    if (select === OPTION_GROUP_SELECT.SINGLE) navigateQuestionnaireForward()
  }, [id, questionId, input, valueType, limits, answerWithOption, select, navigateQuestionnaireForward])
  const discardHandler = useCallback(() => {
    if (!(id && questionId && selected)) return
    unanswerOption({ id, questionId })
  }, [id, questionId, selected, unanswerOption])
  const nextHandler = useCallback(() => {
    if (!(id && questionId && input && singleOption)) return
    const currentInputValue = applyLimitsToValue(valueType, getCurrentInputValue(valueType, input), (limits || {}) as OptionLimits)
    answerWithOption({ questionId, value: `${id}:{{${CASUS_KEYSTRINGS.INPUT} type="${valueType}" value="${currentInputValue}"}}` })
    navigateQuestionnaireForward()
  }, [id, questionId, input, valueType, limits, answerWithOption, navigateQuestionnaireForward, singleOption])

  return (
    <div
      className={classes.wrapper}
      data-type={OPTION_TYPES.STATIC}
      data-select={select}
      data-single-option={singleOption ? '' : undefined}
      data-selected={selected ? '' : undefined}
    >
      {select === OPTION_GROUP_SELECT.SINGLE && singleOption ? (
        <Button onClick={() => navigateQuestionnaireBackward()}>
          <Caret />
        </Button>
      ) : null}
      <Button
        className={classes.optionLabel}
        tabbable={false}
        onClick={event => {
          event.currentTarget.blur()
          if (selected) unanswerOption({ id, questionId })
          else {
            confirmHandler()
            if (input) input.focus()
          }
        }}
      >
        {select === OPTION_GROUP_SELECT.MULTI && selected ? <Check /> : label}
      </Button>
      <Input
        ref={inputRef}
        type={INPUT_TYPE_MAP[valueType]}
        tertiary
        defaultValue={foundValue}
        placeholder={placeholder}
        options={inputOptions}
        debouncedInput={300}
        autoselect={firstOption}
        showActionButtons={!(select === OPTION_GROUP_SELECT.SINGLE && singleOption)}
        onInput={inputHandler}
        onConfirm={confirmHandler}
        onDiscard={discardHandler}
      />
      {select === OPTION_GROUP_SELECT.SINGLE && singleOption ? (
        <Button tertiary onClick={nextHandler}>
          Next
          <Caret />
        </Button>
      ) : null}
    </div>
  )
})

const PredefinedStaticOption: FunctionComponent<WizardLayoutLeftPaneQuestionnairePredefinedStaticOptionProps> = React.memo(props => {
  const { id: questionId } = useQuestionContext() as Question
  const { wizardAnswerById, answerWithOption, unanswerOption, navigateQuestionnaireForward } = useStore(
    `selectWizardAnswerById[${questionId}]`,
    'answerWithOption',
    'unanswerOption',
    'navigateQuestionnaireForward'
  ) as UseStoreHookResultType
  const { option, label, select, firstOption } = props
  const { id, value, text, valueType } = option || {}

  const [button, setButton]: [HTMLButtonElement | undefined, Dispatch<SetStateAction<HTMLButtonElement | undefined>>] = useState()

  const selected = useMemo(() => wizardAnswerById?.values?.some(value => value.match(ANSWER_VALUE_MATCH)?.groups?.id === id), [wizardAnswerById, id])
  const dataSet = useMemo(
    () =>
      Object.entries({
        type: OPTION_TYPES.STATIC,
        predefined: '',
        select,
        singleOption: props.singleOption ? '' : undefined,
        selected: selected ? '' : undefined,
      }).reduce((result, [key, value]) => (value !== undefined ? Object.assign(result, { [key]: value }) : result), {}),
    [select, props.singleOption, selected]
  )

  const formattedText = useMemo(() => text || getFormattedAnswerValue(valueType, value), [text, valueType, value])

  const buttonRef: RefCallback<HTMLButtonElement | undefined> = useCallback(node => node && setButton(node), [])
  const clickHandler = useCallback(() => {
    if (!questionId) return
    if (select === OPTION_GROUP_SELECT.SINGLE) {
      answerWithOption({ questionId, value: `${id}:{{${CASUS_KEYSTRINGS.PREDEFINED} type="${valueType}" value="${value}"}}` })
      navigateQuestionnaireForward()
    } else if (select === OPTION_GROUP_SELECT.MULTI) {
      if (!selected) answerWithOption({ questionId, value: `${id}:{{${CASUS_KEYSTRINGS.PREDEFINED} type="${valueType}" value="${value}"}}` })
      else unanswerOption({ id, questionId })
    }
  }, [questionId, select, answerWithOption, id, valueType, value, navigateQuestionnaireForward, selected, unanswerOption])

  useEffect(() => {
    if (button && firstOption) button.focus()
  }, [button, firstOption])

  return (
    <Button ref={buttonRef} className={classes.wrapper} dataSet={dataSet} onClick={clickHandler}>
      <div className={classes.optionLabel}>{select === OPTION_GROUP_SELECT.MULTI && selected ? <Check /> : label}</div>
      {formattedText}
    </Button>
  )
})

const QuestionnaireOption: FunctionComponent<WizardLayoutLeftPaneQuestionnaireOptionProps> = React.memo(
  ({ option, index, select, singleOption, firstOption }) => {
    const { type, value, label } = option || {}
    const predefined = Boolean(value)

    if (type === OPTION_TYPES.STATIC) {
      if (predefined)
        return (
          <PredefinedStaticOption
            option={option as StaticOption}
            label={label || (index + 10).toString(36).toUpperCase()}
            select={select}
            singleOption={singleOption}
            firstOption={firstOption}
          />
        )
      else
        return (
          <UserInputStaticOption
            option={option as StaticOption}
            label={label || (index + 10).toString(36).toUpperCase()}
            select={select}
            singleOption={singleOption}
            firstOption={firstOption}
          />
        )
    }
    return null
  }
)

QuestionnaireOption.displayName = 'WizardLayout-Questionnaire-QuestionGroup-Question'

export default QuestionnaireOption
