import React, { FunctionComponent, useMemo } from 'react'

import { LOCATION_TYPES } from '___types'
import { useSectionContext } from '.'
import { usePage } from './customHooks'
import Segment from './Segment'
import Marker from './Marker'
import { WizardLayoutRightPaneEditorPageProps, wizardLayoutRightPaneEditorPageClasses as classes } from '../../..'

export const Page: FunctionComponent<WizardLayoutRightPaneEditorPageProps> = React.memo(({ index, range }) => {
  const { index: sectionIndex } = useSectionContext()
  const contentIds = usePage(sectionIndex, index)
  const render = useMemo(
    () =>
      contentIds?.map(id => {
        if (id.slice(0, 7) === 'marker:')
          return <Marker key={id} id={id.slice(7)} type={LOCATION_TYPES.SEGMENTS} parent={`root-${sectionIndex}-${index}`} />
        return <Segment key={id} id={id} />
      }),
    [contentIds, sectionIndex, index]
  )
  return <div className={classes.wrapper}>{render}</div>
})

Page.displayName = 'WizardLayout-RightPane-Editor-Page'

export default Page
