import React, { useMemo } from 'react'
import useStore from '___store'

import { QuestionOptionGroup, TagStrip } from './components'
import { classes } from '../../../index'
import { Button } from 'components'
import { Plus } from 'assets/svgIconComponents'

const noTagMessage = 'No replacement markers.'
const noTagDescription =
  "To replace parts of the document, mark segments/text in the document by highlighting content and selecting appropriate marker buttons. Text markers will be automatically assigned to this question, whilst segment markers need to be assigned manually by pressing the assign button in the marker's header."
const replacementText = 'These markers will be replaced by the answer to this question!'

const QuestionOptions = React.memo(({ questionId }) => {
  const {
    questionOptionGroupIds,
    questionMarkerIds = '',
    addNewOptionGroup,
  } = useStore(`selectQuestionOptionGroupIds[${questionId}]`, `selectQuestionMarkerIds[${questionId}]`, 'addNewOptionGroup')

  const optionGroupIds = useMemo(() => questionOptionGroupIds.split('; ').filter(o => o), [questionOptionGroupIds])
  const optionGroups = useMemo(
    () => optionGroupIds.map(id => <QuestionOptionGroup key={id} questionId={questionId} id={id} />),
    [questionId, optionGroupIds]
  )
  const {
    wrapper: wrapperClass,
    text: textClass,
    questionOptions: { optionGroupList: optionGroupListClass, addOptionGroupButton: addOptionGroupButtonClass },
  } = classes.configEntry.questionConfiguration.fragment

  return (
    <div className={wrapperClass}>
      {/* <span className={titleClass}>Answer Options</span> */}
      <TagStrip questionId={questionId} noTagMessage={noTagMessage} noTagDescription={noTagDescription} />
      {questionMarkerIds ? <span className={textClass}>{replacementText}</span> : null}
      <div className={optionGroupListClass}>{optionGroups}</div>
      <Button className={addOptionGroupButtonClass} onClick={() => addNewOptionGroup({ questionId })}>
        <Plus /> Add new option group
      </Button>
      {/* <ButtonGroup className={classes.questionOptionsFragmentButtonGroup}>
        <QuestionSelectSwitch questionId={questionId} />
        {type === 'choice' ? <AutomaticAssignButton questionId={questionId} /> : null}
        {type === 'replacement' ? <ValueTypeSelect className={classes.questionOptionsFragmentSelect} questionId={questionId} /> : null}
        <Button className={classes.questionOptionsFragmentButton} onClick={() => addNewQuestionOption({ questionId })} colorScheme="primary">
          Add Option
        </Button>
      </ButtonGroup> */}
    </div>
  )
})

QuestionOptions.displayName = 'Wizard-Configuration-QuestionConfiguration-QuestionOptions'

export default QuestionOptions
