import React, { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useUpdateTemplate, useFetchTemplate } from 'queries/templates'
import { useUser } from 'queries/user/useUser'
// import { useIsTemplatePurchaseRequired } from 'hooks/useIsTemplatePurchaseRequired'

import TemplatePreviewModal from 'pages/Authenticated/Templates/components/TemplatePreviewModal/TemplatePreviewModal.component'
import PurchaseTemplateModal from 'pages/Authenticated/Templates/components/PurchaseTemplateModal/PurchaseTemplateModal'

import { isEditorIsOwner } from 'utils'
import { useTemplateActions } from 'hooks/useTemplateActions'
import { useTranslation } from 'react-i18next'

const TemplatePreviewModalContainer: React.FC = () => {
  const { replace, location } = useHistory()

  const update = useUpdateTemplate()
  const user = useUser()
  const { t } = useTranslation()
  const params = new URLSearchParams(location.search)
  const templateId = params.get(t('template'))
  const { onUseTemplate, onEditTemplate } = useTemplateActions()

  const action = params.get('action')

  const { data: template, isLoading, refetch } = useFetchTemplate(templateId)
  useEffect(() => {
    refetch()
  }, [refetch, templateId])

  const noQuestions = template?.questions.length === 0
  const isOpen = action === 'preview' && templateId
  const isFreeUser = user?.subscription.type === 'free'

  // const requirePayment = useIsTemplatePurchaseRequired(templateId)

  const isDocumentGeneration = !location.pathname.startsWith(t('/template'))
  const [showPurchaseModal, setShowPurchaseModal] = useState(false)

  const onClose = (): void => {
    replace(location.pathname)
  }

  const onUpdateCharacteristics = (id: string, characteristics: string) => {
    update.mutate({ id, data: { characteristics } })
  }

  if (!isOpen || (!isLoading && !template)) return null

  const { isEditor, isOwner } = isEditorIsOwner(user?.id, template?.authorId, template?.sharedWith)

  // const actionBtnDisabled = !template?.questions.length || isLoading

  const editingDisabled = isDocumentGeneration || (!isOwner && !isEditor) || isFreeUser

  return (
    <Fragment>
      {true && template && (
        <PurchaseTemplateModal open={showPurchaseModal} templateId={template.id} handleClose={() => setShowPurchaseModal(false)} />
      )}
      <TemplatePreviewModal
        isOpen={!!isOpen}
        questionsLength={template?.questions.length}
        // actionBtnDisabled={actionBtnDisabled}
        template={template!}
        isLoading={isLoading}
        editingDisabled={editingDisabled}
        requirePayment={true}
        noQuestions={noQuestions}
        onClose={onClose}
        onUseTemplate={onUseTemplate}
        onEditTemplate={!editingDisabled ? onEditTemplate : undefined}
        onUpdateCharacteristics={!editingDisabled ? onUpdateCharacteristics : undefined}
      />
    </Fragment>
  )
}

export default TemplatePreviewModalContainer
