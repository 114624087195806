import { ITemplate } from 'types'
import { useQuery, UseQueryOptions } from 'react-query'
import TemplatesApi from 'api/templates.api'
import { queryKeys } from 'queries/queryKeys'
import { AxiosError } from 'axios'

export const useRecentTemplates = (options: UseQueryOptions<ITemplate[], AxiosError, ITemplate[]> = {}) => {
  const keys = [queryKeys.TEMPLATES, { recent: true }]

  const query = useQuery(keys, () => TemplatesApi.getRecents(), {
    ...options,
    refetchOnWindowFocus: false,
  })

  return query
}
