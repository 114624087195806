import React, { FunctionComponent, useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { SpinnerProps, className } from '.'

const strokeLength = 442
const Spinner: FunctionComponent<SpinnerProps> = React.memo(props => {
  const { strokeWidth = 1, strokeFill = 0.1, animationDuration = 3000, fill = 'rgba(30, 144, 255, 0.35)', stroke = 'rgba(0, 22, 64, 1)' } = props

  const dashFill = strokeLength * strokeFill
  const dashGap = strokeLength * (1 - strokeFill)
  const followStroke = 2

  // @ts-ignore
  const id = useMemo(() => uuid().replaceAll('-', ''), [])

  return (
    <svg className={`${className} Spinner`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
      <path
        d="M 70.5 35 A 21 21 0 0 0 50 19 A 25 31 0 0 0 50 81 A 21 21 0 0 0 71 61 h 19 A 40 37 0 0 1 50 97 A 45 47 0 0 1 43 96.5 L 5.5 57 A 45 47 0 0 1 50 3 A 40 37 0 0 1 89.5 35 Z"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill={fill}
        strokeLinejoin="round"
        strokeLinecap="round"
      >
        <animate
          id={`${id}dashoffset`}
          attributeName="stroke-dashoffset"
          values={`${dashFill}; -${strokeLength - dashFill}`}
          dur={`${animationDuration}ms`}
          begin={`0s; ${id}dashoffset.end`}
        />
        <animate
          id={`${id}dasharray`}
          attributeName="stroke-dasharray"
          values={`${dashFill} ${strokeLength}; ${dashFill} ${dashGap}`}
          dur={`${animationDuration * strokeFill}ms`}
          fill="freeze"
          begin="0s"
        />
        <animate
          attributeName="stroke-dasharray"
          keyTimes={`0; ${1 - strokeFill}; 1`}
          values={`${dashFill - followStroke} 0 ${followStroke} ${dashGap};  ${
            dashFill - followStroke
          } ${dashGap} ${followStroke} 0; ${dashFill} ${dashGap} 0 0`}
          dur={`${animationDuration * 4}ms`}
          begin={`${id}dasharray.end`}
          repeatCount="indefinite"
        />
      </path>
    </svg>
  )
})

export { Spinner }
export default Spinner
