import React from 'react'
import useStore from '___store'

import { ButtonGroup, Button } from 'components'
import { classes } from '../..'
import useAIQuestion from './useAIQuestion'
import MarkerAssign from './MarkerAssign'

const MarkerConfiguration = React.memo(({ id }) => {
  const {
    textMarkerLocations,
    locationById: { questionId, optionIds, contentText } = {},
    currentConfiguringQuestion: { id: currentQuestionId } = {},
    concludeConfiguring,
  } = useStore('selectTextMarkerLocations', `selectLocationById[${id}]`, 'selectCurrentConfiguringQuestion', 'concludeConfiguring')

  console.log('QUESTION ID:', questionId)

  const { mutate: createQuestion } = useAIQuestion(id, contentText)

  const {
    footer: footerClass,
    markerConfiguration: { wrapper: wrapperClass },
  } = classes.configEntry

  const relevantParagraphIds = Object.entries(textMarkerLocations).reduce((result, [id, markerArray]) => {
    const foundDuplicates = markerArray.filter(({ contentText: ct }) => ct === contentText)
    if (foundDuplicates.length) result.push(id)
    return result
  }, [])

  const { segments } = useStore('selectSegments')

  const parseParagraphs = (segments = []) =>
    segments
      .reduce((accumulated, current) => {
        const { id, type, textChunks = [] } = current
        if (type === 'table')
          return accumulated.concat(
            ['header', 'footer', 'body'].reduce(
              (combinedSections, section) =>
                (current[section] || []).reduce(
                  (combinedRows, { cells = [] }) =>
                    cells.reduce((combinedCells, { content }) => combinedCells.concat(parseParagraphs(content)), combinedRows),
                  combinedSections
                ),
              []
            )
          )
        if (type === 'paragraph') return accumulated.concat({ id, text: textChunks.map(({ text }) => text).join('') })
        return accumulated
      }, [])
      .filter(({ text }) => text.length)
  const parsedParagraphs = parseParagraphs(segments || [])

  const body = {
    contexts: parsedParagraphs.reduce((acc, cur) => acc.concat(relevantParagraphIds.includes(cur.id) ? cur.text : []), []),
    placeholder: contentText,
  }

  return (
    <>
      <div className={wrapperClass}>
        <div className={classes.configEntry.markerConfiguration.fragment.wrapper}>
          <span className={classes.configEntry.markerConfiguration.fragment.title}>AI</span>
          <ButtonGroup>
            <Button onClick={() => createQuestion(body)} colorScheme="primary">
              Generate Question with AI
            </Button>
          </ButtonGroup>
        </div>
        <MarkerAssign markerId={id} assignedQuestionId={questionId} currentQuestionId={currentQuestionId} assignedOptionIds={optionIds} />
      </div>
      <div className={footerClass}>
        <ButtonGroup>
          <Button onClick={concludeConfiguring} colorScheme="primary">
            Conclude
          </Button>
        </ButtonGroup>
      </div>
    </>
  )
})

MarkerConfiguration.displayName = 'Wizard-Configuration-MarkerConfiguration'

export default MarkerConfiguration
