import { IAnswer, Question } from 'types'
import React, { useRef } from 'react'
import CasusSidebar from './CasusSidebar/CasusSidebar'
import {
  Container,
  TopContent,
  Title,
  QuestionsContainer,
  GroupTitle,
  GroupContainer,
} from './TableOfContent.styles'
import QuestionComponent from './Question/Question'
import { Icon } from 'components/common'
import { QuestionFlattedMap } from 'components/features/documentGeneration/views/Questionnaire/services/createQuestionMap'
import { useTranslation } from 'react-i18next'

interface TableOfContentProps {
  currentQuestionId: string
  values: { [key: string]: IAnswer }
  questionsMap: QuestionFlattedMap
  showTableOfContent: boolean
  isCasus: boolean
  handleOpen: () => void
  handleClose: () => void
  handleEditQuestion: (qid: string) => void
}

const TableOfContent: React.FC<TableOfContentProps> = ({
  currentQuestionId,
  questionsMap,
  values,
  showTableOfContent,
  isCasus,
  handleOpen,
  handleClose,
  handleEditQuestion,
}) => {
  const { t } = useTranslation()
  const groupsRef = useRef([
    ...new Set(
      questionsMap.order
        .map(q => {
          const theQ = questionsMap.questions[q]
          if (!theQ.parentId) return theQ.group
          return ''
        })
        .filter(g => !!g)
    ),
  ])

  const groups = groupsRef.current

  if (!showTableOfContent) {
    return (
      <CasusSidebar
        isCasus={isCasus}
        groups={groups}
        currentGroup={questionsMap.questions[currentQuestionId].group}
        handleOpen={handleOpen}
      />
    )
  }

  const renderGroups = () => {
    return groups.map((group, i) => {
      const groupQuestions: Question[] = []

      questionsMap.order.forEach(qid => {
        if (questionsMap.questions[qid].group === group) {
          groupQuestions.push(questionsMap.questions[qid])
        }
      })

      return (
        <GroupContainer key={i}>
          <GroupTitle>{group}</GroupTitle>
          {groupQuestions.map(theQ => {
            return (
              <QuestionComponent
                questionsMap={questionsMap}
                key={theQ.id}
                question={theQ}
                values={values}
                handleEditQuestion={handleEditQuestion}
              />
            )
          })}
        </GroupContainer>
      )
    })
  }

  const renderWithoutGroups = () => {
    return (
      <GroupContainer>
        {questionsMap.order.map(qId => {
          const theQ = questionsMap.questions[qId]
          return (
            <QuestionComponent
              questionsMap={questionsMap}
              key={theQ.id}
              question={theQ}
              values={values}
              handleEditQuestion={handleEditQuestion}
            />
          )
        })}
      </GroupContainer>
    )
  }

  const render = () => {
    if (isCasus) return renderGroups()
    return renderWithoutGroups()
  }

  return (
    <Container>
      <TopContent>
        <Icon
          iconName='arrow-left-long'
          onClick={handleClose}
          style={{ cursor: 'pointer' }}
        />
        <Title>{t("question_overview")}</Title>
      </TopContent>
      <QuestionsContainer>{render()}</QuestionsContainer>
    </Container>
  )
}

export default TableOfContent
