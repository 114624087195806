import { v4 as uuid } from 'uuid'
import { DateTime } from 'luxon'

import {
  CASUS_KEYSTRINGS,
  DOCUMENT_GENERATION_MODE,
  SUB_QUESTION_TO_MATCH,
  ANSWER_VALUE_MATCH,
  FIND_WITH_INDEX_NOT_FOUND,
  ANSWERING_IDS_MATCH,
  Answer,
  Answers,
  Questions,
  QuestionLayout,
  Options,
  OptionGroupSelectUnionType,
  ValuesOf,
  SegmentsLocation,
  TextLocation,
  Writable,
  Option,
  OptionValueTypeUnionType,
  extractPropertiesFromCustomText,
  OptionValueProperties,
} from '___types'
import { WizardState, extractFromNestedStructure, findWithIndex } from '.'
import { updateWizardState } from './general'
import { getQuestionById, getMergedLocations, FoundMarkerType, getMarkerById, getSubQuestionsByQuestionId } from './template-automation'
import { applyParagraphNumbering } from './editor-content'

// ====================================================================================================================================== //
// ============================================================= GENERATORS ============================================================= //
// ====================================================================================================================================== //
const generateRegularGroupIdOrderNumber = (id: string, orderNumber: number, index: number): string =>
  `${id}:${orderNumber.toLocaleString(undefined, { minimumIntegerDigits: 2, useGrouping: false })}-${(index + 10).toString(36)}`
const generateLooseGroupIdOrderNumber = (id: string, orderNumber: number): string =>
  `${id}:${orderNumber.toLocaleString(undefined, { minimumIntegerDigits: 2, useGrouping: false })}`
const genericAnswer = { id: '', values: [] } as Answer
const generateAnswer = (answer: Answer): Answer => Object.assign({}, genericAnswer, { id: uuid(), values: [] }, answer)
// ====================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ======================================================== INSERT SUB QUESTION ======================================================== //
// ===================================================================================================================================== //
const insertSubQuestion = (questionOrder: QuestionLayout, subQuestionId: string, questions: Questions, answers: Answers | null): QuestionLayout => {
  const subQuestion = questions.find(({ id }) => id === subQuestionId)
  if (!subQuestion) return questionOrder
  console.log(subQuestion.text)
  const { questionId, optionId } = subQuestion?.advanced?.subQuestionTo.match(SUB_QUESTION_TO_MATCH)?.groups || {}
  if (!(questionId && optionId)) return questionOrder
  const answerValues = answers?.find(({ id }) => id === questionId)?.values || []
  const answeredWithRelevantOption = Boolean(answerValues.find(value => value.match(ANSWER_VALUE_MATCH)?.groups?.id === optionId))
  if (!answeredWithRelevantOption) return questionOrder
  return questionOrder.reduce((result, group) => {
    const resultingGroup = Object.assign({}, group, { questions: group.questions.slice() })
    result.push(resultingGroup)
    const index = findWithIndex(resultingGroup.questions, id => id === questionId)[1]
    if (index === -1) return result
    return resultingGroup.questions.splice(index + 1, 0, subQuestionId) && result
  }, [] as QuestionLayout)
}
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ====================================================== MAP IDS TO GROUP NUMBER ====================================================== //
// ===================================================================================================================================== //
const mapIdsToRegularGroupNumber = (groupId: string, questions: string[], counter: number): string[] =>
  questions.map((questionId, i) => `${groupId}:${generateRegularGroupIdOrderNumber(questionId, counter, i)}`)
const mapIdsToLooseGroupNumber = (groupId: string, questions: string[], counter: number): string[] =>
  questions.map((questionId, i) => `${groupId}:${generateLooseGroupIdOrderNumber(questionId, counter + i)}`)
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ====================================================== GENERATE QUESTION ORDER ====================================================== //
// ===================================================================================================================================== //
const generateQuestionOrder = (questionLayout: QuestionLayout, questions: Questions, answers: Answers | null): string[] => {
  const subQuestionIds = (questionLayout.find(({ type }) => type === 'sub-questions')?.questions.slice() || ([] as string[])).reverse()
  console.log(subQuestionIds)
  const baseLayout = questionLayout.filter(({ type }) => type !== 'sub-questions')
  const layoutWithSubQuestions = subQuestionIds.reduce((result, id) => insertSubQuestion(result, id, questions, answers), baseLayout)
  const [questionOrder] = layoutWithSubQuestions.reduce(
    ([result, counter], { id, type, questions }) => {
      if (type === 'sub-questions') return [result, counter]
      if (type === 'loose') return [result.concat(mapIdsToLooseGroupNumber(id, questions, counter)), counter + questions.length]
      return [result.concat(mapIdsToRegularGroupNumber(id, questions, counter)), counter + 1]
    },
    [[] as string[], 1]
  ) as [string[], number]
  return questionOrder
}
// ===================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ==================================================== GET UNANSWERED QUESTION INFO ==================================================== //
// ====================================================================================================================================== //
export const getUnansweredQuestionInfo = (answers: Answers, questionOrder: string[]): [string | undefined, number] => {
  const answeredQuestionIds = (answers || []).reduce((result, { id, values }) => (values.length ? result.concat(id) : result), [] as string[])
  return (questionOrder || []).reduce(
    ([result, count], questionOrderId) => {
      const answered = answeredQuestionIds.includes(questionOrderId.match(ANSWERING_IDS_MATCH)?.groups?.questionId as string)
      if (answered) return [result, count]
      return [result || questionOrderId, count + 1] as [string, number]
    },
    [undefined, 0] as [string | undefined, number]
  )
}
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ================================================= QUESTIONNAIRE NAVIGATION CONSTANTS ================================================= //
// ====================================================================================================================================== //
export const QUESTIONNAIRE_PRE_STEPS = { PRE_STEP: 'pre-answering' } as const
export const QUESTIONNAIRE_POST_STEPS = {
  SKIPPED: 'skipped-question-review',
  CONFIRM: 'confirmation',
  RENAME: 'document-rename',
  POST_STEP: 'post-generation',
} as const
const QUESTIONNAIRE_NAVIGATION_DIRECTIONS = { FORWARDS: 'forwards', BACKWARDS: 'backwards' } as const
type QuestionnaireNavigationDirection = ValuesOf<typeof QUESTIONNAIRE_NAVIGATION_DIRECTIONS>
type QuestionnaireNavigationConditionalMethodsType = {
  [key: string]: {
    conditional: (state: WizardState, direction: QuestionnaireNavigationDirection, previous: string) => boolean
    method: (state: WizardState, direction: QuestionnaireNavigationDirection) => WizardState
  }
}
const QUESTIONNAIRE_NAVIGATION_CONDITIONAL_METHODS = {
  skipQuestionsSkippedSection: {
    conditional: (state: WizardState): boolean =>
      state.answering === QUESTIONNAIRE_POST_STEPS.SKIPPED &&
      Boolean(state.answers?.length && state.questionOrder?.length) &&
      getUnansweredQuestionInfo(state.answers!, state.questionOrder!)[1] === 0,
    method: (state: WizardState, direction: QuestionnaireNavigationDirection): WizardState => {
      if (direction === QUESTIONNAIRE_NAVIGATION_DIRECTIONS.FORWARDS) return updateWizardState(state, { answering: QUESTIONNAIRE_POST_STEPS.CONFIRM })
      const lastQuestionInOrder = state.questionOrder![state.questionOrder!.length - 1]
      return updateWizardState(state, { answering: lastQuestionInOrder })
    },
  },
} as QuestionnaireNavigationConditionalMethodsType
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ============================================================= GET ANSWER ============================================================= //
// ====================================================================================================================================== //
const getAnswer = (state: WizardState, test: (answer: Answer) => boolean): [Answer, number] | Writable<typeof FIND_WITH_INDEX_NOT_FOUND> =>
  findWithIndex(state.answers || [], test)
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ========================================================== GET ANSWER BY ID ========================================================== //
// ====================================================================================================================================== //
const getAnswerById = (state: WizardState, id: string): [Answer, number] | Writable<typeof FIND_WITH_INDEX_NOT_FOUND> =>
  id ? getAnswer(state, answer => answer.id === id) : (FIND_WITH_INDEX_NOT_FOUND.slice() as Writable<typeof FIND_WITH_INDEX_NOT_FOUND>)
// ====================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ============================================================ TIDY ANSWER ============================================================ //
// ===================================================================================================================================== //
type TemporaryOptionGroupType = { options: Options; select: OptionGroupSelectUnionType; maximum: number; enforceLimit: boolean }
const tidyAnswer = (state: WizardState, answer: Answer): Answer => {
  const { optionGroups = [] } = getQuestionById(state, answer.id)[0] || {}
  // remove type conversion after the below is fixed/implemented
  const optionGroupsWithLimits = (optionGroups as unknown as TemporaryOptionGroupType[]).map(({ options, select, maximum, enforceLimit }) => {
    // remove the line above and uncomment the next one after fixing the optionGroup select (string => Object({ select: string, minimum: number, maximum: number, enforceLimit: boolean }))
    // const optionGroupsWithLimits = optionGroups.map(({ options, select: { select, maximum, enforceLimit } }) => ({
    const selectMaximum = (select as never as OptionGroupSelectUnionType) === 'single' ? 1 : maximum // remove type conversion after the above is fixed/implemented
    return { optionIds: options.map(option => option.id), count: (enforceLimit && selectMaximum) || Infinity }
  })
  const reversedValues = answer!.values.slice().reverse()
  const [resultingValues] = reversedValues.reduce(
    ([result, groups], answerValue) => {
      const id = answerValue.match(ANSWER_VALUE_MATCH)?.groups?.id as string
      const relevantGroup = groups.find(group => group.optionIds.includes(id))
      if (relevantGroup?.count) Object.assign(relevantGroup, { count: relevantGroup.count - 1 }) && result.push(answerValue)
      return [result, groups] as [string[], typeof optionGroupsWithLimits]
    },
    [[], optionGroupsWithLimits] as [string[], typeof optionGroupsWithLimits]
  )
  const valueLengthDiffers = answer!.values.length !== resultingValues.length
  if (!valueLengthDiffers) return answer
  return { id: answer.id, values: resultingValues.reverse() }
}
// ===================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ===================================================== GET FORMATTED ANSWER VALUE ===================================================== //
// ====================================================================================================================================== //
type FormatOptions = { dateFormat?: string; dateTimeFormat?: string }
export const getFormattedAnswerValue = (valueType: OptionValueTypeUnionType, value: string = '', options?: FormatOptions): string | number => {
  switch (valueType) {
    case 'date': {
      const luxonFromISO = DateTime.fromISO(value)
      if (!luxonFromISO?.isValid) return value
      if (options?.dateFormat) return luxonFromISO?.toFormat(options.dateFormat)
      return !luxonFromISO?.isValid ? value : luxonFromISO?.toFormat('dd.MM.yyyy')
    }
    case 'date-time': {
      const luxonFromISO = DateTime.fromISO(value)
      if (!luxonFromISO?.isValid) return value
      if (options?.dateFormat) return luxonFromISO?.toFormat(options.dateFormat)
      return !luxonFromISO?.isValid ? value : luxonFromISO?.toFormat('dd.MM.yyyy HH:mm')
    }
    case 'number':
      return Number(value)
    default:
      return value || ''
  }
}
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ======================================================= GET VALUES FROM ANSWER ======================================================= //
// ====================================================================================================================================== //
const getOptionPropertiesFromAnswer = (answer: Answer): Record<string, OptionValueProperties> =>
  answer.values.reduce((result, valueString) => {
    const { id, value } = valueString.match(ANSWER_VALUE_MATCH)?.groups || {}
    const properties = extractPropertiesFromCustomText(value, 'optionValue')
    return Object.assign(result, { [id]: properties })
  }, {})
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ================================================= GET OPTION VALUE FROM ANSWER BY ID ================================================= //
// ====================================================================================================================================== //
export const getOptionPropertiesFromAnswerById = (answer: Answer, id: string): OptionValueProperties => getOptionPropertiesFromAnswer(answer)[id]
// ====================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ======================================================= EVALUATE KNOWN MARKER ======================================================= //
// ===================================================================================================================================== //
const evaluateKnownMarker = <T extends SegmentsLocation | TextLocation>(
  state: WizardState,
  marker: T,
  markerArray: T[],
  index: number,
  evaluateNumbering?: boolean,
  update?: never
): WizardState => {
  const allValues = state.answers?.reduce((result, { values }) => result.concat(values), [] as string[]) || []
  const keepValues = allValues.filter(string => marker.optionIds.includes(string.match(ANSWER_VALUE_MATCH)?.groups?.id!))
  const keep = marker.defaultKeep ? !marker.optionIds.length || Boolean(keepValues.length) : keepValues.length === marker.optionIds.length
  const answer = getAnswerById(state, marker.questionId)[0]

  const replace =
    answer &&
    Object.values(getOptionPropertiesFromAnswer(answer))
      .map(
        properties =>
          `{{${CASUS_KEYSTRINGS.REPLACE} ${Object.entries(properties)
            .reduce((propertyString, [key, value]) => propertyString.concat(`${key}="${value}"`), [] as string[])
            .join(' ')}}}`
      )
      .join('')

  const resultingMarker = Object.assign({}, marker)
  update = ((keep !== marker.keep && Object.assign(resultingMarker, { keep })) as never) || update
  update = ((replace !== marker.replace && Object.assign(resultingMarker, { replace })) as never) || update
  if (update) {
    markerArray.splice(index, 1, resultingMarker)
    if (evaluateNumbering) applyParagraphNumbering(state) // FOR TESTING
    // if (marker.type === LOCATION_TYPES.SEGMENTS && evaluateNumbering) applyParagraphNumbering(state)
    return Object.assign({}, state)
  }
  return state
}
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ======================================================= EVALUATE MARKER BY ID ======================================================= //
// ===================================================================================================================================== //
const evaluateMarkerById = (state: WizardState, markerId: string, evaluateNumbering?: boolean, update?: never): WizardState => {
  const markerById = getMarkerById(state, markerId)
  return markerById[0] ? evaluateKnownMarker(state, markerById[0], markerById[2], markerById[3], evaluateNumbering, update) : state
}
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ========================================================== EVALUATE MARKER ========================================================== //
// ===================================================================================================================================== //
// ===================================================== EVALUATE MARKER: OVERLOAD ===================================================== //
function evaluateMarker(state: WizardState, marker: FoundMarkerType, evaluateNumbering?: boolean, update?: never): WizardState
function evaluateMarker(state: WizardState, markerId: string, evaluateNumbering?: boolean, update?: never): WizardState
// ===================================================================================================================================== //
function evaluateMarker(state: WizardState, arg2: FoundMarkerType | string, evaluateNumbering: boolean = true, update = false as never): WizardState {
  if (typeof arg2 === 'string') return evaluateMarkerById(state, arg2, evaluateNumbering)
  if (Array.isArray(arg2) && arg2[0]) return evaluateKnownMarker(state, arg2[0], arg2[2], arg2[3], evaluateNumbering, update)
  return state
}
// ===================================================================================================================================== //
//
//
//
//
//
//
//
//
//
//
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ========================================================= REDUCER FUNCTIONS ========================================================= //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //

const updateQuestionOrder = (state: WizardState) => {
  const { questions, questionLayout, answers } = state
  if (!(questions && questionLayout)) return state
  const questionOrder = generateQuestionOrder(questionLayout, questions, answers)
  if (questionOrder.join('') === state.questionOrder?.join('')) return state
  const payload = { questionOrder }
  // if (state.mode === DOCUMENT_GENERATION_MODE && !state.answering) Object.assign(payload, { answering: QUESTIONNAIRE_PRE_STEPS.PRE_STEP }) // FOR TESTING
  // if (state.mode === DOCUMENT_GENERATION_MODE && !state.answering) Object.assign(payload, { answering: QUESTIONNAIRE_POST_STEPS.SKIPPED }) // FOR TESTING
  // if (state.mode === DOCUMENT_GENERATION_MODE && !state.answering) Object.assign(payload, { answering: questionOrder[7] }) // FOR TESTING
  if (state.mode === DOCUMENT_GENERATION_MODE && !state.answering) Object.assign(payload, { answering: questionOrder[0] })
  return updateWizardState(state, payload)
}

const navigateQuestionnaireForward = (state: WizardState): WizardState => {
  const { questionOrder, answering } = state
  if (!questionOrder?.length) return state
  if (!answering) return updateWizardState(state, { answering: questionOrder[0] })
  const fullOrder = questionOrder.concat(Object.values(QUESTIONNAIRE_POST_STEPS))
  fullOrder.unshift(...Object.values(QUESTIONNAIRE_PRE_STEPS).slice().reverse())
  const resultingAnswering = fullOrder[fullOrder.indexOf(answering) + 1]
  return Object.values(QUESTIONNAIRE_NAVIGATION_CONDITIONAL_METHODS).reduce(
    (result, { conditional, method }) =>
      conditional(result, QUESTIONNAIRE_NAVIGATION_DIRECTIONS.FORWARDS, answering)
        ? method(result, QUESTIONNAIRE_NAVIGATION_DIRECTIONS.FORWARDS)
        : result,
    updateWizardState(state, { answering: resultingAnswering || questionOrder[0] }) as WizardState
  )
}

const navigateQuestionnaireBackward = (state: WizardState): WizardState => {
  const { questionOrder, answering } = state
  if (!questionOrder?.length) return state
  if (!answering) return updateWizardState(state, { answering: questionOrder[0] })
  const fullOrder = questionOrder.concat(Object.values(QUESTIONNAIRE_POST_STEPS))
  fullOrder.unshift(...Object.values(QUESTIONNAIRE_PRE_STEPS).slice().reverse())
  const resultingAnswering = fullOrder[fullOrder.indexOf(answering) - 1]
  return Object.values(QUESTIONNAIRE_NAVIGATION_CONDITIONAL_METHODS).reduce(
    (result, { conditional, method }) =>
      conditional(result, QUESTIONNAIRE_NAVIGATION_DIRECTIONS.BACKWARDS, answering)
        ? method(result, QUESTIONNAIRE_NAVIGATION_DIRECTIONS.BACKWARDS)
        : result,
    updateWizardState(state, { answering: resultingAnswering || questionOrder[0] }) as WizardState
  )
}

export type NavigateQuestionnaireToPayload = string
const navigateQuestionnaireTo = (state: WizardState, payload: NavigateQuestionnaireToPayload): WizardState => {
  const { questionLayoutGroupId, questionId, orderString } = payload.match(ANSWERING_IDS_MATCH)?.groups || {}
  if (!questionId || state.answering === payload) return state
  if (questionLayoutGroupId && orderString) return updateWizardState(state, { answering: payload })
  const answeringString = state.questionOrder?.find(orderString => orderString.split(':')[1] === questionId)
  return updateWizardState(state, { answering: answeringString })
}

export type AnswerWithOptionPayload = { questionId: string; value: string }
const answerWithOption = (state: WizardState, payload: AnswerWithOptionPayload): WizardState => {
  const { questionId, value } = payload
  const [answer, index] = getAnswerById(state, questionId)
  const { id: optionId, value: optionValue } = value?.match(ANSWER_VALUE_MATCH)?.groups || {}
  if (!optionId) return state
  const [values, shift, update] = (answer?.values.slice().reverse() || []).reduce(
    ([result, optionFound, valuesDiffer], existingAnswerValue) => {
      const { id: existingAnswerOptionId, value: existingAnswerOptionValue } = existingAnswerValue.match(ANSWER_VALUE_MATCH)?.groups || {}
      const sameOptionId = existingAnswerOptionId === optionId
      const sameOptionValue = existingAnswerOptionValue === optionValue
      if (optionFound || !sameOptionId || sameOptionValue) result.push(existingAnswerValue)
      else result.push(value)
      return [result, optionFound || sameOptionId, valuesDiffer || !sameOptionValue]
    },
    [[value], false, false]
  )
  if (shift) {
    // ================================= if the option was already in the answer (previously selected):       shift   === true
    if (!update) return state // ======= and the value of the already-in option is the same in the payload:   update  === false    => just don't update => return state
    values.shift() // ================== otherwise shift the prepended value
  }
  const resultingValues = values.reverse()
  const resultingAnswer = tidyAnswer(state, generateAnswer(Object.assign({ id: questionId, values: resultingValues })))
  const previouslySelectedOptionIds = answer?.values.map(value => value?.match(ANSWER_VALUE_MATCH)?.groups?.id).filter(id => id)
  const resultingSelectedOptionIds = resultingAnswer.values.map(value => value?.match(ANSWER_VALUE_MATCH)?.groups?.id).filter(id => id)
  const resultingOptionIdDifference = previouslySelectedOptionIds?.filter(id => !resultingSelectedOptionIds.includes(id))

  if (index === -1) updateWizardState(state, { answers: (state.answers || []).concat(resultingAnswer) })
  else state.answers!.splice(index, 1, resultingAnswer)

  const [question] = getQuestionById(state, payload?.questionId)
  const relevantOptionIds = (resultingOptionIdDifference || []).concat(optionId)
  const relevantMarkerIds = Array.from(
    new Set(
      question?.optionGroups
        .reduce((resultingOptions, optionGroup) => resultingOptions.concat(optionGroup.options), [] as Options)
        .reduce(
          (resultingMarkerIds, option) => resultingMarkerIds.concat(relevantOptionIds.includes(option.id) ? option.markers : []),
          question?.markers || []
        )
    )
  )

  relevantMarkerIds.reduce((_, markerId) => evaluateMarker(state, markerId), state)
  const subQuestions = getSubQuestionsByQuestionId(state, questionId)
  if (subQuestions.length) updateQuestionOrder(state)
  return Object.assign({}, state)
}

export type UnanswerOptionPayload = { id: string; questionId: string }
const unanswerOption = (state: WizardState, payload: UnanswerOptionPayload): WizardState => {
  const { id, questionId } = payload
  const [answer, index] = getAnswerById(state, questionId)
  if (index === -1) return state
  const resultingValues = answer!.values.filter(answerValue => answerValue.match(ANSWER_VALUE_MATCH)?.groups?.id !== id)
  if (answer!.values.length === resultingValues.length) return state
  state.answers!.splice(index, 1, { id: questionId, values: resultingValues })
  const [question] = getQuestionById(state, payload?.questionId)
  const option = (question
    ? extractFromNestedStructure(question, ['optionGroups', 'options'], option => option.id === id)
    : [])[0] as unknown as Option
  const relevantMarkers = (question?.markers || []).concat(option?.markers || [])
  relevantMarkers.reduce((_, markerId) => evaluateMarker(state, markerId), state)
  const subQuestions = getSubQuestionsByQuestionId(state, questionId)
  if (subQuestions.length) updateQuestionOrder(state)
  return Object.assign({}, state)
}

const evaluateMarkers = (state: WizardState, evaluateNumbering: boolean = true): WizardState => {
  if (state.mode !== DOCUMENT_GENERATION_MODE) return state
  const mergedLocations = getMergedLocations(state)
  const markerIds = Object.values(mergedLocations).reduce((idArray, markerArray) => idArray.concat(markerArray.map(({ id }) => id)), [] as string[])
  return markerIds.reduce((currentState, id) => evaluateMarker(currentState, id, evaluateNumbering), state)
}

export {
  updateQuestionOrder,
  navigateQuestionnaireForward,
  navigateQuestionnaireBackward,
  navigateQuestionnaireTo,
  answerWithOption,
  unanswerOption,
  evaluateMarkers,
}
