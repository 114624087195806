import { ReadonlyRecord } from '.'
import { Time, Author } from './types.general'

// ===========================================================================================  //
// ======================================== CONSTANTS ========================================  //
// ===========================================================================================  //
export const DOCUMENTS_FILTERS = {
  MINE: 'mine',
  COMPANY: 'company',
  SHARED: 'shared',
  PUBLIC: 'public',
  TRASHED: 'trashed',
} as const
type DocumentsFilters = typeof DOCUMENTS_FILTERS
type DocumentsFiltersKeys = keyof DocumentsFilters
export type DocumentsFilter = DocumentsFilters[DocumentsFiltersKeys]

const DOCUMENT_FILTER_MAP = {
  [DOCUMENTS_FILTERS.MINE]: 'my',
  [DOCUMENTS_FILTERS.TRASHED]: 'bin',
} as const
type DocumentsFilterMap = typeof DOCUMENT_FILTER_MAP
type DocumentsFilterMapKeys = keyof DocumentsFilterMap
type DocumentsFilterMapValues = DocumentsFilterMap[DocumentsFilterMapKeys]
export type MappedDocumentsFilter = DocumentsFilterMapValues | Exclude<DocumentsFilter, DocumentsFilterMapKeys>

export const mapDocumentsFilter = (documentFilter: DocumentsFilter): MappedDocumentsFilter =>
  DOCUMENT_FILTER_MAP[documentFilter as DocumentsFilterMapKeys] || documentFilter
export const MAPPED_DOCUMENTS_FILTERS = (Object.entries(DOCUMENTS_FILTERS) as [DocumentsFiltersKeys, DocumentsFilter][]).reduce(
  (acc, [key, value]) => Object.assign(acc, { [key]: mapDocumentsFilter(value) }),
  {}
) as ReadonlyRecord<DocumentsFiltersKeys, MappedDocumentsFilter>
// ===========================================================================================  //

export type Answer = { id: string; values: string[] }
export type Answers = Answer[]
export interface IDocument {
  id: string
  status: string
  name: string
  templateId: string
  templateName: string
  templateContentVersionId: string
  contentVersionId: string

  author: Author
  created: Time
  edited: Time
  answers: Answers
}
