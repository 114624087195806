import React, { FunctionComponent } from 'react'
import useStore, { SetWizardTemplateIdAction } from '___store'

import { WizardLayoutLeftPaneTemplateSelectionProps, wizardLayoutLeftPaneTemplateSelectionClasses as classes } from '../..'
import {
  MyTemplatesGrid,
  SharedTemplatesGrid,
  // CompanyTemplatesGrid
} from 'components/CasusComponents'

type UseStoreHookResultType = { setWizardTemplateId: SetWizardTemplateIdAction }

export const TemplateSelection: FunctionComponent<WizardLayoutLeftPaneTemplateSelectionProps> = React.memo(() => {
  const { setWizardTemplateId } = useStore('setWizardTemplateId') as UseStoreHookResultType
  return (
    <div className={classes.wrapper}>
      <MyTemplatesGrid onClick={(_, templateId) => setWizardTemplateId(templateId)} />
      <SharedTemplatesGrid onClick={(_, templateId) => setWizardTemplateId(templateId)} />
      {/* <CompanyTemplatesGrid onClick={(_, templateId) => setWizardTemplateId(templateId)} /> */}
    </div>
  )
})
TemplateSelection.displayName = 'WizardLayout-TemplateSelection'

export default TemplateSelection
