import UserApi from 'api/user.api'
import { AxiosError } from 'axios'
import { queryKeys } from 'queries/queryKeys'
import { useQuery } from 'react-query'
import { useLogout } from 'queries/auth/useLogout'
// import { useHistory } from 'react-router-dom'

export const useLoadUser = () => {
  const token = localStorage.getItem('casusToken')
  const logout = useLogout()
  // const history = useHistory()
  const query = useQuery(queryKeys.USER, UserApi.getMe, {
    retry: 1,
    enabled: !!token,
    onSuccess: data => {
      if (data) {
        if (data.beta.newDocxMicroservice) {
          if (window.location.hostname !== process.env.REACT_APP_DOMAIN_V2) {
            // show new version available screen
            // history.push('newversion')
          }
        } else {
          if (window.location.hostname !== process.env.REACT_APP_DOMAIN_V1) {
            // redirect to v1 domain
            window.location.assign(`https://${process.env.REACT_APP_DOMAIN_V1}/`)
          }
        }
      }
    },
    onError: (err: AxiosError) => {
      const res = err?.response
      if (res?.status === 401 && ['E0007', 'E0017'].includes(res?.data?.error_code)) {
        logout()
      }
    },
    refetchOnWindowFocus: false,
  })
  return query
}
