import React from 'react'

import { className } from './index'

const Cross = React.memo(() => (
  <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
    <line x1="30" y1="30" x2="70" y2="70" stroke="currentColor" strokeWidth="10" strokeLinecap="round" />
    <line x1="30" y1="70" x2="70" y2="30" stroke="currentColor" strokeWidth="10" strokeLinecap="round" />
  </svg>
))

export { Cross }
export default Cross
