import React, { FunctionComponent, useMemo } from 'react'

import { ITemplate, mapTemplatesFilter } from '___types'
import { useTemplateList } from '___hooks'
import { TemplatesGridProps, classes } from '.'
import Grid from 'components/CasusComponents/Grid'
import { TemplateThumbnail } from 'components/CasusComponents/Template'

export const TemplatesGrid: FunctionComponent<TemplatesGridProps> = React.memo(({ filter, onClick }) => {
  const { isLoading, isFetching, error, data: { pages = [] } = {} } = useTemplateList(filter)

  const templates = useMemo(() => {
    const templateEntries = pages.reduce((acc: ITemplate[], page = []) => acc.concat(page), [])
    return templateEntries.map(template => {
      return <TemplateThumbnail key={`Content-Documents-TemplateThumbnail-${template.id}`} id={template.id} onClick={onClick} />
    })
  }, [pages, onClick])

  const title = useMemo(() => {
    const mappedFilter = mapTemplatesFilter(filter)
    return `${mappedFilter.charAt(0).toUpperCase()}${mappedFilter.slice(1)} Templates`
  }, [filter])

  const errorMessage = useMemo(() => {
    if (typeof error === 'string') return error
    if (error instanceof Error) return error.message
    return ''
  }, [error])

  return (
    <Grid className={classes.wrapper} isLoading={isLoading} isFetching={isFetching} error={errorMessage} title={title}>
      {templates}
    </Grid>
  )
})

TemplatesGrid.displayName = 'Casus-Components-Grid-Templates'

export default TemplatesGrid
