import React, { useMemo } from 'react'
import useStore from '___store'

import { CASUS_CLASSES } from 'Wizard/constants'
import { classes } from '../index'
import { Page } from './index'

const Section = React.memo(({ index }) => {
  const { sectionInfoByIndex: { id, title, pages = [], layout: { paper, orientation, margins: { top, bottom, left, right } = {} } = {} } = {} } =
    useStore(`selectSectionInfoByIndex[${index}]`)

  const render = useMemo(
    () => new Array(pages.length).fill(null).map((_, i) => <Page key={`Section-${index}-Page-${i}`} section={index} index={i} />),
    [pages, index]
  )

  // console.log(`SECTION %c${id}`, 'color: lightgreen;', 'RENDER!')

  const layoutClass = `${paper}-${orientation}-${top}-${bottom}-${left}-${right}`.replaceAll('.', '_')
  const className = [layoutClass, CASUS_CLASSES.section, classes.section.wrapper].join(' ')

  return (
    <>
      <div id={`section-${id}`} className={className}>
        <span>{title}</span>
        {render}
      </div>
      <div className={classes.section.separator} />
    </>
  )
})

Section.displayName = 'Wizard-Editor-Section'

export default Section
