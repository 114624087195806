import ACTION_TYPES, { Action } from '___store/actionTypes'
import initialAppState, {
  setDocumentsFilter,
  setSelectedDocumentId,
  deselectAllDocuments,
  setTemplatesFilter,
  setSelectedTemplateId,
  deselectAllTemplates,
  SetDocumentsFilterPayload,
  SetSelectedDocumentIdPayload,
  SetTemplatesFilterPayload,
  SetSelectedTemplateIdPayload,
} from './helpers'

const reducer = (state = initialAppState, action: Action) => {
  const { type, payload } = action
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////// DOCUMENTS ///////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  if (type === ACTION_TYPES.APP.DOCUMENTS_FILTER_SET) return setDocumentsFilter(state, payload as SetDocumentsFilterPayload)
  if (type === ACTION_TYPES.APP.DOCUMENT_SELECTED) return setSelectedDocumentId(state, payload as SetSelectedDocumentIdPayload)
  if (type === ACTION_TYPES.APP.DOCUMENTS_DESELECTED) return deselectAllDocuments(state)
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //

  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////// TEMPLATES ///////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  if (type === ACTION_TYPES.APP.TEMPLATES_FILTER_SET) return setTemplatesFilter(state, payload as SetTemplatesFilterPayload)
  if (type === ACTION_TYPES.APP.TEMPLATE_SELECTED) return setSelectedTemplateId(state, payload as SetSelectedTemplateIdPayload)
  if (type === ACTION_TYPES.APP.TEMPLATES_DESELECTED) return deselectAllTemplates(state)
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  return state
}

export { reducer }
export default reducer
