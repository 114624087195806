import React, { FunctionComponent, useMemo } from 'react'

import { useTextMarker } from '../customHooks'
import { mapContentToSpan } from '../helpers'
import { WizardLayoutRightPaneEditorMarkerTextProps, wizardLayoutRightPaneEditorMarkerTextClasses as classes } from '../../../..'

export const Text: FunctionComponent<WizardLayoutRightPaneEditorMarkerTextProps> = React.memo(({ id, range, textChunks }) => {
  const { content, keep, markerNavigate } = useTextMarker(id, range, textChunks)

  const render = useMemo(() => content.map((chunk, i) => mapContentToSpan(chunk, id, i)), [content, id])
  return (
    <mark
      id={id}
      className={classes.wrapper}
      data-discard={!keep || undefined}
      data-clickable={typeof markerNavigate === 'function' ? true : undefined}
      onClick={typeof markerNavigate === 'function' ? () => markerNavigate() : undefined}
    >
      {keep === false ? null : render}
    </mark>
  )
})

Text.displayName = 'WizardLayout-RightPane-Editor-Marker-Text'

export default Text
