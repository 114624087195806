import React, { FormEvent, PropsWithChildren, FunctionComponent, useContext } from 'react'
import { DateTime } from 'luxon'

import { OPTION_VALUE_TYPES, OptionLimits, OptionValueTypeUnionType, Question as QuestionType } from '___types'
import PreAnswering from './LeftPane.Questionnaire.PreAnswering'
import QuestionGroup from './LeftPane.Questionnaire.QuestionGroup'
import Question from './LeftPane.Questionnaire.Question'
import OptionGroup from './LeftPane.Questionnaire.OptionGroup'
import Option from './LeftPane.Questionnaire.Option'
import SkippedQuestionReview from './LeftPane.Questionnaire.SkippedQuestionReview'
import Confirmation from './LeftPane.Questionnaire.Confirmation'
import DocumentRename from './LeftPane.Questionnaire.DocumentRename'
import PostGeneration from './LeftPane.Questionnaire.PostGeneration'

import './style.scss'

export const applyLimitsToValue = (valueType: OptionValueTypeUnionType, value: number | string, limits: OptionLimits): number | string => {
  if (valueType === OPTION_VALUE_TYPES.NUMBER) {
    const { min = -Infinity, max = +Infinity, step = 0 } = limits
    const clampedValue = Math.min(Math.max(Number(value), Number(min)), Number(max))
    const remainder = step ? clampedValue % Number(step) : 0
    return clampedValue - remainder // Implement other valueType limits
  }
  return value
}

export const getCurrentInputValue = (valueType: OptionValueTypeUnionType, input: HTMLPreElement | HTMLInputElement) => {
  if (!input) return ''
  switch (valueType) {
    case OPTION_VALUE_TYPES.STRING:
      return (input as HTMLPreElement).innerText
    case OPTION_VALUE_TYPES.DATE:
      return DateTime.fromFormat((input as HTMLInputElement).value, 'yyyy-MM-dd')?.toISO() || ''
    case OPTION_VALUE_TYPES.DATE_TIME:
      return DateTime.fromFormat((input as HTMLInputElement).value, "yyyy-MM-dd'T'HH:mm")?.toISO() || ''
    case OPTION_VALUE_TYPES.NUMBER:
      return Number((input as HTMLInputElement).value) || ''
    default:
      return (input as HTMLInputElement).value
  }
}

const valueTypeExtractMethodMap = {
  [OPTION_VALUE_TYPES.STRING]: (event: FormEvent<HTMLPreElement | HTMLInputElement>) => (event.target as HTMLPreElement).innerHTML,
  [OPTION_VALUE_TYPES.DATE]: (event: FormEvent<HTMLPreElement | HTMLInputElement>) =>
    DateTime.fromFormat((event.target as HTMLInputElement).value, 'yyyy-MM-dd')?.toISO(),
  [OPTION_VALUE_TYPES.DATE_TIME]: (event: FormEvent<HTMLPreElement | HTMLInputElement>) =>
    DateTime.fromFormat((event.target as HTMLInputElement).value, "yyyy-MM-dd'T'HH:mm")?.toISO(),
  [OPTION_VALUE_TYPES.NUMBER]: (event: FormEvent<HTMLPreElement | HTMLInputElement>) => Number((event.target as HTMLInputElement).value),
  [OPTION_VALUE_TYPES.CURRENCY]: (event: FormEvent<HTMLPreElement | HTMLInputElement>) => (event.target as HTMLInputElement).value,
}

export const extractValueFromInputEvent = (valueType: OptionValueTypeUnionType, event: FormEvent<HTMLPreElement | HTMLInputElement>) =>
  valueTypeExtractMethodMap[valueType](event) || (event.target as HTMLInputElement).value

export const QuestionContext = React.createContext<QuestionType>({} as QuestionType)

type QuestionContextProviderProps = PropsWithChildren<{ question: QuestionType }>
export const QuestionContextProvider: FunctionComponent<QuestionContextProviderProps> = ({ question, children }) => {
  return <QuestionContext.Provider value={question}>{children}</QuestionContext.Provider>
}

export const useQuestionContext = () => {
  const currentQuestionContext = useContext(QuestionContext)
  if (!currentQuestionContext) {
    throw new Error('useQuestionContext has to be used within <QuestionContext.Provider>')
  }
  return currentQuestionContext
}

export { PreAnswering, QuestionGroup, Question, OptionGroup, Option, SkippedQuestionReview, Confirmation, DocumentRename, PostGeneration }
