import React, { FunctionComponent, useMemo } from 'react'
import useStore, { WizardModeSelector } from '___store'

import { DOCUMENT_GENERATION_MODE, EDITOR_MODES, EditorMode, TEMPLATE_AUTOMATION_MODE, WizardMode } from '___types'
import { WizardLayoutRightPaneProps, wizardLayoutRightPaneClasses as classes } from '../..'
import Editor from './RightPane.Editor'

type UseStoreHookResultType = { wizardMode: WizardModeSelector }

export const RightPane: FunctionComponent<WizardLayoutRightPaneProps> = React.memo(({ isLoading, isFetching }) => {
  const { wizardMode: mode } = useStore('selectWizardMode', 'selectWizardTemplateId') as UseStoreHookResultType

  const step = useMemo(() => {
    if (mode === DOCUMENT_GENERATION_MODE || mode === TEMPLATE_AUTOMATION_MODE) {
      const editorMode = mode
        ? ((Object.entries(EDITOR_MODES).find(([_, wizardModes]) => (wizardModes as unknown as WizardMode[]).includes(mode)) || [])[0] as EditorMode)
        : undefined
      return <Editor isLoading={isLoading} isFetching={isFetching} mode={editorMode!} />
    }
  }, [mode, isLoading, isFetching])

  return <div className={classes.wrapper}>{step}</div>
})
RightPane.displayName = 'WizardLayout-RightPane'

export default RightPane
