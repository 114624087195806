import { QueryFunctionContext, useInfiniteQuery } from 'react-query'

import { MAPPED_TEMPLATES_FILTERS, MappedTemplatesFilter } from '___types'
import { templatesAPI, templateListPerPage } from '___api'
import { QUERY_KEYS } from '___queries'

const getTemplateListQueryFunction = ({ queryKey, pageParam }: QueryFunctionContext) =>
  templatesAPI.getTemplateList(queryKey[1] as MappedTemplatesFilter, pageParam as string)

export const useFetchTemplateList = (filter: MappedTemplatesFilter) => {
  const templateListQuery = useInfiniteQuery({
    queryKey: [QUERY_KEYS.TEMPLATES, filter],
    queryFn: getTemplateListQueryFunction,
    getNextPageParam: (lastPage = []) => {
      if (lastPage.length < templateListPerPage) return
      const lastTemplate = lastPage[lastPage.length - 1]
      return lastTemplate?.id
    },
    staleTime: filter === MAPPED_TEMPLATES_FILTERS.MINE ? Infinity : 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  })
  return templateListQuery
}

export default useFetchTemplateList
