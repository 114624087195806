import { useQuery } from 'react-query'
import { queryKeys } from 'queries/queryKeys'
import ExternalApi from 'api/external.api'

export const useFetchExternalServiceIdedObjects = service => {
  const query = useQuery([queryKeys.EXTERNAL_SERVICE_IDED_OBJECTS, service], () => ExternalApi.getExternalApiIdedObjects(service), {
    // enabled: false,
    refetchOnWindowFocus: false,
  })
  return query
}
