// @ts-nocheck
import React, { useState, useEffect, Fragment, useMemo, useCallback } from 'react'
import { ITemplate } from 'types'
import Author from './Author/Author'
import StandardModal from 'components/common/Modals/StandardModal/StandardModal'
import { Loader } from 'components/common'
import PurchaseTemplateModal from '../PurchaseTemplateModal/PurchaseTemplateModal'
import { useTranslation } from 'react-i18next'
import ExternalServiceSelect from './ExternalServiceSelect'
import { useDispatch } from 'react-redux'
import { setServiceChosenId } from 'redux/actions'
import { classes } from '.'
import { DOCXDocument, Premium } from 'assets/svgIconComponents'
import { useSubscriptions } from 'queries/subscriptions/useSubscriptions'
import subscriptionsApi from 'api/subscriptions.api'
import { useUser } from 'queries/user/useUser'
import { useToast } from 'hooks/useToast'
import { useFetchExternalServiceEntries } from 'queries/externalServices/useFetchExternalServiceEntries'
import { CASUS_KEYSTRINGS } from '___types'

interface Props {
  isOpen: boolean
  template?: ITemplate | null
  isLoading: boolean
  questionsLength?: number
  // actionBtnDisabled?: boolean
  editingDisabled?: boolean
  requirePayment?: boolean
  noQuestions?: boolean
  onClose: () => void
  onUseTemplate: (templateId: string, additionalData: { name: string; isCasus: boolean }) => void
  onEditTemplate?: (templateId: string) => void
  onUpdateCharacteristics?: (templateId: string, chars: string) => void
}

const TemplatePreviewModal: React.FC<Props> = ({
  isOpen,
  template,
  questionsLength,
  // actionBtnDisabled,
  editingDisabled,
  isLoading,
  requirePayment,
  // noQuestions,
  onUseTemplate,
  onEditTemplate,
  onClose,
  onUpdateCharacteristics,
}) => {
  const dispatch = useDispatch()
  const [externalsChosen, setExternalsChosen] = useState({}) as any
  const { locations } = (template as any) || {}

  const { data: subscriptions, isLoading: subscriptionsLoading, refetch } = useSubscriptions()
  const isPremium = template?.isPremium
  useEffect(() => {
    if (isPremium) refetch()
  }, [isPremium, refetch])

  const user = useUser()

  const isPaid = () => {
    if (user?.subs) {
      return user.subs.some(subscription => subscription.status === 'active')
    }
    return false
  }

  const toast = useToast()
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    const paid = params.get('paid')
    if (paid) {
      toast('success', 'Payment successful')
    }
  }, [toast])

  const [selectedSubscription, setSelectedSubscription] = useState()

  const externalServiceFields = (
    Object.values(Object.assign({}, locations?.segments, locations?.text)).reduce(
      (acc: any, markerArray: any) =>
        acc.concat(
          markerArray.reduce((accumulated: any, { questionId }: any) => {
            if (questionId?.slice(0, 10) === '_external_') {
              const split = questionId.slice(10).split(':')
              accumulated.push({ id: split[0], field: split[1] })
            }
            return accumulated
          }, [])
        ),
      []
    ) as any
  ).reduce((acc: any, cur: any) => Object.assign(acc, { [cur.id]: (acc[cur.id] || []).concat(cur.field) }), {})

  const allExternalsChosen = Object.keys(externalServiceFields).every(external => externalsChosen[external])

  const [chars, setChars] = useState(template?.characteristics ?? '')
  const [show, setShow] = useState(false)
  const { t } = useTranslation()

  const handleSecondaryClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    if (onEditTemplate && template) onEditTemplate(template.id)
  }

  const handleSubscriptionClick = async (): void => {
    console.log('handleSubscriptionClick')
    console.log('selectedSubscription', selectedSubscription)
    if (selectedSubscription) {
      console.log('selectedSubscription', selectedSubscription)
      const res = await subscriptionsApi.getOne(selectedSubscription, template?.id)
      if (res) {
        window.location.href = res.sessionURL
      }
    }
  }

  const handleSelectSubscription = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setSelectedSubscription(e.target.value)
  }

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>): void => {
    if (onUpdateCharacteristics && chars !== template?.characteristics && template) onUpdateCharacteristics(template.id, chars)
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const { value } = e.target
    setChars(value)
  }

  useEffect(() => {
    if (template) setChars(template.characteristics)
  }, [template])

  const onChange = useCallback(
    (service, id) => {
      setExternalsChosen(prev => Object.assign({}, prev, { [service]: id }))
    },
    [setExternalsChosen]
  )

  const charDisabled = !onUpdateCharacteristics || editingDisabled

  // generate external answers for V3 // ======================== IMPLEMENT PROPERLY WHEN A CHANCE ARISES

  const selectedExternalInfo = Object.entries(externalsChosen).reduce(
    (acc, [service, id]) => Object.assign(acc, { [service]: { id, fields: externalServiceFields[service] } }),
    {}
  )

  const { data: externalServiceEntries, isLoading: serviceEntriesLoading } = useFetchExternalServiceEntries(selectedExternalInfo) as any

  const externalAnswers = useMemo(
    () =>
      Object.entries(externalServiceFields).reduce(
        (acc, [service, fieldArray]: any) =>
          acc.concat(
            fieldArray.reduce((accumulated: any, field: any) => {
              const id = `_external_${service}:${field}`
              const value = externalServiceEntries && externalServiceEntries[service] && externalServiceEntries[service][field]
              const type = externalServiceEntries && externalServiceEntries[service] && externalServiceEntries[service].fields[field]
              const mappedType = { text: 'string', date: 'date', number: 'number', currency: 'currency' }[type] || 'string'
              const answer = { id, values: [`${id}:{{${CASUS_KEYSTRINGS.INPUT} type="${mappedType}" value="${value}"}}`] }
              accumulated.push(answer)
              return accumulated
            }, [])
          ),
        []
      ),
    [externalServiceFields, externalServiceEntries]
  )

  const handlePrimaryClick = async (e: React.MouseEvent<HTMLButtonElement>): void => {
    if (isPremium && !isPaid()) return handleSubscriptionClick()
    if (requirePayment) return setShow(true)
    if (template && allExternalsChosen && (Object.keys(externalServiceFields).length || questionsLength)) {
      dispatch(setServiceChosenId(externalsChosen))
      onUseTemplate(template.id, { name: template.name, isCasus: template.isCasus }, externalAnswers.length ? externalAnswers : undefined)
    }
  }

  return (
    <StandardModal
      handleClose={onClose}
      open={isOpen}
      title={template?.name.trim() ?? ''}
      handlePrimaryClick={handlePrimaryClick}
      handleSecondaryClick={editingDisabled ? undefined : handleSecondaryClick}
      primaryButtonText={
        isPremium && !isPaid()
          ? 'subscribe'
          : requirePayment
          ? 'buy_template'
          : !allExternalsChosen
          ? 'Choose above'
          : serviceEntriesLoading
          ? 'Loading'
          : !(questionsLength || Object.keys(externalServiceFields).length)
          ? 'no_questions'
          : 'use'
      }
      secondaryButtonText={editingDisabled ? '' : 'edit'}
      primaryButtonDisabled={
        !allExternalsChosen || serviceEntriesLoading || !(questionsLength || Object.keys(externalServiceFields).length) || isLoading
      }
      secondaryButtonDisabled={isLoading}
    >
      <div className={classes.wrapper}>
        {!isLoading && template ? (
          <Fragment>
            <div className={classes.leftPanel.wrapper}>
              {/* <Document title={document.name} /> */}
              <div className={classes.leftPanel.thumbnail}>
                <DOCXDocument />
              </div>
              <div className={classes.leftPanel.author.wrapper}>
                <div>
                  <span>{t('template_author')}</span>
                  <Author author={template.displayAuthor!} />
                </div>
              </div>
            </div>
            <div className={classes.rightPanel.wrapper}>
              <div className={classes.rightPanel.top}>
                {chars && <span className={classes.rightPanel.title}>{t('template_characteristics')}</span>}
                {!charDisabled ? (
                  <textarea
                    className={classes.rightPanel.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={editingDisabled && !chars ? 'Dieser Vertrag hat keine Merkmale' : chars}
                    disabled={charDisabled}
                    placeholder={t('short_description_template')}
                    spellCheck={false}
                  />
                ) : (
                  <span className={classes.rightPanel.description}>
                    {(chars?.split(/\r?\n/) ?? []).map((p, i, arr) => (
                      <Fragment key={i}>
                        {p}
                        {i < arr.length - 1 ? <br /> : null}
                      </Fragment>
                    ))}
                  </span>
                )}
              </div>
              {Object.keys(externalServiceFields).map(
                service =>
                  (
                    <ExternalServiceSelect
                      key={`${template.id}-external-service-select-${service}`}
                      service={service}
                      chosen={externalsChosen[service]}
                      onChange={id => onChange(service, id)}
                    />
                  ) as any
              )}
              {template.isPremium && !isPaid() && (
                <>
                  <div className={classes.rightPanel.premiumDescription.wrapper}>
                    <div className={classes.rightPanel.premiumDescription.icon}>
                      <Premium />
                    </div>
                    Premium Template - This template is available for Premium users only, please subscribe to use it.
                  </div>
                  <div className={classes.rightPanel.subscription.wrapper}>
                    {subscriptionsLoading && <Loader />}
                    {subscriptions && subscriptions.length > 0
                      ? subscriptions.map(subscription => (
                          <div key={subscription.priceId} className={classes.rightPanel.subscription.input}>
                            <div>
                              <input
                                type="radio"
                                id={subscription.priceId}
                                name="subscription"
                                value={subscription.priceId}
                                onChange={handleSelectSubscription}
                              />
                              <label htmlFor={subscription.priceId}>{subscription.name}</label>
                            </div>
                            <span className={classes.rightPanel.subscription.price}>
                              {subscription.currency.toUpperCase()} {Math.floor(subscription.price / 100)}
                            </span>
                          </div>
                        ))
                      : null}
                  </div>
                </>
              )}
            </div>
          </Fragment>
        ) : (
          <Loader style={{ padding: 0, height: 500 }} />
        )}
      </div>
      {requirePayment && show && template && (
        <PurchaseTemplateModal open={!!requirePayment && show} templateId={template?.id} handleClose={() => setShow(false)} />
      )}
    </StandardModal>
  )
}

export default TemplatePreviewModal
