import React, { FunctionComponent, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Cogwheel, Logout, Person } from 'assets/svgIconComponents'
import { HeaderProfileProps } from '.'
import { headerClasses as classes } from '../..'
import { Button } from 'components/CasusComponents'
import { useUser } from 'queries/user/useUser'
import { useTranslation } from 'react-i18next'

export const Profile: FunctionComponent<HeaderProfileProps> = React.memo(() => {
  const location = useLocation()
  const isDev = useMemo(() => location.pathname.includes('/dev'), [location])
  const user = useUser()
  const { t } = useTranslation()
  const ProfileImage = () => user?.imageUrl ? <img src={user.imageUrl} className={classes.profileDropdown.image} alt="profile"/> : <Person />

  const navButtons = [
    {
      label: t('settings'),
      icon: Cogwheel,
      path: `${isDev ? '/dev' : ''}/${t('settings').toLowerCase()}`
    },
    {
      label: t('logout'),
      icon: Logout,
      path: '/logout'
    }
  ]

  const dropdown = useMemo(() => {
    return (
      <ul className={classes.profileDropdown.dropdownList}>
        {navButtons.map((item) => {
          return (
            <li
              title={item.label}
              key={`Header-Profile-list-item-${item.label}`}
              className={classes.profileDropdown.dropdownListItem}
              onClick={event => (event.target as HTMLElement).blur()}
            >
              <Link to={item.path}>
                <item.icon key={`Header-Profile-list-item-icon-${item.label}`} />
                {item.label}
              </Link>
            </li>
          )
        })}
      </ul>
    )
  }, [navButtons])

  return (
    <div className={classes.profileDropdown.wrapper}>
      <Button className={classes.profileDropdown.button} onClick={() => {}}>
        <ProfileImage/>
      </Button>
      {dropdown}
    </div>
  )
})

Profile.displayName = 'Header-Profile'

export default Profile

