import React, { useMemo } from 'react'

import {
  AdvancedQuestionConfiguration,
  MarkerConfiguration,
  OptionConfiguration,
  QuestionConfiguration,
  QuestionGeneration,
  Questionnaire,
} from './index'
import { classes } from '../index'

const getConfigurationComponent = (keystring, id) => {
  switch (keystring) {
    case 'template-creation':
      return <QuestionGeneration />
    case 'question':
      return <QuestionConfiguration key={id} id={id} />
    case 'question-advanced':
      return <AdvancedQuestionConfiguration id={id} />
    case 'option':
      return <OptionConfiguration key={id} id={id} />
    case 'marker':
      return <MarkerConfiguration key={id} id={id} />
    case 'document-generation':
      return <Questionnaire />
    default:
      return null
  }
}

const ConfigEntry = React.memo(props => {
  const { keystring, id } = props
  const render = useMemo(() => getConfigurationComponent(keystring, id), [keystring, id])
  return <div className={classes.configEntry.wrapper}>{render}</div>
})

ConfigEntry.displayName = 'Wizard-Configuration-ConfigEntry'

export default ConfigEntry
