import ACTION_TYPES from '___store/actionTypes'
import {
  polluteState,
  updateTitle,
  // evaluateDependencies,
  addNewQuestionLayoutGroupSeparator,
  addNewQuestionLayoutGroup,
  updateQuestionLayoutGroup,
  unpackQuestionLayoutGroup,
  removeQuestionLayoutGroup,
  updateQuestion,
  removeQuestion,
  assignQuestion,
  unassignQuestion,
  // updateQuestionOption,
  updateAdvancedQuestionConfiguration,
  addNewConditionalRule,
  updateConditionalRule,
  removeConditionalRule,
  // answerWithOption,
  // unanswerOption,
  makeIntoSubQuestion,
  removeSubQuestionStatus,
  updateQuestionVisibility,
  updateQuestionRuleLogic,
  replaceParagraphContent,
  addNewParagraphStyles,
  removeParagraphStyles,
  toggleParagraphStyle,
  applyParagraphCustomStyle,
  insertParagraphAbove,
  insertParagraphBelow,
  removeParagraph,
  // applyParagraphNumbering,
  removeConfiguration,
  // addNewLocation,
  // removeLocation,
  // addNewQuestionOption,

  ////////////////
  // refactored //
  ////////////////
  addNewQuestion,
  addNewOptionGroup,
  updateOptionGroup,
  removeOptionGroup,
  addNewOption,
  updateOption,
  removeOption,
  unassignMarker,
  removeLocation,
  assignMarker,
  addNewLocation,
  evaluateMarker,
  // evaluateAllMarkers,
  unanswerQuestion,
  // unanswerOption,
  goToPreviousQuestion,
  goToNextQuestion,
} from './helpers/index' // remove /index

import {
  answerQuestion,
  applyToConfiguringStack,
  // assignMarker,
  // evaluateSegmentsMarker,
  // evaluateTextMarker,
  getQuestionOption,
  getStateQuestion,
  // initializeWizard,
} from './helpers'
import {
  initialState,
  // resetWizard as resetWizardOld
} from './helpers/general'
import {
  resetWizard,
  initializeWizard,
  updateWizardState,
  setWizardTemplateId,
  resetWizardTemplateId,
  updateWizardTitle,
  navigateQuestionnaireForward,
  navigateQuestionnaireBackward,
  navigateQuestionnaireTo,
  answerWithOption,
  unanswerOption,
} from './typified/helpers'

// const initialState = {}
// const initialState = {
//   initialized: false,
//   popup: null,
//   title: '',
//   configuring: [],
//   answering: null,
//   dataStructure: {},
//   sections: [],
//   locations: { choice: {}, replacement: {} },
//   styles: {},
//   numberingSystem: {},
//   questions: [],
//   dependencies: {},
//   questionLayout: [],
//   answers: [],
//   dirty: false,
//   mode: '',
// }

const reducer = (state = initialState, action) => {
  const { type, payload } = action
  // if (type === ACTION_TYPES.WIZARD.WIZARD_RESET) return resetWizardOld()
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // ////////////////////////////////////////////// GENERAL ////////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // if (type === ACTION_TYPES.WIZARD.WIZARD_INITIALIZED)
  //   return evaluateDependencies(applyParagraphNumbering(evaluateAllMarkers(initializeWizard(state, payload))))
  if (type === ACTION_TYPES.WIZARD.WIZARD_STATE_POLLUTED) return polluteState(state)
  if (type === ACTION_TYPES.WIZARD.TITLE_UPDATED) return updateTitle(state, payload)
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // /////////////////////////////////////////// CONFIGURATION /////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  if (type === ACTION_TYPES.WIZARD.CONFIGURING_REMOVED) return removeConfiguration(state, payload)
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // ////////////////////////////////////////// QUESTION LAYOUT ////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  if (type === ACTION_TYPES.WIZARD.QUESTION_LAYOUT_SEPARATOR_ADDED) return addNewQuestionLayoutGroupSeparator(state)
  if (type === ACTION_TYPES.WIZARD.QUESTION_LAYOUT_GROUP_ADDED) return addNewQuestionLayoutGroup(state)
  if (type === ACTION_TYPES.WIZARD.QUESTION_LAYOUT_GROUP_UPDATED) return updateQuestionLayoutGroup(state, payload)
  if (type === ACTION_TYPES.WIZARD.QUESTION_LAYOUT_GROUP_UNPACKED) return unpackQuestionLayoutGroup(state, payload)
  if (type === ACTION_TYPES.WIZARD.QUESTION_LAYOUT_GROUP_REMOVED) return removeQuestionLayoutGroup(state, payload)
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////// QUESTIONS ///////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  if (type === ACTION_TYPES.WIZARD.NEW_QUESTION_ADDED) return addNewQuestion(state, payload)
  if (type === ACTION_TYPES.WIZARD.QUESTION_UPDATED) return updateQuestion(state, payload)
  if (type === ACTION_TYPES.WIZARD.QUESTION_REMOVED) return removeQuestion(state, payload)
  if (type === ACTION_TYPES.WIZARD.QUESTION_ASSIGNED) return assignQuestion(state, payload)
  if (type === ACTION_TYPES.WIZARD.QUESTION_UNASSIGNED) return unassignQuestion(state, payload)
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // /////////////////////////////////////////// OPTION GROUPS /////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  if (type === ACTION_TYPES.WIZARD.NEW_OPTION_GROUP_ADDED) return addNewOptionGroup(state, payload)
  if (type === ACTION_TYPES.WIZARD.OPTION_GROUP_UPDATED) return updateOptionGroup(state, payload)
  if (type === ACTION_TYPES.WIZARD.OPTION_GROUP_REMOVED) return removeOptionGroup(state, payload)
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // ////////////////////////////////////////////// OPTIONS ////////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  if (type === ACTION_TYPES.WIZARD.NEW_OPTION_ADDED) return addNewOption(state, payload)
  if (type === ACTION_TYPES.WIZARD.OPTION_UPDATED) return updateOption(state, payload)
  if (type === ACTION_TYPES.WIZARD.OPTION_REMOVED) return removeOption(state, payload)
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // ///////////////////////////////////////// ADVANCED QUESTION ///////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  if (type === ACTION_TYPES.WIZARD.ADVANCED_QUESTION_CONFIGURATION_UPDATED) return updateAdvancedQuestionConfiguration(state, payload)
  if (type === ACTION_TYPES.WIZARD.QUESTION_CONDITIONAL_RULE_ADDED) return addNewConditionalRule(state, payload)
  if (type === ACTION_TYPES.WIZARD.QUESTION_CONDITIONAL_RULE_UPDATED) return updateConditionalRule(state, payload)
  if (type === ACTION_TYPES.WIZARD.QUESTION_CONDITIONAL_RULE_REMOVED) return removeConditionalRule(state, payload)
  if (type === ACTION_TYPES.WIZARD.QUESTION_MADE_INTO_SUB_QUESTION) return makeIntoSubQuestion(state, payload)
  if (type === ACTION_TYPES.WIZARD.SUB_QUESTION_STATUS_REMOVED) return removeSubQuestionStatus(state, payload)
  if (type === ACTION_TYPES.WIZARD.QUESTION_VISIBILITY_UPDATED) return updateQuestionVisibility(state, payload)
  if (type === ACTION_TYPES.WIZARD.QUESTION_RULE_LOGIC_UPDATED) return updateQuestionRuleLogic(state, payload)
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////// ANSWERS /////////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  if (type === ACTION_TYPES.WIZARD.QUESTION_UNANSWERED) return unanswerQuestion(state, payload)
  if (type === ACTION_TYPES.WIZARD.PREVIOUS_QUESTION_SET) return goToPreviousQuestion(state)
  if (type === ACTION_TYPES.WIZARD.NEXT_QUESTION_SET) return goToNextQuestion(state)

  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // /////////////////////////////////////// EDITOR/SECTION/PAGE ///////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  if (type === ACTION_TYPES.WIZARD.PARAGRAPH_CONTENT_REPLACED) return replaceParagraphContent(state, payload)
  if (type === ACTION_TYPES.WIZARD.NEW_PARAGRAPH_STYLES_ADDED) return addNewParagraphStyles(state, payload)
  if (type === ACTION_TYPES.WIZARD.PARAGRAPH_STYLES_REMOVED) return removeParagraphStyles(state, payload)
  if (type === ACTION_TYPES.WIZARD.PARAGRAPH_STYLE_TOGGLED) return toggleParagraphStyle(state, payload)
  if (type === ACTION_TYPES.WIZARD.PARAGRAPH_CUSTOM_STYLE_APPLIED) return applyParagraphCustomStyle(state, payload)
  if (type === ACTION_TYPES.WIZARD.PARAGRAPH_INSERTED_ABOVE) return insertParagraphAbove(state, payload)
  if (type === ACTION_TYPES.WIZARD.PARAGRAPH_INSERTED_BELOW) return insertParagraphBelow(state, payload)
  if (type === ACTION_TYPES.WIZARD.PARAGRAPH_REMOVED) return removeParagraph(state, payload)

  if (type === ACTION_TYPES.WIZARD.LOCATION_MARKED) return addNewLocation(state, payload)
  if (type === ACTION_TYPES.WIZARD.LOCATION_UNMARKED) return removeLocation(state, payload)
  if (type === ACTION_TYPES.WIZARD.MARKER_ASSIGNED) return assignMarker(state, payload)
  if (type === ACTION_TYPES.WIZARD.MARKER_UNASSIGNED) return unassignMarker(state, payload)
  if (type === ACTION_TYPES.WIZARD.MARKER_EVALUATED) return evaluateMarker(state, payload)

  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //

  // if (type === ACTION_TYPES.WIZARD.QUESTION_REMOVED) {
  //   const { id } = payload
  //   const newQuestions = state.questions.filter(q => q.id !== id)
  //   if (state.questions.length === newQuestions) return state
  //   return { ...Object.assign(state, { questions: newQuestions }) }
  // }
  if (type === ACTION_TYPES.WIZARD.SEGMENTS_MARKER_UPDATED)
    return Object.values(state.locations.segments).some((array, i, all) => {
      const index = array.findIndex(m => m.id === payload.id)
      return index !== -1 && array.splice(index, 1, Object.assign({ ...array[index] }, payload))
    })
      ? { ...state }
      : state
  if (type === ACTION_TYPES.WIZARD.CONFIGURING_SET) return { ...state, configuring: applyToConfiguringStack(state.configuring, payload) }
  if (type === ACTION_TYPES.WIZARD.ANSWERING_SET) return { ...state, answering: payload.id }
  if (type === ACTION_TYPES.WIZARD.CONFIGURING_DONE) return { ...state, configuring: state.configuring.slice(0, state.configuring.length - 1) }
  // if (type === ACTION_TYPES.WIZARD.QUESTION_OPTION_ADDED) {
  //   const { questionId } = payload
  //   const [question, index] = getStateQuestion(state, questionId)
  //   if (index === -1) return state
  //   const resultingOptions = question.options?.slice() || []
  //   const ignore = ['questionId']
  //   const fallback = { id: uuid() }
  //   const optionObject = Object.entries(payload).reduce((acc, [k, v]) => (ignore.includes(k) ? acc : Object.assign(acc, { [k]: v })), fallback)
  //   // if (question.type === 'choice') Object.assign(optionObject, { text: `Option ${String(question.options?.length + 1 || 1)}` })
  //   // if (question.type === 'replacement')
  //   Object.assign(optionObject, { text: `Option ${String(question.options?.length + 1 || 1)}` })

  //   resultingOptions.push(optionObject)
  //   const optionTypes = new Set(resultingOptions.map(({ type }) => type))
  //   const computedQuestionValueType = optionTypes.size === 1 ? Array.from(optionTypes)[0] : 'ambiguous'
  //   state.questions.splice(index, 1, Object.assign({}, question, { options: resultingOptions, valueType: computedQuestionValueType }))
  //   return { ...state }
  // }
  // if (type === ACTION_TYPES.WIZARD.QUESTION_OPTION_UPDATED) {
  //   const { questionId, id } = payload
  //   const [question, index] = getStateQuestion(state, questionId)
  //   if (index === -1) return state
  //   const [option, optionIndex] = getQuestionOption(question, id)
  //   const ignore = ['questionId', 'id']
  //   const optionObject = Object.entries(payload).reduce(
  //     (acc, [k, v]) => (ignore.includes(k) ? acc : Object.assign(acc, { [k]: v })),
  //     { ...option }
  //   )
  //   question.options.splice(optionIndex, 1, optionObject)
  //   const optionTypes = new Set(question.options.map(({ type }) => type))
  //   const computedQuestionValueType = optionTypes.size === 1 ? Array.from(optionTypes)[0] : 'ambiguous'
  //   if (question.valueType !== computedQuestionValueType)
  //     state.questions.splice(index, 1, Object.assign({}, question, { valueType: computedQuestionValueType }))
  //   return { ...state }
  // }
  if (type === ACTION_TYPES.WIZARD.QUESTION_OPTION_REMOVED) {
    const { questionId, id } = payload
    const [question, index] = getStateQuestion(state, questionId)
    if (index === -1) return state
    const resultingOptions = question.options?.filter(o => o.id !== id) || []
    if (question.options?.length === resultingOptions.length) return state
    state.questions.splice(index, 1, Object.assign({}, question, { options: resultingOptions }))
    return { ...state }
  }
  if (type === ACTION_TYPES.WIZARD.QUESTION_OPTION_REORDERED) {
    const { questionId, id, order } = payload
    if (!Number(order)) return state
    const [question, index] = getStateQuestion(state, questionId)
    if (index === -1) return state
    const [option, optionIndex] = getQuestionOption(question, id)
    if (optionIndex === -1) return state
    const newIndex = Math.min(Math.max(optionIndex + order, 0), question.options.length - 1)
    question.options.splice(optionIndex, 1)
    question.options.splice(newIndex, 0, option)
    state.questions.splice(index, 1, Object.assign({}, question))
    return { ...state }
  }
  if (type === ACTION_TYPES.WIZARD.QUESTION_ANSWERED) return answerQuestion(state, payload)
  // if (type === ACTION_TYPES.WIZARD.CHOICE_MARKER_EVALUATED) return evaluateSegmentsMarker(state, payload)
  // if (type === ACTION_TYPES.WIZARD.REPLACEMENT_MARKER_EVALUATED) return evaluateTextMarker(state, payload)

  // return payload.id !== state.configuring.id ? { ...state, configuring: payload } : state

  // if (type === ACTION_TYPES.WIZARD.SEGMENT_INSERTED_AT_INDEX) {
  //   const segment = generateSegment(payload.segmentType)
  //   const configuring = segment.id
  //   const dataStructure = {
  //     ...insertSegmentAtIndex(state.dataStructure, payload.parentId, payload.inside, payload.index, segment),
  //   }
  //   return { ...state, dataStructure, configuring }
  // }
  // if (type === ACTION_TYPES.WIZARD.SEGMENT_REMOVED_AT_INDEX) {
  //   const dataStructure = { ...removeSegmentAtIndex(state.dataStructure, payload.parentId, payload.index) }
  //   const configuring = state.configuring === payload.id ? null : state.configuring
  //   return { ...state, dataStructure, configuring }
  // }

  // if (type === ACTION_TYPES.WIZARD.SEGMENT_INSERTED_ABOVE) {
  //   const segment = generateSegment(payload.segmentType)
  //   return { ...insertSegmentAbove(state, payload.id, segment), configuring: segment.id }
  // }
  // if (type === ACTION_TYPES.WIZARD.SEGMENT_LABEL_UPDATED) return { ...updateSegmentLabel(state, payload.id, payload.label) }
  // if (type === ACTION_TYPES.WIZARD.CUSTOM_STYLE_APPLIED) return { ...applyCustomStyle(state, payload.id, payload.customStyle) }
  // if (type === ACTION_TYPES.WIZARD.SEGMENT_REMOVED)
  //   return {
  //     ...removeSegment(state, payload.id, payload.customStyle),
  //     configuring: state.configuring === payload.id ? null : state.configuring,
  //   }

  // //////////////////////////////////////////////////////////////////////////////////////// //
  // //////////////////////////////////////////////////////////////////////////////////////// //
  // /////////////////////////////////////// TYPIFIED /////////////////////////////////////// //
  // //////////////////////////////////////////////////////////////////////////////////////// //
  // //////////////////////////////////////////////////////////////////////////////////////// //
  if (type === ACTION_TYPES.WIZARD.WIZARD_RESET) return resetWizard()
  if (type === ACTION_TYPES.WIZARD.WIZARD_INITIALIZED) return initializeWizard(payload)
  if (type === ACTION_TYPES.WIZARD.STATE_UPDATED) return updateWizardState(state, payload)
  if (type === ACTION_TYPES.WIZARD.TEMPLATE_ID_SET) return setWizardTemplateId(state, payload)
  if (type === ACTION_TYPES.WIZARD.TEMPLATE_ID_RESET) return resetWizardTemplateId(state, payload)
  if (type === ACTION_TYPES.WIZARD.TITLE_UPDATED) return updateWizardTitle(state, payload)

  // ================================================================= //
  // ====================== DOCUMENT GENERATION ====================== //
  // ================================================================= //

  // ====================== QUESTION NAVIGATION ====================== //
  if (type === ACTION_TYPES.WIZARD.QUESTIONNAIRE.NAVIGATED_FORWARD) return navigateQuestionnaireForward(state)
  if (type === ACTION_TYPES.WIZARD.QUESTIONNAIRE.NAVIGATED_BACKWARD) return navigateQuestionnaireBackward(state)
  if (type === ACTION_TYPES.WIZARD.QUESTIONNAIRE.NAVIGATED_TO) return navigateQuestionnaireTo(state, payload)
  // ====================== ANSWERING QUESTIONS ====================== //
  if (type === ACTION_TYPES.WIZARD.QUESTION_ANSWERED_WITH_OPTION) return answerWithOption(state, payload)
  if (type === ACTION_TYPES.WIZARD.QUESTION_OPTION_UNANSWERED) return unanswerOption(state, payload)
  // ================================================================= //
  // ======================================================================================== //
  // ======================================================================================== //
  // ======================================================================================== //
  return state
}

export { reducer }
export default reducer
