import React, { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import DocumentsList from './views/DocumentsList/DocumentsList.page'
import DocumentSettings from './views/DocumentSettings/DocumentSettings.page'
import Reminders from './views/Reminders/Reminders.page'

import { DocumentsLayout } from './layout/Layout'
import { documentsRoutes } from 'constants/routes'
import { useTranslation } from 'react-i18next'

const DocumentsPage: React.FC = () => {
  const {t} = useTranslation()
  useEffect(() => {
    document.title = `${t('u_documents')} | CASUS`
  }, [t])

  return (
    <DocumentsLayout>
      <Switch>
        <Route path={t(documentsRoutes.reminders)} component={Reminders} />
        <Route path={t(documentsRoutes.settings)} component={DocumentSettings} />
        <Route exact path={t(documentsRoutes.main)} component={DocumentsList} />
        <Redirect to={t(documentsRoutes.main)} />
      </Switch>
    </DocumentsLayout>
  )
}

export default DocumentsPage
