import { TemplatesFilter } from '___types'
import { AppState } from '.'

export type SetTemplatesFilterPayload = TemplatesFilter
const setTemplatesFilter = (state: AppState, payload: SetTemplatesFilterPayload): AppState =>
  !payload || payload === state.templates.filter ? state : Object.assign(state.templates, { filter: payload }) && Object.assign({}, state)

export type SetSelectedTemplateIdPayload = string
const setSelectedTemplateId = (state: AppState, payload: SetSelectedTemplateIdPayload): AppState =>
  !payload || payload === state.templates.selected ? state : Object.assign(state.templates, { selected: payload }) && Object.assign({}, state)

const deselectAllTemplates = (state: AppState): AppState =>
  !state.templates.selected ? state : Object.assign(state.templates, { selected: null }) && Object.assign({}, state)

export { setTemplatesFilter, setSelectedTemplateId, deselectAllTemplates }
