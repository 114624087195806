import React, { Fragment, useState } from 'react'
import Document from './FileComponent'
import { IDocument, ITemplate } from 'types'

import { FolderItem } from 'types'
import { IPopupMenuItem } from 'components/common/MenuPopover/MenuPopover.types'
import { Docx, Download, Pdf } from 'assets/svgIconComponents'
import { classes } from '.'
import { useDocumentFileActions } from 'hooks/useDocumentFileActions/useDocumentFileActions'
import { useUser } from 'queries/user/useUser'
import { useEditor } from 'config/textEditor/useEditor'
import ActionsDropdown from './ActionsDropdown'

interface Props {
  menuItems: IPopupMenuItem[]
  document: IDocument | ITemplate
  renameActive?: boolean
  isShared?: boolean
  currentFolderType?: FolderItem['type']
  handleLeftClick?: (docId: string) => void
  handleDoubleClick?: () => void
  handleUpdateName?: (docId: string, newName: string) => void
  isDownloadable?: boolean
  isLoading?: boolean
  showSignatureStatus?: boolean
  isSelected?: boolean
}

const FileWithMenu: React.FC<Props> = ({
  menuItems,
  document,
  renameActive,
  currentFolderType,
  handleLeftClick,
  handleDoubleClick,
  handleUpdateName,
  isDownloadable = false,
  isLoading = false,
  isSelected = false,
}) => {
  const [open, setOpen] = useState(false)

  const { onDownload } = useDocumentFileActions()
  const user = useUser()!
  const editor = useEditor()

  const leftClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (handleLeftClick && document.status !== 'trashed' && !isLoading) handleLeftClick(document.id)
  }

  return (
    <Fragment>
      <Document
        id={document.id}
        title={document.name}
        edited={document.edited}
        renameActive={renameActive}
        templateName={(document as IDocument).templateName}
        isPremium={(document as ITemplate).isPremium}
        type={(document as IDocument).type}
        sharedWith={document.sharedWith}
        sharedAuthor={document.author}
        sharingEnabled={document.sharingEnabled}
        isUpToDateWithTheLatestTemplateVersion={(document as IDocument)?.isUpToDateWithTheLatestTemplateVersion}
        currentFolderType={currentFolderType}
        isCasus={!!(document as ITemplate)?.isCasus}
        onClick={leftClick}
        onDoubleClick={handleDoubleClick}
        handleUpdateName={handleUpdateName}
        isDownloadable={isDownloadable}
        isLoading={isLoading}
        signatureStatus={(document as IDocument)?.signatureStatus}
        isSelected={isSelected}
        menuItems={menuItems}
      >
        <div className={classes.thumbnail.sideActions.wrapper}>
          {isDownloadable && (
            <button className={classes.thumbnail.sideActions.button} type="button" onClick={() => setOpen(prev => !prev)}>
              <Download />
              <div className={`DownloadButton-optionsDiv${open ? ' open' : ''}`}>
                <div
                  onClick={event => {
                    event.preventDefault()
                    event.stopPropagation()
                    onDownload(document.id, 'docx', document.name, editor)
                  }}
                  style={{
                    width: '2em',
                    minWidth: '0',
                    height: 'fit-content',
                    padding: '4px',
                    zIndex: 100,
                    marginTop: '0.7em',
                  }}
                >
                  <Docx />
                </div>
                {user.privacy.pdfDownload && (
                  <div
                    onClick={event => {
                      event.preventDefault()
                      event.stopPropagation()
                      onDownload(document.id, 'pdf', document.name, editor)
                    }}
                    style={{
                      width: '2em',
                      minWidth: '0',
                      height: 'fit-content',
                      padding: '4px',
                      zIndex: 100,
                      marginTop: '0.7em',
                    }}
                  >
                    <Pdf />
                  </div>
                )}
              </div>
            </button>
          )}
          <ActionsDropdown actions={menuItems} />
        </div>
      </Document>
    </Fragment>
  )
}

export default FileWithMenu

