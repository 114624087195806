import React from 'react'
import Document from 'components/files/File/FileComponent'
import { NoItemsAction } from 'components/common'
import Container from 'components/files/components/Container/Container'
import { FolderItem, ITemplate } from 'types'
import { useCreateTemplate } from 'queries/templates'
import LoadMoreButton from 'components/files/components/LoadMoreButton/LoadMoreButton'
import { t } from 'i18next'
import template_png from 'assets/illustrations/dokumente-vorlagen.svg'

interface Props {
  templates: ITemplate[]
  currentFolder: FolderItem
  isFetchingMore?: boolean
  hasMoreTemplates?: boolean
  fetchMore: () => void
  onTemplateClick: (templateId: string) => void
}

const TemplatesList: React.FC<Props> = ({
  templates,
  currentFolder,
  isFetchingMore,
  hasMoreTemplates,
  fetchMore,
  onTemplateClick,
}) => {
  const { type } = currentFolder
  const create = useCreateTemplate()

  if (!templates.length && type !== 'casus')
    {
      const templatesNoItems = {
        text: t('no_templates'),
        actionText: t('create_new_template'),
        heading: t('templates'),
        actionBtn: t('new_template'),
        image: template_png,
      }
      return (
      <NoItemsAction
        {...templatesNoItems}
        handleActionClick={() =>
          type === 'my' ? create.mutate({}) : undefined
        }
      />
    )}
  return (
    <Container>
      {templates.map((templateItem) => {
        return (
          <Document
            key={templateItem.id}
            id={templateItem.id}
            title={templateItem.name}
            edited={templateItem.edited}
            isCasus={templateItem.isCasus}
            isPremium={templateItem.isPremium}
            onClick={() => onTemplateClick(templateItem.id)}
          />
        )
      })}
      {hasMoreTemplates && (
        <LoadMoreButton
          text={t('load_more')}
          onClick={fetchMore}
          isLoading={!!isFetchingMore}
        />
      )}
    </Container>
  )
}

export default TemplatesList
