import React, { useCallback, useEffect, useMemo } from 'react'
import { Loader } from 'components/common'
import { useFetchTemplate, useUpdateTemplate } from 'queries/templates'
import { useUser } from 'queries/user/useUser'
import { useHistory, useParams } from 'react-router'
import { TemplateCreationWizard } from 'Wizard'
import { CreateTemplateProvider } from './provider/CreateTemplateProvider'
import TemplateEdit from './TemplateEdit'
import { useTranslation } from 'react-i18next'
import { useFetchExternalServiceFields } from 'queries/externalServices'

// const externals = [
//   { id: 'bamboo_hr', title: 'BambooHR', fields: ['first_name', 'last_name', 'address_line_one', 'address_line_two', 'best_email', 'role'] },
//   { id: 'some_other_api', title: 'Cool API... Yeah!', fields: ['first_name', 'last_name', 'address'] },
//   { id: 'empty_api', title: 'Empty API', fields: [] },
// ]

const TemplateEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { data = {} as any, isSuccess, refetch } = useFetchTemplate(id)
  const { push } = useHistory()
  const user = useUser()
  const { t } = useTranslation()
  // const docXmicro = !!user?.beta?.newDocxMicroservice
  const version = user?.version
  // const externalServiceFields = useFetchExternalServiceFields()
  const { data: { data: externalServiceFields = [] } = {}, isLoading: servicesLoading } = useFetchExternalServiceFields() as any
  // console.log('EXTERNAL SERVICE FIELDS: ', externalServiceFields)

  const update = useUpdateTemplate()
  const { questions, htmlData, settings, originalStylesKept, name, cssData, dataStructure, v3 = {} } = data

  const {
    title: v3title,
    dataStructure: v3dataStructure,
    locations: v3locations,
    cssData: v3cssData,
    numberingSystem: v3numberingSystem,
    questions: v3questions,
    questionLayout: v3questionLayout,
  } = v3

  let renderOld = false
  // renderOld = !docXmicro
  renderOld = version !== 'v3'

  const OldComponent = useMemo(
    () => (
      <CreateTemplateProvider
        initialData={{
          id,
          questions,
          htmlData,
          hyphens: settings?.hyphens,
          originalStylesKept,
          name,
          cssData,
          dataStructure,
        }}
      >
        <TemplateEdit />
      </CreateTemplateProvider>
    ),
    [id, questions, htmlData, settings, originalStylesKept, name, cssData, dataStructure]
  )

  useEffect(() => {
    if (id) refetch()
  }, [id, refetch])

  const closeHandler = useCallback(() => push('/templates'), [push])
  const finishHandler = useCallback(
    ({ title, dataStructure, locations, questions, questionLayout }) => {
      update.mutate({ version: 'v3', id, name: title, data: { dataStructure, locations, questions, questionLayout } })
      push(`/${t('templates')}`)
    },
    [push, t, id, update]
  )

  const V3component = useMemo(
    () =>
      v3dataStructure && !servicesLoading ? (
        <TemplateCreationWizard
          // @ts-ignore
          id={id}
          title={v3title}
          dataStructure={v3dataStructure}
          locations={v3locations}
          cssData={v3cssData}
          numberingSystem={v3numberingSystem}
          questions={v3questions}
          questionLayout={v3questionLayout}
          externals={externalServiceFields}
          closeHandler={closeHandler}
          saveHandler={finishHandler}
        />
      ) : null,
    [
      id,
      v3title,
      v3dataStructure,
      servicesLoading,
      v3locations,
      v3cssData,
      v3numberingSystem,
      v3questions,
      v3questionLayout,
      externalServiceFields,
      closeHandler,
      finishHandler,
    ]
  )

  const render = useMemo(() => {
    if (renderOld) return OldComponent
    return V3component
  }, [renderOld, OldComponent, V3component])

  if (!isSuccess) return <Loader />

  // const container = document.createElement('div')
  // container.innerHTML = htmlData

  // console.log('::::::: TEP :::::::')
  // console.log(htmlData.slice(350, 600))

  return render
}

export default TemplateEditPage
