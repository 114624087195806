import {
  FileManagerModalComponentProps,
  FileManagerModalFile,
} from './FileManagerModal.types'
import React, { Fragment, useState } from 'react'
import StandardModal from '../../../common/Modals/StandardModal/StandardModal'
import {
  Container,
  FileItemContainer,
  Text,
  TitleWrapper,
  CenterWrapper,
  Title,
  Logo,
} from './FileManagerModal.styles'
import { Icon } from 'components/common'
import theme from 'config/themes/theme'
import { secondary } from 'config/themes/colors'
import { FolderItem } from 'types'
import Loader from 'components/common/Loader/Loader'
import { limitLongString } from 'utils'
import { useTranslation } from 'react-i18next'

const filterFilesForCurrentFolder = (
  files: FileManagerModalFile[],
  currentFolder: FolderItem
): FileManagerModalFile[] => {
  const isCasus = currentFolder.type === 'casus'
  const isRoot = currentFolder.id === currentFolder.type

  return files.filter(fileItem => {
    if (Boolean(fileItem?.isCasus) === isCasus) {
      if (isRoot && !fileItem.category.length) return true
      if (!isRoot && fileItem.category.includes(currentFolder.id)) return true
    }
    return false
  })
}

const returnPrevFolders = (
  rootFolder: FolderItem,
  currentFolder: FolderItem
): FolderItem[] => {
  const folderPathOrder: FolderItem[] = []
  let currentPath = [...currentFolder.path]
  let search = currentPath.shift()

  const findFolder = (folder: FolderItem) => {
    if (!search || !folder) return
    let folderFound: FolderItem | undefined
    if (search.id === rootFolder.id) {
      folderFound = rootFolder
    } else {
      folderFound = folder.children.find(childFolder => childFolder.id === search?.id)
    }
    if (folderFound) {
      folderPathOrder.push(folderFound)
      search = currentPath.pop()
      findFolder(folderFound)
    }
  }
  findFolder(rootFolder)
  return [...folderPathOrder]
}

const FileManagerModal: React.FC<FileManagerModalComponentProps> = ({
  currentFolder,
  files,
  isLoading,
  rootFolder,
  primaryButtonDisabled,
  folderToMoveId,
  handleConfirmMove,
  onFolderChange,
  ...standardModalProps
}) => {
  const [theFolder, setTheFolder] = useState<FolderItem>(currentFolder)
  const [theFiles, setTheFiles] = useState<FileManagerModalFile[]>(
    filterFilesForCurrentFolder(files, currentFolder)
  )
  const [folderHistory, setFolderHistoy] = useState<FolderItem[]>(
    returnPrevFolders(rootFolder, currentFolder)
  )
  const { t } = useTranslation()

  const handleFolderClick = (folder: FolderItem) => {
    if (folder.id === folderToMoveId) return
    onFolderChange(folder)
    setTheFolder(folder)
    setFolderHistoy(prev => [...prev, theFolder])
    setTheFiles(filterFilesForCurrentFolder(files, folder))
  }

  const handleBackClick = () => {
    if (folderHistory.length === 0) return
    let copyFoldersHistory = [...folderHistory]
    let prevFolder = copyFoldersHistory.pop()
    if (!prevFolder) return
    setTheFolder(prevFolder)
    setFolderHistoy(copyFoldersHistory)
    setTheFiles(filterFilesForCurrentFolder(files, prevFolder))
  }

  const TitleComponent: React.FC = () => (
    <TitleWrapper>
      {folderHistory.length > 0 && (
        <Icon
          iconName='arrow-left-long'
          style={{ cursor: 'pointer' }}
          onClick={handleBackClick}
        />
      )}
      <Title>{limitLongString(t(theFolder.name), 35)}</Title>
    </TitleWrapper>
  )

  const renderFolders = () => {
    return theFolder.children.map(childFolder => (
      <FileItemContainer
        key={childFolder.id}
        isfolder={1}
        iscurrent={childFolder.id === folderToMoveId ? 1 : 0}
        disabled={childFolder.id === folderToMoveId ? 1 : 0}
        onClick={() => handleFolderClick(childFolder)}
      >
        <Icon iconName='folder' fill={secondary[300]} style={{ marginRight: 16 }} />
        <Text>{limitLongString(t(childFolder.name), 45)}</Text>
      </FileItemContainer>
    ))
  }

  const renderFiles = () => {
    return theFiles.map(fileItem => (
      <FileItemContainer key={fileItem.id}>
        {fileItem?.format && fileItem.format === 'pdf' ? (
          <Icon iconName='pdf' style={{ width: 16, height: 'auto', marginRight: 12 }} />
        ) : (
          <Logo>C</Logo>
        )}
        <Text>{limitLongString(fileItem.name, 45)}</Text>
      </FileItemContainer>
    ))
  }

  let content = null

  if (isLoading) {
    content = <Loader />
  } else {
    if (!theFolder.children.length && !theFiles.length) {
      content = (
        <CenterWrapper>
          <Text style={{ color: secondary[200] }}>Dieser Ordner ist leer</Text>
        </CenterWrapper>
      )
    } else {
      content = (
        <Fragment>
          {renderFolders()}
          {renderFiles()}
        </Fragment>
      )
    }
  }

  return (
    <StandardModal
      title={<TitleComponent />}
      {...standardModalProps}
      primaryButtonDisabled={
        primaryButtonDisabled || isLoading || theFolder.id === currentFolder.id
      }
      handlePrimaryClick={() => handleConfirmMove(theFolder)}
      RootModalProps={{
        style: { marginBottom: '30vh' },
        backdropStyle: { background: theme.colorz.secondary[100], opacity: 0.8 },
      }}
    >
      <Container>{content}</Container>
    </StandardModal>
  )
}

export default FileManagerModal
