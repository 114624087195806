import React, { useEffect, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import useStore from '___store'

import { mapTemplatesFilter } from '___types'
import { QUERY_KEYS } from '___queries'
import { useTemplateList } from '___hooks'
import { Button } from 'components/CasusComponents'
import { TemplateThumbnail } from 'components/CasusComponents/Template'
import { contentClasses, contentTemplatesClasses as classes } from '../..'

const LoadMoreButton = React.memo(({ onClick, label, disabled, loading }) => {
  return (
    <Button onClick={onClick} className={classes.loadMoreButton} disabled={disabled} loading={loading} showSpinner>
      {label}
    </Button>
  )
})

export const Templates = React.memo(() => {
  const { templatesFilter, deselectTemplates, setSelectedTemplateId } = useStore(
    'selectTemplatesFilter',
    'deselectTemplates',
    'setSelectedTemplateId'
  )
  const { data: { pages = [] } = {}, status, hasNextPage, isFetchingNextPage, fetchNextPage } = useTemplateList(templatesFilter)

  const templates = useMemo(() => {
    const templateEntries = pages?.reduce((acc, page) => acc.concat(page), [])
    return templateEntries.map(template => {
      return (
        <TemplateThumbnail
          key={`Content-Documents-TemplateThumbnail-${template.id}`}
          id={template.id}
          onClick={(_, templateId) => setSelectedTemplateId(templateId)}
        />
      )
    })
  }, [pages, setSelectedTemplateId])

  const buttonLabel = useMemo(() => {
    if (status === 'error') return 'Error loading templates'
    if (status === 'loading') return 'Loading...'
    if (isFetchingNextPage) return 'Loading more...'
    return 'Load more'
  }, [status, isFetchingNextPage])

  useEffect(() => {
    const deselectTemplatesOnEscKey = event => event.key === 'Escape' && deselectTemplates()
    document.addEventListener('keydown', deselectTemplatesOnEscKey)
    return () => {
      document.removeEventListener('keydown', deselectTemplatesOnEscKey)
      return deselectTemplates()
    }
  }, [deselectTemplates])

  const sectionClass = `${contentClasses.section.wrapper} ${classes.wrapper}`

  const queryClient = useQueryClient()

  return (
    <div className={sectionClass}>
      {templates}
      {hasNextPage ? (
        <LoadMoreButton
          onClick={() => fetchNextPage()}
          label={buttonLabel}
          disabled={!hasNextPage || isFetchingNextPage}
          loading={status === 'loading' || isFetchingNextPage}
        />
      ) : null}
      <Button
        className={classes.loadMoreButton}
        emphasized
        onClick={() => {
          console.log('RESETTING: ', templatesFilter)
          queryClient.setQueryData([QUERY_KEYS.TEMPLATES, mapTemplatesFilter(templatesFilter)], () => {
            return { pages: [], pageParams: [] }
          })
          queryClient.removeQueries([QUERY_KEYS.TEMPLATES, mapTemplatesFilter(templatesFilter)])
        }}
      >
        RESET TEST
      </Button>
      <Button
        className={classes.loadMoreButton}
        primary
        onClick={() => {
          console.log('RESETTING: ', templatesFilter)
          queryClient.setQueryData([QUERY_KEYS.TEMPLATES, mapTemplatesFilter(templatesFilter)], () => {
            return { pages: [], pageParams: [] }
          })
          queryClient.removeQueries([QUERY_KEYS.TEMPLATES, mapTemplatesFilter(templatesFilter)])
        }}
      >
        RESET TEST
      </Button>
      <Button
        className={classes.loadMoreButton}
        secondary
        onClick={() => {
          console.log('RESETTING: ', templatesFilter)
          queryClient.setQueryData([QUERY_KEYS.TEMPLATES, mapTemplatesFilter(templatesFilter)], () => {
            return { pages: [], pageParams: [] }
          })
          queryClient.removeQueries([QUERY_KEYS.TEMPLATES, mapTemplatesFilter(templatesFilter)])
        }}
      >
        RESET TEST
      </Button>
      <Button
        className={classes.loadMoreButton}
        tertiary
        onClick={() => {
          console.log('RESETTING: ', templatesFilter)
          queryClient.setQueryData([QUERY_KEYS.TEMPLATES, mapTemplatesFilter(templatesFilter)], () => {
            return { pages: [], pageParams: [] }
          })
          queryClient.removeQueries([QUERY_KEYS.TEMPLATES, mapTemplatesFilter(templatesFilter)])
        }}
      >
        RESET TEST
      </Button>
      <Button
        className={classes.loadMoreButton}
        onClick={() => {
          console.log('RESETTING: ', templatesFilter)
          queryClient.setQueryData([QUERY_KEYS.TEMPLATES, mapTemplatesFilter(templatesFilter)], () => {
            return { pages: [], pageParams: [] }
          })
          queryClient.removeQueries([QUERY_KEYS.TEMPLATES, mapTemplatesFilter(templatesFilter)])
        }}
      >
        RESET TEST
      </Button>
    </div>
  )
})

Templates.displayName = 'Content-Templates'

export default Templates
