import { useQuery } from 'react-query'
import { queryKeys } from 'queries/queryKeys'
import ExternalApi from 'api/external.api'

export const useFetchExternalServiceFields = () => {
  const query = useQuery([queryKeys.EXTERNAL_SERVICE_FIELDS], () => ExternalApi.getExternalApiFields(), {
    // enabled: false,
    refetchOnWindowFocus: false,
  })
  return query
}
