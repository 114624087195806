import React from 'react'
import { useQueryClient } from 'react-query'

import { useConfirmPopup } from 'providers/ConfirmPopupProvider'
import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { useRootFolder } from 'hooks/useRootFolder'
import { useToast } from 'hooks/useToast'

import PagesLayout from 'layout/PagesLayout/PagesLayout'

import { IPopupMenuItem } from 'components/common/MenuPopover/MenuPopover.types'
import { FileManager } from 'utils'
import { Container } from '../../../Authenticated.page.styles'
import TemplatesSidebar from './TemplatesSidebar/TemplatesSidebar'

import { queryKeys } from 'queries/queryKeys'
import TemplatesApi from 'api/templates.api'
import { POPUP_CONFIRM_DELETE_ALL_FILES } from 'constants/confirmation-popups'
import { useTranslation } from 'react-i18next'
import { DetailsSidebar } from 'components/DetailsSidebar/DetailsSidebar'
import { DetailsProvider } from 'components/DetailsSidebar/provider/DetailsProvider'
import { MainLayout } from 'layout'

export const TemplatesLayout: React.FC = ({ children }) => {
  const { currentFolder, setCurrentFolder } = useCurrentFolder('templates')
  const { rootFolder } = useRootFolder('templates')
  const queryClient = useQueryClient()
  const { popup, close, setIsLoading } = useConfirmPopup()
  const toast = useToast()

  const { type } = currentFolder
  const { t } = useTranslation()

  const onEmptyBin = async () => {
    setIsLoading(true)
    try {
      await TemplatesApi.emptyTrashed()
      queryClient.invalidateQueries([queryKeys.TEMPLATES, { type: 'trashed', folderId: 'trashed' }])
      queryClient.invalidateQueries([queryKeys.TEMPLATE_FOLDERS, 'trashed'])
      close()
    } catch (err) {
      toast('error', 'default')
    } finally {
      setIsLoading(false)
    }
  }

  const getMenuItems = (): IPopupMenuItem[] => {
    const menuItems: IPopupMenuItem[] = []
    switch (type) {
      case 'trashed':
        menuItems.push({
          label: t('empty_bin'),
          icon: 'trash',
          handleClick: () =>
            popup({
              ...POPUP_CONFIRM_DELETE_ALL_FILES,
              onConfirm: onEmptyBin,
              onCancel: close,
            }),
        })
        break
    }
    return menuItems
  }

  const onPathClick = (folderId: string) => {
    const fileManager = new FileManager(rootFolder, currentFolder)
    const folder = fileManager.findFolderById(folderId)
    if (folder) setCurrentFolder(folder)
  }

  return (
    <MainLayout>
      <Container>
        <DetailsProvider type="template">
          <TemplatesSidebar />
          <PagesLayout
            hideHeader
            isLoading={false}
            menuItems={getMenuItems()}
            breadcrumbNavProps={{
              folder: currentFolder,
              handlePathClick: onPathClick,
            }}
          >
            {children}
          </PagesLayout>
          <DetailsSidebar />
        </DetailsProvider>
      </Container>
    </MainLayout>
  )
}

