import React, { useMemo } from 'react'
import useStore from '___store'

import { Insert } from 'assets/svgIconComponents'
import { Select } from 'components'
import { classes } from '../..'

const AssignSelectOptionButton = React.memo(({ label, payload }) => {
  const { assignMarker } = useStore('assignMarker')
  return (
    <button
      className="Select-option"
      type="button"
      onClick={event => {
        event.target.blur()
        event.currentTarget.blur()
        assignMarker(payload)
      }}
    >
      {label}
    </button>
  )
})

const AssignExternalAPI = React.memo(({ markerId, id, label }) => {
  return <AssignSelectOptionButton label={label} payload={{ markerId, externalId: id }} />
})

const ExternalAssignSelectOptions = React.memo(({ markerId, id, assignedQuestionId }) => {
  const { externals = [] } = useStore(`selectExternals`)
  const relevantExternal = useMemo(() => externals.find(({ id: externalId }) => externalId === id) || {}, [externals, id])
  const availableExternalFields = useMemo(
    () => relevantExternal.fields?.filter(field => assignedQuestionId !== `_external_${relevantExternal.id}:${field.id}`) || [],
    [relevantExternal, assignedQuestionId]
  )
  const render = useMemo(
    () =>
      availableExternalFields.map(field => (
        <AssignExternalAPI
          key={`MarkerAssignSelect-${markerId}-external-option-${field.id}`}
          markerId={markerId}
          id={`_external_${relevantExternal.id}:${field.id}`}
          label={field.name}
        />
      )),
    [availableExternalFields, markerId, relevantExternal]
  )
  const { optionGroupLabel: optionGroupLabelClass } = classes.marker.markerAssignSelect
  return availableExternalFields.length ? (
    <>
      <span className={optionGroupLabelClass}>{relevantExternal.title}</span>
      {render}
    </>
  ) : null
})

const AssignSelectOption = React.memo(({ markerId, id }) => {
  const { optionById: { text = '', value = '', placeholder = '' } = {} } = useStore(`selectOptionById[${id}]`)
  return <AssignSelectOptionButton label={text || value || placeholder || 'User input...'} payload={{ markerId, optionId: id }} />
})

const OptionGroupAssignSelectOptions = React.memo(({ markerId, questionId, id, assignedOptionIds }) => {
  const { questionOptionGroupById: { label = '', options = [] } = {} } = useStore(`selectQuestionOptionGroupById[${questionId},${id}]`)
  const availableOptionIds = options.filter(({ id: optionId }) => !assignedOptionIds.includes(optionId)).map(({ id: optionId }) => optionId)
  const render = useMemo(
    () =>
      availableOptionIds.map(id => (
        <AssignSelectOption key={`MarkerAssignSelect-${markerId}-optionGroup-option-${id}`} markerId={markerId} id={id} />
      )),
    [availableOptionIds, markerId]
  )
  const { optionGroupLabel: optionGroupLabelClass } = classes.marker.markerAssignSelect
  return availableOptionIds.length ? (
    <>
      <span className={optionGroupLabelClass}>{label}</span>
      {render}
    </>
  ) : null
})

const SelectOptionSwitch = React.memo(props => {
  const { id, label, markerId, currentQuestionId, assignedQuestionId, assignedOptionIds } = props
  const { optionGroupLabel: optionGroupLabelClass } = classes.marker.markerAssignSelect
  switch (label) {
    case 'optionGroup':
      return (
        <OptionGroupAssignSelectOptions
          key={`MarkerAssignSelect-${markerId}-optionGroup-${id}`}
          markerId={markerId}
          questionId={currentQuestionId}
          id={id}
          assignedOptionIds={assignedOptionIds}
        />
      )
    case 'external':
      return (
        <ExternalAssignSelectOptions
          key={`MarkerAssignSelect-${markerId}-external-${id}`}
          markerId={markerId}
          id={id}
          assignedQuestionId={assignedQuestionId}
        />
      )
    default:
      return (
        <>
          <span className={optionGroupLabelClass}>Question</span>
          <AssignSelectOptionButton
            key={`MarkerAssignSelect-${markerId}-currentQuestion-${id}`}
            label={label}
            payload={{ markerId, questionId: id }}
          />
        </>
      )
  }
})

OptionGroupAssignSelectOptions.displayName = 'Wizard-Editor-Marker-MarkerAssignSelect-OptionGroupAssignSelectOptions'

const MarkerAssignSelect = React.memo(({ markerId, assignedQuestionId, currentQuestionId, assignedOptionIds }) => {
  const {
    externals = [],
    questionById: { text = '' } = {},
    questionOptionGroupIds = '',
    questionOptionIds = '',
  } = useStore(
    'selectExternals',
    `selectQuestionById[${currentQuestionId}]`,
    `selectQuestionOptionGroupIds[${currentQuestionId}]`,
    `selectQuestionOptionIds[${currentQuestionId}]`
  )
  const { wrapper: wrapperClass } = classes.marker.markerAssignSelect
  const optionGroupIds = useMemo(() => questionOptionGroupIds.split('; ').filter(o => o), [questionOptionGroupIds])
  const optionIds = useMemo(() => questionOptionIds.split('; ').filter(o => o), [questionOptionIds])
  const externalIds = useMemo(() => externals.map(({ id }) => id), [externals])
  const externalOptions = useMemo(() => externalIds.reduce((acc, cur) => Object.assign(acc, { [cur]: 'external' }), {}), [externalIds])
  const allAssignedIds = useMemo(() => assignedOptionIds.concat(assignedQuestionId), [assignedOptionIds, assignedQuestionId])
  const allOptions = useMemo(() => optionIds.concat(currentQuestionId).concat(externalIds), [optionIds, currentQuestionId, externalIds])
  const availableOptions = useMemo(() => allOptions.filter(id => id && !allAssignedIds.includes(id)), [allOptions, allAssignedIds])
  const selectOptions = useMemo(
    () =>
      optionGroupIds.reduce(
        (acc, cur) => Object.assign(acc, { [cur]: 'optionGroup' }),
        Object.assign(
          {},
          externalOptions,
          currentQuestionId && assignedQuestionId !== currentQuestionId ? { [currentQuestionId]: text || 'Current question' } : {}
        )
      ),
    [optionGroupIds, externalOptions, assignedQuestionId, currentQuestionId, text]
  )

  return availableOptions.length ? (
    <Select
      className={wrapperClass}
      options={selectOptions}
      render={(id, label) => (
        <SelectOptionSwitch
          key={`MarkerAssignSelect-${markerId}-${id}`}
          id={id}
          label={label}
          markerId={markerId}
          currentQuestionId={currentQuestionId}
          assignedQuestionId={assignedQuestionId}
          assignedOptionIds={assignedOptionIds}
        />
      )}
      chooseMode
      chooseRender={<Insert />}
      noCaret
      directionPriority="vertical"
      horizontalDirection="right"
    />
  ) : null
})

MarkerAssignSelect.displayName = 'Wizard-Editor-Marker-MarkerAssignSelect'

export default MarkerAssignSelect
