import React, { FunctionComponent, useMemo } from 'react'
import useStore, { WizardAnsweringSelector } from '___store'

import { PreAnswering, QuestionGroup, SkippedQuestionReview, Confirmation, DocumentRename, PostGeneration } from './QuestionnaireComponents'
import { WizardLayoutLeftPaneQuestionnaireProps, wizardLayoutLeftPaneQuestionnaireClasses as classes } from '../..'
import { QUESTIONNAIRE_POST_STEPS, QUESTIONNAIRE_PRE_STEPS } from '___store/storeSegments/wizard/typified/helpers/document-generation'

type UseStoreHookResultType = { wizardAnswering: WizardAnsweringSelector }

export const Questionnaire: FunctionComponent<WizardLayoutLeftPaneQuestionnaireProps> = React.memo(({ isLoading, isFetching }) => {
  const { wizardAnswering } = useStore('selectWizardAnswering') as UseStoreHookResultType
  const step = useMemo(() => {
    if (wizardAnswering === QUESTIONNAIRE_PRE_STEPS.PRE_STEP) return <PreAnswering isLoading={isLoading} isFetching={isFetching} />
    if (wizardAnswering === QUESTIONNAIRE_POST_STEPS.SKIPPED) return <SkippedQuestionReview isLoading={isLoading} isFetching={isFetching} />
    if (wizardAnswering === QUESTIONNAIRE_POST_STEPS.CONFIRM) return <Confirmation isLoading={isLoading} isFetching={isFetching} />
    if (wizardAnswering === QUESTIONNAIRE_POST_STEPS.RENAME) return <DocumentRename isLoading={isLoading} isFetching={isFetching} />
    if (wizardAnswering === QUESTIONNAIRE_POST_STEPS.POST_STEP) return <PostGeneration isLoading={isLoading} isFetching={isFetching} />
    return <QuestionGroup key={`QuestionGroup-${wizardAnswering}`} id={wizardAnswering} isLoading={isLoading} isFetching={isFetching} />
  }, [wizardAnswering, isLoading, isFetching])
  return <div className={classes.wrapper}>{step}</div>
})

Questionnaire.displayName = 'WizardLayout-Questionnaire'

export default Questionnaire
