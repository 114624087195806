import { DateTimeC } from '___types'

import MainLayout from './MainLayout'

import './style.scss'

export type MainLayoutProps = {}
export type MainLayoutHeaderProps = {}
export type MainLayoutLeftPaneProps = {}
export type MainLayoutRightPaneProps = {}
export type MainLayoutRightPaneDocumentProps = {}
export type MainLayoutRightPaneDocumentInfoSectionProps = { template?: string; owner?: string; created?: DateTimeC }
export type MainLayoutRightPaneTemplateProps = {}
export type MainLayoutRightPaneTemplateActionsSectionProps = { templateId?: string }
export type MainLayoutRightPaneTemplateInfoSectionProps = { owner?: string; created?: DateTimeC }
export type MainLayoutFooterProps = {}

export const mainLayoutRightPaneTemplateClasses = {
  wrapper: 'MainLayout-RightPane-Template-wrapper',
  section: 'MainLayout-RightPane-Template-section',
  title: 'MainLayout-RightPane-Template-title',
  actions: {
    wrapper: 'MainLayout-RightPane-Template-actions-wrapper',
    button: 'MainLayout-RightPane-Template-actions-button',
    dropdown: 'MainLayout-RightPane-Template-actions-dropdown',
  },
  info: { wrapper: 'MainLayout-RightPane-Template-info-wrapper', snippet: 'MainLayout-RightPane-Template-info-snippet' },
  share: 'MainLayout-RightPane-Template-share',
} as const
export const mainLayoutRightPaneDocumentClasses = {
  wrapper: 'MainLayout-RightPane-Document-wrapper',
  section: 'MainLayout-RightPane-Document-section',
  title: 'MainLayout-RightPane-Document-title',
  templateName: 'MainLayout-RightPane-Document-templateName',
  actions: {
    wrapper: 'MainLayout-RightPane-Document-actions-wrapper',
    button: 'MainLayout-RightPane-Document-actions-button',
    dropdown: 'MainLayout-RightPane-Document-actions-dropdown',
  },
  info: { wrapper: 'MainLayout-RightPane-Document-info-wrapper', snippet: 'MainLayout-RightPane-Document-info-snippet' },
  esignature: 'MainLayout-RightPane-Document-esignature',
  share: 'MainLayout-RightPane-Document-share',
} as const
export const mainLayoutRightPaneClasses = {
  wrapper: 'MainLayout-RightPane-wrapper',
  document: mainLayoutRightPaneDocumentClasses,
  template: mainLayoutRightPaneTemplateClasses,
} as const
export const mainLayoutClasses = {
  wrapper: 'MainLayout-wrapper',
  //   header: mainLayoutHeaderClasses,
  //   left: mainLayoutLeftPaneClasses,
  right: mainLayoutRightPaneClasses,
  //   footer: mainLayoutFooterClasses,
} as const

export { MainLayout, mainLayoutClasses as classes }
export default MainLayout
