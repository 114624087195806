import { useMemo } from 'react'
import useStore, { WizardParagraphNumberingByIdSelector, WizardTextMarkerLocationsByParentIdSelector } from '___store'

import { TextChunks } from '___types'
import { useEditorPreviewContext } from '..'
import { IteratedChunks, parseChunkContent } from '../helpers'

type UseStoreHookTextMarkerLocationResultType = { wizardTextMarkerLocationsByParentId?: WizardTextMarkerLocationsByParentIdSelector }
type UseStoreHookParagraphNumberingResultType = { wizardParagraphNumberingById?: WizardParagraphNumberingByIdSelector }
type UseParagraphResultType = { content: IteratedChunks; numberingSystem: string; numberingValue: string }

export const useParagraph = (id: string, chunks?: TextChunks): UseParagraphResultType => {
  const editorContext = useEditorPreviewContext()
  const { numbering, locations } = editorContext

  // ======================== TEXT MARKERS ======================== //
  const contextTextMarkers = locations?.text[id]

  const { wizardTextMarkerLocationsByParentId } = useStore(
    !locations ? `selectWizardTextMarkerLocationsByParentId[${id}]` : undefined
  ) as UseStoreHookTextMarkerLocationResultType

  const resultingMarkers = contextTextMarkers || wizardTextMarkerLocationsByParentId || []
  // ============================================================== //

  // ========================== NUMBERING ========================== //
  const contextNumbering = numbering && numbering[id]
  const { wizardParagraphNumberingById = ':' } = useStore(
    !numbering ? `selectWizardParagraphNumberingById[${id}]` : undefined
  ) as UseStoreHookParagraphNumberingResultType
  const resultingNumbering = contextNumbering ? `${contextNumbering?.system}:${contextNumbering.value}` : wizardParagraphNumberingById
  // =============================================================== //

  const content = useMemo(() => (chunks?.length ? parseChunkContent(resultingMarkers, chunks) : []), [resultingMarkers, chunks])
  const useParagraphReturn = useMemo(() => {
    const [numberingSystem, numberingValue] = resultingNumbering.split(':')
    return { content, numberingSystem, numberingValue }
  }, [content, resultingNumbering])

  return useParagraphReturn
}

export default useParagraph
