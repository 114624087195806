import React, { FunctionComponent, useMemo, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import useStore, {
  WizardInitializedSelector,
  WizardTemplateIdSelector,
  WizardTitleSelector,
  WizardEditorStylesSelector,
  WizardDataStructureSelector,
  WizardLocationsSelector,
  WizardQuestionsSelector,
  WizardQuestionLayoutSelector,
  WizardAnswersSelector,
  ResetWizardAction,
  InitializeWizardAction,
  UpdateWizardStateAction,
  InitializeWizardPayload,
  UpdateWizardStatePayload,
} from '___store'

import { DIRECTORY_MATCH, DIRECTORY_WIZARD_MODE_MAP, DOCUMENTS_DIRECTORY, Directory } from '___types'
import { useDocument, useTemplate } from '___hooks'
import { WizardLayoutProps, Header, LeftPane, RightPane, Footer } from '.'
import { wizardLayoutClasses as classes } from '.'

type UseStoreHookResultType = {
  wizardInitialized: WizardInitializedSelector
  wizardTemplateId: WizardTemplateIdSelector
  wizardTitle: WizardTitleSelector
  wizardEditorStyles: WizardEditorStylesSelector
  wizardDataStructure: WizardDataStructureSelector
  wizardLocations: WizardLocationsSelector
  wizardQuestions: WizardQuestionsSelector
  wizardQuestionLayout: WizardQuestionLayoutSelector
  wizardAnswers: WizardAnswersSelector
  resetWizard: ResetWizardAction
  initializeWizard: InitializeWizardAction
  updateWizardState: UpdateWizardStateAction
}

export const WizardLayout: FunctionComponent<WizardLayoutProps> = React.memo(() => {
  const history = useHistory()
  const { pathname } = useLocation()
  const { templateId, documentId } = useParams() as { templateId: string | undefined; documentId: string | undefined }
  const {
    wizardInitialized,
    wizardTemplateId,
    wizardTitle,
    wizardEditorStyles,
    wizardDataStructure,
    wizardLocations,
    wizardQuestions,
    wizardQuestionLayout,
    wizardAnswers,
    resetWizard,
    initializeWizard,
    updateWizardState,
  } = useStore(
    'selectWizardInitialized',
    'selectWizardTemplateId',
    'selectWizardTitle',
    'selectWizardEditorStyles',
    'selectWizardDataStructure',
    'selectWizardLocations',
    'selectWizardQuestions',
    'selectWizardQuestionLayout',
    'selectWizardAnswers',
    'resetWizard',
    'initializeWizard',
    'updateWizardState'
  ) as UseStoreHookResultType
  const { isLoading, isFetching, data: template } = useTemplate(wizardTemplateId)
  const { data: { answers } = {} } = useDocument(documentId!)
  const directory = useMemo(() => pathname.match(DIRECTORY_MATCH)?.groups?.directory, [pathname])
  useEffect(() => {
    if (!wizardInitialized && directory) {
      const payload = { mode: DIRECTORY_WIZARD_MODE_MAP[`${directory}s` as Directory] } as InitializeWizardPayload
      if (templateId) Object.assign(payload, { templateId })
      initializeWizard(payload)
    }
    return resetWizard
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (`${directory}s` === DOCUMENTS_DIRECTORY && !templateId && wizardTemplateId) history.push(`/dev/${directory}/${wizardTemplateId}/new`)
  }, [directory, templateId, wizardTemplateId, history])
  useEffect(() => {
    if (!isLoading && !isFetching && template) {
      const payload = {} as UpdateWizardStatePayload
      const { questions, questionLayout, name, dataStructure, locations, styles } = JSON.parse(JSON.stringify(template))
      if (!wizardTitle && name) Object.assign(payload, { title: name })
      if (!wizardEditorStyles && styles) Object.assign(payload, { styles })
      if (!wizardDataStructure && dataStructure) Object.assign(payload, { dataStructure })
      if (!wizardLocations && locations) Object.assign(payload, { locations })
      if (!wizardQuestions && questions) Object.assign(payload, { questions })
      if (!wizardQuestionLayout && questionLayout) Object.assign(payload, { questionLayout })
      if (!wizardAnswers && answers) Object.assign(payload, { answers })
      if (Object.keys(payload).length) updateWizardState(payload)
    }
  }, [
    isLoading,
    isFetching,
    template,
    wizardTitle,
    wizardEditorStyles,
    wizardDataStructure,
    wizardLocations,
    wizardQuestions,
    wizardQuestionLayout,
    wizardAnswers,
    answers,
    updateWizardState,
  ])
  return (
    <div className={classes.wrapper} data-show-header data-show-left-pane data-show-right-pane={Boolean(wizardTemplateId) || undefined}>
      <Header isLoading={isLoading} isFetching={isFetching} />
      <LeftPane isLoading={isLoading} isFetching={isFetching} />
      <RightPane key={wizardTemplateId} isLoading={isLoading} isFetching={isFetching} />
      {/* {Boolean(wizardTemplateId) ? <RightPane key={wizardTemplateId} isLoading={isLoading} isFetching={isFetching} /> : null} */}
      <Footer isLoading={isLoading} isFetching={isFetching} />
    </div>
  )
})
WizardLayout.displayName = 'WizardLayout'

export default WizardLayout
