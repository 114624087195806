import { useFetchDocument, useFetchDocumentList, useMutateDocument } from './documents'
import { useFetchTemplate, useFetchTemplateList } from './templates'

const QUERY_KEYS = {
  DOCUMENTS: 'QUERY_DOCUMENTS',
  DOCUMENT: 'QUERY_DOCUMENT',
  TEMPLATES: 'QUERY_TEMPLATES',
  TEMPLATE: 'QUERY_TEMPLATE',
}

export { QUERY_KEYS }
export { useFetchDocument, useFetchDocumentList, useMutateDocument }
export { useFetchTemplate, useFetchTemplateList }
