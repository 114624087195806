//@ts-ignore
export const debounceFunction: <T extends (...params: any) => void>(
  callback: T,
  timeout?: number
) => T extends (...params: infer P) => void ? (...params: P) => NodeJS.Timeout : undefined = (callback, timeout = 100) => {
  // export const debounceFunction: <T>(callback: (...params: T[]) => void, timeout?: number) => (...params: T) => NodeJS.Timeout = (
  //   callback,
  //   timeout = 100
  // ) => {
  if (typeof callback !== 'function') return
  let debounceTimeout: NodeJS.Timeout
  return (...params) => {
    window.clearTimeout(debounceTimeout)
    debounceTimeout = setTimeout(() => callback(...params), timeout)
    return debounceTimeout
  }
}

//@ts-ignore
export const throttleFunction: <T>(callback: (params?: T) => unknown, interval?: number) => (params?: T) => undefined = (
  callback,
  interval = 100
) => {
  let throttle = false
  return (...params) => {
    if (throttle) return
    throttle = true
    setTimeout(() => {
      callback(...params)
      throttle = false
    }, interval)
  }
}

export const isObject = (object: unknown) => typeof object === 'object' && !Array.isArray(object) && object !== null
export const unnestObjectValues = (object: Record<keyof any, unknown>): unknown[] =>
  Object.values(object).reduce(
    (result: unknown[], value) => result.concat(isObject(value) ? unnestObjectValues(value as Record<keyof any, unknown>) : value),
    []
  )
