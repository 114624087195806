import {
  COMPLEX_GETTER_NOT_FOUND,
  FIND_WITH_INDEX_NOT_FOUND,
  LOCATION_TYPES,
  LocationType,
  Question,
  Questions,
  SegmentsLocation,
  SegmentsLocations,
  TextLocation,
  TextLocations,
  Writable,
} from '___types'
import { WizardState, findWithIndex } from '.'

// ====================================================================================================================================== //
// ============================================================= GENERATORS ============================================================= //
// ====================================================================================================================================== //
//
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ============================================================ GET QUESTION ============================================================ //
// ====================================================================================================================================== //
const getQuestion = (state: WizardState, test: (question: Question) => boolean) => findWithIndex(state.questions || [], test)
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ========================================================= GET QUESTION BY ID ========================================================= //
// ====================================================================================================================================== //
export const getQuestionById = (state: WizardState, id: string): [Question, number] | Writable<typeof FIND_WITH_INDEX_NOT_FOUND> =>
  id ? getQuestion(state, question => question.id === id) : (FIND_WITH_INDEX_NOT_FOUND as Writable<typeof FIND_WITH_INDEX_NOT_FOUND>)
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ========================================================== GET SUBQUESTIONS ========================================================== //
// ====================================================================================================================================== //
export const getSubQuestions = (state: WizardState): Questions => {
  const subQuestionIds = state.questionLayout?.find(group => group.type === 'sub-questions')?.questions
  return (subQuestionIds?.length && state.questions?.filter(question => subQuestionIds.includes(question.id))) || []
}
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ================================================== GET SUB QUESTIONS BY QUESTION ID ================================================== //
// ====================================================================================================================================== //
export const getSubQuestionsByQuestionId = (state: WizardState, questionId: string): Questions => {
  const subQuestions = getSubQuestions(state)
  return subQuestions.filter(question => question.advanced.subQuestionTo.split(':')[0] === questionId)
}
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// =================================================== GET SUB QUESTIONS BY OPTION ID =================================================== //
// ====================================================================================================================================== //
export const getSubQuestionsByOptionId = (state: WizardState, optionId: string): Questions => {
  const subQuestions = getSubQuestions(state)
  return subQuestions.filter(question => question.advanced.subQuestionTo.split(':')[2] === optionId)
}
// ====================================================================================================================================== //
//
//
//
//
//
//
// ====================================================================================================================================== //
// ======================================================== GET MERGED LOCATIONS ======================================================== //
// ====================================================================================================================================== //
type MergedLocationsType = SegmentsLocations & TextLocations
export const getMergedLocations = (state: WizardState): MergedLocationsType =>
  state.locations ? Object.assign({}, state.locations.segments, state.locations.text) : {}
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ============================================================= GET MARKER ============================================================= //
// ====================================================================================================================================== //
export type FoundMarkerType = [SegmentsLocation | TextLocation, string, SegmentsLocation[] | TextLocation[], number]
// ======================================================== GET MARKER: OVERLOAD ======================================================== //
function getMarker(
  state: WizardState,
  testFunction: (marker: SegmentsLocation) => boolean,
  type: typeof LOCATION_TYPES.SEGMENTS
): FoundMarkerType | typeof COMPLEX_GETTER_NOT_FOUND
function getMarker(
  state: WizardState,
  testFunction: (marker: TextLocation) => boolean,
  type: typeof LOCATION_TYPES.TEXT
): FoundMarkerType | typeof COMPLEX_GETTER_NOT_FOUND
function getMarker(
  state: WizardState,
  testFunction: ((marker: SegmentsLocation) => boolean) | ((marker: TextLocation) => boolean),
  type?: LocationType
): FoundMarkerType | typeof COMPLEX_GETTER_NOT_FOUND
// ====================================================================================================================================== //
function getMarker(
  state: WizardState,
  testFunction: ((marker: SegmentsLocation) => boolean) | ((marker: TextLocation) => boolean),
  type?: LocationType,
  result = undefined as never,
  parentId = undefined as never,
  markerArray = undefined as never,
  index = -1 as never
): FoundMarkerType | typeof COMPLEX_GETTER_NOT_FOUND {
  const locations = state.locations ? (type && state.locations[type]) || getMergedLocations(state) : ({} as MergedLocationsType)
  ;(Object.entries(locations) as [string, SegmentsLocation[] | TextLocation[]][]).some(([id, locationArray]) =>
    locationArray.some(
      //@ts-ignore
      (location, i) =>
        testFunction(location as never) && // ============== if the location passes the test function
        (result = location as never) && // ================= set the result to the found location
        (parentId = id as never) && // ===================== set the parent id to the current iterating id
        (markerArray = locationArray as never) && // ======= set the marker array to the current iterating location array
        (index = i as never) // ============================ set the index to the current iterating index
    )
  )
  return [result, parentId, markerArray, index]
}
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ========================================================== GET MARKER BY ID ========================================================== //
// ====================================================================================================================================== //
export const getMarkerById = (state: WizardState, id: string): FoundMarkerType | typeof COMPLEX_GETTER_NOT_FOUND =>
  id ? getMarker(state, (marker: SegmentsLocation | TextLocation) => marker.id === id) : COMPLEX_GETTER_NOT_FOUND
// ====================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ===================================================== GET MARKER BY TYPE AND ID ===================================================== //
// ===================================================================================================================================== //
type TestFunctionMarkerParameterType<T> = T extends typeof LOCATION_TYPES.SEGMENTS ? SegmentsLocation : TextLocation
// ================================================ GET MARKER BY TYPE AND ID: OVERLOAD ================================================ //
export function getMarkerByTypeAndId(
  state: WizardState,
  type: typeof LOCATION_TYPES.SEGMENTS,
  id: string
): [SegmentsLocation, string, SegmentsLocation[], number] | typeof COMPLEX_GETTER_NOT_FOUND
export function getMarkerByTypeAndId(
  state: WizardState,
  type: typeof LOCATION_TYPES.TEXT,
  id: string
): [TextLocation, string, TextLocation[], number] | typeof COMPLEX_GETTER_NOT_FOUND
export function getMarkerByTypeAndId(state: WizardState, type: LocationType, id: string): FoundMarkerType | typeof COMPLEX_GETTER_NOT_FOUND
// ====================================================================================================================================== //
// ====================================================================================================================================== //
// ====================================================================================================================================== //
export function getMarkerByTypeAndId(state: WizardState, type: LocationType, id: string): FoundMarkerType | typeof COMPLEX_GETTER_NOT_FOUND {
  return type && id ? getMarker(state, (marker: TestFunctionMarkerParameterType<typeof type>) => marker.id === id, type) : COMPLEX_GETTER_NOT_FOUND
}
// ===================================================================================================================================== //
//
//
//
//
//
//
//
//
//
//
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ========================================================= REDUCER FUNCTIONS ========================================================= //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
