import { Thumbnail as DocumentThumbnail } from './Document.Thumbnail'

import './style.scss'

const classes = {
  thumbnail: {
    wrapper: 'Document-Thumbnail-wrapper',
    selectButton: 'Document-Thumbnail-selectButton',
    sideActions: {
      wrapper: 'Document-Thumbnail-sideActions-wrapper',
      button: 'Document-Thumbnail-sideActions-button',
      more: {
        wrapper: 'Document-Thumbnail-sideActions-dropdown-wrapper',
        button: 'Document-Thumbnail-sideActions-dropdown-button',
        dropdownList: 'Document-Thumbnail-sideActions-dropdown-dropdownList',
        dropdownListItem: 'Document-Thumbnail-sideActions-dropdown-dropdownListItem',
        dropdownListItemDivider: 'Document-Thumbnail-sideActions-dropdown-dropdownListItem-divider',
      },
    },
    preview: 'Document-Thumbnail-preview',
    base: {
      wrapper: 'Document-Thumbnail-base-wrapper',
      title: 'Document-Thumbnail-base-title',
      relativeTime: 'Document-Thumbnail-base-relativeTime',
      signbar: { wrapper: 'Document-Thumbnail-base-signbar-wrapper', progress: 'Document-Thumbnail-base-signbar-progress' },
    },
  },
}


export { DocumentThumbnail, classes }

