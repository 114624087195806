import { IPopupMenuItem } from 'components/common/MenuPopover/MenuPopover.types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router'

import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'

import ViewListIcon from '@material-ui/icons/ViewList'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import { AddFolder, Download, Edit, Settings, Trash, Upload } from 'components/common/Icons/Icons.styles'

import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { useUser } from 'queries/user/useUser'
import { useDocumentsUpdate, useLoadDocuments } from 'queries/documents'
import { useDocumentFileActions } from 'hooks/useDocumentFileActions/useDocumentFileActions'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'
import { useConfirmPopup } from 'providers/ConfirmPopupProvider'

import Container from 'components/files/components/Container/Container'
import DocumentWithMenu from 'components/files/File/FileWithMenu'
import LoadMoreButton from 'components/files/components/LoadMoreButton/LoadMoreButton'
import NoDocs from 'components/common/NoItemsAction/NoItemsAction'
import doc_png from 'assets/illustrations/aktenschrank.svg'
import TrashEmpty from 'components/files/components/TrashEmpty/TrashEmpty'
import { POPUP_CONFIRM_DELETE_FILE, POPUP_CONFIRM_TRASH_FILE } from 'constants/confirmation-popups'
import { isEditorIsOwner, toBase64 } from 'utils'
import moment from 'moment'
import dummyPreview from '../../../../../assets/icons/dummyPreview.svg'
import loaderAnimation from '../../../../../assets/icons/loader.svg'

import './style.scss'
import { useDocumentsDuplicate } from 'queries/documents/useDocumentDuplicate'
import TableContainer from 'components/files/components/TableContainer/TableContainer'
import { FilePicker, MenuPopover } from 'components/common'
import { useTranslation } from 'react-i18next'
import { useDetails } from 'components/DetailsSidebar/provider/DetailsProvider'
import { useToast } from 'hooks/useToast'
import { DocsUploadApi } from 'api/docs.api'
import { useQueryClient } from 'react-query'
import { queryKeys } from 'queries'

const DocumentsList: React.FC = () => {
  const [view, setView] = useState('card')
  // const queryClient = useQueryClient()
  const { currentFolder } = useCurrentFolder('docs')
  const { type, children, id } = currentFolder

  const queryClient = useQueryClient()

  const toast = useToast()

  const { selectedId, select } = useDetails()

  const { fetchNextPage, data, hasNextPage, isLoading, isFetchingNextPage } = useLoadDocuments(currentFolder, { enabled: false })
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [file, setFile] = useState<null | File>(null)

  const [selectedMenuItem, setSelectedMenuItem] = useState<IPopupMenuItem[] | []>([])
  const update = useDocumentsUpdate()
  const duplicate = useDocumentsDuplicate()
  const user = useUser()!
  // const superadmins = useMasterData()?.superadmins ?? []
  const { onEdit, onOpen, onDownload } = useDocumentFileActions()
  const { popup, close, setIsLoading } = useConfirmPopup()
  const { push, location } = useHistory()
  const { check } = useSubscriptionCheck()
  const [renameId, setRenameId] = useState<null | string>(null)
  const { t } = useTranslation()

  const uploadFile = useCallback(
    async (file: File) => {
      try {
        toast('info', 'Ihr Dokument wird hochgeladen. Einen Moment bitte...')
        const base64data = await toBase64(file)
        await DocsUploadApi.uploadPDF({
          category: id === type ? [] : [id],
          filename: file.name ?? '',
          base64data,
        })
        queryClient.invalidateQueries([queryKeys.DOCUMENTS, { type, folderId: id }])
        toast('success', 'Ihr Dokument wurde hochgeladen.')
      } catch (err) {
        toast('error', 'default')
      } finally {
      }
    },
    [id, type, toast, queryClient]
  )

  useEffect(() => {
    if (file) {
      uploadFile(file)
      setFile(null)
    }
  }, [file, uploadFile])

  const filePickRef = useRef<HTMLButtonElement>(null)

  const noDocsProps = {
    text: t('no_documents'),
    actionText: t('new_document'),
    heading: t('u_documents'),
    actionBtn: t('new_document'),
    image: doc_png,
  }

  // const isSuperadmin = superadmins.includes(user.email)

  const documentFiles = data?.pages.flat(1) ?? []

  const deleteDocument = useCallback(
    (id: string) => {
      const status = type === 'trashed' ? 'deleted' : 'trashed'
      const POPUP = type === 'trashed' ? POPUP_CONFIRM_DELETE_FILE : POPUP_CONFIRM_TRASH_FILE

      popup({
        ...POPUP,
        onConfirm: () => {
          setIsLoading(true)
          update.mutate({ id: id, data: { status } }, { onSuccess: close, onSettled: () => setIsLoading(false) })
        },
        onCancel: close,
      })
    },
    [close, popup, setIsLoading, type, update]
  )

  const getMenuItems = useCallback(
    (doc: any) => {
      const { id, authorId, sharedWith } = doc

      const { isOwner, isEditor } = isEditorIsOwner(user?.id, authorId, sharedWith)

      const menuItems: IPopupMenuItem[] = []

      if (type !== 'trashed') {
        menuItems.push({
          icon: 'preview',
          label: t('preview'),
          handleClick: () => onOpen(id),
        })
      }

      if (type !== 'trashed' && (isEditor || isOwner) && doc.type !== 'pdf' && !doc.signatureRequest) {
        menuItems.push({
          icon: 'edit',
          label: t('edit'),
          handleClick: () => {
            if (check()) onEdit(doc)
          },
        })
      }

      menuItems.push({
        icon: 'reminder',
        label: t('reminders'),
        handleClick: () => {
          if (check({ isFree: true })) push(`${location.pathname}?entity=${id}&action=mahnung`)
        },
      })

      if (type === 'my' && isOwner) {
        menuItems.push({
          icon: 'move',
          label: t('move_document'),
          handleClick: () => {
            if (check({ isFree: true })) push(`${location.pathname}?file=${id}&action=move`)
          },
        })

        if (type === 'my') {
          menuItems.push({
            icon: 'share',
            label: t('share'),
            handleClick: () => {
              console.info('sharing doc')
              if (check({ isFree: true, isBasic: true, isStartup: true })) push(`${location.pathname}?file=${id}&action=share`)
            },
          })
        }
      }

      menuItems.push({
        icon: 'download',
        label: t('download'),
        handleClick: () => {},
        subItems: [
          {
            icon: 'docx',
            label: 'DOCX',
            handleClick: () => onDownload(id, 'docx'),
          },
        ],
      })

      if (type !== 'trashed' && isEditor && view === 'card')
        menuItems.push({
          icon: 'rename',
          label: t('rename'),
          handleClick: () => {
            if (check()) setRenameId(id)
          },
        })

      menuItems.push({
        icon: 'duplicate',
        label: t('duplicate'),
        handleClick: () => {
          if (check()) duplicate.mutate(doc)
        },
      })

      if (type === 'trashed' && isOwner) {
        menuItems.push({
          icon: 'restore',
          label: t('restore'),
          handleClick: () => {
            if (check()) update.mutate({ id: id, data: { status: 'new' } })
          },
        })
      }

      if (type !== 'shared' && isOwner)
        menuItems.push({
          icon: 'trash',
          label: t('delete'),
          handleClick: () => deleteDocument(id),
        })

      return menuItems
    },
    [deleteDocument, update, location.pathname, check, duplicate, onDownload, onEdit, onOpen, push, type, user, view, t]
  )

  const getTypeIcon = useCallback((doc: any) => {
    if (doc.isLoading) {
      return (
        <div>
          <img src={loaderAnimation} alt="" className="documentRow-fileType" />
        </div>
      )
    } else {
      return (
        <div>
          <img src={dummyPreview} alt="" className="documentRow-fileType" />
        </div>
      )
    }
  }, [])

  const generateActions = useCallback(
    (doc: any) => {
      const leftClick = (e: any) => {
        if (!isLoading) {
          const thisDocMenuItem = getMenuItems(doc)
          setSelectedMenuItem(thisDocMenuItem)
          setAnchorEl(e.currentTarget)
        }
      }
      return (
        <div key={doc.id} className="documentRow-actionsWrapper">
          <button
            type="button"
            className="documentRow-actionButton"
            onClick={() => onDownload(doc.id, 'docx')}
            onKeyDown={event => {
              if (event.key === 'ENTER') onDownload(doc.id, 'docx')
            }}
          >
            <Download />
          </button>
          <button
            type="button"
            className="documentRow-actionButton"
            disabled={!!doc.signatureRequest}
            onClick={() => onEdit(doc)}
            onKeyDown={event => {
              if (event.key === 'ENTER') onEdit(doc)
            }}
          >
            <Edit />
          </button>
          <button
            type="button"
            className="documentRow-actionButton"
            onClick={() => deleteDocument(doc.id)}
            onKeyDown={event => {
              if (event.key === 'ENTER') deleteDocument(doc.id)
            }}
          >
            <Trash />
          </button>
          <button
            type="button"
            className="documentRow-actionButton"
            onClick={(event: React.MouseEvent<HTMLElement>) => leftClick(event)}
            onKeyDown={event => {
              if (event.key === 'ENTER') leftClick(event)
            }}
          >
            <Settings />
          </button>
        </div>
      )
    },
    [deleteDocument, onEdit, onDownload, getMenuItems, isLoading]
  )

  const render = useMemo(() => {
    switch (view) {
      case 'card':
        return (
          <Container>
            {documentFiles?.map(docItem => {
              const { id, authorId, sharedWith } = docItem

              const { isOwner, isEditor } = isEditorIsOwner(user?.id, authorId, sharedWith)

              const isShared = docItem.sharedWith?.some(sh => sh.id === user?.id) || (isOwner && !!docItem.sharedWith.length)

              const updateName = (docId: string = id, newName: string) => {
                setRenameId(null)
                if (newName !== docItem.name) update.mutate({ id: docId, data: { name: newName } })
              }

              const menuItems: IPopupMenuItem[] = []

              if (type !== 'trashed') {
                menuItems.push({
                  icon: 'preview',
                  label: t('preview'),
                  handleClick: () => onOpen(id),
                })
              }

              if (type !== 'trashed' && (isEditor || isOwner) && docItem.type !== 'pdf' && !docItem.signatureRequest) {
                menuItems.push({
                  icon: 'edit',
                  label: t('edit'),
                  handleClick: () => {
                    if (check()) onEdit(docItem)
                  },
                })
              }

              menuItems.push({
                icon: 'reminder',
                label: t('reminders'),
                handleClick: () => {
                  if (check({ isFree: true })) push(`${location.pathname}?entity=${id}&action=mahnung`)
                },
              })

              if (type === 'my' && isOwner) {
                menuItems.push({
                  icon: 'move',
                  label: t('move_document'),
                  handleClick: () => {
                    if (check({ isFree: true })) push(`${location.pathname}?file=${id}&action=move`)
                  },
                })

                if (type === 'my') {
                  menuItems.push({
                    icon: 'share',
                    label: t('share'),
                    handleClick: () => {
                      console.info('sharing doc')
                      if (check({ isFree: true, isBasic: true, isStartup: true })) push(`${location.pathname}?file=${id}&action=share`)
                    },
                  })
                }
              }

              menuItems.push({
                icon: 'download',
                label: t('download'),
                handleClick: () => {},
                subItems: [
                  {
                    icon: 'docx',
                    label: 'DOCX',
                    handleClick: () => onDownload(id, 'docx'),
                  },
                ],
              })

              if (type !== 'trashed' && isEditor)
                menuItems.push({
                  icon: 'rename',
                  label: t('rename'),
                  handleClick: () => {
                    if (check()) setRenameId(id)
                  },
                })

              menuItems.push({
                icon: 'duplicate',
                label: t('duplicate'),
                handleClick: () => {
                  if (check()) duplicate.mutate(docItem)
                },
              })

              if (type === 'trashed' && isOwner) {
                menuItems.push({
                  icon: 'restore',
                  label: t('restore'),
                  handleClick: () => {
                    if (check()) update.mutate({ id: id, data: { status: 'new' } })
                  },
                })
              }

              if (type !== 'shared' && isOwner)
                menuItems.push({
                  icon: 'trash',
                  label: t('delete'),
                  handleClick: () => deleteDocument(id),
                })

              return (
                <DocumentWithMenu
                  key={id}
                  document={docItem}
                  menuItems={menuItems}
                  renameActive={renameId === id}
                  isShared={!!isShared || !!(isOwner && sharedWith.length && type === 'my')}
                  currentFolderType={type}
                  handleLeftClick={() => select(id)}
                  handleDoubleClick={() => onOpen(id)}
                  handleUpdateName={updateName}
                  isDownloadable={true}
                  isLoading={docItem.isLoading}
                  isSelected={selectedId === id}
                />
              )
            })}
          </Container>
        )
      case 'table': {
        const createColumn = (id?: any, label?: any, minWidth?: any, align?: any) => ({ id, label, minWidth, align })
        const columns = [
          createColumn('type', 'Type', 30, 'left'),
          createColumn('name', 'Name', 250, 'left'),
          createColumn('template', 'Template', 250, 'left'),
          createColumn('modified', 'Last modified', 150, 'right'),
          createColumn('actions', 'Actions', 200, 'right'),
        ]
        const createRow = (id: String, name?: any, template?: any, modified?: any, actions?: any, type?: any) => ({
          type,
          id,
          name,
          template,
          modified,
          actions,
        })
        const rows = documentFiles.map(d => {
          return createRow(d.id, d.name, d.templateName, moment.unix(d.edited?._seconds).fromNow(), generateActions(d), getTypeIcon(d))
        })
        return (
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map(c => (
                    <TableCell key={c.id} align={c.align} style={{ minWidth: c.minWidth }}>
                      {c.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((r: any) => {
                  return (
                    <TableRow key={r.id}>
                      {columns.map(c => {
                        const key = String(c.id)
                        const value = r[key]

                        return (
                          <TableCell key={`${r.id}-${c.id}`} align={c.align} style={{ minWidth: c.minWidth }}>
                            {value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
                <MenuPopover
                  anchorOrigin={{
                    vertical: 20,
                    horizontal: 'left',
                  }}
                  menuItems={selectedMenuItem}
                  handleClose={() => setAnchorEl(null)}
                  anchorEl={anchorEl}
                />
              </TableBody>
            </Table>
          </TableContainer>
        )
      }
      default:
        return null
    }
  }, [
    view,
    generateActions,
    check,
    deleteDocument,
    select,
    selectedId,
    duplicate,
    documentFiles,
    location.pathname,
    onDownload,
    onEdit,
    onOpen,
    push,
    renameId,
    type,
    update,
    user,
    selectedMenuItem,
    getTypeIcon,
    anchorEl,
    t,
  ])

  if (type === 'trashed' && !documentFiles.length && !children.length) {
    return <TrashEmpty />
  }

  if (!documentFiles.length && !children.length) {
    return (
      <NoDocs
        {...noDocsProps}
        handleActionClick={
          type === 'my'
            ? () => {
                if (check()) push(`/${t('document')}/${t('new')}`)
              }
            : undefined
        }
      />
    )
  }

  // const onDuplicate = async (id: string) => {
  //   await DocsApi.duplicate(id)
  //   queryClient.invalidateQueries([
  //     queryKeys.DOCUMENTS,
  //     {type, folderId: currentFolder.id },
  //   ])
  // }

  return (
    <>
      <div className="view-buttons-wrapper">
        <button type="button" onClick={() => filePickRef.current?.click()} onKeyDown={event => event.key === 'ENTER' && setView('card')}>
          <Upload />
        </button>
        <button
          type="button"
          onClick={() => push(`${location.pathname}?createFolder=true`)}
          onKeyDown={event => event.key === 'ENTER' && setView('card')}
        >
          <AddFolder />
        </button>
        <button
          className={(view === 'card' && 'active') || ''}
          type="button"
          onClick={() => setView('card')}
          onKeyDown={event => event.key === 'ENTER' && setView('card')}
        >
          <InsertDriveFileIcon />
        </button>
        <button
          className={(view === 'table' && 'active') || ''}
          type="button"
          onClick={() => setView('table')}
          onKeyDown={event => event.key === 'ENTER' && setView('table')}
        >
          <ViewListIcon />
        </button>
      </div>
      {render}
      {hasNextPage && <LoadMoreButton text={t('show_more')} isLoading={isLoading || isFetchingNextPage} onClick={fetchNextPage} />}
      <FilePicker accept="application/pdf" setFile={setFile}>
        <button style={{ position: 'absolute', visibility: 'hidden' }} ref={filePickRef}>
          dasdasd
        </button>
      </FilePicker>
    </>
  )
}

export default DocumentsList
