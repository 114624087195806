import React, { FunctionComponent, PropsWithChildren, useContext } from 'react'
import { WizardDataStructureSelector, WizardLocationsSelector, WizardNumberingSelector } from '___store'

import Preview from './Preview'

import './style.scss'

// ======================================================================================================= //
// ============================================ TABLE CONTEXT ============================================ //
// ======================================================================================================= //
type TableContextType = { id: string }
export const TableContext = React.createContext<TableContextType>({} as TableContextType)
type TableContextProviderProps = PropsWithChildren<TableContextType>
export const TableContextProvider: FunctionComponent<TableContextProviderProps> = ({ id, children }) => {
  return <TableContext.Provider value={{ id }}>{children}</TableContext.Provider>
}
export const useTableContext = () => {
  const currentTableContext = useContext(TableContext)
  if (!currentTableContext) {
    throw new Error('useTableContext has to be used within <TableContext.Provider>')
  }
  return currentTableContext
}
// ======================================================================================================= //
//
//
//
type SectionContextType = { index: number }
export const SectionContext = React.createContext<SectionContextType>({} as SectionContextType)
type SectionContextProviderProps = PropsWithChildren<SectionContextType>
export const SectionContextProvider: FunctionComponent<SectionContextProviderProps> = ({ index, children }) => {
  return <SectionContext.Provider value={{ index }}>{children}</SectionContext.Provider>
}
export const useSectionContext = () => {
  const currentSectionContext = useContext(SectionContext)
  if (!currentSectionContext) {
    throw new Error('useSectionContext has to be used within <SectionContext.Provider>')
  }
  return currentSectionContext
}
// ======================================================================================================= //
//
//
//
// ======================================================================================================== //
// ======================================== EDITOR PREVIEW CONTEXT ======================================== //
// ======================================================================================================== //
type EditorPreviewContextType = {
  dataStructure?: WizardDataStructureSelector
  numbering?: WizardNumberingSelector
  locations?: WizardLocationsSelector
}
export const EditorPreviewContext = React.createContext<EditorPreviewContextType>({} as EditorPreviewContextType)
type EditorPreviewContextProviderProps = PropsWithChildren<EditorPreviewContextType>
export const EditorPreviewContextProvider: FunctionComponent<EditorPreviewContextProviderProps> = ({
  dataStructure,
  numbering,
  locations,
  children,
}) => {
  return <EditorPreviewContext.Provider value={{ dataStructure, numbering, locations }}>{children}</EditorPreviewContext.Provider>
}
export const useEditorPreviewContext = () => {
  const currentEditorPreviewContext = useContext(EditorPreviewContext)
  if (!currentEditorPreviewContext) {
    throw new Error('useEditorPreviewContext has to be used within <EditorPreviewContext.Provider>')
  }
  return currentEditorPreviewContext
}
// ======================================================================================================== //

export { Preview }
