import React, { FunctionComponent, ReactElement, useMemo } from 'react'
import useStore, { WizardQuestionByIdSelector, NavigateQuestionnaireForwardAction, NavigateQuestionnaireBackwardAction } from '___store'

import {
  OPTION_GROUP_SELECT,
  OptionGroup as OptionGroupType,
  Question,
  OptionGroupSelectUnionType,
  CUSTOM_TEXT_SPLIT,
  extractPropertiesFromCustomText,
} from '___types'
import { Button, Link } from 'components/CasusComponents'
import { OptionGroup, QuestionContextProvider } from '.'
import {
  WizardLayoutLeftPaneQuestionnaireQuestionProps,
  WizardLayoutLeftPaneQuestionnaireQuestionDetailsProps,
  wizardLayoutLeftPaneQuestionnaireQuestionClasses as classes,
} from '../../..'

const QuestionnaireQuestionDetails: FunctionComponent<WizardLayoutLeftPaneQuestionnaireQuestionDetailsProps> = React.memo(({ text, details }) => {
  const { description = '', hint = '' } = details || {}
  const parsedDescription = useMemo(
    () =>
      description.split(CUSTOM_TEXT_SPLIT).reduce((resultingDescription, string, i) => {
        const properties = extractPropertiesFromCustomText(string, 'casusLink')
        if (!Object.keys(properties).length) return resultingDescription.concat(string)
        return resultingDescription.concat((<Link key={`Question-description-link-${i}`} properties={properties} />) as unknown as HTMLLinkElement)
      }, [] as (string | HTMLLinkElement)[]),
    [description]
  )
  return (
    <div className={classes.details.wrapper}>
      <span className={classes.details.text}>{text}</span>
      <span className={classes.details.description}>{parsedDescription}</span>
      <span className={classes.details.hint}>{hint}</span>
    </div>
  )
})

type UseStoreHookResultType = {
  wizardQuestionById: WizardQuestionByIdSelector
  navigateQuestionnaireForward: NavigateQuestionnaireForwardAction
  navigateQuestionnaireBackward: NavigateQuestionnaireBackwardAction
}

const QuestionnaireQuestion: FunctionComponent<WizardLayoutLeftPaneQuestionnaireQuestionProps> = React.memo(
  ({ id, isLoading, isFetching, orderString }) => {
    const {
      wizardQuestionById: question,
      navigateQuestionnaireForward,
      navigateQuestionnaireBackward,
    } = useStore(`selectWizardQuestionById[${id}]`, 'navigateQuestionnaireForward', 'navigateQuestionnaireBackward') as UseStoreHookResultType
    // @ts-ignore
    const { text = '', description = '', hint = '', optionGroups = [] } = question || {} // TODO: remove this line, and uncomment the line below, when done with template-automation rework
    // const { text = '', questionDetails: { description = '', example = '', hint = '' } = {} } = question || {}

    const groups = useMemo(() => {
      if (!optionGroups?.length)
        return (
          <OptionGroup optionGroup={{ options: new Array(3).fill(null) } as OptionGroupType} singleGroup={true} indexOffset={0} firstGroup={false} />
        )
      return (
        optionGroups.reduce(
          ([result, indexOffset], group, i, array) => {
            const { id } = group
            const res = result.concat(
              <OptionGroup key={id} optionGroup={group} singleGroup={array.length === 1} indexOffset={indexOffset} firstGroup={i === 0} />
            )
            return [res, group.options.length]
          },
          [[], 0] as [ReactElement[], number]
        ) as [ReactElement[], number]
      )[0]
    }, [optionGroups])

    const isSingleUserInputOption = useMemo(
      () =>
        optionGroups.length === 1 &&
        optionGroups[0].options.length === 1 &&
        (optionGroups[0].select as unknown as OptionGroupSelectUnionType) === OPTION_GROUP_SELECT.SINGLE && // remove type conversion when select is fixed to be an object instead of a OptionGroupSelectUnionType string and change the prop to be {select.select}
        optionGroups[0].options[0].value === '',
      [optionGroups]
    )

    return (
      <div className={classes.wrapper} data-order={orderString} data-loading={isLoading || isFetching || undefined}>
        <QuestionnaireQuestionDetails text={text} details={{ description, hint }} />
        <QuestionContextProvider question={question || ({} as Question)}>
          <div className={classes.optionGroups.wrapper}>{groups}</div>
        </QuestionContextProvider>
        {!isLoading && !isFetching && !isSingleUserInputOption ? (
          <div className={classes.actions.wrapper}>
            <Button tertiary onClick={() => navigateQuestionnaireBackward()}>
              Previous
            </Button>
            <Button tertiary onClick={() => navigateQuestionnaireForward()}>
              Next
            </Button>
          </div>
        ) : null}
      </div>
    )
  }
)

QuestionnaireQuestion.displayName = 'WizardLayout-Questionnaire-QuestionGroup-Question'

export default QuestionnaireQuestion
