import React, { FunctionComponent } from 'react'

import { Spinner } from 'assets/svgIconComponents'
import { GridProps, classes } from '.'

export const Grid: FunctionComponent<GridProps> = React.memo(({ className: cn, isLoading, isFetching, error, title, children }) => {
  const className = [classes.wrapper, cn].filter(s => s).join(' ')
  return (
    <div
      className={className}
      data-loading={isLoading || undefined}
      data-fetching={isFetching || undefined}
      data-error={Boolean(error) || undefined}
      data-title={title || undefined}
    >
      {isLoading ? <Spinner strokeWidth={7} /> : null}
      {!error ? children : <span className={classes.error}>{error}</span>}
    </div>
  )
})

Grid.displayName = 'Casus-Components-Grid'

export default Grid
