import React from 'react'
import { useTranslation } from 'react-i18next'
import { Switch, Route } from 'react-router-dom'
import { PdfPreviewPage } from './view/PdfPreview/PdfPreviewPage'

const Pdf: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Switch>
      <Route exact path={`/${t('pdf')}/:id`} component={PdfPreviewPage} />
    </Switch>
  )
}

export default Pdf
