import React, { Fragment, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useTemplateCreation } from 'pages/Authenticated/Template/views/TemplateEdit/provider/CreateTemplateProvider'
import { useToast } from 'hooks/useToast'
import { useEditor } from 'config/textEditor/useEditor'

import { CTContainer } from './TemplateEdit.styles'
import Layout from '../../layout/Layout'
import { FooterNavigation } from 'components/features'
import { QuestionsPreview } from './components/QuestionsPreview/QuestionsPreview'
// import UploadContainer from './UploadContainer'
import QuestionCreation from './components/QuestionCreation/QuestionCreation'
import LastStepContainer from './components/LastStep'
import * as content from 'pages/Authenticated/Template/constants/content'

import { ERR_MSG_DEFAULT } from 'constants/messages'
import { StorageService } from 'utils/storageService'
import { useConfirmPopup } from 'providers/ConfirmPopupProvider'
import {
  POPUP_ON_TEMPLATE_EDIT_FINISH_WQ,
  POPUP_ON_TEMPLATE_EDIT_CLOSE,
} from '../../constants/confirm-text'
import { useUser } from 'queries/user/useUser'
import { useTranslation } from 'react-i18next'

const TemplateEdit: React.FC = () => {
  const { push } = useHistory()
  const [loading, setLoading] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const { popup, close, setIsLoading } = useConfirmPopup()
  const toast = useToast()
  const editor = useEditor()
  const user = useUser()
  const docXmicro = !!user?.beta?.newDocxMicroservice
  const {t} = useTranslation()

  const {
    set,
    save,
    isTemplateEdited,
    step,
    showQuestionsPreview,
    filteredQuestions,
    htmlData,
    questions,
    hyphens,
    name,
    question,
  } = useTemplateCreation()

  useEffect(() => {
    setIsSaved(false)
  }, [htmlData, questions, hyphens, name])

  const load = (val: boolean) => {
    setLoading(val)
    setIsLoading(val)
  }

  const onFinishSave = () => {
    const isEdited = isTemplateEdited(htmlData)
    if (isEdited) {
      load(true)
      if (!docXmicro) save(status => {
        if (status.success) {
          const payload = { step: 3 } as any
          if (!docXmicro) payload.htmlData = htmlData
          set(payload)
          setIsSaved(true)
          StorageService.remove('textSelectionHtmlData')
        }
        if (status.error) {
          toast('error', ERR_MSG_DEFAULT)
        }
        load(false)
      })
      else {
        save(status => {
          if (status.success) {
            const payload = { step: 3 } as any
            if (!docXmicro) payload.htmlData = htmlData
            set(payload)
            setIsSaved(true)
            StorageService.remove('textSelectionHtmlData')
          }
          if (status.error) {
            toast('error', ERR_MSG_DEFAULT)
          }
          load(false)
        }, { questions: questions })
      }
    } else {
      const payload = { step: 3 } as any
      if (!docXmicro) payload.htmlData = htmlData
      set(payload)
      setIsSaved(true)
    }
    close()
  }

  const onCloseSave = () => {
    if (loading) return
    const theHTML = question ? htmlData : editor?.getBody()?.innerHTML
    const providedData = theHTML ? { htmlData: theHTML } : {}
    const isEdited = isTemplateEdited(htmlData)
    if (!isEdited) {
      push(`/${t('templates')}`)
      close()
      return
    }
    load(true)
    save(status => {
      load(false)
      if (status.success) {
        StorageService.remove('templateCreationState')
        StorageService.remove('textSelectionHtmlData')
        close()
        return push(`/${t('templates')}`)
      }
      if (status.error) toast('error', ERR_MSG_DEFAULT)
    }, providedData)
  }

  const onFinish = () => {
    if (loading) return
    if (showQuestionsPreview)
      return set({
        showQuestionsPreview: !showQuestionsPreview,
      })

    if (question) {
      popup({
        ...POPUP_ON_TEMPLATE_EDIT_FINISH_WQ,
        onConfirm: onFinishSave,
        onCancel: close,
      })
    } else {
      onFinishSave()
    }
    StorageService.remove('textSelectionHtmlData')
  }

  const onClose = () => {
    if (loading) return
    const isEdited = isTemplateEdited(htmlData)
    if ((isSaved || !isEdited) && !question) return push(`/${t('templates')}`)

    const POPUP_CONTENT = question
      ? POPUP_ON_TEMPLATE_EDIT_FINISH_WQ
      : POPUP_ON_TEMPLATE_EDIT_CLOSE

    popup({
      ...POPUP_CONTENT,
      onConfirm: onCloseSave,
      onCancel: () => {
        if (!question) push(`/${t('templates')}`)
        close()
      },
      buttonWidth: question ? undefined : 250,
    })
  }

  const onBack = () => {
    if (step === 3) {
      setIsSaved(false)
      return set({ step: 2 })
    }
    onClose()
  }

  const renderSteps = () => {
    switch (step) {
      case 1:
      case 2:
        return (
          <Fragment>
            {/* <UploadContainer /> */}
            <QuestionCreation />
          </Fragment>
        )
      case 3:
        return <LastStepContainer />
      default:
        return null
    }
  }

  const renderContent = () => {
    if (showQuestionsPreview) {
      return (
        <QuestionsPreview
          onClose={() => set({ showQuestionsPreview: !showQuestionsPreview })}
          questions={filteredQuestions}
        />
      )
    } else {
      return renderSteps()
    }
  }

  return (
    <Layout
      step={step}
      onClose={onClose}
      heading={loading ? "changes_being_saved" : content.heading}
    >
      <CTContainer>{renderContent()}</CTContainer>
      <FooterNavigation
        handleBackClick={onBack}
        handleForwardClick={onFinish}
        isLoading={loading}
      />
    </Layout>
  )
}

export default TemplateEdit
