import { v4 as uuid } from 'uuid'
import ACTION_TYPES from '___store/actionTypes'
import { getLogArray } from '___store/helpers'
import { getStateQuestionLayoutGroup } from './helpers'
import { getSegmentById } from './helpers/editor-content'
import {
  getContentParentIdByMarkerId,
  // getQuestionById
} from './helpers/template-creation'
import { CASUS_IDS } from 'Wizard/constants'
import { unnestObjectValues } from 'utilities/helpers'

const intercept = action => {
  const { type, payload } = action
  console.groupCollapsed(...getLogArray('intercept', type))
  Object.entries(payload).forEach(([key, value]) => {
    console.log(`${key}: `, value)
  })
  console.groupEnd()
}

const getQuestion = (store, id) => (store.select([`selectQuestionById[${id}]`]) || {})[`questionById[${id}]`]

// const getUnassignedMarkers = q => {
//   const assignedMarkers = Array.from(q?.options?.reduce((acc, o) => o.markers?.forEach(id => acc.add(id)) || acc, new Set()) || [])
//   return q?.markers?.filter(id => !assignedMarkers.includes(id)) || []
// }

// const getCurrentConfiguringQuestion = store => {
//   const { configStackKeys: keyids = '', questions = [] } = store.select(['selectConfigStackKeys', 'selectQuestions'])
//   const configuringQuestionId = (
//     keyids
//       .split('; ')
//       .reverse()
//       .find(keyid => keyid.split(':')[0] === 'question') || ''
//   ).split(':')[1]
//   return questions.find(q => q.id === configuringQuestionId)
// }

const findMarkerById = (state, type, id, res = undefined) =>
  Object.values(state.locations[type]).find(markerArray => markerArray.find(marker => id === marker.id && (res = marker))) && res
const idMatchRegex = /^([^:\n]+\/)?((section)?[^:\n/]*)(:(s)?(e)?)?$/
const mapSegmentId = id => {
  const idMatch = id.match(idMatchRegex)?.slice(2) || []
  return idMatch[1] ? 'root' : idMatch[0]
}
const mapSegmentsLocationRange = (
  state,
  range,
  id = undefined // need to account for table header/body/footer offsets
) =>
  range.map((limit, i) => {
    return typeof limit === 'number'
      ? limit
      : (id = mapSegmentId(limit)) &&
          (findMarkerById(state, 'segments', id)?.range || new Array(2).fill((getSegmentById(state, id) || []).slice(3)[0]).map((v, i) => v + i))[i]
  })

const popUpModalActionInterceptors = unnestObjectValues(ACTION_TYPES.WIZARD)
  .filter(at => at?.slice(0, 5) !== 'POPUP')
  .reduce(
    (acc, cur) =>
      Object.assign(acc, {
        [cur]: {
          check: store => store.select(['selectPopup'])?.selectPopup,
          method: (store, next, action) => intercept(action),
        },
      }),
    {}
  )

const interceptors = Object.assign(popUpModalActionInterceptors, {
  [ACTION_TYPES.BATCH_ACTIONS]: {
    check: () => true,
    method: (store, next, action) => {
      const { type, actionArray } = action
      console.groupCollapsed(...getLogArray('intercept', type))
      actionArray.forEach(({ type, payload }) => {
        console.log(`${type}: `, payload)
      })
      console.groupEnd()
      actionArray.forEach(action => store.dispatch(action))
    },
  },
  [ACTION_TYPES.WIZARD.CONFIGURING_SET]: {
    check: (store, payload) => {
      const { id, key } = store.select(['selectConfiguring'])?.configuring || {}
      return payload.id === id && payload.key === key
    },
    method: (store, next, action) => intercept(action),
  },
  [ACTION_TYPES.WIZARD.QUESTION_LAYOUT_GROUP_REMOVED]: {
    check: () => true,
    method: (store, next, action) => {
      const { payload } = action
      const { id } = payload
      const [questionLayoutGroup, index] = getStateQuestionLayoutGroup(store.getState()?.wizard, id)
      if (index === -1) return intercept(action)
      next(action)
      const { questionIds = [] } = questionLayoutGroup
      const questionCount = questionIds.length
      if (questionCount) {
        if (questionCount === 1) {
          const questionRemovedAction = { type: ACTION_TYPES.WIZARD.QUESTION_REMOVED, payload: { id: questionIds[0] } }
          store.dispatch(questionRemovedAction)
        }
      } else {
        const actionArray = []
        const batchActions = { type: ACTION_TYPES.BATCH_ACTIONS, actionArray }
        questionIds.forEach(qid => actionArray.push({ type: ACTION_TYPES.WIZARD.QUESTION_REMOVED, payload: { id: qid } }))
        store.dispatch(batchActions)
      }
    },
  },
  [ACTION_TYPES.WIZARD.NEW_QUESTION_ADDED]: {
    check: () => true,
    method: (store, next, action) => {
      if (!action.payload) Object.assign(action, { payload: { id: uuid() } })
      if (!action.payload.id) Object.assign(action.payload, { id: uuid() })
      next(action)
      store.dispatch({ type: ACTION_TYPES.WIZARD.CONFIGURING_SET, payload: { key: 'question', id: action.payload.id } })
    },
  },
  // [ACTION_TYPES.WIZARD.LOCATION_UNMARKED]: {
  //   check: (store, payload) => {
  //     const { [`locationById[${payload.id}]`]: marker } = store.select([`selectLocationById[${payload.id}]`])
  //     return marker.optionIds.concat(marker.questionId).filter(id => id).length && marker
  //   },
  //   method: (store, next, action, marker) => {
  //     const actionArray = marker.optionIds
  //       .map(id => ({ type: ACTION_TYPES.WIZARD.MARKER_UNASSIGNED, payload: { markerId: marker.id, optionId: id } }))
  //       .concat(
  //         marker.questionId ? { type: ACTION_TYPES.WIZARD.MARKER_UNASSIGNED, payload: { markerId: marker.id, questionId: marker.questionId } } : []
  //       )
  //     return store.dispatch({ type: ACTION_TYPES.BATCH_ACTIONS, actionArray }) || next(action)
  //   },
  // },
  [ACTION_TYPES.WIZARD.LOCATION_MARKED]: {
    // check: (store) => getCurrentConfiguringQuestion(store) || true,
    // method: (store, next, action, question) => {
    check: () => true,
    method: (store, next, action) => {
      const { payload = {} } = action || {}
      const { type, parentId, range } = payload
      if (!['segments', 'text'].includes(type)) return intercept(action)
      if (type === 'segments') {
        const storeState = store.getState()?.wizard || {}
        const mappedId = mapSegmentId(parentId)
        const mappedRange = mapSegmentsLocationRange(storeState, range)
        const contentParentId = getContentParentIdByMarkerId(storeState, mappedId)
        const parentStructure = getSegmentById(storeState, contentParentId === 'root' ? CASUS_IDS.dataStructure : mappedId)[0] || {}
        const structureContent = parentStructure[Object.keys(parentStructure).includes('segments') ? 'segments' : 'content'] || []
        const replaceStyles = structureContent.slice(...mappedRange).reduce(
          (acc, { customStyle, styles }) => ({
            styles: Array.from(new Set(acc.styles.concat(styles))),
            customStyle: Array.from(new Set(acc.customStyle.concat(customStyle))),
          }),
          { styles: [], customStyle: [] }
        )
        const valueOverwrite = { parentId: mappedId, range: mappedRange, contentStyles: replaceStyles.styles }
        if (replaceStyles.customStyle.length === 1) Object.assign(valueOverwrite, { contentCustomStyle: replaceStyles.customStyle[0] })
        Object.assign(payload, valueOverwrite)
      }
      return next(action)
    },
  },
  // [ACTION_TYPES.WIZARD.QUESTION_ANSWERED_WITH_OPTION]: {
  //   check: () => true,
  //   method: (store, next, action) => {
  //     next(action)
  //     const { payload: { questionId } = {} } = action
  //     const question = getQuestionById(store.getState()?.wizard, questionId)[0]
  //     if (!question) return
  //     const markers = question.optionGroups.reduce(
  //       (acc, { options }) => options.reduce((accumulated, { markers }) => accumulated.concat(markers), acc),
  //       question.markers || []
  //     )
  //     return (
  //       markers.length &&
  //       store.dispatch({
  //         type: ACTION_TYPES.BATCH_ACTIONS,
  //         actionArray: markers.map(id => ({ type: ACTION_TYPES.WIZARD.MARKER_EVALUATED, payload: { id } })),
  //       })
  //     )
  //   },
  // },
  // [ACTION_TYPES.WIZARD.QUESTION_UNANSWERED]: {
  //   check: () => true,
  //   method: (store, next, action) => {
  //     next(action)
  //     const { payload: { id } = {} } = action
  //     const question = getQuestionById(store.getState()?.wizard, id)[0]
  //     if (!question) return
  //     const markers = question.optionGroups.reduce(
  //       (acc, { options }) => options.reduce((accumulated, { markers }) => accumulated.concat(markers), acc),
  //       question.markers || []
  //     )
  //     return (
  //       markers.length &&
  //       store.dispatch({
  //         type: ACTION_TYPES.BATCH_ACTIONS,
  //         actionArray: markers.map(id => ({ type: ACTION_TYPES.WIZARD.MARKER_EVALUATED, payload: { id } })),
  //       })
  //     )
  //   },
  // },
  // [ACTION_TYPES.WIZARD.QUESTION_OPTION_UNANSWERED]: {
  //   check: () => true,
  //   method: (store, next, action) => {
  //     next(action)
  //     const { payload: { questionId } = {} } = action
  //     const question = getQuestionById(store.getState()?.wizard, questionId)[0]
  //     if (!question) return
  //     const markers = question.optionGroups.reduce(
  //       (acc, { options }) => options.reduce((accumulated, { markers }) => accumulated.concat(markers), acc),
  //       question.markers || []
  //     )
  //     return (
  //       markers.length &&
  //       store.dispatch({
  //         type: ACTION_TYPES.BATCH_ACTIONS,
  //         actionArray: markers.map(id => ({ type: ACTION_TYPES.WIZARD.MARKER_EVALUATED, payload: { id, questionId } })),
  //       })
  //     )
  //   },
  // },
  // [ACTION_TYPES.WIZARD.QUESTION_ANSWERED]: {
  //   check: () => true,
  //   method: (store, next, action) => {
  //     next(action)
  //     const { payload } = action
  //     const { id } = payload
  //     const [question, index] = getStateQuestion(store.getState()?.wizard, id)
  //     if (index === -1) return
  //     const { type = 'choice', markers } = question
  //     if (markers.length) {
  //       const actionArray = []
  //       const batchActions = { type: ACTION_TYPES.BATCH_ACTIONS, actionArray }
  //       const actionType = type === 'choice' ? ACTION_TYPES.WIZARD.CHOICE_MARKER_EVALUATED : ACTION_TYPES.WIZARD.REPLACEMENT_MARKER_EVALUATED
  //       markers.forEach(markerId => actionArray.push({ type: actionType, payload: { id: markerId } }))
  //       store.dispatch(batchActions)
  //     }
  //   },
  // },
  [ACTION_TYPES.WIZARD.NEW_SUB_QUESTION_ADDED]: {
    check: () => true,
    method: (store, next, action) => {
      const { payload: { questionId, optionGroupId, optionId } = {} } = action
      const question = getQuestion(store, questionId)
      const optionGroup = question?.optionGroups.find(({ id }) => id === optionGroupId)
      const option = optionGroup?.options.find(({ id }) => id === optionId)
      const newQuestionId = uuid()
      return (
        intercept(action) ||
        (option &&
          store.dispatch({
            type: ACTION_TYPES.BATCH_ACTIONS,
            actionArray: [
              { type: ACTION_TYPES.WIZARD.NEW_QUESTION_ADDED, payload: { id: newQuestionId } },
              { type: ACTION_TYPES.WIZARD.QUESTION_MADE_INTO_SUB_QUESTION, payload: { id: newQuestionId, questionId, optionGroupId, optionId } },
            ],
          }))
      )
    },
  },
  // [ACTION_TYPES.WIZARD.QUESTION_MARKERS_ASSIGNED_AUTOMATICALLY]: {
  //   check: () => true,
  //   method: (store, next, action) => {
  //     intercept(action)
  //     const { payload: { id } = {} } = action
  //     const { [`questionUnassignedMarkerIds[${id}]`]: markers } = store.select([`selectQuestionUnassignedMarkerIds[${id}]`])
  //     const markerIds = markers.split('; ').filter(id => id)
  //     if (!markerIds.length) return
  //     const actionType = ACTION_TYPES.WIZARD.NEW_QUESTION_OPTION_ADDED
  //     const actionArray = markerIds.map(mid => ({ type: actionType, payload: { questionId: id, markers: [mid] } }))
  //     const batchActions = { type: ACTION_TYPES.BATCH_ACTIONS, actionArray }
  //     store.dispatch(batchActions)
  //   },
  // },
  // [ACTION_TYPES.WIZARD.QUESTION_COMPLEX_UPDATED]: {
  //   check: (store, payload) => true,
  //   method: (store, next, action) => {
  //     intercept(action)
  //     const q = getQuestion(store, action.payload.id)
  //     if (!q) return
  //     const actionArray = []
  //     const batchActions = { type: ACTION_TYPES.BATCH_ACTIONS, actionArray }
  //     const specificQuestionActionTypeKeys = ['options', 'advanced']
  //     const questionUpdatePayload = Object.entries(action.payload)
  //       .filter(([key]) => !specificQuestionActionTypeKeys.includes(key))
  //       .reduce((acc, [key, value]) => Object.assign(acc, { [key]: value }), {})
  //     if (Object.keys(questionUpdatePayload).length > 1)
  //       actionArray.push({ type: ACTION_TYPES.WIZARD.QUESTION_UPDATED, payload: questionUpdatePayload })
  //     if (action.payload.options)
  //       Object.entries(action.payload.options).forEach(([id, payload]) =>
  //         actionArray.push({
  //           type: ACTION_TYPES.WIZARD.QUESTION_OPTION_UPDATED,
  //           payload: Object.assign(payload, { questionId: action.payload.id, id }),
  //         })
  //       )
  //     if (action.payload.advanced) {
  //       const specificAdvancedQuestionActionTypeKeys = ['subQuestionTo']
  //       const advancedQuestionUpdatePayload = Object.entries(action.payload.advanced)
  //         .filter(([key]) => !specificAdvancedQuestionActionTypeKeys.includes(key))
  //         .reduce((acc, [key, value]) => Object.assign(acc, { [key]: value }), { id: action.payload.id })
  //       if (Object.keys(questionUpdatePayload).length > 1)
  //         actionArray.push({ type: ACTION_TYPES.WIZARD.ADVANCED_QUESTION_CONFIGURATION_UPDATED, payload: advancedQuestionUpdatePayload })
  //       if (action.payload.advanced.subQuestionTo) {
  //         const [questionId, optionId] = action.payload.advanced.subQuestionTo.split(':').filter(id => id)
  //         actionArray.push({ type: ACTION_TYPES.WIZARD.QUESTION_MADE_INTO_SUB_QUESTION, payload: { id: action.payload.id, questionId, optionId } })
  //       }
  //     }
  //     if (actionArray.length) store.dispatch(batchActions)
  //   },
  // },
  [ACTION_TYPES.WIZARD.PARAGRAPH_CONTENT_ALIGNED]: {
    check: (store, payload) => true,
    method: (store, next, action) => {
      intercept(action)
      const { payload: { id, alignment } = {} } = action || {}
      const alignments = ['left', 'right', 'center', 'justify']
      const toApply = alignments.splice(alignments.indexOf(alignment), 1)
      const actionArray = [
        { type: ACTION_TYPES.WIZARD.PARAGRAPH_STYLES_REMOVED, payload: { id, styles: alignments.map(s => `_casus_align-${s}`) } },
        { type: ACTION_TYPES.WIZARD.NEW_PARAGRAPH_STYLES_ADDED, payload: { id, styles: toApply.map(s => `_casus_align-${s}`) } },
      ]
      return store.dispatch({ type: ACTION_TYPES.BATCH_ACTIONS, actionArray })
    },
  },
  [ACTION_TYPES.WIZARD.PARAGRAPH_INSERTED_ABOVE]: {
    check: (store, payload) => true,
    method: (store, next, action) => {
      if (!(action.payload && action.payload.id)) return intercept(action)
      const newParagaphId = uuid()
      return (
        Object.assign(action.payload, { newParagaphId }) &&
        next(action) &&
        store.dispatch({ type: ACTION_TYPES.WIZARD.CONFIGURING_SET, payload: { key: 'paragraph', id: newParagaphId } })
      )
    },
  },
  [ACTION_TYPES.WIZARD.PARAGRAPH_INSERTED_BELOW]: {
    check: (store, payload) => true,
    method: (store, next, action) => {
      if (!(action.payload && action.payload.id)) return intercept(action)
      const newParagaphId = uuid()
      return (
        Object.assign(action.payload, { newParagaphId }) &&
        next(action) &&
        store.dispatch({ type: ACTION_TYPES.WIZARD.CONFIGURING_SET, payload: { key: 'paragraph', id: newParagaphId } })
      )
    },
  },
  [ACTION_TYPES.WIZARD.PARAGRAPH_REMOVED]: {
    check: (store, payload) => {
      const markerArray = store.getState()?.wizard?.locations?.replacement[payload.id]
      return markerArray?.length && markerArray
    },
    method: (store, next, action, markerArray) => {
      store.dispatch({
        type: ACTION_TYPES.BATCH_ACTIONS,
        actionArray: markerArray.map(marker => ({ type: ACTION_TYPES.WIZARD.LOCATION_UNMARKED, payload: { id: marker.id } })),
      })
      return next(action)
    },
  },
})

export { interceptors }
export default interceptors
