import React, { FunctionComponent, useCallback } from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import useStore, {
  WizardModeSelector,
  WizardTemplateIdSelector,
  WizardAnsweringSelector,
  ResetWizardTemplateIdAction,
  WizardTitleSelector,
  WizardAnswersSelector,
} from '___store'

import { WIZARD_MODE_DIRECTORY_MAP, Directory, DOCUMENTS_DIRECTORY, ValuesOf, DOCUMENTS_FILTERS } from '___types'
import { CreateVariables } from '___queries/documents'
import { useDocument } from '___hooks'
import { Caret } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import { wizardLayoutHeaderClasses as classes } from '../..'
import { QUESTIONNAIRE_POST_STEPS, QUESTIONNAIRE_PRE_STEPS } from '___store/storeSegments/wizard/typified/helpers/document-generation'

const isAtPreSteps = (directory: Directory, wizardTemplateId: WizardTemplateIdSelector, wizardAnswering: WizardAnsweringSelector) =>
  directory === DOCUMENTS_DIRECTORY &&
  wizardTemplateId &&
  Object.values(QUESTIONNAIRE_PRE_STEPS).includes(wizardAnswering as ValuesOf<typeof QUESTIONNAIRE_PRE_STEPS>)
const isAtPostSteps = (wizardAnswering: WizardAnsweringSelector) =>
  Object.values(QUESTIONNAIRE_POST_STEPS).includes(wizardAnswering as ValuesOf<typeof QUESTIONNAIRE_POST_STEPS>)

const generateButtonLabel = (directory: Directory, wizardTemplateId: WizardTemplateIdSelector, wizardAnswering: WizardAnsweringSelector) => {
  const atPreSteps = isAtPreSteps(directory, wizardTemplateId, wizardAnswering)
  const atPostSteps = isAtPostSteps(wizardAnswering)
  if (atPreSteps) return 'Select a different template'
  if (atPostSteps) return 'Save as a draft'
  return `To ${directory.charAt(0).toUpperCase()}${directory.slice(1)}`
}

type UseStoreHookResultType = {
  wizardMode: WizardModeSelector
  wizardTemplateId: WizardTemplateIdSelector
  wizardTitle: WizardTitleSelector
  wizardAnswers: WizardAnswersSelector
  wizardAnswering: WizardAnsweringSelector
  resetWizardTemplateId: ResetWizardTemplateIdAction
}

export const BackButton: FunctionComponent = React.memo(() => {
  const { create } = useDocument()
  const { t: translate } = useTranslation()
  const history = useHistory()
  const { wizardMode, wizardTemplateId, wizardTitle, wizardAnswers, wizardAnswering, resetWizardTemplateId } = useStore(
    'selectWizardMode',
    'selectWizardTemplateId',
    'selectWizardTitle',
    'selectWizardAnswers',
    'selectWizardAnswering',
    'resetWizardTemplateId'
  ) as UseStoreHookResultType
  const directory = wizardMode && WIZARD_MODE_DIRECTORY_MAP[wizardMode]
  const buttonOnClickHandler = useCallback(() => {
    if (directory && isAtPreSteps(directory, wizardTemplateId, wizardAnswering)) {
      resetWizardTemplateId()
      const singularDirectory = directory.charAt(directory.length - 1) === 's' ? directory.slice(0, directory.length - 1) : directory
      return history.push(`/${translate(singularDirectory)}/${translate('new')}`)
    }
    if (isAtPostSteps(wizardAnswering)) {
      const payload = {
        document: { templateId: wizardTemplateId, name: wizardTitle || 'Generic document name', answers: wizardAnswers },
        category: DOCUMENTS_FILTERS.MINE,
      } as CreateVariables
      create(payload)
    }
    history.push(`/${translate(directory!)}`)
  }, [directory, wizardTemplateId, wizardAnswering, resetWizardTemplateId, history, translate, wizardTitle, wizardAnswers, create])
  if (!directory) return null
  const buttonLabel = generateButtonLabel(directory, wizardTemplateId, wizardAnswering)
  return (
    <Button className={classes.backButton} onClick={buttonOnClickHandler}>
      {!wizardTemplateId || wizardAnswering === 'pre' || wizardAnswering === 'post-generation' ? <Caret /> : null}
      {buttonLabel}
    </Button>
  )
})
BackButton.displayName = 'WizardLayout-Header-BackButton'

export default BackButton
