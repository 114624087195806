import { useMemo } from 'react'
import useStore, {
  WizardDataStructureSelector,
  WizardSectionByIndexSelector,
  WizardSectionPageContentIdsByIndicesSelector,
  WizardState,
  getMappedSegmentIds,
} from '___store'

import { useEditorPreviewContext } from '..'

type UseStoreHookResultType = { wizardSectionPageContentIdsByIndices?: WizardSectionPageContentIdsByIndicesSelector }

export const usePage = (sectionIndex?: number, pageIndex?: number): string[] => {
  const editorContext = useEditorPreviewContext()
  const { dataStructure } = editorContext
  const { sections } = dataStructure || ({} as WizardDataStructureSelector)!
  const contextSection = sections && typeof sectionIndex === 'number' && !isNaN(sectionIndex) && sections[sectionIndex]
  const { pages } = contextSection || ({} as WizardSectionByIndexSelector)!
  const contextPageRange = pages && typeof pageIndex === 'number' && !isNaN(pageIndex) && pages[pageIndex]

  const staticPseudoState = Object.assign({}, editorContext) as WizardState

  const mappedContentIds = getMappedSegmentIds(staticPseudoState, 'root')
    .slice(...(contextPageRange || [0, 0]))
    .map(id => id.split('; ').pop()!)
  const contextPageContentIds = mappedContentIds && Array.from(new Set(mappedContentIds)).join('; ')

  const { wizardSectionPageContentIdsByIndices = '' } = useStore(
    !contextPageContentIds ? `selectWizardSectionPageContentIdsByIndices[${sectionIndex},${pageIndex}]` : undefined
  ) as UseStoreHookResultType

  const result = contextPageContentIds || wizardSectionPageContentIdsByIndices
  const usePageReturn = useMemo(() => result.split('; ').filter(id => id), [result])

  return usePageReturn
}

export default usePage
