import { ITemplate, DataStructure, MappedTemplatesFilter } from '___types'
import API from './api'
import { mapStructureKeys, parseCssData, parseStyles } from './helpers'

export const templateListPerPage = 10

class Templates extends API {
  public constructor() {
    super('templates/')
  }

  public getTemplateList = async (filter?: MappedTemplatesFilter | null, lastTemplateId?: string | null): Promise<ITemplate[] | undefined> => {
    if (!filter) return
    const params = new URLSearchParams({ limit: String(templateListPerPage) })
    if (lastTemplateId) params.set('startAfter', lastTemplateId)
    const { data: { data: { templates = [] } = {} } = {} } = await this.get(`/list/${filter}?${String(params)}`, {}, {}, 'v1')
    return templates as ITemplate[]
  }

  public getTemplate = async (id?: string | null): Promise<ITemplate | undefined> => {
    if (!id) return
    const res = await this.get(id)
    const template = res.data.data as ITemplate
    const parsedCssData = parseCssData(template.cssData as Record<string, string>)
    const parsedStyles = Object.assign(parsedCssData, { standardStyles: parseStyles(parsedCssData, template.dataStructure) })
    /////////////////////////////////////////////////////////////////////////// //
    ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    /////////////////////////////////////////////////////////////////////////// //
    // remove parsing when BE fixes styleName => customStyle & id(number) => id(string)
    const parsedDataStructure = mapStructureKeys(template.dataStructure) as DataStructure
    Object.assign(template, { dataStructure: parsedDataStructure })
    /////////////////////////////////////////////////////////////////////////// //
    /////////////////////////////////////////////////////////////////////////// //
    /////////////////////////////////////////////////////////////////////////// //
    return Object.assign(template, { styles: parsedStyles }) as ITemplate
  }
}

export const templatesAPI = new Templates()

export default templatesAPI
