import AlignCenter from './AlignCenter'
import AlignJustify from './AlignJustify'
import AlignLeft from './AlignLeft'
import AlignRight from './AlignRight'
import Bold from './Bold'
import Caret from './Caret'
import Check from './Check'
import CheckBox from './CheckBox'
import Clock from './Clock'
import Cogwheel from './Cogwheel'
import Company from './Company'
import Cross from './Cross'
import DatePicker from './DatePicker'
import Deselect from './Deselect'
import DOCXDocument from './Document.docx'
import Document from './Document'
import PDFDocument from './Document.pdf'
import Docx from './Docx'
import Download from './Download'
import Duplicate from './Duplicate'
import Edit from './Edit'
import EditText from './EditText'
import Extract from './Extract'
import HorizontalLines from './HorizontalLines'
import Info from './Info'
import Insert from './Insert'
import InsertAbove from './InsertAbove'
import InsertBelow from './InsertBelow'
import Italic from './Italic'
import Logout from './Logout'
import Move from './Move'
import NumberInput from './NumberInput'
import Pdf from './Pdf'
import Pen from './Pen'
import Person from './Person'
import Plus from './Plus'
import Premium from './Premium'
import Preview from './Preview'
import RadioButton from './RadioButton'
import RecentDocuments from './RecentDocuments'
import Reminders from './Reminders'
import Rename from './Rename'
import Share from './Share'
import Signature from './Signature'
import Squares from './Squares'
import StringInput from './StringInput'
import Team from './Team'
import Template from './Template'
import TimePicker from './TimePicker'
import Trash from './Trash'
import Underline from './Underline'
import VerticalEllipsis from './VerticalEllipsis'
import Warning from './Warning'

import Spinner from './Spinner'
import CasusLogo from './CasusLogo'

import './style.scss'

export const className = 'SVG-Icon'

export const VIEWPORT_SIZE = 100

export {
  AlignCenter,
  AlignJustify,
  AlignLeft,
  AlignRight,
  Bold,
  Caret,
  Check,
  CheckBox,
  Cogwheel,
  Company,
  Cross,
  DatePicker,
  Deselect,
  DOCXDocument,
  Document,
  PDFDocument,
  Docx,
  Download,
  Duplicate,
  Edit,
  EditText,
  Extract,
  HorizontalLines,
  Info,
  Insert,
  InsertAbove,
  InsertBelow,
  Italic,
  Logout,
  Move,
  NumberInput,
  Pdf,
  Pen,
  Person,
  Plus,
  Premium,
  Preview,
  RadioButton,
  RecentDocuments,
  Reminders,
  Rename,
  Share,
  Signature,
  Squares,
  StringInput,
  Team,
  Template,
  TimePicker,
  Trash,
  Underline,
  VerticalEllipsis,
  Warning,
}

export type CasusLogoProps = {
  className?: string
  animate?: boolean
  strokeWidth?: string | number
  strokeFill?: number
  animationDuration?: number
  fill?: string
  stroke?: string
}
export type ClockProps = {
  strokeWidth?: string | number
  h?: number
  m?: number
}
export type SpinnerProps = {
  strokeWidth?: string | number
  strokeFill?: number
  animationDuration?: number
  fill?: string
  stroke?: string
}
export { CasusLogo }
export { Spinner }
export { Clock }

const polarToRectangularOffset = (angle: number, length: number = 0, xOffset: number = 0, yOffset: number = 0): [number, number] => [
  xOffset + Math.sin(angle) * length,
  yOffset - Math.cos(angle) * length,
]

const angleToDegrees = (angle: number): number => (angle / 180) * Math.PI

export const drawStarPath = (
  centerX: number = 50,
  centerY: number = 50,
  points: number = 8,
  tipWidth: number = 0,
  baseWidth: number = 0,
  tipLength: number = 30,
  baseLength: number = 10
): string => {
  const spikeAngle = (2 * Math.PI) / points
  const path = new Array(points).fill(null).reduce((acc: string, _, i) => {
    const tipStartAngle = spikeAngle * i - angleToDegrees(tipWidth / 2)
    const tipEndAngle = spikeAngle * i + angleToDegrees(tipWidth / 2)
    const baseStartAngle = spikeAngle * (i + 0.5) - angleToDegrees(baseWidth / 2)
    const baseEndAngle = spikeAngle * (i + 0.5) + angleToDegrees(baseWidth / 2)
    const [tipStartX, tipStartY] = polarToRectangularOffset(tipStartAngle, tipLength, centerX, centerY)
    const [tipEndX, tipEndY] = polarToRectangularOffset(tipEndAngle, tipLength, centerX, centerY)
    const [baseStartX, baseStartY] = polarToRectangularOffset(baseStartAngle, baseLength, centerX, centerY)
    const [baseEndX, baseEndY] = polarToRectangularOffset(baseEndAngle, baseLength, centerX, centerY)
    const start = `L ${tipStartX} ${tipStartY}`
    const tipArc = tipWidth ? `A ${tipLength} ${tipLength} 0 ${Number(tipWidth > 180)} 1 ${tipEndX} ${tipEndY}` : ''
    const toBase = `L ${baseStartX} ${baseStartY}`
    const baseArc = baseWidth ? `A ${baseLength} ${baseLength} 0 ${Number(baseWidth > 180)} 1 ${baseEndX} ${baseEndY}` : ''
    return acc + start + tipArc + toBase + baseArc
  }, '')
  return 'M' + path.slice(1) + ' Z'
}
