import React from 'react'
import { useTranslation } from 'react-i18next'
import { Switch, Route, Redirect } from 'react-router-dom'

import DocumentCreate from './views/DocumentCreate/DocumentCreate.page'
import DocumentEdit from './views/DocumentEdit/DocumentEdit.page'
import DocumentPreview from './views/DocumentPreview/DocumentPreview.page'

const DocumentFile: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Switch>
      <Route path={`/${t('document')}/${t('new')}`} component={DocumentCreate} />
      <Route exact path={`/${t('document')}/:id/${t('edit')}`} component={DocumentEdit} />
      <Route exact path={`/${t('document')}/:id`} component={DocumentPreview} />
      <Redirect to={`/${t('documents')}`} />
    </Switch>
  )
}

export default DocumentFile