import React, { useMemo } from 'react'

import { classes } from '.'

export const Option = React.memo(props => {
  const {
    className: cn,
    // id,
    label = '',
  } = props

  const className = useMemo(() => [classes.option.wrapper, cn].filter(c => c).join(' '), [cn])

  return (
    <button
      type="button"
      onClick={() => {
        // console.log(id)
      }}
      className={className}
    >
      {label}
    </button>
  )
})

export const displayName = 'Casus-Components-Dropdown-Option'
Option.displayName = displayName

export default Option
