import { PropsWithChildren, ReactNode } from 'react'
import Dropdown from './Dropdown'
import DropdownOption from './Dropdown.Option'

import './style.scss'

const optionClasses = {
  wrapper: 'Casus-Components-Dropdown-Option-wrapper',
} as const
const classes = {
  wrapper: 'Casus-Components-Dropdown-wrapper',
  button: 'Casus-Components-Dropdown-button',
  options: {
    wrapper: 'Casus-Components-Dropdown-options-wrapper',
    option: optionClasses,
  },
} as const

export type DropdownProps = PropsWithChildren<{
  className?: string
  emphasized?: boolean
  primary?: boolean
  secondary?: boolean
  tertiary?: boolean
  label?: string
  caretRight?: boolean
  replaceCaret?: ReactNode
}>

export { Dropdown, DropdownOption, classes }
export default Dropdown
