import { IDocument, ILazyLoadParams } from 'types'
import { useInfiniteQuery, UseInfiniteQueryOptions, useQueryClient } from 'react-query'
import DocumentsApi from 'api/docs.api'
import { queryKeys } from 'queries/queryKeys'
import { AxiosError } from 'axios'

const getInitialLimit = () => {
  const docHeight = 364
  const docWidht = 256 + 32
  const width = window.innerWidth - 360
  const height = window.innerHeight - 150

  const rows = Math.ceil(height / docHeight)
  const rowItems = Math.floor(width / docWidht)
  return rows * rowItems
}

export const useLoadSignatureDocuments = (
  filter: string,
  options: UseInfiniteQueryOptions<IDocument[], AxiosError, IDocument[]> = {},
  sLimit?: number
) => {
  const queryClient = useQueryClient()

  const keys = [queryKeys.DOCUMENTS, { filter, limit: sLimit }]
  const data = queryClient.getQueryData<IDocument[]>(keys)
  const search: ILazyLoadParams = {}

  if (data?.length) search.lastItemFetched = data[data.length - 1].id
  let limit = !data?.length ? getInitialLimit() : 12

  if (sLimit) {
    limit = sLimit
  }

  const query = useInfiniteQuery(
    keys,
    ({ pageParam }) =>
      DocumentsApi.getWithSignatures({
        filter,
        limit,
        lastItemFetched: pageParam,
      }),
    {
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage) return undefined
        if (lastPage?.length < limit) return undefined
        const id = lastPage[lastPage.length - 1]?.id
        return id
      },
      refetchOnWindowFocus: false,
      ...options,
    }
  )

  return query
}

