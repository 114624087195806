import React from 'react'
import TextInputPreview from './TextInputPreview'
import CasusLink from './CasusLink'

import './style.scss'

const classes = {
  inputPreview: {
    wrapper: 'Input-preview-wrapper',
    content: 'Input-preview-content',
    casusLink: {
      wrapper: 'Input-preview-CasusLink-wrapper',
    },
  },
}

export { TextInputPreview, classes }
export default TextInputPreview

const linkRegex = /(casus_(?<type>link)(?<properties>([^{}]|\\{|\\})*))/
const linkPropertiesRegex = /((?<prop>text|href)=("|')(?<value>[^"']+)("|'))/g
const splitRegex = /(?=(?<!\\){)|(?<=(?<!\\)})/gm
const parseText = string => {
  const parsed = string
    .split(splitRegex)
    .map((subString, i) => {
      const { groups } = subString.match(linkRegex) || {}
      if (groups) {
        switch (groups.type) {
          case 'link': {
            const properties = Array.from(groups.properties.matchAll(linkPropertiesRegex)).reduce(
              (acc, cur) => Object.assign(acc, { [cur.groups?.prop]: cur.groups?.value }),
              {}
            )
            return { type: 'link', properties }
          }
          default:
            return { type: 'plainText', properties: { text: subString } }
        }
      }
      return { type: 'plainText', properties: { text: subString } }
    })
    .reduce((acc, cur) => {
      const last = acc.length && acc[acc.length - 1]
      return last && last.type === 'plainText' && cur.type === 'plainText'
        ? Object.assign(last, { properties: { text: last.properties.text + cur.properties.text } }) && acc
        : acc.push(cur) && acc
    }, [])
  return parsed.map((contentObject, i) => {
    const { type, properties = {} } = contentObject || {}
    const { text } = properties
    if (type === 'plainText') return text
    if (type === 'link') return <CasusLink key={`CasusLink-${text}-${i}`} properties={properties} />
    return ''
  })
}

export { parseText }
