import MainLayout from './MainLayout'
import WizardLayout from './WizardLayout'

import './style.scss'

export const headerClasses = {
  wrapper: 'Header-wrapper',
  logo: 'Header-logo',
  navDropdown: {
    wrapper: 'Header-NavDropdown-wrapper',
    button: 'Header-NavDropdown-button',
    dropdownList: 'Header-NavDropdown-dropdownList',
    dropdownListItem: 'Header-NavDropdown-dropdownListItem',
  },
  addDocumentButton: 'Header-addDocumentButton',
  documentsButton: 'Header-documentsButton',
  signaturesDropdown: 'Header-signaturesDropdown',
  profileButton: 'Header-profileButton',
  profileDropdown: {
    wrapper: 'Header-profile-wrapper',
    button: 'Header-profile-button',
    dropdownList: 'Header-profile-dropdownList',
    dropdownListItem: 'Header-profile-dropdownListItem',
    image: 'Header-profile-image',
  },
} as const
export const leftPaneClasses = {
  wrapper: 'LeftPane-wrapper',
  section: { wrapper: 'LeftPane-section-wrapper', navButton: 'LeftPane-section-navButton' },
} as const
export const contentDocumentsClasses = {
  wrapper: 'Content-Documents-wrapper',
  loadMoreButton: 'Content-Documents-loadMoreButton',
}
export const contentTemplatesClasses = {
  wrapper: 'Content-Templates-wrapper',
  loadMoreButton: 'Content-Templates-loadMoreButton',
}
export const contentClasses = {
  wrapper: 'Content-wrapper',
  backgroundLogo: 'Content-backgroundLogo',
  section: { wrapper: 'Content-section-wrapper' },
  documents: contentDocumentsClasses,
  templates: contentTemplatesClasses,
} as const
export const footerClasses = { wrapper: 'Footer-wrapper' } as const
export const componentClasses = {
  header: headerClasses,
  leftPane: leftPaneClasses,
  content: contentClasses,
  footer: footerClasses,
} as const
export const classes = { components: componentClasses } as const

export { MainLayout, WizardLayout }

export default MainLayout
