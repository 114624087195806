import React from 'react'
import { EmptySignatures, classes } from '.'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { IDocument } from 'types'
import { useLoadSignatureDocuments } from 'queries/documents/useLoadSignatureDocuments'
import { Signature } from 'assets/svgIconComponents'

interface IProps {
  documents: IDocument[]
}

const getSignedCount = (signatures: any) => {
  return signatures.filter((sign: any) => sign.status_code === 'SIGNED').length
}

const SignaturesList: React.FC<IProps> = ({ documents }) => {
  if (documents && documents.length === 0) {
    return <EmptySignatures />
  }

  return (
    <div className={classes.list.wrapper}>
      {documents?.map((document: any) =>
        document.signatureRequest ? (
          <div className={classes.list.item.wrapper}>
            <span>{document.name}</span>
            <div className={classes.list.item.counter} data-status={document.signatureStatus}>
              {getSignedCount(document.signatureRequest.signatures)}/{document?.signatureRequest?.signatures.length}
            </div>
          </div>
        ) : null
      )}
    </div>
  )
}

const SkeletonList: React.FC = () => {
  return (
    <div className={classes.list.wrapper} data-skeleton={true}>
      <div className={classes.list.item.wrapper}>
        <span className={classes.list.item.title} />
        <div className={classes.list.item.counter} />
      </div>
      <div className={classes.list.item.wrapper}>
        <span className={classes.list.item.title} />
        <div className={classes.list.item.counter} />
      </div>
      <div className={classes.list.item.wrapper}>
        <span className={classes.list.item.title} />
        <div className={classes.list.item.counter} />
      </div>
    </div>
  )
}

const SignaturesPanel: React.FC = () => {
  const { push } = useHistory()
  const { t } = useTranslation()

  const { data, isLoading } = useLoadSignatureDocuments('', {}, 3)

  const documentFiles = data?.pages.flat(1) ?? []

  return (
    <div className={classes.wrapper}>
      <div className={classes.title.wrapper}>
        <span className={classes.title.textAndIcon}>
          <Signature /> E-Signatures
        </span>
        <span onClick={() => push(`/${t('signatures')}`)} className={classes.title.showAll}>
          {t('show_all')} &gt;
        </span>
      </div>
      {!isLoading ? <SignaturesList documents={documentFiles} /> : <SkeletonList />}
    </div>
  )
}

export default SignaturesPanel

