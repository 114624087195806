import { TupleToUnion, ValuesOf } from '___types'

const ACTION_TYPES = {
  BATCH_ACTIONS: 'BATCH_ACTIONS', // Multiple actions sent at once
  APP: {
    DOCUMENTS_FILTER_SET: 'DOCUMENTS_FILTER_SET', // The user has set a document filter
    DOCUMENT_SELECTED: 'DOCUMENT_SELECTED', // The user has selected a document
    DOCUMENTS_DESELECTED: 'DOCUMENTS_DESELECTED', // The user has deselected all documents

    TEMPLATES_FILTER_SET: 'TEMPLATES_FILTER_SET', // The user has set a template filter
    TEMPLATE_SELECTED: 'TEMPLATE_SELECTED', // The user has selected a template
    TEMPLATES_DESELECTED: 'TEMPLATES_DESELECTED', // The user has deselected all templates
  },
  WIZARD: {
    WIZARD_RESET: 'WIZARD_RESET', // Wizard state was reset to uninitialized
    WIZARD_INITIALIZED: 'WIZARD_INITIALIZED', // Wizard was initialized with the initial state
    WIZARD_STATE_POLLUTED: 'WIZARD_STATE_POLUTED', // Wizard state was changed and no longer matches the initial state
    // ///////////////////////////////////////////////////////////////////////////////// //
    // ////////////////////////////////// CONFIGURING ////////////////////////////////// //
    // ///////////////////////////////////////////////////////////////////////////////// //
    CONFIGURING_REMOVED: 'CONFIGURING_REMOVED', // User has filtered an entry from the configuring stack
    // ///////////////////////////////////////////////////////////////////////////////// //
    // //////////////////////////////// QUESTION LAYOUT //////////////////////////////// //
    // ///////////////////////////////////////////////////////////////////////////////// //
    QUESTION_LAYOUT_SEPARATOR_ADDED: 'QUESTION_LAYOUT_SEPARATOR_ADDED', // The user has added a new question layout separator
    QUESTION_LAYOUT_GROUP_ADDED: 'QUESTION_LAYOUT_GROUP_ADDED', // The user has added a new question layout group
    QUESTION_LAYOUT_GROUP_UPDATED: 'QUESTION_LAYOUT_GROUP_UPDATED', // The user has updated a question layout group
    QUESTION_LAYOUT_GROUP_UNPACKED: 'QUESTION_LAYOUT_GROUP_UNPACKED', // The user has unpacked a question layout group
    QUESTION_LAYOUT_GROUP_REMOVED: 'QUESTION_LAYOUT_GROUP_REMOVED', // The user has removed a question layout group
    // ///////////////////////////////////////////////////////////////////////////////// //
    // /////////////////////////////////// QUESTIONS /////////////////////////////////// //
    // ///////////////////////////////////////////////////////////////////////////////// //
    NEW_QUESTION_ADDED: 'NEW_QUESTION_ADDED', // The user created a new question
    QUESTION_UPDATED: 'QUESTION_UPDATED', // The user has updated a question configuration
    QUESTION_REMOVED: 'QUESTION_REMOVED', // The user has removed a question
    QUESTION_ASSIGNED: 'QUESTION_ASSIGNED', // The user assigned a question to a question layout group
    QUESTION_UNASSIGNED: 'QUESTION_UNASSIGNED', // The user unassigned a question from a question layout group
    NEW_SUB_QUESTION_ADDED: 'NEW_SUB_QUESTION_ADDED', // The user created a new sub question
    QUESTION_COMPLEX_UPDATED: 'QUESTION_COMPLEX_UPDATED', // The user has updated multiple question fields / question options
    QUESTION_VISIBILITY_UPDATED: 'QUESTION_VISIBILITY_UPDATED', // The user has updated a question's visibility logic
    QUESTION_RULE_LOGIC_UPDATED: 'QUESTION_RULE_LOGIC_UPDATED', // The user has updated a question's conditional rule logic
    // ///////////////////////////////////////////////////////////////////////////////// //
    // ///////////////////////////////// OPTION GROUPS ///////////////////////////////// //
    // ///////////////////////////////////////////////////////////////////////////////// //
    NEW_OPTION_GROUP_ADDED: 'NEW_OPTION_GROUP_ADDED', // The user has added a new option group
    OPTION_GROUP_UPDATED: 'OPTION_GROUP_UPDATED', // The user has updated an option group configuration
    OPTION_GROUP_REMOVED: 'OPTION_GROUP_REMOVED', // The user has removed an option group
    // ///////////////////////////////////////////////////////////////////////////////// //
    // //////////////////////////////////// OPTIONS //////////////////////////////////// //
    // ///////////////////////////////////////////////////////////////////////////////// //
    NEW_OPTION_ADDED: 'NEW_OPTION_ADDED', // The user add a new option
    OPTION_UPDATED: 'OPTION_UPDATED', // The user has updated an option
    OPTION_REMOVED: 'OPTION_REMOVED', // The user has updated an option
    // QUESTION_MARKERS_ASSIGNED_AUTOMATICALLY: 'QUESTION_MARKERS_ASSIGNED_AUTOMATICALLY', // The user has assigned all unassigned question markers to new options automatically
    // ///////////////////////////////////////////////////////////////////////////////// //
    // /////////////////////////////// ADVANCED QUESTION /////////////////////////////// //
    // ///////////////////////////////////////////////////////////////////////////////// //
    ADVANCED_QUESTION_CONFIGURATION_UPDATED: 'ADVANCED_QUESTION_CONFIGURATION_UPDATED', // The user has updated an advanced question configuration
    QUESTION_CONDITIONAL_RULE_ADDED: 'QUESTION_CONDITIONAL_RULE_ADDED', // The user has added a question conditional rule
    QUESTION_CONDITIONAL_RULE_UPDATED: 'QUESTION_CONDITIONAL_RULE_UPDATED', // The user has updated a question conditional rule
    QUESTION_CONDITIONAL_RULE_REMOVED: 'QUESTION_CONDITIONAL_RULE_REMOVED', // The user has removed a question conditional rule
    QUESTION_MADE_INTO_SUB_QUESTION: 'QUESTION_MADE_INTO_SUB_QUESTION', // A question was assigned to a question option as its sub-question
    SUB_QUESTION_STATUS_REMOVED: 'SUB_QUESTION_STATUS_REMOVED', // A question has lost its sub-question status, and is now a regular question
    // ///////////////////////////////////////////////////////////////////////////////// //
    // //////////////////////////////////// MARKERS //////////////////////////////////// //
    // ///////////////////////////////////////////////////////////////////////////////// //
    MARKER_EVALUATED: 'MARKER_EVALUATED', // A marker was evaluated based on question answers
    // ///////////////////////////////////////////////////////////////////////////////// //
    // //////////////////////////////////// ANSWERS //////////////////////////////////// //
    // ///////////////////////////////////////////////////////////////////////////////// //
    NEXT_QUESTION_SET: 'NEXT_QUESTION_SET', // The user has moved to the next question
    PREVIOUS_QUESTION_SET: 'PREVIOUS_QUESTION_SET', // The user has moved to the next question
    QUESTION_ANSWERED_WITH_OPTION: 'QUESTION_ANSWERED_WITH_OPTION', // The user has answered a question by selecting an option
    QUESTION_UNANSWERED: 'QUESTION_UNANSWERED', // The user has removed an option from the question answer and in doing so a sub-question disappeared, discarding its answer
    QUESTION_OPTION_UNANSWERED: 'QUESTION_OPTION_UNANSWERED', // The user has removed an option from the question answer
    // ///////////////////////////////////////////////////////////////////////////////// //
    // //////////////////////////////////// CONTENT //////////////////////////////////// //
    // ///////////////////////////////////////////////////////////////////////////////// //
    PARAGRAPH_CONTENT_REPLACED: 'PARAGRAPH_CONTENT_REPLACED', // Paragraph content (textChunks) was replaced
    NEW_PARAGRAPH_STYLES_ADDED: 'NEW_PARAGRAPH_STYLES_ADDED', // New paragraph style attibutes were added
    PARAGRAPH_STYLES_REMOVED: 'PARAGRAPH_STYLES_REMOVED', // Paragraph style attibutes were removed
    PARAGRAPH_STYLE_TOGGLED: 'PARAGRAPH_STYLE_TOGGLED', // Paragraph style attibute was toggled
    PARAGRAPH_CUSTOM_STYLE_APPLIED: 'PARAGRAPH_CUSTOM_STYLE_APPLIED', // Paragraph custom style was applied
    PARAGRAPH_CONTENT_ALIGNED: 'PARAGRAPH_CONTENT_ALIGNED', // Paragraph content was aligned
    PARAGRAPH_INSERTED_ABOVE: 'PARAGRAPH_INSERTED_ABOVE', // A new paragraph was inserted above a paragraph
    PARAGRAPH_INSERTED_BELOW: 'PARAGRAPH_INSERTED_BELOW', // A new paragraph was inserted below a paragraph
    PARAGRAPH_REMOVED: 'PARAGRAPH_REMOVED', // A paragraph was removed
    // ///////////////////////////////////////////////////////////////////////////////// //
    // ///////////////////////////////////////////////////////////////////////////////// //
    // ///////////////////////////////////////////////////////////////////////////////// //

    LOCATION_MARKED: 'LOCATION_MARKED', // The user marked a location for a choice/replacement marker
    LOCATION_UNMARKED: 'LOCATION_UNMARKED', // The user removed a location marker
    MARKER_ASSIGNED: 'MARKER_ASSIGNED', // The user has assigned a marker to a specified question
    MARKER_UNASSIGNED: 'MARKER_UNASSIGNED', // The user has unassigned a marker from a specified question
    SEGMENTS_MARKER_UPDATED: 'SEGMENTS_MARKER_UPDATED', // The user has updated a choice marker configuration
    REPLACEMENT_MARKER_UPDATED: 'REPLACEMENT_MARKER_UPDATED', // The user has updated a replacement marker configuration
    CHOICE_MARKER_EVALUATED: 'CHOICE_MARKER_EVALUATED', // The user has answered a question which triggered a choice marker reevaluation
    REPLACEMENT_MARKER_EVALUATED: 'REPLACEMENT_MARKER_EVALUATED', // The user has answered a question which triggered a replacement marker reevaluation
    CONFIGURING_SET: 'CONFIGURING_SET', // Current configuring key and id was changed
    ANSWERING_SET: 'ANSWERING_SET', // Current answering id was changed
    CONFIGURING_DONE: 'CONFIGURING_DONE', // User has concluded configuring the current configuration object
    // QUESTION_OPTION_ADDED: 'QUESTION_OPTION_ADDED', // The user has added a question option
    QUESTION_OPTION_REMOVED: 'QUESTION_OPTION_REMOVED', // The user has removed a question option
    QUESTION_OPTION_REORDERED: 'QUESTION_OPTION_REORDERED', // The user has reordered a question option
    QUESTION_ANSWERED: 'QUESTION_ANSWERED', // The user has answered a question
    // SEGMENT_INSERTED_AT_INDEX: 'SEGMENT_INSERTED_AT_INDEX', // A segment was inserted at the specified index, inside a specified parent, placed inside/outside existing markers
    // SEGMENT_REMOVED_AT_INDEX: 'SEGMENT_REMOVED_AT_INDEX', // A segment was removed at the specified index, inside a specified parent
    // CUSTOM_STYLE_APPLIED: 'CUSTOM_STYLE_APPLIED', // An existing custom style was applied to a segment
    // SEGMENT_REMOVED: 'SEGMENT_REMOVED', // An IDed segment was removed
    // SEGMENT_INSERTED_INTO_PAGE: 'SEGMENT_INSERTED_INTO_PAGE', // A segment was inserted at the end of an IDed page
    // SEGMENT_LABEL_UPDATED: 'SEGMENT_LABEL_UPDATED', // A segment label was updated

    // //////////////////////////////////////////////////////////////////////////////////////////// //
    // //////////////////////////////////////////////////////////////////////////////////////////// //
    // ///////////////////////////////////////// TYPIFIED ///////////////////////////////////////// //
    // //////////////////////////////////////////////////////////////////////////////////////////// //
    // //////////////////////////////////////////////////////////////////////////////////////////// //
    STATE_UPDATED: 'WIZARD_STATE_UPDATED',
    TEMPLATE_ID_SET: 'WIZARD_TEMPLATE_ID_SET',
    TEMPLATE_ID_RESET: 'WIZARD_TEMPLATE_ID_RESET',
    TITLE_UPDATED: 'WIZARD_TITLE_UPDATED',
    // ============================================================================================= //
    // ======================================= QUESTIONNAIRE ======================================= //
    // ============================================================================================= //
    QUESTIONNAIRE: {
      NAVIGATED_FORWARD: 'WIZARD_QUESTIONNAIRE_NAVIGATED_FORWARD',
      NAVIGATED_BACKWARD: 'WIZARD_QUESTIONNAIRE_NAVIGATED_BACKWARD',
      NAVIGATED_TO: 'WIZARD_QUESTIONNAIRE_NAVIGATED_TO',
    },
    // ============================================================================================= //
  },
} as const

type ActionTypes = typeof ACTION_TYPES
type StoreSegmentActionTypes = ValuesOf<ActionTypes>
//@ts-ignore
type UnravelStoreSegmentActionTypes<T> = [T extends string ? T : TupleToUnion<UnravelStoreSegmentActionTypes<ValuesOf<T>>>]
type AllActionTypes = UnravelStoreSegmentActionTypes<StoreSegmentActionTypes>
type ActionType = TupleToUnion<AllActionTypes>
export type Action = { type: ActionType; payload?: Record<keyof any, unknown> | unknown }

export default ACTION_TYPES
