import React, { FunctionComponent, useMemo } from 'react'

import { CUSTOM_TEXT_SPLIT, LOCATION_TYPES, OptionValueTypeUnionType, extractPropertiesFromCustomText } from '___types'
import { Paragraph } from './Paragraph'
import { useSegmentsMarker } from '../customHooks'
import Marker from '../Marker'
import Segment from '../Segment'
import { WizardLayoutRightPaneEditorMarkerSegmentsProps, wizardLayoutRightPaneEditorMarkerSegmentsClasses as classes } from '../../../..'
import { getFormattedAnswerValue } from '___store/storeSegments/wizard/typified/helpers'

// WARNING!
// SEGMENTS MARKERS SPANNING MULTIPLE PAGES MIGHT HAVE THE SAME ID! - fix if the issue arises!

export const Segments: FunctionComponent<WizardLayoutRightPaneEditorMarkerSegmentsProps> = React.memo(({ id, parent }) => {
  const { contentIds, keep, replace, start, end, contentCustomStyle, contentStyles, markerNavigate } = useSegmentsMarker(id, parent)

  const replaceSegments = useMemo(() => {
    if (!(start && replace)) return null
    return replace.split(CUSTOM_TEXT_SPLIT).map((replaceString, i) => {
      const properties = extractPropertiesFromCustomText(replaceString, 'markerReplace')
      const replacementText = properties.type ? getFormattedAnswerValue(properties.type as OptionValueTypeUnionType, properties.value) : replaceString
      if (replacementText === undefined || replacementText === null || Number.isNaN(replacementText as number)) return null
      return (
        <Paragraph
          key={`SegmentsMarker-${id}-list-paragraph-${i}`}
          id={`${id}-${i}`}
          customStyle={contentCustomStyle}
          styles={contentStyles}
          text={String(replacementText)}
        />
      )
    })
  }, [start, replace, id, contentCustomStyle, contentStyles])

  const render = useMemo(() => {
    if (replaceSegments) return replaceSegments
    return contentIds?.map(id => {
      if (id.slice(0, 7) === 'marker:') return <Marker key={id} id={id.slice(7)} type={LOCATION_TYPES.SEGMENTS} parent={parent} />
      return <Segment key={id} id={id} />
    })
  }, [replaceSegments, contentIds, parent])

  return (
    <div
      id={`${start ? ';' : ''}${id}${end ? ';' : ''}`}
      className={classes.wrapper}
      data-start={start || undefined}
      data-end={end || undefined}
      data-discard={!keep || undefined}
      data-clickable={typeof markerNavigate === 'function' ? true : undefined}
      onClick={typeof markerNavigate === 'function' ? () => markerNavigate() : undefined}
    >
      {keep === false ? null : render}
    </div>
  )
})

Segments.displayName = 'WizardLayout-RightPane-Editor-Marker-Segments'

export default Segments
