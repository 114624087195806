import React, { useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { VIEWPORT_SIZE, className } from './index'

const Company = React.memo(props => {
  const { strokeWidth = 8, rx = 5 } = props
  const maskId = useMemo(uuid, [])

  return (
    <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
      <defs>
        <mask id={maskId}>
          <rect x="0" y="0" width={`${VIEWPORT_SIZE}`} height={`${VIEWPORT_SIZE}`} stroke="none" fill="white" rx={`${rx}`}></rect>
          <rect x="10" y="15" width="50" height="70" stroke="black" strokeWidth={`${strokeWidth}`} fill="black" rx={`${rx}`}></rect>
        </mask>
      </defs>
      <rect x="10" y="15" width="50" height="70" stroke="currentColor" strokeWidth={`${strokeWidth}`} fill="none" rx={`${rx}`}></rect>
      <rect x="20" y="70" width="30" height="15" stroke="currentColor" strokeWidth={`${(strokeWidth * 2) / 3}`} fill="none" rx={`${rx}`}></rect>
      <rect x="20" y="25" width="10" height="10" stroke="none" fill="currentColor" />
      <rect x="40" y="25" width="10" height="10" stroke="none" fill="currentColor" />
      <rect x="20" y="45" width="10" height="10" stroke="none" fill="currentColor" />
      <rect x="40" y="45" width="10" height="10" stroke="none" fill="currentColor" />
      <rect
        x="10"
        y="30"
        width="75"
        height="55"
        stroke="currentColor"
        strokeWidth={`${(strokeWidth * 2) / 3}`}
        fill="none"
        rx={`${rx}`}
        mask={`url(#${maskId})`}
      />
      <rect x="67.5" y="40" width="10" height="10" stroke="none" fill="currentColor" />
      <rect x="67.5" y="60" width="10" height="10" stroke="none" fill="currentColor" />
    </svg>
  )
})

export { Company }
export default Company
