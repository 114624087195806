import React, { FunctionComponent, Dispatch, SetStateAction, RefCallback, useState, useCallback } from 'react'
import useStore, {
  WizardTitleSelector,
  UpdateWizardTitleAction,
  NavigateQuestionnaireForwardAction,
  WizardAnswersSelector,
  WizardTemplateIdSelector,
} from '___store'

import { DOCUMENTS_FILTERS } from '___types'
import { CreateVariables } from '___queries/documents'
import { useDocument } from '___hooks'
import { Button, Input, CASUS_INPUT_TYPES } from 'components/CasusComponents'
import { WizardLayoutLeftPaneQuestionnaireDocumentRenameProps, wizardLayoutLeftPaneQuestionnaireDocumentRenameClasses as classes } from '../../..'

type UseStoreHookResultType = {
  wizardTemplateId: WizardTemplateIdSelector
  wizardTitle: WizardTitleSelector
  wizardAnswers: WizardAnswersSelector
  updateWizardTitle: UpdateWizardTitleAction
  navigateQuestionnaireForward: NavigateQuestionnaireForwardAction
}

const QuestionnaireDocumentRename: FunctionComponent<WizardLayoutLeftPaneQuestionnaireDocumentRenameProps> = React.memo(
  ({ isLoading, isFetching }) => {
    const { wizardTemplateId, wizardTitle, wizardAnswers, updateWizardTitle, navigateQuestionnaireForward } = useStore(
      'selectWizardTemplateId',
      'selectWizardTitle',
      'selectWizardAnswers',
      'updateWizardTitle',
      'navigateQuestionnaireForward'
    ) as UseStoreHookResultType
    const { create } = useDocument()
    const [input, setInput]: [HTMLPreElement | undefined, Dispatch<SetStateAction<HTMLPreElement | undefined>>] = useState()

    const inputRef: RefCallback<HTMLPreElement | undefined> = useCallback(node => node && setInput(node), [])
    // const inputHandler = useCallback(event => updateWizardTitle(event.target.innerText || ''), [updateWizardTitle])
    const confirmHandler = useCallback(() => {
      updateWizardTitle(input?.innerText || '')
      const payload = {
        document: { templateId: wizardTemplateId, name: input?.innerText || '', answers: wizardAnswers },
        category: DOCUMENTS_FILTERS.MINE,
      } as CreateVariables
      create(payload)
      navigateQuestionnaireForward()
    }, [updateWizardTitle, input, create, wizardTemplateId, wizardAnswers, navigateQuestionnaireForward])

    return (
      <div className={classes.wrapper} data-loading={isLoading || isFetching || undefined}>
        <span className={classes.texts.primary}>Congrats! Time to give your document a name.</span>
        <Input
          ref={inputRef}
          className={classes.input}
          type={CASUS_INPUT_TYPES.MULTILINE}
          tertiary
          placeholder={wizardTitle || 'Document title goes here...'}
          debouncedInput={300}
          autoselect
          // showActionButtons={false}
          // onInput={inputHandler}
          onConfirm={confirmHandler}
        />
        <div className={classes.actions.wrapper}>
          <Button tertiary onClick={confirmHandler}>
            Save
          </Button>
        </div>
      </div>
    )
  }
)

QuestionnaireDocumentRename.displayName = 'WizardLayout-Questionnaire-DocumentRename'

export default QuestionnaireDocumentRename
