import React from 'react'

import { className } from './index'

const RadioButton = React.memo(({ active = false }) => (
  <svg
    className={className}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="none"
  >
    <circle cx="50" cy="50" r="35" stroke="currentColor" strokeWidth="10" strokeLinecap="round" fill="none" />
    <circle cx="50" cy="50" r="20" fill={active ? 'currentColor' : 'none'} />
  </svg>
))

export { RadioButton }
export default RadioButton
