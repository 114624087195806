import React from 'react'

import { className } from './index'

const Docx = React.memo(() => (
  <svg className={className} width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Document-creation" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Dashboard---My-Documents---Download" transform="translate(-480.000000, -3819.000000)">
        <g id="Group-14" transform="translate(456.000000, 3707.000000)">
          <g id="ico-docx" transform="translate(24.000000, 112.000000)">
            <rect id="Rectangle" fill="#3A5EB2" x="0" y="0" width="21" height="21" rx="2"></rect>
            <text id="W" font-family="Heebo-Bold, Heebo" font-size="13" font-weight="bold" letter-spacing="0.173333333" fill="#FFFFFF">
              <tspan x="5" y="15">
                W
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </g>
  </svg>
))

export default Docx

