import React, { FunctionComponent, useMemo } from 'react'

import { OptionGroupSelectUnionType } from '___types'
import { Option } from '.'
import { WizardLayoutLeftPaneQuestionnaireOptionGroupProps, wizardLayoutLeftPaneQuestionnaireOptionGroupClasses as classes } from '../../..'

const QuestionnaireOptionGroup: FunctionComponent<WizardLayoutLeftPaneQuestionnaireOptionGroupProps> = React.memo(
  ({ optionGroup, singleGroup, indexOffset = 0, firstGroup }) => {
    const {
      // id,
      label,
      options,
      select,
    } = optionGroup || {}
    const isSpecial =
      Boolean(label) || (!singleGroup && select?.select === 'multi' && (select.minimum !== 0 || select.maximum !== options?.length)) || undefined
    const render = useMemo(
      () =>
        options?.map((option, i) => (
          <Option
            key={`${i}:${option?.id}`}
            option={option}
            index={indexOffset + i}
            singleOption={singleGroup && options.length === 1}
            select={select as unknown as OptionGroupSelectUnionType} // remove type conversion when select is fixed to be an object instead of a OptionGroupSelectUnionType string and change the prop to be {select.select}
            firstOption={firstGroup && i === 0}
          />
        )),
      [options, indexOffset, singleGroup, select, firstGroup]
    )
    return (
      <div className={classes.wrapper} data-special={isSpecial} data-label={label || undefined}>
        {render}
      </div>
    )
  }
)

QuestionnaireOptionGroup.displayName = 'WizardLayout-Questionnaire-QuestionGroup-Question'

export default QuestionnaireOptionGroup
