import { DocumentsFilter } from '___types'
import { AppState } from '.'

export type SetDocumentsFilterPayload = DocumentsFilter
const setDocumentsFilter = (state: AppState, payload: SetDocumentsFilterPayload): AppState =>
  !payload || payload === state.documents.filter ? state : Object.assign(state.documents, { filter: payload }) && Object.assign({}, state)

export type SetSelectedDocumentIdPayload = string
const setSelectedDocumentId = (state: AppState, payload: SetSelectedDocumentIdPayload): AppState =>
  !payload || payload === state.documents.selected ? state : Object.assign(state.documents, { selected: payload }) && Object.assign({}, state)

const deselectAllDocuments = (state: AppState): AppState =>
  !state.documents.selected ? state : Object.assign(state.documents, { selected: null }) && Object.assign({}, state)

export { setDocumentsFilter, setSelectedDocumentId, deselectAllDocuments }
