import { DOCUMENTS_FILTERS, DocumentsFilter, MappedDocumentsFilter, Answer, Answers, IDocument } from '___types'

import API from './api'

export const documentListPerPage = 10

class Documents extends API {
  public constructor() {
    super('documents/')
  }

  public getDocumentList = async (filter?: MappedDocumentsFilter | null, lastDocumentId?: string | null): Promise<IDocument[] | undefined> => {
    if (!filter) return
    const params = new URLSearchParams({ limit: String(documentListPerPage) })
    if (lastDocumentId) params.set('startAfter', lastDocumentId)
    const { data: { data: { documents = [] } = {} } = {} } = await this.get(`/list/${filter}?${String(params)}`, {}, {}, 'v1')
    return documents as IDocument[]
  }

  public getDocument = async (id?: string | null): Promise<IDocument | undefined> => {
    if (!id) return
    const res = await this.get(id)
    // /////////////////////////////////////////////////////////////////////////// //
    // ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    // /////////////////////////////////////////////////////////////////////////// //
    // remove parsing when response fixes v3Answers => answers
    const document = res.data.data
    const parsedAnswers = (document.v3Answers
      ?.map((answer: any) => {
        if (!answer.questionId) return null
        const parsed = Object.assign({}, answer, { id: answer.questionId })
        delete parsed.questionId
        return parsed
      })
      .filter((a: Answer | null) => a) || []) as Answers
    const parsedDocument = Object.assign(document, { answers: parsedAnswers || [] })
    delete parsedDocument.v3Answers
    return parsedDocument as IDocument
    // /////////////////////////////////////////////////////////////////////////// //
    // /////////////////////////////////////////////////////////////////////////// //
    // /////////////////////////////////////////////////////////////////////////// //
    // return res.data.data as IDocument
  }

  public createDocument = async (document: IDocument, category: DocumentsFilter = DOCUMENTS_FILTERS.MINE): Promise<IDocument> => {
    // /////////////////////////////////////////////////////////////////////////// //
    // ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    // /////////////////////////////////////////////////////////////////////////// //
    // remove parsing when response fixes v3Answers => answers
    const payload = Object.assign({
      new: true,
      templateId: document.templateId,
      name: document.name,
      v3Answers: document.answers.map(({ id, values }) => ({ questionId: id, values })),
      category: [],
    })
    const res = await this.post('', payload)
    // /////////////////////////////////////////////////////////////////////////// //
    // /////////////////////////////////////////////////////////////////////////// //
    // /////////////////////////////////////////////////////////////////////////// //
    // const res = await this.post('', document)
    return res.data.data as IDocument
  }

  public updateDocument = async (document: IDocument): Promise<IDocument> => {
    const res = await this.patch(document.id, document)
    return res.data.data
  }
}

export const documentsAPI = new Documents()

export default documentsAPI
