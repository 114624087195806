import { isObject } from 'utilities/helpers'
import { CASUS_KEYSTRINGS } from 'Wizard/constants'

import { polluteState, updateTitle, removeConfiguration } from './general'

import {
  evaluateDependencies,
  addNewQuestionLayoutGroupSeparator,
  addNewQuestionLayoutGroup,
  updateQuestionLayoutGroup,
  unpackQuestionLayoutGroup,
  removeQuestionLayoutGroup,
  // addNewQuestion,
  updateQuestion,
  // removeQuestion,
  assignQuestion,
  unassignQuestion,
  addNewQuestionOption,
  updateQuestionOption,
  // updateAdvancedQuestionConfiguration,
  addNewConditionalRule,
  updateConditionalRule,
  removeConditionalRule,
  // makeIntoSubQuestion,
  removeSubQuestionStatus,
  updateQuestionVisibility,
  updateQuestionRuleLogic,
  // addNewLocation,
  // removeLocation,
  // unassignMarker,
} from './template-creation-old'

import {
  goToNextQuestion,
  goToPreviousQuestion,
  answerWithOption,
  unanswerQuestion,
  unanswerOption,
  evaluateMarker,
  evaluateAllMarkers,
} from './document-generation'

import {
  replaceParagraphContent,
  addNewParagraphStyles,
  removeParagraphStyles,
  toggleParagraphStyle,
  applyParagraphCustomStyle,
  insertParagraphAbove,
  insertParagraphBelow,
  removeParagraph,
  applyParagraphNumbering,
} from './editor-content'

// //////////////////////////////////////////////////////////////////// //
// ///////////////////////////// READABLE ///////////////////////////// //
// //////////////////////////////////////////////////////////////////// //
import {
  addNewQuestion,
  removeQuestion,
  updateAdvancedQuestionConfiguration,
  makeIntoSubQuestion,
  addNewOptionGroup,
  updateOptionGroup,
  removeOptionGroup,
  addNewOption,
  updateOption,
  removeOption,
  unassignMarker,
  removeLocation,
  assignMarker,
  addNewLocation,
} from './template-creation'

export {
  addNewQuestion,
  removeQuestion,
  updateAdvancedQuestionConfiguration,
  makeIntoSubQuestion,
  addNewOptionGroup,
  updateOptionGroup,
  removeOptionGroup,
  addNewOption,
  updateOption,
  removeOption,
  unassignMarker,
  removeLocation,
  assignMarker,
  addNewLocation,
}
// //////////////////////////////////////////////////////////////////// //
// //////////////////////////////////////////////////////////////////// //
// //////////////////////////////////////////////////////////////////// //

const deepAssign = (base, ...toApplyObjectArray) =>
  toApplyObjectArray.reduce(
    (result, toApply) =>
      Object.entries(toApply).reduce(
        (acc, [k, v]) => Object.assign(acc, { [k]: isObject(acc[k]) && isObject(v) ? deepAssign(acc[k], v) : v }),
        result
      ),
    base
  )
const fullAssign = (...objectArray) => objectArray.reverse().reduce((acc, cur) => Object.assign(cur, acc))
const filterObjectFields = (object, ...keys) =>
  Object.entries(object).reduce(
    ([res, ignoreList], [k, v]) => [ignoreList.includes(k) || v === undefined ? res : Object.assign(res, { [k]: v }), ignoreList],
    [{}, keys.reduce((a, c) => a.concat(c), [])]
  )[0]
const findWithIndex = (array, test, found = undefined, index = -1) =>
  (array?.some((entry, i) => test(entry) && ((found = entry) || true) && ((index = i) || true)) || true) && [found, index]
const generateKnownIds = (payload = {}) =>
  Object.entries(payload).reduce((acc, [key, value]) => (key.slice(-2) === 'Id' ? Object.assign(acc, { [`${key.slice(0, -2)}s`]: value }) : acc), {})
const paramCheck = (object, nestArray, test, knownIds) => {
  const validObject = typeof object === 'object' && !Array.isArray(object) && object !== null
  const validNestArray = nestArray.length && typeof nestArray[0] === 'string'
  const validTest = typeof test === 'function'
  const validKnownIds = typeof knownIds === 'object' && !Array.isArray(knownIds)
  return validObject && validNestArray && Array.isArray(object[nestArray[0]]) && validTest && (!knownIds || validKnownIds)
}
const extractFromNestedStructure = (object, nestArray, test, knownIds, nestedResult = undefined) => {
  if (!paramCheck(object, nestArray, test, knownIds)) return []
  const array = object[nestArray[0]]
  const knownId = knownIds && knownIds[nestArray[0]]
  const check =
    (knownId && (entry => entry.id === knownId)) ||
    (nestArray.length === 1 && test) ||
    (entry => (nestedResult = extractFromNestedStructure(entry, nestArray.slice(1), test, knownIds)) && nestedResult[0])
  const found = findWithIndex(array, check)
  if (nestArray.length === 1) return (found[0] && found) || []
  if (knownId) return (found[0] && extractFromNestedStructure(found[0], nestArray.slice(1), test, knownIds).concat(found)) || []
  return (found[0] && (nestedResult || []).concat(found)) || []
}

const parseUserInputValue = val =>
  // eslint-disable-next-line no-useless-escape
  [...val.matchAll(new RegExp(`(^${CASUS_KEYSTRINGS.input}:)([a-zA-Z0-9\-]+):(.*)`, 'g'))][0]
const conditionalOutcomeTest = (state, rule) => {
  const { questionId, optionId, condition = {} } = rule
  const { needed } = condition
  const { value = [] } = state.answers.find(a => a.id === questionId) || {}
  const values = typeof value === 'string' ? [value] : value
  const answeredOptions = values.map(val => {
    const parsedValue = parseUserInputValue(val)
    return parsedValue ? parsedValue[2] : val
  })
  if (optionId) return answeredOptions.includes(optionId) === needed
  return true // Instead of "true" add non optionId related rules (e.g. replacement question value rules)
}
const test = state => rule => conditionalOutcomeTest(state, rule)
const evaluateQuestionConditionalOutcome = (state, id) => {
  const { advanced: { conditionalFunctionality = 'show', logic = 'and', rules = [] } = {} } = state.questions?.find(q => q.id === id) || {}
  return rules.length ? (conditionalFunctionality === 'show') === (logic === 'and' ? rules.every(test(state)) : rules.some(test(state))) : true
}

export { polluteState, updateTitle, removeConfiguration }

export {
  // /////////////////////////////// //
  // /////////// GENERAL /////////// //
  // /////////////////////////////// //
  deepAssign,
  fullAssign,
  filterObjectFields,
  findWithIndex,
  generateKnownIds,
  extractFromNestedStructure,
  //
  evaluateQuestionConditionalOutcome,
  // /////////////////////////////// //
  // ///////// DEPENDENCIES //////// //
  // /////////////////////////////// //
  evaluateDependencies,
  // /////////////////////////////// //
  // ///// QUESTION GENERATION ///// //
  // /////////////////////////////// //
  addNewQuestionLayoutGroupSeparator,
  addNewQuestionLayoutGroup,
  updateQuestionLayoutGroup,
  unpackQuestionLayoutGroup,
  removeQuestionLayoutGroup,
  // /////////////////////////////// //
  // ////////// QUESTIONS ////////// //
  // /////////////////////////////// //
  // addNewQuestion,
  updateQuestion,
  // removeQuestion,
  assignQuestion,
  unassignQuestion,
  // /////////////////////////////// //
  // /////////// OPTIONS /////////// //
  // /////////////////////////////// //
  addNewQuestionOption,
  updateQuestionOption,
  // /////////////////////////////// //
  // /////////////////////////////// //
  // ////// ADVANCED QUESTION ////// //
  // /////////////////////////////// //
  // updateAdvancedQuestionConfiguration,
  addNewConditionalRule,
  updateConditionalRule,
  removeConditionalRule,
  // makeIntoSubQuestion,
  removeSubQuestionStatus,
  updateQuestionVisibility,
  updateQuestionRuleLogic,
  // /////////////////////////////// //
  // addNewLocation,
  // removeLocation,
  // unassignMarker,
}

export { goToNextQuestion, goToPreviousQuestion, answerWithOption, unanswerQuestion, unanswerOption, evaluateMarker, evaluateAllMarkers }

export {
  replaceParagraphContent,
  addNewParagraphStyles,
  removeParagraphStyles,
  toggleParagraphStyle,
  applyParagraphCustomStyle,
  insertParagraphAbove,
  insertParagraphBelow,
  removeParagraph,
  applyParagraphNumbering,
}
