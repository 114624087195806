import React from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import './styles.scss'
import { SidebarItem } from './SidebarItem'

interface Filter {
  id: string
  name: string
  url: string
}

const SidebarFolderTree: React.FC = () => {
  const { location, push } = useHistory()
  const { t } = useTranslation()

  const isCurrent = (filter: Filter) => location.pathname + location.search === filter.url

  const filters: Array<Filter> = [
    {
      id: 'all',
      name: t('all'),
      url: `/${t('signatures')}`,
    },
    {
      id: 'open',
      name: t('pending'),
      url: `/${t('signatures')}?filter=OPEN`,
    },
    {
      id: 'declined',
      name: t('declined'),
      url: `/${t('signatures')}?filter=DECLINED`,
    },
    {
      id: 'signed',
      name: t('signed'),
      url: `/${t('signatures')}?filter=SIGNED`,
    },
  ]

  const handleFilter = (filter: any): void => {
    push(filter.url)
  }

  return (
    <div>
      {filters.map(filter => (
        <SidebarItem key={filter.id} filter={filter} handleItemClick={() => handleFilter(filter)} isCurrent={isCurrent(filter)} />
      ))}
    </div>
  )
}

export default SidebarFolderTree

