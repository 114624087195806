import React from 'react'

import { classes } from '../index'

export const Footer = React.memo(props => {
  const {
    components: {
      footer: { wrapper: wrapperClass },
    },
  } = classes
  return <div className={wrapperClass}>{}</div>
})

Footer.displayName = 'Footer'

export default Footer
