import { Icon, Loader } from 'components/common'
import React from 'react'
import { Actions } from './components/Actions/Actions'
import { Description } from './components/Description/Description'
import { Insights } from './components/Insights/Insights'
import { Header } from './components/Header/Header'
import { Informations } from './components/Informations/Informations'
import { SharedWith } from './components/SharedWith/SharedWith'
import { Signatures } from './components/Signatures/Signatures'
import { useDetails } from './provider/DetailsProvider'
import { useUser } from 'queries/user/useUser'
import './style.scss'
import { PaywallSignatures } from './components/Signatures/PaywallSignatures'
import { getSubscriptionStatus } from 'utils'

const classes = {
  wrapper: 'details-sidebar',
  container: 'details-sidebar-container',
  closeButton: 'close-button',
  divider: 'details-sidebar-divider',
}

export const DetailsSidebar = () => {
  const { data, isLoading, isError, selectedId, clear } = useDetails()
  let { type } = useDetails()
  const user = useUser()

  const tenant = window.location.host.split('.')[0]

  if (data && data.type === 'pdf') {
    type = 'pdf'
  }

  const content = () => {
    if (isLoading || !data) {
      return (
        <div>
          <Loader />
        </div>
      )
    } else if (isError) {
      return <div>Error</div>
    } else {
      return (
        <div className={classes.container}>
          <Header type={type} name={data.name} id={data.id} />
          <Actions data={data} type={type} />
          <Informations type={type} createdAt={data.created?._seconds * 1000} editedAt={data.edited?._seconds * 1000} author={data.author} />
          <div className={classes.divider} />
          {type === 'template' ? (
            <>
              <Description id={data.id} templateChars={data.characteristics} />
              <div className={classes.divider} />
            </>
          ) : null}
          <SharedWith id={data.id} type={type} sharedWith={data.sharedWith} />
          <div className={classes.divider} />
          {(type === 'document' || type === 'pdf' || type === 'signature') && (tenant !== 'sbv' || getSubscriptionStatus(user)) ? (
            <>
              <Signatures type={type} documentId={data.id} signatureRequest={data.signatureRequest} />
              <div className={classes.divider} />
            </>
          ) : (
            <PaywallSignatures />
          )}
          {false && user.privacy.gptServicesEnabled ? (
            <Insights documentId={data.id} parties={data.parties} templateId={data.templateId} answers={data.answers} enabled={type === 'template'} />
          ) : null}

          <button className={classes.closeButton} onClick={() => clear()}>
            <Icon iconName="close" />
          </button>
        </div>
      )
    }
  }
  return selectedId ? <div className={classes.wrapper}>{content()}</div> : null
}

