import SubscriptionAdPanel from './SubscriptionAdPanel'

const classes = {
  wrapper: 'Homepage-dashboard-grid-ad-wrapper',
  title: 'Homepage-dashboard-grid-ad-title',
  image: 'Homepage-dashboard-grid-ad-image',
  button: { wrapper: 'Homepage-dashboard-grid-ad-button-wrapper', item: 'Homepage-dashboard-grid-ad-button-item' },
}

export { classes }

export default SubscriptionAdPanel

