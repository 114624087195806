import React, { useState } from 'react'
import './style.scss'
import { useTranslation } from 'react-i18next'
import SignatureModal from 'components/SignatureModal'
import Button from 'components/ButtonGroup'

const classes = {
  title: 'title',
  noSignatureContainer: 'no-signature-container',
  requestButton: 'request-button',
  signaturesContainer: 'signatures-container',
  listContainer: 'list-container',
  listImageContainer: 'list-image-container',
  listImage: 'list-image',
  listPerson: 'list-person',
  listImagePlus: 'list-image-plus',
  listPersonSignatureTag: 'list-person-signature-tag',
  signaturesProgressBar: {
    wrapper: 'Signatures-ProgressBar-wrapper',
    empty: 'Signatures-ProgressBar-Empty-wrapper',
    bar: 'Signatures-ProgressBar-Bar-wrapper',
    text: 'Signatures-ProgressBar-Text-wrapper',
  },
}

const getSignedCount = signatures => {
  return signatures.filter(sign => sign.status_code === 'SIGNED').length
}

export const Signatures = ({ type, signatureRequest, documentId, storageId }) => {
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState(null)

  return (
    <div className="details-sidebar-section-container">
      {!signatureRequest || signatureRequest.signatures.length === 0 ? (
        <div className={classes.noSignatureContainer}>
          <h3 className={classes.title}>{t('signature_request')} </h3>

          <Button
            onClick={e => {
              setAnchorEl(e.currentTarget)
            }}
            className={classes.requestButton}
          >
            Request
          </Button>
        </div>
      ) : (
        <div className={classes.signaturesContainer}>
          <h3 className={classes.title}>{t('signature_request')} </h3>
          <div
            className={classes.signaturesProgressBar.wrapper}
            onClick={e => {
              setAnchorEl(e.currentTarget)
            }}
          >
            <div className={classes.signaturesProgressBar.empty}>
              <span className={classes.signaturesProgressBar.text}>Signers signed</span>
              <span className={classes.signaturesProgressBar.text}>
                {getSignedCount(signatureRequest.signatures)}/{signatureRequest.signatures.length}
              </span>
              <div
                className={classes.signaturesProgressBar.bar}
                style={{
                  width:
                    (getSignedCount(signatureRequest.signatures) / signatureRequest.signatures.length) * 100 +
                    '%',
                }}
              />
            </div>
          </div>
        </div>
      )}
      <SignatureModal
        close={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        type={type}
        signDocumentId={documentId}
        storageId={storageId}
        signatureRequest={signatureRequest}
        isView={!!signatureRequest}
      />
    </div>
  )
}

