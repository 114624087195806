import ACTION_TYPES from '../../actionTypes'
import { SetDocumentsFilterPayload, SetSelectedDocumentIdPayload, SetSelectedTemplateIdPayload, SetTemplatesFilterPayload } from './helpers'

export type SetDocumentsFilterAction = (payload: SetDocumentsFilterPayload) => void
export type SetSelectedDocumentIdAction = (payload: SetSelectedDocumentIdPayload) => void
export type DeselectDocumentsAction = () => void

export type SetTemplatesFilterAction = (payload: SetTemplatesFilterPayload) => void
export type SetSelectedTemplateIdAction = (payload: SetSelectedTemplateIdPayload) => void
export type DeselectTemplatesAction = () => void

const actionCreators = {
  setDocumentsFilter: (payload: SetDocumentsFilterPayload) => ({ type: ACTION_TYPES.APP.DOCUMENTS_FILTER_SET, payload }),
  setSelectedDocumentId: (payload: SetSelectedDocumentIdPayload) => ({ type: ACTION_TYPES.APP.DOCUMENT_SELECTED, payload }),
  deselectDocuments: () => ({ type: ACTION_TYPES.APP.DOCUMENTS_DESELECTED }),

  setTemplatesFilter: (payload: SetTemplatesFilterPayload) => ({ type: ACTION_TYPES.APP.TEMPLATES_FILTER_SET, payload }),
  setSelectedTemplateId: (payload: SetSelectedTemplateIdPayload) => ({ type: ACTION_TYPES.APP.TEMPLATE_SELECTED, payload }),
  deselectTemplates: () => ({ type: ACTION_TYPES.APP.TEMPLATES_DESELECTED }),
}

export { actionCreators }
export default actionCreators
