import React, { useState, useEffect } from 'react'

import { debounceFunction } from 'utilities/helpers'
import { MarkSegmentsConfirmButton, MarkTextConfirmButton, SegmentsBackground } from './Selection'
import { locationHandler } from '../selection'
import { classes } from '..'

const noRange = { parent: null, range: [] }

const SelectionControls = React.memo(() => {
  const [segments, setSegments] = useState(noRange)
  const [text, setText] = useState(noRange)

  useEffect(() => {
    const editor = document.getElementsByClassName(classes.wrapper)[0]
    if (!editor) return
    editor.dataset.selectingMultiple = segments.parent && !text.parent
    return () => (editor.dataset.selectingMultiple = !segments.parent || text.parent)
  }, [segments, text])

  useEffect(() => {
    let timeout = null
    const debounced = debounceFunction(() => locationHandler(setSegments, setText, noRange))
    const eventHandler = () => (timeout = debounced())
    document.addEventListener('selectionchange', eventHandler)
    return () => {
      clearTimeout(timeout)
      document.removeEventListener('selectionchange', eventHandler)
    }
  }, [setSegments])

  return (
    <>
      {segments.parent ? (
        <>
          <SegmentsBackground segments={segments} />
          <MarkSegmentsConfirmButton segments={segments} />
        </>
      ) : null}
      {text.parent ? <MarkTextConfirmButton text={text} /> : null}
    </>
  )
})

SelectionControls.displayName = 'Wizard-Editor-SelectionControls'

export default SelectionControls
