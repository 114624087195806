import React, {
  FunctionComponent,
  // Children,
  useMemo,
} from 'react'

import { Caret } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import { DropdownProps, classes } from '.'
// import { displayName } from './Dropdown.Option'

export const Dropdown: FunctionComponent<DropdownProps> = React.memo(props => {
  const {
    className: cn = '',
    emphasized,
    primary,
    secondary,
    tertiary,
    label = '',
    caretRight = false,
    replaceCaret = null,
    // children = []
  } = props

  //   const options = useMemo(
  //     () =>
  //       Children.map(children, child => child.type?.displayName === displayName && { id: child.props?.id, label: child.props?.label }).filter(o => o),
  //     [children]
  //   )

  const className = useMemo(() => [classes.wrapper, cn].filter(c => c).join(' '), [cn])
  const emphasis = ['emphasized', 'primary', 'secondary', 'tertiary'].find(prop => props[prop as keyof DropdownProps])

  return (
    <div className={className} data-emphasis={emphasis} data-caret-right={caretRight || undefined}>
      <Button className={classes.button} onClick={() => {}} emphasized={emphasized} primary={primary} secondary={secondary} tertiary={tertiary}>
        {replaceCaret || <Caret />}
        {label}
      </Button>
      <div className={classes.options.wrapper}></div>
    </div>
  )
})

Dropdown.displayName = 'Casus-Components-Dropdown'

export default Dropdown
