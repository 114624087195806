import NewDocumentPanel from './NewDocumentPanel'
import EmptyTemplates from './EmptyTemplates'

import './style.scss'

const classes = {
  wrapper: 'Homepage-dashboard-grid-newDocument-wrapper',
  blurBg: 'Homepage-dashboard-grid-newDocument-blurBg',
  title: 'Homepage-dashboard-grid-newDocument-title',
  filter: {
    wrapper: 'Homepage-dashboard-grid-newDocument-filter-wrapper',
    text: 'Homepage-dashboard-grid-newDocument-filter-text',
  },
  empty: {
    wrapper: 'Homepage-dashboard-grid-panel-empty-wrapper',
    icon: 'Homepage-dashboard-grid-panel-empty-icon',
    text: 'Homepage-dashboard-grid-panel-empty-text',
  },
  templatesList: {
    wrapper: 'Homepage-dashboard-grid-newDocument-templatesList-wrapper',
    empty: 'Homepage-dashboard-grid-newDocument-templatesList-empty',
    emptyText: 'Homepage-dashboard-grid-newDocument-templatesList-emptyText',
  },
}

export { classes, EmptyTemplates }

export default NewDocumentPanel

