import React from 'react'
import { Redirect } from 'react-router'
import { Switch, Route } from 'react-router-dom'

import SelectTemplate from './SelectTemplate/SelectTemplate.page'
import GenerateDocument from './GenerateDocument/GenerateDocument.page'
import { documentsRoutes } from 'constants/routes'
import { useTranslation } from 'react-i18next'

const DocumentCreate: React.FC = () => {
  const {t} = useTranslation()
  return (
    <Switch>
      <Route exact path={`/${t('document')}/${t('new')}/:templateId`} component={GenerateDocument} />
      <Route exact path={`/${t('document')}/${t('new')}`} component={SelectTemplate} />
      <Redirect to={t(documentsRoutes.main)} />
    </Switch>
  )
}

export default DocumentCreate
