import React, { FunctionComponent, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Document, HorizontalLines, Signature, Squares, Team, Template } from 'assets/svgIconComponents'
import { HeaderNavigationProps } from '.'
import { headerClasses as classes } from '../../'
import { useTranslation } from 'react-i18next'

export const Navigation: FunctionComponent<HeaderNavigationProps> = React.memo(({ teamDisabled, templateDisabled }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const isDev = useMemo(() => location.pathname.includes('/dev'), [location])

  const navButtons = [
    {
      label: 'Dashboard',
      icon: Squares,
      path: `/`,
      disabled: false,
    },
    {
      label: t('u_documents'),
      icon: Document,
      path: `${isDev ? '/dev' : ''}/${t('documents').toLowerCase()}`,
      disabled: false,
    },
    {
      label: t('u_signatures'),
      icon: Signature,
      path: `${isDev ? '/dev' : ''}/${t('signatures').toLowerCase()}`,
      disabled: false,
    },
    {
      label: t('u_templates'),
      icon: Template,
      path: `${isDev ? '/dev' : ''}/${t('templates')}`,
      disabled: templateDisabled,
    },
    {
      label: t('u_team'),
      icon: Team,
      path: `${isDev ? '/dev' : ''}/team`,
      disabled: teamDisabled,
    },
  ]

  const dropdown = useMemo(() => {
    return (
      <ul className={classes.navDropdown.dropdownList}>
        {navButtons.map((item, index) =>
          item.disabled ? null : (
            <li
              key={`Header-Navigation-list-item-${index}`}
              className={classes.navDropdown.dropdownListItem}
              onClick={event => (event.target as HTMLElement).blur()}
            >
              <Link to={item.path}>
                <item.icon key={`Header-Navigation-list-item-icon-${index}`} />
                {item.label}
              </Link>
            </li>
          )
        )}
      </ul>
    )
  }, [navButtons])

  return (
    <div className={classes.navDropdown.wrapper}>
      <button className={classes.navDropdown.button} type="button" onClick={() => {}}>
        <HorizontalLines />
      </button>
      {dropdown}
    </div>
  )
})

Navigation.displayName = 'Header-Navigation'

export default Navigation

