import React, { FunctionComponent } from 'react'

import { SEGMENT_TYPES, ParagraphObject } from '___types'
import { useSegment, ComputedTableSegmentType } from './customHooks'
import { Paragraph, Table } from './Segment.Components'
import { WizardLayoutRightPaneEditorSegmentProps } from '../../..'

export const Segment: FunctionComponent<WizardLayoutRightPaneEditorSegmentProps> = React.memo(({ id }) => {
  const segment = useSegment(id)!
  const { type, customStyle, styles } = segment
  const { textChunks } = segment as ParagraphObject
  const { tableSections } = segment as ComputedTableSegmentType

  if (!type) return null
  if (type === SEGMENT_TYPES.PARAGRAPH) return <Paragraph id={id} customStyle={customStyle} styles={styles} textChunks={textChunks} />
  if (type === SEGMENT_TYPES.TABLE) return <Table id={id} customStyle={customStyle} styles={styles} tableSections={tableSections} />
  return null
})

Segment.displayName = 'WizardLayout-RightPane-Editor-Segment'

export default Segment
